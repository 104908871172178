<template>
  <div class="bar-wrapper cursor-pointer" @click="onBarClick">
    <div class="bar">
      <v-icon size="24" color="#D52819" class="mr-1 mr-md-3 bar-icons">
        mdi-email
      </v-icon>
      <span class="text">
        Please verify your email to gain full access to the XL functionality
      </span>
      <v-icon size="24" color="#D52819" class="ml-1 ml-md-3 bar-icons">
        mdi-arrow-right
      </v-icon>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "VerifyEmailBar",
  methods: {
    ...mapActions("AuthHelpers", ["isUserVerified"]),
    async onBarClick() {
      await this.isUserVerified(this.$store.state.user);
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-wrapper {
  //background: rgba(221, 34, 111, 0.2);
  background: rgb(245, 206, 223);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 5px;
  position: fixed;
  width: 100%;
  top: 59px;
  z-index: 9;

  .bar {
    text-align: center;
  }
  .text {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    color: #d52819;
    text-align: center;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #840037;
  }
  ::v-deep i {
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #840037;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .bar-wrapper {
    z-index: 175;
  }
}
@media #{map-get($display-breakpoints, 'xl-only')} {
  .bar-wrapper {
    top: 73px;
  }
}

@media screen and (max-width: 700px) {
  .bar-wrapper .text {
    font-size: 15px;
  }
}
@media screen and (max-width: 580px) {
  .bar-wrapper {
    padding: 0 5px;
    .text {
      font-size: 10px;
      line-height: 22px;
      padding: 0;
    }
  }
}
@media screen and (max-width: 400px) {
  .bar-wrapper {
    .text {
      font-weight: normal;
    }
  }
  .bar-icons {
    font-size: 14px !important;
  }
}
</style>
