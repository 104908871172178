import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default async (assetInfo) => {
  const mutation = `mutation editAssetOnNext(
        $currentStep: String,
        $id: String!,
        $caConfirmed:Boolean,
        $TOCConfirmed:Boolean,
        $BDAConfirmed:Boolean,
        $customCADoc: String,
      ) {
        editAsset(
          currentStep: $currentStep,
          id: $id,
          caConfirmed:$caConfirmed,
          TOCConfirmed: $TOCConfirmed,
          customCADoc: $customCADoc,
          BDAConfirmed:$BDAConfirmed,
        ) {
       ${ASSET}
        }
      }`;
  let variables = assetInfo;

  return API.graphql({
    query: mutation,
    variables,
  });
};
