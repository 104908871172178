import { API } from "aws-amplify";

export default async (assetInfo) => {
  assetInfo.privacy = assetInfo.privacy ? "private" : "public";

  const mutation = `mutation editWithoutNote(
        $id: String!,
        $type: AssetType!,
        $assetStatus: [String]!,
        $assetDescription: String,
        $name: String,
        $principalBalance: ValueAmountInput,
        $listingPrice: ValueAmountInput,
        $requireBidDeposit: Boolean,
        $bidDepositSum: ValueAmountInput,
        $bidDepositChannel: String,
        $contacts: [ContactInput]!,
        $address: AddressInput,
        $noteOverview: AssetNoteInput,
        $propertyOverview: PropertyInfoInput,
        $callForOffersPeriod: PeriodInput,
        $bestAndFinalPeriod: PeriodInput,
        $upbDate: AWSDateTime,
        $bestCashOffer: Boolean,
        $images: [String]
      ) {
        editAsset(
          id: $id
          type: $type
          assetStatus: $assetStatus,
          name: $name
          description: $assetDescription
          upbDate: $upbDate
          callForOffersPeriod: $callForOffersPeriod,
          bestAndFinalPeriod: $bestAndFinalPeriod,
          contacts: $contacts,
          principalBalance: $principalBalance,
          listingPrice: $listingPrice,
          requireBidDeposit: $requireBidDeposit,
          bidDepositSum: $bidDepositSum,
          preferredBidDepositChannel: $bidDepositChannel,
          bestCashOffer: $bestCashOffer,
          address: $address,
          noteOverview: $noteOverview,
          propertyOverview: $propertyOverview,
          images: $images
        ) {
          id
          name
        }
      }`;

  let variables = {
    id: assetInfo.id,
    name: assetInfo.name,
    assetStatus: assetInfo.assetStatus,
    type: assetInfo.type,
    assetDescription: assetInfo.description || null,
    // privacy: assetInfo.privacy || null,
    upbDate: new Date(assetInfo.upbDate.split("-").join("/")).toISOString(),
    preferredBidDepositChannel: assetInfo.preferredBidDepositChannel || null,
    requireBidDeposit: assetInfo.requireBidDeposit || null,
    images: assetInfo.images,
    contacts: assetInfo.contacts,
    principalBalance:
      (typeof assetInfo.principalBalance !== "object" && {
        amount: +assetInfo.principalBalance,
      }) ||
      null,
    listingPrice:
      (typeof assetInfo.listingPrice !== "object" && {
        amount: +assetInfo.listingPrice,
      }) ||
      null,
    bidDepositSum:
      (typeof assetInfo.bidDepositSum !== "object" && {
        amount: +assetInfo.bidDepositSum,
      }) ||
      null,
    // displayDueDate: assetInfo.displayDueDate || null,
    callForOffersPeriod:
      (assetInfo.callForOffersPeriod &&
        !assetInfo.callForOffersPeriod.startDate) ||
      (assetInfo.callForOffersPeriod &&
        !assetInfo.callForOffersPeriod.endDate) ||
      !assetInfo.callForOffersPeriod ||
      !assetInfo.callForOffersPeriod
        ? null
        : {
          startDate:
              (assetInfo.callForOffersPeriod &&
                assetInfo.callForOffersPeriod.startDate &&
                new Date(
                  assetInfo.callForOffersPeriod.startDate
                ).toISOString()) ||
              null,
          endDate:
              (assetInfo.callForOffersPeriod &&
                assetInfo.callForOffersPeriod.endDate &&
                new Date(
                  assetInfo.callForOffersPeriod.endDate
                ).toISOString()) ||
              null,
        },
    bestAndFinalPeriod:
      (assetInfo.bestAndFinalPeriod &&
        !assetInfo.bestAndFinalPeriod.startDate) ||
      (assetInfo.bestAndFinalPeriod && !assetInfo.bestAndFinalPeriod.endDate) ||
      !assetInfo.bestAndFinalPeriod ||
      !assetInfo.bestAndFinalPeriod
        ? null
        : {
          startDate:
              (assetInfo.bestAndFinalPeriod &&
                assetInfo.bestAndFinalPeriod.startDate &&
                new Date(
                  assetInfo.bestAndFinalPeriod.startDate
                ).toISOString()) ||
              null,
          endDate:
              (assetInfo.bestAndFinalPeriod &&
                assetInfo.bestAndFinalPeriod.endDate &&
                new Date(assetInfo.bestAndFinalPeriod.endDate).toISOString()) ||
              null,
        },
    ownerEmail: assetInfo.ownerEmail,
    address: {
      country: assetInfo.address.country || null,
      state: assetInfo.address.state,
      city: assetInfo.address.city || null,
      address: assetInfo.address.address || null,
      zipCode:
        (typeof assetInfo.address.zipCode !== "object" &&
          +assetInfo.address.zipCode) ||
        null,
    },
    bestCashOffer: assetInfo.bestCashOffer,

    propertyOverview:
      assetInfo.propertyOverview.propertyType.length > 0
        ? {
          propertyType:
              !assetInfo.propertyOverview.propertyType ||
              (!assetInfo.propertyOverview.propertyType.length && null) ||
              assetInfo.propertyOverview.propertyType,
          subtype: assetInfo.propertyOverview.subtype.length
            ? assetInfo.propertyOverview.subtype
            : ["None"],
          classField: assetInfo.propertyOverview.classField || null,
          yearBuilt:
              (typeof assetInfo.propertyOverview.yearBuilt !== "object" &&
                +assetInfo.propertyOverview.yearBuilt) ||
              null,
          yearRenovated:
              (typeof assetInfo.propertyOverview.yearRenovated !== "object" &&
                +assetInfo.propertyOverview.yearRenovated) ||
              null,
          size:
              (typeof assetInfo.propertyOverview.size !== "object" &&
                +assetInfo.propertyOverview.size) ||
              null,
          apn: assetInfo.propertyOverview.apn,
          zoning: assetInfo.propertyOverview.zoning || null,
          nraTotal:
              (typeof assetInfo.propertyOverview.nraTotal !== "object" &&
                +assetInfo.propertyOverview.nraTotal) ||
              null,
          unitOfMeasure: assetInfo.propertyOverview.unitOfMeasure || null,
          units:
              (typeof assetInfo.propertyOverview.units !== "object" &&
                +assetInfo.propertyOverview.units) ||
              null,
          constructionType:
              assetInfo.propertyOverview.constructionType || null,
          buildingLayout: assetInfo.propertyOverview.buildingLayout || null,
          stories: assetInfo.propertyOverview.stories || null,
          buildings: assetInfo.propertyOverview.buildings || null,
          condo:
              assetInfo.propertyOverview.condo === "yes"
                ? true
                : assetInfo.propertyOverview.condo === "no"
                  ? false
                  : null,
          multiProperty:
              assetInfo.propertyOverview.multiProperty === "yes"
                ? true
                : assetInfo.propertyOverview.multiProperty === "no"
                  ? false
                  : null,
          parkingType: assetInfo.propertyOverview.parkingType || null,
          parkingSpaces:
              (typeof assetInfo.propertyOverview.parkingSpaces !== "object" &&
                +assetInfo.propertyOverview.parkingSpaces) ||
              null,
          environmentalIssues:
              assetInfo.propertyOverview.environmentalIssues || null,
          propertyCondition:
              assetInfo.propertyOverview.propertyCondition || null,
          feeLeasehold: assetInfo.propertyOverview.feeLeasehold || null,
          largestTenantCredit:
              assetInfo.propertyOverview.largestTenantCredit || null,
          tenantType: assetInfo.propertyOverview.tenantType || null,
          isOwnerOccupied:
              assetInfo.propertyOverview.isOwnerOccupied === "yes"
                ? true
                : assetInfo.propertyOverview.isOwnerOccupied === "no"
                  ? false
                  : null,
          ownerOcc:
              (typeof assetInfo.propertyOverview.ownerOcc !== "object" &&
                +assetInfo.propertyOverview.ownerOcc) ||
              null,
        }
        : null,
  };

  return API.graphql({
    query: mutation,
    variables,
  });
};
