import { API } from "aws-amplify";
export default (id) => {
  const query = `query {
    getAsset(id: "${id}") {
          id,
            name,
            status,
            assetStatus,
            type,
            images,
            payment {
              status
            }
            contacts {
              emailAddress
            }
            ownerEmail
            listingPrice {
            amount
            currency
          }
            callForOffersPeriod {
            startDate
            endDate
          }
            bestAndFinalPeriod {
            startDate
            endDate
          }
            address {
            country
              state
              city
              address
              zipCode
            }
          tier_1_whitelist
          numberOfCAs
          numberOfOffers
          numberOfActiveOffers
          numberOfTier2s
          offers {
            id
          }
      }
    }
  `;

  return API.graphql({
    query,
    authMode: "AWS_IAM",
  });
};
