export const PERFORMING_LOAN_DETAILS = `
perfomingLoanDetails{
    maturityDate
    interestType
    originationDate
    term
    amortization
    prepaymentPenalty
    dscr
    monthsOpenAtPar
    noteRateFloor
  }
`;
export const NON_PERFORMING_LOAN_DETAILS = `
nonPerfomingLoanDetails{
    recourseGuaranty
    additionalLiens
    summaryJudgmentReceived
    totalPayoff
    ownerOccupied
  }
`;

export const SUB_PERFORMING_LOAN_DETAILS = `
subPerfomingLoanDetails{
    lastDelinquencyDate
    maturityDate
    interestType
    amortization
    numberDelinquentPayments
    originationDate
    term
    prepaymentPenalty
    dscr
    monthsOpenAtPar
    noteRateFloor
  }
`;

export const HOTEL_NON_PERFORMING_LOAN_DETAILS = `
hotelNonPerfomingLoanDetails{
  additionalLiens
  ownerOccupied
  recourseGuaranty
  hotelFlaggedCurrently
  hotelFlaggedAtFMVDate
  totalPayoff
  fairMarketValueDate
  summaryJudgmentReceived
  PIPRequirement
  franchiseAgreementExpirationDate
  }
`;

export const SPECIFICS = `
specifics{
  upb
    interestRate
    lienPosition
    unitOfMeasure
    netRentableArea
    propertySubType
    assetTitle
    fairMarketValue
    userRole
    assetStatus
    assetType
  }
`;
