<template>
  <div class="navigation-menu">
    <div v-if="title" class="navigation-menu__title">
      {{ title }}
    </div>
    <div v-for="item in items" :key="`${item.title}-${item.link}`">
      <div
        @click="onItemClick(item)"
        class="navigation-menu__item"
        :class="{
          'navigation-menu__item--title-exist': title,
        }"
      >
        <div
          class="navigation-menu__item-title"
          :class="{
            'navigation-menu__item-title--active': isActive(item.link),
          }"
        >
          {{ item.title }}
        </div>
        <img :src="getNavigationArrow(item.link)" />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from "@/assets/img/icons/navMobileIcons/navigation_arrow.svg";
import activeArrow from "@/assets/img/icons/navMobileIcons/navigation_arrow_active.svg";

export default {
  name: "NavigationMenu",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    onItemClick(item = {}) {
      const { external = false } = item ?? {};

      if (external) {
        window.location.href = item.link;
      } else {
        this.$router.push(item.link);
      }
    },
    isActive(link = "") {
      return this.getCurrentPage === link;
    },
    getNavigationArrow(link = "") {
      if (this.isActive(link)) {
        return activeArrow;
      }
      return arrow;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getCurrentPage() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.navigation-menu {
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #001154;
    margin-bottom: 20px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #9aabc2;
    margin-bottom: 16px;
    &--title-exist {
      margin-left: 16px;
    }
  }
  &__item-title {
    font-weight: 400;
    font-size: 20px;
    color: #001154;
    &--active {
      color: #2969ff;
    }
  }
}
</style>
