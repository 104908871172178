import { API } from "aws-amplify";

export default ({ id, companyName, companyProfile, jobTitle, aum }) => {
  const mutation = `mutation adminUpdateUserProfile($id: String!, 
      $companyName: String,
      $companyProfile: [CompanyProfile],
      $jobTitle: String,
      $aum: Float,
    ) {
      adminUpdateUserProfile(id: $id, 
        companyName: $companyName,
        companyProfile: $companyProfile,
        jobTitle: $jobTitle,
        aum: $aum,
    ) {
        id
        roles
        firstName
        lastName
        officePhone
        companyName
        companyProfile
        jobTitle
        aum
        address {
          country
          state
          city
          address 
          zipCode
        }
      }
    }`;
  return API.graphql({
    query: mutation,
    variables: {
      id,
      companyName,
      companyProfile,
      jobTitle,
      aum,
    },
  });
};
