import { initUserOffers } from "@/utils/localDatabse/offersDatabaseManagment";
import { initUserAssets } from "@/utils/localDatabse/userAssets/userAssetsDatabaseManagment";

export const initLocalDatabaseData = async (dbInstance, vuexStore) => {
  try {
    vuexStore.commit("LocalDatabase/setIsOffersLoading", true);
    await initUserOffers(dbInstance, vuexStore);
    vuexStore.commit("LocalDatabase/setIsOffersLoading", false);
    await initUserAssets(dbInstance, vuexStore);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("initLocalDatabaseData ERROR ", err);
  }
};
