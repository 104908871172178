import ErrorParser from "@/utils/ErrorParser";
import Offer from "@/services/apiCalls/offer/offer";
import BDA from "@/services/apiCalls/bda/bda";
import Asset from "@/services/apiCalls/asset/asset";

export default {
  namespaced: true,
  state: {
    offer: null,
    lpaLink: "",
    ssLink: "",
    lpaParams: {
      sign: null,
      check: false,
    },
    ssParams: {
      sign: null,
      check: false,
    },
  },
  getters: {},
  mutations: {
    setOffer(state, value) {
      state.offer = value;
    },
    setLPALink(state, value) {
      state.lpaLink = value;
    },
    setSSLink(state, value) {
      state.ssLink = value;
    },
    setLPAParams(state, value) {
      state.lpaParams = value;
    },
    setSSParams(state, value) {
      state.ssParams = value;
    },
    resetInfo(state) {
      state.offer = null;
      state.lpaLink = "";
      state.ssLink = "";
      state.lpaParams = {
        sign: null,
        check: false,
      };
      state.ssParams = {
        sign: null,
        check: false,
      };
    },
  },
  actions: {
    async searchClosingInProgressOffersSeller({ commit }, params) {},
    async editBuyerClosingPeriod({ commit }, { id, buyerClosingPeriod }) {
      const result = await BDA.editBuyerClosingPeriod(id, buyerClosingPeriod)
        .then(({ data }) => {
          console.log("editBuyerClosingPeriod - ", data);
          return true;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async editSellerClosingPeriod({ commit }, { id, sellerClosingPeriod }) {
      const result = await BDA.editSellerClosingPeriod(id, sellerClosingPeriod)
        .then(({ data }) => {
          console.log("editSellerClosingPeriod - ", data);
          return true;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async setOfferClosingHistoryDate(
      { state, commit },
      { id, opened, docType }
    ) {
      const result = await BDA.setOfferClosingHistoryDate(id, opened, docType)
        .then(({ data }) => {
          console.log("setOfferClosingHistoryDate - ", data);
          return true;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async selectBankForOfferBuyer(
      { state, commit },
      { offerId, bankingInfoId }
    ) {
      const result = await BDA.selectBankForOfferBuyer(offerId, bankingInfoId)
        .then(({ data }) => {
          return true;
        })
        .catch((err) => {
          commit(
            "openSnackBar",
            { text: ErrorParser(err), color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async selectBankForOfferSeller(
      { state, commit },
      { offerId, bankingInfoId }
    ) {
      const result = await BDA.selectBankForOfferSeller(offerId, bankingInfoId)
        .then(({ data }) => {
          return true;
        })
        .catch((err) => {
          commit(
            "openSnackBar",
            { text: ErrorParser(err), color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async selectBankForOfferAdmin(
      { state, commit },
      { offerId, bankingInfoId }
    ) {
      const result = await BDA.selectBankForOfferAdmin(offerId, bankingInfoId)
        .then(({ data }) => {
          return true;
        })
        .catch((err) => {
          commit(
            "openSnackBar",
            {
              text: ErrorParser(err),
              color: "error",
            },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async generateLegalDocument({ state, commit }, { id, docType }) {
      const result = await BDA.generateLegalDocument(id, docType)
        .then(({ data }) => {
          if (data.generateLegalDocument) {
            const { rawKey } = data.generateLegalDocument;
            if (docType === "LPA") {
              commit("setLPALink", rawKey);
            } else {
              commit("setSSLink", rawKey);
            }
          }
          return true;
        })
        .catch((err) => {
          commit(
            "openSnackBar",
            {
              text: ErrorParser(err),
              color: "error",
            },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async fetchOffer({ commit }, id) {
      commit("setOffer", null);
      const result = await Offer.getOffer(id)
        .then(({ data }) => {
          commit("setOffer", data.getOffer);
          return data.getOffer;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async getUserDocument({ commit }, { id, key, docType }) {
      const result = await Asset.getUserDocument(id, key)
        .then(({ data }) => {
          if (data.getUserDocument) {
            switch (docType) {
              case "BDA":
                break;
              case "SS":
                commit("setSSLink", data.getUserDocument);
                break;
              case "LPA":
                commit("setLPALink", data.getUserDocument);
                break;
              default:
                break;
            }
            return data.getUserDocument;
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async signBDADocument({ commit }, { id, signature }) {
      const result = await BDA.signBDADocument(id, signature)
        .then(({ data }) => {
          return true;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async signLegalDocument(
      { commit },
      { id, signature, type, signatureDimensions }
    ) {
      console.log("signatureDimensions - ", signatureDimensions);
      const result = await BDA.signLegalDocument(
        id,
        signature,
        type,
        signatureDimensions
      )
        .then(({ data }) => {
          if (data.signLegalDocument) {
            const { rawKey } = data.signLegalDocument;
            commit("setSSLink", rawKey);
          }
          return true;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          // "openSnackBar", {
          //   text: "The deadline was updated.",
          //   color: "success",
          //

          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
  },
};
