import { API, graphqlOperation } from "aws-amplify";
import { OFFER } from "@/services/apiCalls/types/Offer/Offer";

export default function subscribeToEditOffer() {
  const query = `subscription {
    subscribeToEditOffer {
            ${OFFER}
        }
    }`;

  return API.graphql(graphqlOperation(query));
}
