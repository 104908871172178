<template>
  <div
    class="stepper 11111"
    :class="{
      'stepper--mobile': mobile,
    }"
    :key="key"
  >
    <div v-if="!mobile" class="stepper-title">
      {{ stepperTitle }}
    </div>

    <div
      class="steps"
      :class="{
        'steps--mobile': mobile,
      }"
    >
      <div
        class="step"
        v-for="(step, i) in steps"
        :key="i"
        :class="{
          pointer:
            activatedSteps ||
            (withRouter &&
              (assetInfo.registrationStatus ||
                step.order <= getIndex + 1 ||
                checkIsStepCompleted(step))),
          '--completed':
            (activatedSteps && step.order !== activeStep) ||
            step.order <= getIndex ||
            assetInfo.registrationStatus ||
            checkIsStepCompleted(step),
          'step--mobile': mobile,
        }"
        @click="onStepClick(step)"
      >
        <div class="dots-wrapper" v-if="step.order !== 1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="3"
            height="10"
            viewBox="0 0 3 10"
            fill="none"
          >
            <circle
              cx="1.5"
              cy="1.5"
              r="1.5"
              :fill="
                activatedSteps ||
                checkIsStepCompleted(step) ||
                (checkIsStepActive(step) && showCompletedSteps)
                  ? '#35F9A7'
                  : 'white'
              "
            />
            <circle
              cx="1.5"
              cy="8.5"
              r="1.5"
              :fill="
                activatedSteps ||
                checkIsStepCompleted(step) ||
                (checkIsStepActive(step) && showCompletedSteps)
                  ? '#35F9A7'
                  : 'white'
              "
            />
          </svg>
        </div>
        <div
          class="step-order-wrapper"
          :class="{ 'step-order-wrapper--active': checkIsStepActive(step) }"
        >
          <div
            class="step__order"
            :class="{
              '--active': checkIsStepActive(step),
              'step__order--mobile': mobile,
            }"
          >
            {{ step.order }}
          </div>
        </div>

        <div class="step__title" :class="checkIsStepActive(step) && '--active'">
          {{ step.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "HorizontalStepper",
  props: {
    activeStep: {
      type: Number,
      default: 0,
    },
    stepperTitle: {
      type: String,
      default: "",
    },
    steps: {
      type: Array,
      default: () => [],
    },
    withRouter: {
      type: Boolean,
      default: false,
    },
    showCompletedSteps: {
      type: Boolean,
      default: false,
    },
    customCompletedSteps: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    activatedSteps: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    $route() {
      this.key = this.key + 1;
    },
  },
  data() {
    return {
      key: 0,
    };
  },
  methods: {
    ...mapMutations("Helpers", ["editCurrentStepIdx"]),
    checkIsStepActive(step) {
      if (!this.withRouter) {
        return step.order === this.activeStep;
      } else {
        return (
          step.link === this.$route.fullPath ||
          this.$route.fullPath.includes(step.link)
        );
      }
    },
    checkIsStepCompleted(step) {
      if (this.customCompletedSteps) {
        return step.isCompleted;
      } else {
        if (step.order < this.calculatedActiveStep.order) {
          return true;
        }
        return false;
      }
    },
    onStepClick(step) {
      if (
        this.withRouter &&
        (this.assetInfo.registrationStatus ||
          step.order <= this.getIndex + 1 ||
          this.checkIsStepCompleted(step))
      ) {
        this.$router.push(step.link);
      }
      this.$emit("onStepClick", step);
    },
  },
  computed: {
    ...mapState("asset", ["assetInfo"]),
    ...mapState("Helpers", ["assetCreationFlowSteps", "currentStepIdx"]),
    getIndex() {
      return this.assetCreationFlowSteps.indexOf(this.assetInfo.currentStep);
    },
    calculatedActiveStep() {
      if (this.withRouter) {
        const item = this.steps.find((s) => {
          return (
            s.link === this.$route.fullPath ||
            this.$route.fullPath.includes(s.link)
          );
        });
        if (item) {
          return item;
        } else {
          return {
            order: 0,
          };
        }
      } else {
        return {
          order: this.activeStep,
        };
      }
    },
  },
};
</script>
<style scoped lang="scss">
.stepper {
  height: 100%;
  width: 100%;
  background: #001154;
  padding: 0px 21px;

  &--mobile {
    background: transparent !important;
    height: auto !important;
  }
}
.stepper-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* White */

  color: #ffffff;
  padding-top: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.steps {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  &--mobile {
    justify-content: space-around;
    flex-direction: row !important;
  }
}
.step {
  display: flex;
  column-gap: 16px;
  position: relative;
  &--mobile {
    flex-direction: column;
    align-items: center;
    column-gap: 24px;

    .step__title {
      margin-top: 8px;
      color: #001154;
    }

    .step__order {
      position: relative;
      color: #001154;
      &:after {
        position: absolute;
        content: "";
        display: block;
        border-radius: 50%;
        border: 2px solid #001154;
        width: 41px;
        height: 41px;
      }
    }

    .step__order.--active {
      background: #001154;
      color: #fff;
    }

    &.--completed {
      .step-order-wrapper {
        border: 2px solid #368600 !important;
      }
      .step__order {
        background: #368600 !important;
        border: none !important;
        color: white !important;
        width: 30px !important;
        height: 29px !important;
        &:after {
          border: 2px solid #368600;
        }
      }
    }
  }
  &.--completed {
    cursor: pointer;
    .step-order-wrapper {
      border: 2px solid #35f9a7;
    }
    .step__order {
      background: #35f9a7;
      color: #001154;
    }
  }

  &__order {
    font-style: normal;
    color: white;
    &.--active {
      background: #ffffff;
      color: #001154;
    }
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* DarkBlue */
    width: 28px;
    height: 28px;
    border-radius: 20px;
    border: 1px solid #001154;
  }
  &__title {
    font-style: normal;
    font-weight: 400;

    &.--active {
      font-weight: 600;
    }
    font-size: 12px;
    line-height: 26px;
    /* identical to box height, or 217% */

    /* White */

    color: #ffffff;
  }
}
.step-order-wrapper {
  border-radius: 51%;
  width: 32px;
  height: 32px;
  position: relative;
  bottom: 1px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dots-wrapper {
  position: absolute;
  bottom: 32px;
  left: 14px;
}
</style>
