export default {
  computed: {
    showSideBar() {
      return (
        ![
          "/buy-commercial-real-estate-loans",
          "/contact",
          "/",
          "/callendly",
        ].includes(this.$route.path) &&
        !this.$route.path.includes("/asset/") &&
        !this.$route.path.includes("/buy-commercial-real-estate-loans") &&
        this.$route.path !== "/contact" &&
        this.$route.path !== ""
      );
    },
  },
};
