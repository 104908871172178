import { API } from "aws-amplify";
export default (nextToken) => {
  const query = `query me($nextToken: String) {
    listAssetsByUser(limit: 12, nextToken: $nextToken){
      count
      nextToken
      assets {
        id
        name
        type
        tier_1_whitelist
        ownerEmail
        contacts {
          emailAddress
        }
        offers {
          id
        }
        listingPrice {
          amount
        }
        callForOffersPeriod {
          startDate
          endDate
        }
          bestAndFinalPeriod {
          startDate
          endDate
        }
        status
        assetStatus
        address {
          address
        }
        documents{
          type
            key
  name
    dateModified
  dataOwnerId
        }
        images
        favoriteForUsers
        numberOfCAs
        numberOfOffers
        numberOfActiveOffers
        numberOfTier2s
        noteOverview{
          unpaidPrincipalBalance{
            amount
            currency
          }
          totalPayoff{
            amount
            currency
          }
        }
        address{
          state
          city
        }
        
      }     
    }
  }`;

  return API.graphql({
    query: query,
    variables: {
      nextToken: nextToken || null,
    },
  });
};
