<template>
  <div
    class="mt-2"
    :class="useDefaultStyle ? 'error-messages' : 'custom-error'"
    v-bind="$attrs"
  >
    <p>
      {{ error.toString() }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ErrorMessages",
  props: {
    error: {
      type: Array,
      default: () => [],
    },
    useDefaultStyle: {
      type: Boolean,
      default: true,
    },
  },
  //    ["error"]
};
</script>

<style lang="scss" scoped>
.error-messages {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    color: map-get($colors, "accent");
    margin: 0;
    text-align: left;
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
