import Numeral from "numeraljs";

/**
 * @param {Number, String} value
 * @return {Number} Return parsed currency number
 */
export default (value) => {
  const formatted = Numeral(value).format("0.00");
  if (formatted.includes(".00")) {
    formatted.replace(".00", "");
  }
  return Numeral(formatted).value();
};
