import { API, graphqlOperation } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default function subscribeToAssetUpdatesBySeller() {
  const query = `subscription {
    subscribeToAssetUpdatesBySeller {
            ${ASSET}
        }
    }`;

  return API.graphql(graphqlOperation(query));
}
