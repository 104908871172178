export default {
    VOPAY_EMBEDURL:'https://embed-dev.vopay.com/10b23f1c4198c042336d9fe9bf9db679e25c9429',
    GOOGLE_MAP_API_KEY: 'AIzaSyDoTG-QcNxVlPjsuhD10Z6yeVNPRc9JiSg',
    GOOGLE_PLACES_API_KEY: 'AIzaSyDoTG-QcNxVlPjsuhD10Z6yeVNPRc9JiSg',
    GTM_DEV: {
       GTM_ACCOUNT:'GTM-WD6R9WX',
       GTM_AUTH:'WGcHwnnqi3MFA8T0yGzvvg',
       GTM_PREVIEW:'env-3',
    },
    GTM_TEST: {
       GTM_ACCOUNT:'GTM-WD6R9WX',
       GTM_AUTH:'a3isU5CpVnnvUw1SIKmriw',
       GTM_PREVIEW:'env-4',
    },
    GTM_STAGING: {
       GTM_ACCOUNT:'GTM-WD6R9WX',
       GTM_AUTH:'ge_sk-tZIqs_BGFn7-lNtA',
       GTM_PREVIEW:'env-8',
    },
    GTM_PROD: {
       GTM_ACCOUNT:'GTM-MPPFSVW',
       GTM_AUTH:'YIM6yEbzPukO-coyj5x8lw',
       GTM_PREVIEW:'env-1',
    },
    ZENTINEL_URL: 'https://stage-webagent.zentinel.app/',
    ZENTINEL_API_URL: 'https://zentinel.test.xchange.loans',
    SHOW_BUYER_REQUEST_BUTTON: 'FALSE',
    SHOW_ASSET_REPORTS:'TRUE',
    SHOW_SELLER_PROFILE: 'TRUE',
    STRIPE_PUBLISHABLE_KEY:'pk_test_nACYJgrpYDcBOwKPiFz1BSp000SiGqgvU9',
    SENTRY_DSN:'https://7cb8ee6ebf344b1489e07647892a83fe@o4503932588392448.ingest.sentry.io/4503998469242880',
    SENTRY_ENVIRONMENT:'test',
    ZENTINEL_CLIENT_ID:'xl-test',
    ZENTINEL_CATEGORY_ID:'31480',
    ZENTINEL_SITE_URL:'https://stage.zentinel.app'
}
