export const DEFAULT_DICTIONARY = {
  required: "",
  numeric: "This field must be of numeric value",
};

class ValidationFabric {
  $v;
  dictionary;

  constructor(instance, dictionary) {
    this.$v = instance;
    this.dictionary = dictionary || DEFAULT_DICTIONARY;
  }

  getFieldError(token) {
    const errors = [];

    if (!token || !this.$v) {
      return errors;
    }
    const validationObject = this.getProp(this.$v, token);
    if (!validationObject || !validationObject.$dirty) {
      return errors;
    }

    const rules = Object.keys(validationObject).filter(
      (rule) => !rule.includes("$")
    );
    if (!rules || !rules.length) {
      return errors;
    }

    rules.forEach((rule) => {
      if (!validationObject[rule]) {
        errors.push(this.dictionary[rule] || "");
      }
    });

    return errors;
  }

  getProp(object, path, defaultVal = {}) {
    const _path = Array.isArray(path)
      ? path
      : path.split(".").filter((i) => i.length);

    if (!_path.length) {
      return object === undefined ? defaultVal : object;
    }

    return this.getProp(object[_path.shift()], _path, defaultVal);
  }
}

export default ValidationFabric;
