import { API } from "aws-amplify";

export default {
  getToken() {
    const query = `query getZentinelToken {
        getZentinelToken
      }`;

    return API.graphql({
      query,
    });
  },
};
