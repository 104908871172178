import { S3_OBJECT_INFO } from "@/services/apiCalls/types/Global/S3ObjectInfo";

export const CLOSING_HISTORY = `
  buyerReceived{
  ${S3_OBJECT_INFO}
  }
  buyerOpened{
  ${S3_OBJECT_INFO}
  }
  buyerExecuted{
  ${S3_OBJECT_INFO}
  }
  sellerReceived{
  ${S3_OBJECT_INFO}
  }
  sellerOpened{
  ${S3_OBJECT_INFO}
  }
  sellerExecuted{
  ${S3_OBJECT_INFO}
  }
   buyerAccepted
  sellerAccepted
`;
