import { API, graphqlOperation } from "aws-amplify";

export default function subscribeToNoticeMessageAdded() {
  const query = `subscription {
    subscribeToNoticeMessageAdded {
      msgText
      senderID
      receiverID
      chatTwilioSID
    }
  }`;

  return API.graphql(graphqlOperation(query));
}
