import { valueDetails } from "@/assets/data/enums/valueDetails";
import Vdr from "@/services/apiCalls/vdr/vdr";
import Calculator from "@/services/apiCalls/value/calculator";
import Valuation from "@/services/apiCalls/valuations/valuation";
import {
  formatAssetValue,
  formatUserDataObject,
} from "@/utils/valuePage/calculatorObjFormatter";
import router from "@/router";

const initialState = {
  address: null,
  propertyType: null,
  specifics: {
    assetTitle: "-",
    assetStatus: "",
    userRole: "",
    upb: "",
    lienPosition: "",
    interestRate: "",
    fairMarketValue: "",
    propertySubType: "",
    netRentableArea: "",
    unitOfMeasure: "",
    assetType: "",
  },
  perfomingLoanDetails: {
    originationDate: "",
    term: "",
    maturityDate: "",
    amortization: "",
    interestType: "",
    noteRateFloor: "",
    dscr: "",
    prepaymentPenalty: "",
    monthsOpenAtPar: "",
  },
  subPerfomingLoanDetails: {
    originationDate: "",
    term: "",
    maturityDate: "",
    amortization: "",
    interestType: "",
    noteRateFloor: "",
    dscr: "",
    prepaymentPenalty: "",
    lastDelinquencyDate: "",
    monthsOpenAtPar: "",
    numberDelinquentPayments: "",
  },
  nonPerfomingLoanDetails: {
    totalPayoff: "",
    additionalLiens: "",
    summaryJudgmentReceived: "",
    recourseGuaranty: "",
    ownerOccupied: "",
  },
  hotelNonPerfomingLoanDetails: {
    totalPayoff: "",
    summaryJudgmentReceived: "",
    fairMarketValueDate: "",
    recourseGuaranty: "",
    hotelFlagged: "",
    additionalLiens: "",
    hotelFlaggedCurrently: "",
    ownerOccupied: "",
    franchiseAgreementExpirationDate: "",
    pipRequirement: "",
  },
  calculator: {},
  calculatorResultId: "",
};
export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  actions: {
    async editValuation(state, isSaveBtnClicked = false) {
      state.commit("spinner", true, { root: true });
      let userInput = {
        ...state.state,
      };
      delete userInput.calculator;
      delete userInput.calculatorResultId;
      userInput = formatUserDataObject(userInput);

      if (userInput.userData) {
        delete userInput.userData.address.additional.sublocality;
        delete userInput.userData.address.additional.state;
        delete userInput.userData.address.additional.locality;
        delete userInput.userData.address.additional.neighborhood;
        delete userInput.userData.address.additional
          .administrative_area_level_3;
      }

      const { data } = await Valuation.editValuation({
        id: state.getters.currentEditValuationId,
        userInput,
      });
      state.commit("setCalculator", data.editValuation.calculatorResult);
      state.commit("spinner", false, { root: true });

      if (isSaveBtnClicked) {
        router.push("/valuations");
      }
      return data.editValuation;
    },

    async getCalcResponse(state, isError) {
      isError = true;
      let formattedObj = formatAssetValue(state);
      const userInput = {
        userData: {
          ...state.state,
        },
      };
      delete userInput.userData.calculator;

      userInput.userData = formatUserDataObject(userInput.userData);
      formattedObj.userInput = userInput;

      const DICTIONARY = {
        "Performing Loan": "perfomingLoanDetails",
        "Sub-Performing Loan": "subPerfomingLoanDetails",
        "Non-Performing Loan": "nonPerfomingLoanDetails",
      };

      const assetStatus =
        formattedObj?.userInput?.userData?.specifics?.assetStatus || null;
      const allowedFields = ["address", "propertyType", "specifics"];

      if (assetStatus) {
        let getTypeData = DICTIONARY[assetStatus];
        const isPropertyTypeHotel =
          formattedObj?.userInput?.userData.propertyType === "Hotel";
        const isAssetStatus = getTypeData === "nonPerfomingLoanDetails";

        if (isPropertyTypeHotel && isAssetStatus) {
          getTypeData = "hotelNonPerfomingLoanDetails";
        }

        allowedFields.push(getTypeData);
      }

      Object.keys(formattedObj.userInput.userData).forEach((item) => {
        if (allowedFields.includes(item)) {
          return;
        }

        delete formattedObj.userInput.userData[item];
      });

      delete formattedObj.userInput.userData.address.additional.sublocality;
      delete formattedObj.userInput.userData.address.additional.state;
      delete formattedObj.userInput.userData.address.additional.locality;
      delete formattedObj.userInput.userData.address.additional.neighborhood;
      delete formattedObj.userInput.userData.address.additional
        .administrative_area_level_3;

      const { data } = await Calculator.getCalculatorResponse(formattedObj);
      state.commit("setCalculator", {
        ...data.getCalculatorResponse,
        creationDate: new Date(),
      });
    },
    async getConvertedValuationToAsset(
      state,
      id,
      pricingPlan = "LenderDirect"
    ) {
      state.commit("spinner", true, { root: true });
      try {
        const { data: createVdrData } = await Vdr.createVDR(id);
        const {
          createVDR = {
            tier1_id: "",
            tier2_id: "",
            tier_home_id: "",
          },
        } = createVdrData;

        const { data } = await Valuation.convertToAsset({
          ...createVDR,
          id: id,
          pricingPlan,
        });

        return data.convertValuationToAsset;
      } catch (e) {
        console.error(e);
      } finally {
        state.commit("spinner", true, { root: true });
      }
    },
    async pushCreatedValuation(state, valuation) {},
  },
  mutations: {
    setCalculatorResultId(state, payload) {
      state.calculatorResultId = payload;
    },
    setEditValuationId(state, payload) {
      state.editValuationId = payload;
    },
    setAddress(state, payload) {
      state.address = payload;
    },
    setSpecific(state, payload) {
      state.specifics = payload;
    },
    setCalculator(state, payload) {
      state.calculator = payload;
    },
    setPerfomingLoanDetails(state, payload) {
      state.perfomingLoanDetails = payload;
    },
    setSubPerfomingLoanDetails(state, payload) {
      state.subPerfomingLoanDetails = payload;
    },
    setNonPerfomingLoanDetails(state, payload) {
      state.nonPerfomingLoanDetails = payload;
    },
    setHotelNonPerfomingLoanDetails(state, payload) {
      state.hotelNonPerfomingLoanDetails = payload;
    },
    setPropertyType(state, payload) {
      state.propertyType = payload;
    },

    setInitialState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(initialState)));
      state = JSON.parse(JSON.stringify(initialState));
    },
  },
  getters: {
    checkGeneralValidation(state) {
      const { userRole } = state.specifics;
      return userRole || state.address;
    },
    getAssetInfo(state) {
      return state?.asset?.assetInfo || null;
    },
    checkSpecificValidation(state) {
      const {
        assetTitle,
        assetStatus,
        userRole,
        upb,
        lienPosition,
        interestRate,
        fairMarketValue,
        netRentableArea,
        unitOfMeasure,
        assetType,
      } = state.specifics;

      return !(
        !assetTitle ||
        !assetStatus ||
        !userRole ||
        !upb ||
        !lienPosition ||
        !interestRate ||
        !fairMarketValue ||
        !netRentableArea ||
        !unitOfMeasure ||
        !state.propertyType ||
        !assetType
      );
    },
    checkPerformingLoanValidation(state) {
      const {
        originationDate,
        term,
        maturityDate,
        amortization,
        interestType,
        noteRateFloor,
        dscr,
        prepaymentPenalty,
        monthsOpenAtPar,
      } = state.perfomingLoanDetails;

      return !(
        !originationDate ||
        !term ||
        !maturityDate ||
        !amortization ||
        !interestType ||
        !noteRateFloor ||
        !dscr ||
        !prepaymentPenalty ||
        !monthsOpenAtPar
      );
    },
    checkSubPerformingLoanValidation(state) {
      const {
        originationDate,
        term,
        maturityDate,
        amortization,
        interestType,
        noteRateFloor,
        dscr,
        prepaymentPenalty,
        monthsOpenAtPar,
        lastDelinquencyDate,
        numberDelinquentPayments,
      } = state.subPerfomingLoanDetails;

      return !(
        !originationDate ||
        !term ||
        !maturityDate ||
        !amortization ||
        !interestType ||
        !noteRateFloor ||
        !dscr ||
        !prepaymentPenalty ||
        !monthsOpenAtPar ||
        !lastDelinquencyDate ||
        !numberDelinquentPayments
      );
    },
    checkNonPerformingLoanValidation(state) {
      const {
        totalPayoff,
        additionalLiens,
        summaryJudgmentReceived,
        recourseGuaranty,
        ownerOccupied,
      } = state.nonPerfomingLoanDetails;

      return !(
        !totalPayoff ||
        additionalLiens === "" ||
        summaryJudgmentReceived === "" ||
        !recourseGuaranty ||
        !ownerOccupied === ""
      );
    },
    checkHotelNonPerfomingLoanDetails(state) {
      const {
        totalPayoff,
        additionalLiens,
        summaryJudgmentReceived,
        recourseGuaranty,
        ownerOccupied,
        fairMarketValueDate,
        hotelFlagged,
        hotelFlaggedCurrently,
        franchiseAgreementExpirationDate,
        pipRequirement,
      } = state.hotelNonPerfomingLoanDetails;

      return !(
        !totalPayoff ||
        additionalLiens === "" ||
        summaryJudgmentReceived === "" ||
        !recourseGuaranty ||
        !ownerOccupied === "" ||
        !fairMarketValueDate ||
        hotelFlagged === "" ||
        hotelFlaggedCurrently === "" ||
        !franchiseAgreementExpirationDate ||
        pipRequirement === ""
      );
    },
    currentDetailsType(state) {
      const selectedStatus = state.specifics.assetStatus;
      const propertyType = state.propertyType;
      switch (selectedStatus) {
        case "Non-Performing Loan":
          if (propertyType === "Hospitality") {
            return valueDetails.HOTEL_PERFOMING_LOAN;
          } else {
            return valueDetails.NON_PERFORMING_LOAN;
          }

        case "Performing Loan":
          return valueDetails.PERFOMING_LOAN;
        case "Sub-Performing Loan":
          return valueDetails.SUB_PERFOMING_LOAN;
      }
    },
    isDetailsStepCompleted(state, getters) {
      const selectedDetailsType = getters.currentDetailsType;
      switch (selectedDetailsType) {
        case valueDetails.HOTEL_PERFOMING_LOAN:
          return getters.checkHotelNonPerfomingLoanDetails;

        case valueDetails.NON_PERFORMING_LOAN:
          return getters.checkNonPerformingLoanValidation;

        case valueDetails.SUB_PERFOMING_LOAN:
          return getters.checkSubPerformingLoanValidation;

        case valueDetails.PERFOMING_LOAN:
          return getters.checkPerformingLoanValidation;

        default:
          return false;
      }
    },
    currentEditValuationId(state, getters, rootState) {
      return rootState.editValuationId;
    },
  },
};
