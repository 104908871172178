<template>
  <v-dialog :value="dialog" max-width="290" persistent>
    <v-card class="valuation-close">
      <v-card-text>
        Are you sure you want to leave XL Valuation? Your data will be lost
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="valuation-close__button"
          @click="onEmitCloseConfirmDialog"
        >
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="valuation-close__button valuation-close__button--confirm"
          @click="onCloseFromAreYouSure"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ConfirmCloseValuationDialog",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("value", ["setInitialState"]),
    onEmitCloseConfirmDialog() {
      this.$emit("close-dialog");
    },
    onEmitClose() {
      this.$emit("confirm-close-dialog");
    },
    onCloseFromAreYouSure() {
      this.onEmitClose();
      this.onResetValuation();
      this.onEmitCloseConfirmDialog();
    },
    onResetValuation() {
      [
        "addressData",
        "propertyTypes",
        "specifics",
        "subPerfomingLoanDetails",
        "perfomingLoanDetails",
        "nonPerfomingLoanDetails",
        "hotelNonPerfomingLoanDetails",
      ].forEach((item) => localStorage.removeItem(item));
      this.setInitialState();
    },
  },
};
</script>

<style lang="scss" scoped>
.valuation-close {
  border-radius: 5px;
  padding-top: 16px;
  &__button {
    background: #9aabc2 !important;
    border-radius: 7px;
    max-height: 29px !important;
    min-height: 29px !important;
    height: 29px !important;
    font-size: 12px !important;
    margin-bottom: 16px;
    color: #fff !important;
    &--confirm {
      background: #2969ff !important;
    }
  }
}
</style>
