import { API } from "aws-amplify";
export default (id) => {
  const query = `query {
    getAsset(id: "${id}") {
          id,
          name,
          status,
          assetStatus,
          type,
          images,
          callForOffersPeriod {
            startDate,
            endDate
          },
          bestAndFinalPeriod {
            startDate,
            endDate
          },
          address {
            address
            city
            state
            country
          }
          listingPrice {
            amount
          }
          principalBalance {
            amount
          }
          noteOverview {
            unpaidPrincipalBalance {
              amount
            }
          }
          requireBidDeposit,
      }
    }
  `;

  return API.graphql({
    query,
    authMode: "AWS_IAM",
  });
};
