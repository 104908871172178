<template>
  <footer class="footer pt-4 pt-md-16">
    <v-container>
      <div class="footer__visual px-md-3">
        <v-row no-gutters justify="center">
          <v-col cols="12" md="3" class="pr-md-5">
            <div class="footer__links mx-auto mx-md-0">
              <div class="text-center">
                <v-img
                  :src="require('@/assets/img/xchangeloans-footer-logo.svg')"
                  alt="Xchange.Loans"
                  :width="getLogoSize"
                  class="mx-auto"
                />
              </div>

              <div class="footer__linkedin mb-6 mb-md-0">
                <a
                  href="https://www.linkedin.com/company/xchangeloans"
                  target="_blank"
                  title="Our LinkedIn"
                  class="footer__linkedin-icons"
                >
                  <div class="footer__linkedin-icons-text">
                    Connect with us on
                  </div>
                  <img
                    class="ml-2 footer__linkedin-icons-image"
                    src="@/assets/img/icons/linkedin.svg"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="4" md="3" class="d-none d-sm-flex">
            <div class="footer__navigation">
              <div class="section-title mb-5">Site Navigation</div>
              <v-row no-gutters>
                <v-col cols="6" class="mb-4">
                  <div class="button">
                    <span class="cursor-pointer" @click="goToLender">
                      Sell: Self-Service
                    </span>
                  </div>
                </v-col>
                <v-col cols="6" class="mb-4">
                  <div
                    v-if="!isUserAuth"
                    @click="openRegisterDialog"
                    class="button cursor-pointer"
                  >
                    Register/Login
                  </div>
                  <div v-else class="button">
                    <router-link to="/profile/edit"> Profile </router-link>
                  </div>
                </v-col>
                <v-col cols="6" class="mb-4">
                  <div class="button">
                    <span class="cursor-pointer" @click="goToExclusive">
                      Sell: Full-Service
                    </span>
                  </div>
                </v-col>
                <v-col cols="6" class="mb-4">
                  <div class="button">
                    <router-link to="/about-xchange-loans">
                      About Us
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="6" class="mb-4">
                  <div class="button">
                    <router-link to="/buy-commercial-real-estate-loans">
                      Buy
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="6" class="mb-4">
                  <div class="button">
                    <a href="https://knowledge.xchange.loans/"> Knowledge </a>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="button">
                    <router-link to="/free-loan-valuation/general">
                      Value
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="button cursor-pointer" @click="onFAQClick">
                    FAQ
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="6" sm="4" md="3" class="pr-md-5 mt-5 mt-sm-0">
            <ul class="footer__contacts">
              <li class="footer__contacts-title mb-2 mb-sm-5">
                <div class="contact-text" @click="openContactUs">
                  Contact Us
                </div>
              </li>
              <li class="contact-us-row" v-if="$vuetify.breakpoint.xsOnly">
                <div class="mx-4">
                  <a
                    href="https://www.linkedin.com/company/xchangeloans"
                    target="_blank"
                    title="Our LinkedIn"
                    class="footer__linkedin-icons"
                  >
                    <img
                      class="footer__linkedin-icons-image"
                      src="@/assets/img/icons/linkedin.svg"
                      alt="linkedin"
                    />
                  </a>
                </div>
                <div class="mx-4">
                  <a href="tel:8009138139" title="Our phone">
                    <img src="@/assets/img/footer/phone.svg" alt="phone" />
                  </a>
                </div>
                <div class="mx-4">
                  <a href="mailto:info@xchange.loans" title="Our email address">
                    <img src="@/assets/img/footer/email.svg" />
                  </a>
                </div>
                <div class="mx-4">
                  <a
                    href="https://www.google.com/maps/place/365+5th+Ave+S,+Naples,+FL+34102,+USA/@26.1412299,-81.8023287,17z/data=!4m5!3m4!1s0x88dae1a04e9d3de7:0xddb39fdf5eea600c!8m2!3d26.1412299!4d-81.8023287"
                  >
                    <img src="@/assets/img/footer/location.svg" />
                  </a>
                </div>
              </li>
              <li class="footer__contacts-item mb-6">
                <a href="tel:8009138139" title="Our phone">
                  <div class="d-flex">
                    <img src="@/assets/img/footer/phone.svg" alt="phone" />
                  </div>
                  <div class="ml-5">(800) 913-8139</div>
                </a>
              </li>

              <li class="footer__contacts-item mb-6">
                <a href="mailto:info@xchange.loans" title="Our email address">
                  <div class="d-flex">
                    <img src="@/assets/img/footer/email.svg" />
                  </div>
                  <div class="ml-5">info@xchange.loans</div>
                </a>
              </li>

              <li class="d-flex footer__contacts-item cursor-pointer">
                <!--                  href="http://maps.google.com/?q=1200 Pennsylvania Ave SE, Washington, District of Columbia, 20003"-->
                <a
                  href="http://maps.google.com/?q=365 Fifth Ave South, Ste 201, Naples, FL 34102"
                  target="_blank"
                >
                  <div>
                    <img src="@/assets/img/footer/location.svg" />
                  </div>
                  <div class="ml-5">
                    <span
                      >365 Fifth Ave South, Ste 201,
                      <br />
                      Naples, FL 34102</span
                    >
                  </div>
                </a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="8" md="3" class="mt-sm-8 mt-md-0">
            <div class="footer__contact-form">
              <div class="footer__contact-form-title">Have a Question?</div>

              <div>
                <v-text-field
                  class="footer__contact-form-field"
                  placeholder="Your E-mail Address"
                  v-model="form.email"
                />
              </div>

              <div class="mt-2">
                <v-textarea
                  class="footer__contact-form-textarea"
                  textarea
                  rows="2"
                  v-model="form.message"
                  auto-grow
                  placeholder="Leave a Question"
                />
              </div>

              <div class="button-wrapper">
                <v-btn
                  color="#2969FF"
                  width="172px"
                  height="32px"
                  class="button-second"
                  rounded
                  shaped
                >
                  <div
                    class="white--text footer__contact-form-button"
                    @click="onSubmit"
                  >
                    Send
                  </div>
                </v-btn>
              </div>
            </div></v-col
          >
        </v-row>
      </div>

      <div class="footer-terms mt-8 py-2">
        <router-link to="/privacy-policy" class="mr-5">
          Privacy Policy
        </router-link>
        <router-link to="/tos" class="ml-5"> Terms of Service </router-link>
      </div>
      <div class="footer__main-text py-2 py-md-3">
        ©{{ nowYear }} Xchange.Loans, Inc. All rights Reserved.
      </div>
    </v-container>

    <ContactUsDialog ref="contactUsDialog"></ContactUsDialog>
  </footer>
</template>

<script>
import apiSendContactUs from "@/services/apiCalls/message/message";
import { normalizeForm } from "@/utils/normalize";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ContactUsDialog from "@/components/GlobalDialogs/ContactUsDialog.vue";
export default {
  name: "AppFooter",
  components: { ContactUsDialog },
  mixins: [validationMixin],
  data: function () {
    return {
      form: {
        name: "_",
        email: "",
        phone: "_",
        purpose: "_",
        message: "",
      },
    };
  },
  validations: {
    form: {
      email: { required, email },
      message: { required },
    },
  },
  methods: {
    openContactUs() {
      this.$refs.contactUsDialog.open();
    },
    openLoginDialog() {
      this.$root.openLoginDialog({});
    },
    openRegisterDialog() {
      this.$root.openRegisterDialog({});
    },
    onFAQClick() {
      this.$router.push("/faq");
    },
    goToLender() {
      this.$router.push("/loan-sales");
    },
    goToExclusive() {
      this.$router.push("/loan-sale-advisory");
    },
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.snackbar = true;
        this.form = normalizeForm(this.form);
        this.$store.commit("spinner", true);
        try {
          await apiSendContactUs.sendContactUs({
            ...this.form,
            phone: this.form.phone && this.form.phone.replace(/[()-]/g, ""),
          });
          this.$store.commit("openSnackBar", {
            text: "Your message has been sent successfully.",
            color: "success",
          });
          this.form = {
            name: "_",
            email: "",
            phone: "_",
            purpose: "_",
            message: "",
          };
          this.$v.$reset();
          this.$store.commit("spinner", false);
        } catch (err) {
          this.$store.commit("spinner", false);
          console.error("err", err);
        }
      }
    },
  },
  computed: {
    getLogoSize() {
      return this.$vuetify.breakpoint.sm
        ? 272
        : this.$vuetify.breakpoint.xs
        ? 248
        : 320;
    },
    isUserAuth() {
      return this.$store.getters.user;
    },
    nowYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #121727;
  color: #fff;
  @include mediaQ(
    map-get($mediabreakpoints, "sm"),
    map-get($mediabreakpoints, "md")
  ) {
    padding: 48px 0 0;
  }
  a {
    color: #fff !important;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  &__visual {
  }
  &__links {
    max-width: 320px;
    .footer__linkedin {
      a {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }
      &-icons {
        display: flex;
        font-weight: 500;
        font-size: 12px;
        &-text {
          padding-top: 0px;
          font-weight: 500;
          font-size: 16px;
          line-height: 35px;
        }
        &-image {
          width: 16px;
          height: 16px;
          position: relative;
          top: 1px;
        }
      }
    }
  }
  &__icons {
    display: flex;
    column-gap: 24px;
    margin-top: 8px;
    justify-content: center;
    &-text {
      padding-top: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 35px;
    }
  }
  &__navigation {
    color: #fcfcfc;
    .row {
      .col-6:nth-child(odd) {
        max-width: 180px;
        @media screen and (min-width: 600px) and (max-width: 670px) {
          max-width: 70px;
        }
      }
    }
    .button {
      a {
        color: #fcfcfc !important;
      }
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
    }
    .section-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
    }
  }
  &__contacts {
    @include mediaQ(
      map-get($mediabreakpoints, "xs"),
      map-get($mediabreakpoints, "sm")
    ) {
      text-align: center;
    }
    &-title a {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
    }
    &-item {
      & a {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
  &__contact-form {
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: #fcfcfc;
      margin-bottom: 15px;
    }
    &-field {
      @media (max-width: 768px) {
        max-width: 100%;
      }
      background: rgba(241, 241, 241, 0.5) !important;
      border-radius: 5px;
      width: 100%;
      max-width: 100%;
      height: 40px;
      padding-top: 0px !important;
      padding-left: 8px;
    }
    &-textarea {
      @media (max-width: 768px) {
        max-width: 100%;
      }
      background: rgba(241, 241, 241, 0.5) !important;
      width: 100%;
      max-width: 100%;
      height: 96px;
      overflow: auto;
      padding-top: 0px !important;
      padding-left: 8px;
      line-height: initial;
      border-radius: 5px;
    }
    &-button {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 12px;
      text-align: center;
      letter-spacing: 0.444444px;
      color: #ffffff;
    }
  }
  &__main-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-top: 1px solid rgba(241, 241, 241, 0.2);
    color: #fcfcfc;
    display: flex;
    justify-content: center;
    @include mediaQ(
      map-get($mediabreakpoints, "xs"),
      map-get($mediabreakpoints, "sm")
    ) {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
}
::v-deep {
  .v-text-field__details {
    display: none !important;
  }
  .v-input__slot:after {
    display: none;
  }
  .v-input__slot {
    margin: 0 !important;
  }
  .v-input__control {
    height: 100%;
  }
  .v-input__slot {
    height: 100%;
  }
  .v-btn {
    min-height: unset !important;
  }
  .v-input__slot {
    &:before {
      display: none;
    }
  }
  input {
    color: #ffffff !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.65;
    caret-color: #ffffff !important;
    padding: 11px;
    &::placeholder {
      color: #ffffff !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      opacity: 0.65;
    }
  }
  textarea {
    color: #ffffff !important;
    padding: 11px !important;
    caret-color: #ffffff !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
    opacity: 0.65;
    height: calc(85px - 25px) !important;
    &::placeholder {
      color: #ffffff !important;
      font-weight: 400;
      font-size: 14px;
      opacity: 0.65;
    }
  }
}
.navigation-items {
  display: flex;
  display: grid;
  grid-template-columns: 39% 50%;
  row-gap: 22px;
  column-gap: 10px;
  padding-top: 15px;
  width: 222px;
  li {
    // width: 41%;
    white-space: nowrap;
  }
  flex-wrap: wrap;
}
.button-wrapper {
  margin-top: 6px;
}
.contact-us-row {
  display: flex;
  justify-content: center;
  img {
    width: 23px;
    height: 23px;
  }
}
.footer-terms {
  display: flex;
  justify-content: center;
  @include mediaQ(
    map-get($mediabreakpoints, "md"),
    map-get($mediabreakpoints, "xl")
  ) {
    border-top: 1px solid rgba(241, 241, 241, 0.2);
  }
  a {
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    color: #fcfcfc;
    @include mediaQ(
      map-get($mediabreakpoints, "xs"),
      map-get($mediabreakpoints, "sm")
    ) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
@include mediaQ(
  map-get($mediabreakpoints, "xs"),
  map-get($mediabreakpoints, "sm")
) {
  .navigation-items {
    display: none;
  }
  .footer__contact-form {
    display: none;
  }
  .footer__linkedin {
    display: none;
  }
  .footer__contacts-item {
    display: none !important;
  }
}
.faq-button {
  color: white;
  cursor: pointer;
}
.contact-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  cursor: pointer;
}
</style>
