export default (str) => {
  if (typeof str === "boolean") {
    return str ? "Yes" : "No";
  }
  if (!str) return str;
  if (str === "REO") return str;
  return str
    .replace(/\./g, "")
    .replace(/\s/g, "")
    .replace(/([A-Z])/g, " $1");
};
