<template>
  <v-dialog
    :value="dialog"
    width="613"
    persistent
    @keydown.esc="close"
    :fullscreen="isMobile"
  >
    <div class="dialog-content">
      <div class="dialog-title">Success!</div>
      <img :src="successContactUsIcon" />
      <div class="contacting-text">Thank you for contacting us!</div>
      <div class="additional-text">We'll contact you soon</div>
      <div class="button-wrapper">
        <v-btn
          @click="
            $router.push('/');
            close();
          "
          color="#2969FF"
          :width="isMobile ? '100%' : '177px'"
          :height="isMobile ? '40px' : '32px'"
          class="button-second"
          rounded
          shaped
        >
          <div class="white--text button-text">Go to Homepage</div>
        </v-btn>
      </div>
      <div class="close-icon-wrapper" @click="close">
        <v-icon> mdi-close </v-icon>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import successContactUsIcon from "@/assets/img/successContactUs.svg";
export default {
  name: "SuccessContactUsDialog",
  data() {
    return {
      dialog: false,
      successContactUsIcon,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-content {
  width: 100%;
  height: 100%;
  background: white;
  height: 388px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 71px;
  position: relative;
  border-radius: 5px;
}
.dialog-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.32px;
  color: #2969ff;
}
.contacting-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2969ff;
  margin-top: 33px;
}
.additional-text {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #001154;
  opacity: 0.85;
}
.button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
}
::v-deep .v-btn {
  min-height: unset !important;
}
img {
  margin-top: 14px;
}
.button-wrapper {
  margin-top: 31px;
}
.close-icon-wrapper {
  position: absolute;
  right: 9px;
  top: 8px;
  cursor: pointer;
}

@include mediaQ(
  map-get($mediabreakpoints, "xs"),
  map-get($mediabreakpoints, "sm")
) {
  ::v-deep {
    .v-dialog {
      background: white;
    }
  }
  .dialog-content {
    padding-top: 0px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  img {
    margin-top: 32px;
    width: 176px;
    height: 102px;
  }
  .dialog-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
  .contacting-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */

    text-align: center;
    margin-top: 40px;
    color: #2969ff;
  }
  .additional-text {
    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    text-align: center;

    color: #001154;
  }
  .button-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */

    text-align: center;

    /* Primary/White */

    color: #ffffff;
  }
  .button-wrapper {
    padding: 0px 16px;
    width: 100%;
    margin-top: 48px;
  }
}
</style>
