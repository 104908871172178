<template>
  <span>
    <span v-show="counter" class="new-msg-icon">
      {{ counter }}
    </span>
  </span>
</template>

<script>
export default {
  name: "ConversationChatListNewMsg",
  props: {
    counter: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.new-msg-icon {
  display: inline-flex;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: #dd226f;
  margin-top: 8px;
  font-size: 10px;
  justify-content: center;
  align-items: flex-end;
  color: #ffffff;
  margin-right: 8px;
}
</style>
