// eslint-disable-next-line no-unused-vars
import { valueDetails } from "@/assets/data/enums/valueDetails";
import Vue from "vue";

export const formatAssetValue = (store) => {
  let assetStatus = formatStatus(store.state.specifics.assetStatus);

  const data = store.state;
  // eslint-disable-next-line no-unused-vars
  // В залежності від того який тип вибрав користувач використовуємо одну з функцій форматування обєкту
  let additionalObject = null;
  const currentDetailsType = store.getters.currentDetailsType;

  switch (currentDetailsType) {
    case valueDetails.PERFOMING_LOAN:
      additionalObject = getPerformingLoanObj(data.perfomingLoanDetails);
      break;
    case valueDetails.SUB_PERFOMING_LOAN:
      additionalObject = getSubPerformingLoanObj(data.subPerfomingLoanDetails);
      break;
    case valueDetails.NON_PERFORMING_LOAN:
      additionalObject = getNonPerformingLoanObj(data.nonPerfomingLoanDetails);
      break;
    case valueDetails.HOTEL_PERFOMING_LOAN:
      assetStatus = "Hotel Non-Performing Loan";
      additionalObject = getHotelNonPerformingLoanObj(
        data.hotelNonPerfomingLoanDetails
      );
      break;
    default:
      additionalObject = null;
  }

  const { county, administrative_area_level_1ShortName } =
    data.address.additional;
  let obj = {
    assetStatus,
    county,
    state: administrative_area_level_1ShortName,
    lienPosition: data.specifics.lienPosition,
    upb: Number.parseFloat(data.specifics.upb),
    interestRate: Number.parseFloat(data.specifics.interestRate),
    fairMarketValue: data.specifics.fairMarketValue,
    ...additionalObject,
  };
  return obj;
};

const formatStatus = (status) => {
  return status;
  // if(status === "Performing Loan"){
  //   return "Performing";
  // }
  // if(status === "Sub-Performing Loan"){
  //   return "Sub-Performing";
  // }
  // if(status === "Non-Performing Loan"){
  //   return "Non-Performing";
  // }
};

const getPerformingLoanObj = (item) => {
  return {
    dscr: Number.parseFloat(item.dscr),
    originationDate: formatDateField(item.originationDate),
    maturityDate: formatDateField(item.maturityDate),
    monthsOpenAtPar: formatInteger(item.monthsOpenAtPar),
    prepaymentPenalty: item.prepaymentPenalty === "Yes",
    interestType: item.interestType,
    noteRateFloor: formatFloat(item.noteRateFloor),
    term: formatInteger(item.term),
    amortization: formatInteger(item.amortization),
  };
};
const getSubPerformingLoanObj = (item) => {
  return {
    dscr: Number.parseFloat(item.dscr),
    originationDate: formatDateField(item.originationDate),
    maturityDate: formatDateField(item.maturityDate),
    lastDelinquencyDate: formatDateField(item.lastDelinquencyDate),
    monthsOpenAtPar: formatInteger(item.monthsOpenAtPar),
    term: formatInteger(item.term),
    amortization: formatInteger(item.amortization),
    prepaymentPenalty: item.prepaymentPenalty === "Yes",
    interestType: item.interestType,
    noteRateFloor: formatFloat(item.noteRateFloor),
    numberDelinquentPayments: formatInteger(item.numberDelinquentPayments),
  };
};
const getNonPerformingLoanObj = (item) => {
  return {
    additionalLiens: item.additionalLiens,
    summaryJudgmentReceived: item.summaryJudgmentReceived,
    totalPayoff: formatFloat(item.totalPayoff),
    recourseGuaranty: item.recourseGuaranty,
    ownerOccupied: item.ownerOccupied,
  };
};
const getHotelNonPerformingLoanObj = (item) => {
  return {
    additionalLiens: item.additionalLiens,
    ownerOccupied: item.ownerOccupied,
    recourseGuaranty: item.recourseGuaranty,
    hotelFlaggedCurrently: item.hotelFlaggedCurrently,
    hotelFlaggedAtFMVDate: item.hotelFlagged,
    totalPayoff: formatFloat(item.totalPayoff),
    fairMarketValueDate: formatDateField(item.fairMarketValueDate),
    summaryJudgmentReceived: item.summaryJudgmentReceived,
    PIPRequirement: formatFloat(item.pipRequirement),
    franchiseAgreementExpirationDate: formatDateField(
      item.franchiseAgreementExpirationDate
    ),
  };
};
const getSpecifics = (item) => {
  return {
    ...item,
    interestRate: Number.parseFloat(item.interestRate),
    netRentableArea: Number.parseFloat(item.netRentableArea),
  };
};
const formatFloat = (val) => {
  if (val === "") {
    return null;
  }
  return Number.parseFloat(val);
};
const formatInteger = (val) => {
  if (val === "") {
    return null;
  }
  return Number.parseFloat(val);
};

export const formatDateField = (value) => {
  if (!value) {
    return null;
  }
  return Vue.moment(new Date(value.replace(/-/g, "/"))).format("YYYY-MM-DD");
};
export const formatUserDataObject = (userData) => {
  userData.perfomingLoanDetails = getPerformingLoanObj(
    userData.perfomingLoanDetails
  );
  userData.subPerfomingLoanDetails = getSubPerformingLoanObj(
    userData.subPerfomingLoanDetails
  );
  userData.nonPerfomingLoanDetails = getNonPerformingLoanObj(
    userData.nonPerfomingLoanDetails
  );
  userData.hotelNonPerfomingLoanDetails = getHotelNonPerformingLoanObj(
    userData.hotelNonPerfomingLoanDetails
  );
  userData.specifics = getSpecifics(userData.specifics);
  return userData;
};
