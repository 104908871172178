import { API } from "aws-amplify";

export default (id) => {
  const query = `mutation addUserFavoriteAsset($id: String!) {
    addUserFavoriteAsset(id: $id) {
      id
    }
  }`;

  return API.graphql({
    query,
    variables: {
      id,
    },
  });
};
