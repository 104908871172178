import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default async (assetInfo) => {
  console.log("create pre - ", assetInfo);
  const mutation = `mutation createPreAsset(
        $currentStep: String,
        $description: String,
        $name: String,
        $assetStatus: [String]
        $type: AssetType,
        $principalBalance: ValueAmountInput,
        $listingPrice: ValueAmountInput,
        $address: AddressInput!,
        $displayDueDate: DisplayDueDate,
        $upbDate: String,
        $tier1_id: String!,
        $tier2_id: String!,
        $tier_home_id: String!,
        $pricingPlan:PricingPlan
        $propertyOverview:PropertyInfoInput
        $noteOverview:AssetNoteInput
        ) {
        createAsset(
          currentStep: $currentStep,
          name: $name
          description: $description
          type: $type
          assetStatus: $assetStatus
          pricingPlan: $pricingPlan
          upbDate: $upbDate
          displayDueDate: $displayDueDate,
          principalBalance: $principalBalance,
          listingPrice: $listingPrice,
          address: $address,
          tier1_id: $tier1_id,
          tier2_id: $tier2_id,
          tier_home_id: $tier_home_id,
          propertyOverview: $propertyOverview,
          noteOverview: $noteOverview,
          ) {
          ${ASSET}
        }
      }`;

  let variables = assetInfo;

  return API.graphql({
    query: mutation,
    variables,
  });
};
