import { API } from "aws-amplify";
export default (id) => {
  const query = `query {
    getAsset(id: "${id}") {
        principalBalance {
           amount
           currency
      }
    }
}
    `;

  return API.graphql({
    query,
    authMode: "AWS_IAM",
  });
};
