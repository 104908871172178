<template>
  <div>
    <span v-if="img" class="avatar">
      <img
        :src="img"
        class="app-bar__dropdown-wrapper-image"
        alt="user-avatar"
      />
    </span>
    <span v-else class="app-bar__dropdown-wrapper">
      <span class="cursor-pointer rounded-username">
        <span class="active-pointer"></span>
        {{ getUsername }}
      </span>
    </span>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
export default {
  name: "ChatAssetImg",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    assetImage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      img: null,
    };
  },
  computed: {
    getUsername() {
      if (this.user?.firstName) {
        return `${this.user.firstName?.at(0)}${this.user.lastName?.at(0)}`;
      }

      return this.user?.email?.at(0) || "AS";
    },
  },
  watch: {
    assetImage: {
      async handler(newValue) {
        if (newValue) {
          this.img = await Storage.get(newValue);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 8px 25px 0px rgba(0, 17, 84, 0.25);
}
</style>
