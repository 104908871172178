var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[(!_vm.formTitle)?_c('p',{staticClass:"cardPopUp__description title-description"},[_vm._v(" "+_vm._s(_vm.registrationText)+" ")]):_c('p',{staticClass:"cardPopUp__description"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('div',{staticClass:"second-input-with-background email"},[_c('c-label',[_vm._v(" Email ")]),_c('v-text-field',{attrs:{"placeholder":"user@mail.com","color":_vm.inputColor,"error-messages":_vm.emailErrors},on:{"blur":function($event){return _vm.$v.email.$touch()},"input":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"second-input-with-background"},[_c('c-label',[_vm._v(" Password ")]),_c('v-text-field',{attrs:{"placeholder":"Insert Password","color":_vm.inputColor,"error-messages":_vm.passwordErrors,"type":_vm.passwordVisible ? 'text' : 'password'},on:{"blur":function($event){return _vm.$v.password.$touch()},"input":function($event){return _vm.$v.password.$touch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"custom-eye-icon",attrs:{"size":"16","tabindex":"-1"},on:{"click":function($event){_vm.passwordVisible = !_vm.passwordVisible}}},[_vm._v(_vm._s(_vm.passwordVisible ? "mdi-eye" : "mdi-eye-off"))])]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"list__wrapper"},[_c('p',{staticClass:"validation__description"},[_vm._v("Password Must Contain:")]),_c('ul',{staticClass:"list listValidatePassword d-flex flex-wrap flex-column"},[_c('li',{staticClass:"list__item",style:({
          color: '#001154',
        })},[_c('v-icon',{style:({ opacity: _vm.password.length >= 8 ? 1 : 0.15 }),attrs:{"color":_vm.password.length >= 8 ? '#2969ff' : '#002c91',"size":"8"}},[_vm._v(" "+_vm._s(_vm.password.length >= 8 ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle")+" ")]),_vm._v(" 8 Characters ")],1),_c('li',{staticClass:"list__item",style:({
          color: '#001154',
        })},[_c('v-icon',{style:({ opacity: /[A-Z]/g.test(_vm.password) ? 1 : 0.15 }),attrs:{"color":/[A-Z]/g.test(_vm.password) ? '#2969ff' : '#002c91',"size":"8"}},[_vm._v(" "+_vm._s(/[A-Z]/g.test(_vm.password) ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle")+" ")]),_vm._v(" An Uppercase ")],1),_c('li',{staticClass:"list__item",style:({
          color: '#001154',
        })},[_c('v-icon',{style:({ opacity: /\d/g.test(_vm.password) ? 1 : 0.15 }),attrs:{"color":/\d/g.test(_vm.password) ? '#2969ff' : '#002c91',"size":"8"}},[_vm._v(" "+_vm._s(/\d/g.test(_vm.password) ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle")+" ")]),_vm._v(" Number ")],1),_c('li',{staticClass:"list__item",style:({
          color: '#001154',
        })},[_c('v-icon',{style:({ opacity: /[a-z]/g.test(_vm.password) ? 1 : 0.15 }),attrs:{"color":/[a-z]/g.test(_vm.password) ? '#2969ff' : '#002c91',"size":"8"}},[_vm._v(" "+_vm._s(/[a-z]/g.test(_vm.password) ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle")+" ")]),_vm._v(" A Lowercase ")],1),_c('li',{staticClass:"list__item",style:({
          color: '#001154',
        })},[_c('v-icon',{style:({ opacity: /\W|_/g.test(_vm.password) ? 1 : 0.15 }),attrs:{"color":/\W|_/g.test(_vm.password) ? '#2969ff' : '#002c91',"size":"8"}},[_vm._v(" "+_vm._s(/\W|_/g.test(_vm.password) ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle")+" ")]),_vm._v(" A Special Character ")],1)])]),_c('v-btn',{staticClass:"submit-btn mt-6",attrs:{"color":_vm.$vuetify.theme.themes.light.accent,"loading":_vm.$store.state.ui.buttonLoading,"width":"100%","disabled":!_vm.isAllValid,"height":"40px"},on:{"click":_vm.submit}},[_vm._v("Create Account")]),_c('ZentinelLogo')],1)}
var staticRenderFns = []

export { render, staticRenderFns }