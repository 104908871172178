export default (
  ctx,
  errorClassname = "error--text",
  shoudScrollIntoView = false
) => {
  const isMobile = ctx.$vuetify.breakpoint.xs;
  if (!isMobile) {
    return;
  }
  setTimeout(() => {
    const errorInputs = document.getElementsByClassName(errorClassname);
    // eslint-disable-next-line no-console
    console.log("errorImputs", errorInputs);
    if (errorInputs && errorInputs.length) {
      const elemToScroll = errorInputs[0];
      if (shoudScrollIntoView) {
        elemToScroll.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        window.scrollTo({ top: elemToScroll, behavior: "smooth" });
      }
    }
  }, 1000);
};
