/* eslint-disable no-unused-vars */
import Asset from "@/services/apiCalls/asset/asset";
import ErrorParser from "@/utils/ErrorParser";
import Vue from "vue";
import { Storage } from "aws-amplify";
import { normalizeForm } from "@/utils/normalize";
import router from "@/router";
import transformBoolean from "@/utils/transformBoolean";
import validateDate from "@/utils/validations/date";
import countSellerAssets from "@/services/apiCalls/asset/queries/countSellerAssets";
import trackAssetView from "@/services/apiCalls/asset/mutations/trackAssetView";

const INITIAL_ASSET_INFO = {
  name: "",
  description: "",
  assetStatus: "",
  type: "",
  status: "Draft",
  upbDate: "",
  ownerEmail: "",
  callForOffersPeriod: {
    startDate: "",
    endDate: "",
  },
  bestAndFinalPeriod: {
    startDate: "",
    endDate: "",
  },
  displayDueDate: "Date",
  principalBalance: "",
  listingPrice: "",
  privacy: "",
  images: "",
  caConfirmed: false,
  TOCConfirmed: false,
  requireBidDeposit: false,
  contactsDisclosed: false,
  bidDepositSum: "",
  customCADoc: "",
  preferredBidDepositChannel: "",
  address: {
    city: "",
    state: "",
    country: "",
    zipCode: "",
    address: "",
  },
  imageKeys: [],
  contacts: [],
  contactsEditingStatus: false,
  tier1_id: "",
  tier2_id: "",
  tier_home_id: "",
  pricingPlan: "LenderDirect",
  bestCashOffer: false,
  noteOverview: {
    numberOfLoans: "",
    originationDate: "",
    maturityDate: "",
    loanAmount: "",
    unpaidPrincipalBalance: "",
    totalPayoff: "",
    estCollateralValue: "",
    initialInterestRate: "",
    initialInterestType: "",
    currentInterestRate: "",
    defaultInterestRate: "",
    term: "",
    penalty: "",
    amortization: "",
    modified: "",
    assumptionOfLoanAllowed: "",
    prePaymentLimitations: "",
    monetaryDefault: "",
    maturityDefault: "",
    technicalDefault: "",
    recourse: "",
  },
  propertyOverview: {
    propertyType: [],
    subtype: [],
    classField: "",
    yearBuilt: "",
    yearRenovated: "",
    size: "",
    apn: "",
    zoning: "",
    nraTotal: "",
    unitOfMeasure: "",
    constructionType: "",
    propertyTaxesCurrent: "",
    buildingLayout: "",
    units: "",
    stories: "",
    buildings: "",
    condo: "",
    multiProperty: "",
    parkingType: "",
    parkingSpaces: "",
    environmentalIssues: "",
    propertyCondition: "",
    feeLeasehold: "",
    largestTenantCredit: "",
    tenantType: "",
    isOwnerOccupied: "",
    ownerOcc: "",
  },
};

export default {
  namespaced: true,
  state: {
    assetTotalCount: null,
    // isEditDataRetrieved: false,
    assetInfo: JSON.parse(JSON.stringify(INITIAL_ASSET_INFO)),
    currentCreatedAsset: null,
    isVdrFolderCreated: false,
    isGoToNextPage: false,
    isAllowedToGoToNextPage: false,
    sellerDialog: false,
    currentVdrName: "",
    propertyOverviewPanel: 0,
    vdrUploadLinks: {
      tier1: "",
      tier2: "",
    },
    vdrFolderId: null,
    carouselAssetsData: {
      assets: null,
      images: {},
    },
    favorites: [],
  },
  getters: {
    getPricingPlan(state) {
      const { pricingPlan } = state.assetInfo ?? {};

      return pricingPlan ? pricingPlan : "LenderDirect";
    },
    checkMainInfoValidity(state) {
      const { name, status, pricingPlan, type, principalBalance, upbDate } =
        state.assetInfo;
      const { propertyType, subtype } = state.assetInfo.propertyOverview;
      const { totalPayoff, estCollateralValue } = state.assetInfo.noteOverview;
      const { address, city, country, zipCode } = state.assetInfo.address;
      if (
        !name ||
        !status ||
        !pricingPlan ||
        !type ||
        !principalBalance ||
        !upbDate ||
        !address ||
        !city ||
        !zipCode ||
        !totalPayoff ||
        !estCollateralValue ||
        (propertyType && propertyType.length === 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkNoteOverview(state) {
      const {
        numberOfLoans,
        loanAmount,
        estCollateralValue,
        originationDate,
        currentInterestRate,
        term,
        amortization,
        recourse,
        monetaryDefault,
        maturityDefault,
        technicalDefault,
        initialInterestType,
      } = state.assetInfo.noteOverview;
      if (
        !numberOfLoans ||
        !originationDate ||
        !estCollateralValue ||
        currentInterestRate === "0" ||
        currentInterestRate === "" ||
        !Number.isInteger(term) ||
        !Number.isInteger(amortization) ||
        !recourse ||
        !monetaryDefault ||
        !maturityDefault ||
        !technicalDefault ||
        !loanAmount ||
        !initialInterestType
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkPropertyOverview(state) {
      const { propertyType } = state.assetInfo.propertyOverview;
      return !!propertyType;
    },
    checkOverviewInfoValidity(state) {
      const {
        numberOfLoans,
        loanAmount,
        totalPayoff,
        estCollateralValue,
        originationDate,
        unpaidPrincipalBalance,
        currentInterestRate,
        term,
        amortization,
        recourse,
        monetaryDefault,
        maturityDefault,
        technicalDefault,
        defaultInterestRate,
      } = state.assetInfo.noteOverview;
      const {
        propertyType,
        yearRenovated,
        size,
        yearBuilt,
        ownerOcc,
        units,
        stories,
        buildings,
        parkingSpaces,
      } = state.assetInfo.propertyOverview;

      const numberOfLoansError = !isNaN(+numberOfLoans);
      const loanAmountError = !isNaN(+loanAmount);
      const unpaidPrincipalBalanceError = !isNaN(+unpaidPrincipalBalance);
      const currentInterestRateError =
        (currentInterestRate &&
          !isNaN(+currentInterestRate) &&
          currentInterestRate >= 0 &&
          currentInterestRate <= 100) ||
        true;
      const termError = !isNaN(+term);
      const amortizationError = !isNaN(+amortization);
      const yearRenovatedError =
        (yearRenovated &&
          !isNaN(+yearRenovated) &&
          yearRenovated >= 1000 &&
          yearRenovated <= 2100) ||
        true;
      const sizeError = !isNaN(+size);
      const totalPayoffError = !isNaN(+totalPayoff);
      const estCollateralValueError = !isNaN(+estCollateralValue);
      const defaultInterestRateError =
        (defaultInterestRate &&
          !isNaN(+defaultInterestRate) &&
          defaultInterestRate >= 0 &&
          defaultInterestRate <= 100) ||
        true;
      const yearBuiltError =
        (yearBuilt &&
          !isNaN(+yearBuilt) &&
          yearBuilt >= 1000 &&
          yearBuilt <= 2100) ||
        true;
      const ownerOccError = !isNaN(+ownerOcc);
      const unitsError = !isNaN(+units);
      const storiesError = !isNaN(+stories);
      const buildingsError = !isNaN(+buildings);
      const parkingSpacesError = !isNaN(+parkingSpaces);

      if (
        !numberOfLoans ||
        !originationDate ||
        !unpaidPrincipalBalance ||
        !currentInterestRate ||
        !Number.isInteger(term) ||
        !Number.isInteger(amortization) ||
        !recourse ||
        !monetaryDefault ||
        !maturityDefault ||
        !technicalDefault ||
        !propertyType ||
        !propertyType.length ||
        !numberOfLoansError ||
        !unpaidPrincipalBalanceError ||
        !currentInterestRateError ||
        !termError ||
        !amortizationError ||
        !yearRenovatedError ||
        !sizeError ||
        !loanAmountError ||
        !totalPayoffError ||
        !estCollateralValueError ||
        !defaultInterestRateError ||
        !yearBuiltError ||
        !ownerOccError ||
        !unitsError ||
        !storiesError ||
        !buildingsError ||
        !parkingSpacesError
      ) {
        return false;
      } else return true;
    },
    checkImagesInfoValidity(state) {
      if (!state.assetInfo.images || !state.assetInfo.images.length) {
        return false;
      } else {
        return true;
      }
    },
    checkCaTocConfirmed(state, getters, rootState) {
      const isAutoDeposit = rootState.UserSettings.userSettings.autoDeposit;
      const assetInfo = state.assetInfo;

      if (isAutoDeposit) {
        return (
          assetInfo.TOCConfirmed &&
          assetInfo.caConfirmed &&
          assetInfo.BDAConfirmed
        );
      }
      return assetInfo.TOCConfirmed && assetInfo.caConfirmed;
    },
    checkAdvisors(state) {
      return (
        [
          "Published",
          "CallForOffers",
          "ContractPending.BestAndFinal",
          "ContractPending.CallForOffers",
          "UnderSelection.BestAndFinal",
          "UnderSelection.CallForOffers",
          "BestAndFinal",
          "UnderContract",
          "Closed",
          "OnHold.Published",
          "OnHold.CallForOffers",
          "OnHold.BestAndFinal",
          "ListingExpired",
        ].includes(state.assetInfo.status) || state.assetInfo.contactsDisclosed
      );
    },
    checkIsAllAssetComplete(state, getters) {
      return (
        getters.checkImagesInfoValidity &&
        getters.checkMainInfoValidity &&
        getters.checkNoteOverview &&
        getters.checkOverviewInfoValidity &&
        getters.checkPropertyOverview &&
        getters.checkCaTocConfirmed
      );
    },
  },
  mutations: {
    setAssetInfoCurrentStep(state, step) {
      state.assetInfo.currentStep = step;
    },
    setTotalCount(state, count) {
      state.assetTotalCount = count;
    },
    setPropertyOverviewPanel(state, payload) {
      state.propertyOverviewPanel = payload;
    },
    setSellerDialog(state, payload) {
      state.sellerDialog = payload;
    },
    setCarouselAssets(state, payload) {
      Vue.set(state.carouselAssetsData, "assets", payload);
    },
    setCarouselImages(state, payload) {
      Vue.set(state.carouselAssetsData.images, payload.id, payload.result);
    },
    addTiersToAssetInfo(state, payload) {
      state.assetInfo.tier1_id = payload.tier1_id;
      state.assetInfo.tier2_id = payload.tier2_id;
      state.assetInfo.tier_home_id = payload.folderId;
    },
    setIsGoToNextPage(state, payload) {
      state.isGoToNextPage = payload;
    },
    setNextPagePermission(state, payload) {
      state.isAllowedToGoToNextPage = payload;
    },
    changeAssetInfo(state, payload) {
      state.assetInfo = { ...state.assetInfo, ...payload };
    },
    putCreatedAssetData(state, payload) {
      state.currentCreatedAsset = payload;
    },
    setVdrData(state, { tier1, tier2 }) {
      state.vdrUploadLinks.tier1 = tier1;
      state.vdrUploadLinks.tier2 = tier2;
    },
    updateVdrState(state, payload) {
      state.isVdrFolderCreated = payload;
    },
    resetVdrState(state) {
      state.assetInfo.tier_home_id = null;
      state.vdrUploadLinks.tier1 = null;
      state.vdrUploadLinks.tier2 = null;
    },
    changeCurrentVdrName(state, payload) {
      state.currentVdrName = payload;
    },
    updateCurrentAsset(state, payload) {
      const { noteOverview, propertyOverview, contacts, ...others } = payload;
      if (noteOverview) {
        state.assetInfo.noteOverview = {
          ...noteOverview,
          unpaidPrincipalBalance:
            (noteOverview.unpaidPrincipalBalance &&
              noteOverview.unpaidPrincipalBalance.amount) ||
            "",
          totalPayoff:
            (noteOverview.totalPayoff && noteOverview.totalPayoff.amount) || "",
          estCollateralValue:
            (noteOverview.estCollateralValue &&
              noteOverview.estCollateralValue.amount) ||
            "",
          loanAmount:
            (noteOverview.loanAmount && noteOverview.loanAmount.amount) || "",
          assumptionOfLoanAllowed: noteOverview.assumptionOfLoanAllowed
            ? "yes"
            : noteOverview.assumptionOfLoanAllowed === false
              ? "no"
              : noteOverview.assumptionOfLoanAllowed,

          prePaymentLimitations: noteOverview.prePaymentLimitations
            ? "yes"
            : noteOverview.prePaymentLimitations === false
              ? "no"
              : noteOverview.prePaymentLimitations,
          monetaryDefault: noteOverview.monetaryDefault
            ? "yes"
            : noteOverview.monetaryDefault === false
              ? "no"
              : noteOverview.monetaryDefault,
          maturityDefault: noteOverview.maturityDefault
            ? "yes"
            : noteOverview.maturityDefault === false
              ? "no"
              : noteOverview.maturityDefault,
          technicalDefault: noteOverview.technicalDefault
            ? "yes"
            : noteOverview.technicalDefault === false
              ? "no"
              : noteOverview.technicalDefault,
          recourse: noteOverview.recourse
            ? "yes"
            : noteOverview.recourse === false
              ? "no"
              : noteOverview.recourse,
          modified: noteOverview.modified
            ? "yes"
            : noteOverview.modified === false
              ? "no"
              : noteOverview.modified,
          originationDate:
            (noteOverview.originationDate &&
              Vue.moment(noteOverview.originationDate).format("MM-DD-YYYY")) ||
            "",
          maturityDate:
            (noteOverview.maturityDate &&
              Vue.moment(noteOverview.maturityDate).format("MM-DD-YYYY")) ||
            "",
        };
      }
      if (propertyOverview) {
        state.assetInfo.propertyOverview = propertyOverview;
        state.assetInfo.propertyOverview.condo = transformBoolean(
          propertyOverview.condo
        );
        state.assetInfo.propertyOverview.multiProperty = transformBoolean(
          propertyOverview.multiProperty
        );
        state.assetInfo.propertyOverview.isOwnerOccupied = transformBoolean(
          propertyOverview.isOwnerOccupied
        );
      }

      if (contacts.length) {
        contacts.forEach((item, idx) => {
          item.id = `${item.emailAddress}-${idx}`;
        });
      }

      state.assetInfo = {
        ...state.assetInfo,
        ...others,
        contacts,
        upbDate:
          (others.upbDate && Vue.moment(others.upbDate).format("MM-DD-YYYY")) ||
          "",
        principalBalance:
          (others.principalBalance && others.principalBalance.amount) || "",
        listingPrice: (others.listingPrice && others.listingPrice.amount) || "",
        assetStatus: others.assetStatus,
        bidDepositSum:
          (others.bidDepositSum && others.bidDepositSum.amount) || "",
        description: others.description || "",
        callForOffersPeriod: others.callForOffersPeriod || {
          startDate: "",
          endDate: "",
        },
        bestAndFinalPeriod: others.bestAndFinalPeriod || {
          startDate: "",
          endDate: "",
        },
      };
    },
    resetAssetInfo(state) {
      state.assetInfo = JSON.parse(JSON.stringify(INITIAL_ASSET_INFO));
      // vdrUploadLinks: {
      //   tier1: "",
      //   tier2: ""
      // },
      state.vdrUploadLinks = {
        tier1: "",
        tier2: "",
      };
      state.isVdrFolderCreated = false;
    },
    addContactsToAssetInfo(state, payload) {
      state.assetInfo.contacts = state.assetInfo.contacts || [];
      if (
        state.assetInfo.contacts.some(
          (contact) => contact.emailAddress === payload.emailAddress
        )
      )
        return;
      if (Array.isArray(payload)) {
        state.assetInfo.contacts = [...state.assetInfo.contacts, ...payload];
      } else {
        state.assetInfo.contacts.push(payload);
      }
    },
    addContacts(state, payload) {
      state.assetInfo.contacts = [...payload];
    },
    addDefaultContact(state, user) {
      state.assetInfo.contacts = [user];
    },
    deleteContactFromAssetInfo(state, payload) {
      state.assetInfo.contacts = state.assetInfo.contacts.filter(
        (contact) => contact.emailAddress !== payload
      );
    },
    addImages(state, payload) {
      state.assetInfo.images = (state.assetInfo.images && [
        ...state.assetInfo.images,
        payload,
      ]) || [payload];
    },
    caConfirmed(state, payload) {
      state.assetInfo.caConfirmed = payload;
    },
    TOCConfirmed(state, payload) {
      state.assetInfo.TOCConfirmed = payload;
    },
    setImages(state, payload) {
      state.assetInfo.images = [...payload];
    },
    deleteImage(state, payload) {
      state.assetInfo.images =
        (state.assetInfo.images &&
          state.assetInfo.images.filter((key) => key !== payload)) ||
        [];
    },
    customCADoc(state, payload) {
      state.assetInfo.customCADoc = payload;
    },
    callForOffersStart(state, payload) {
      state.assetInfo.callForOffersPeriod.startDate = payload;
    },
    callForOffersEnd(state, payload) {
      state.assetInfo.callForOffersPeriod.endDate = payload;
    },
    bestAndFinalStart(state, payload) {
      state.assetInfo.bestAndFinalPeriod.startDate = payload;
    },
    bestAndFinalEnd(state, payload) {
      state.assetInfo.bestAndFinalPeriod.endDate = payload;
    },
    upbDate(state, payload) {
      state.assetInfo.upbDate = payload;
    },
    assetDescription(state, payload) {
      state.assetInfo.description = payload;
    },
    marketingStatus(state, payload) {
      state.assetInfo.status = payload;
    },

    assetStatus(state, payload) {
      state.assetInfo.assetStatus = payload;
    },
    assetType(state, payload) {
      state.assetInfo.type = payload;
    },
    displayDueDate(state, payload) {
      state.assetInfo.displayDueDate = payload;
    },
    contactsDisclosed(state, payload) {
      state.assetInfo.contactsDisclosed = payload;
    },
    bidDepositChannel(state, payload) {
      state.assetInfo.preferredBidDepositChannel = payload;
    },
    country(state, payload) {
      state.assetInfo.address.country = payload;
    },
    state(state, payload) {
      state.assetInfo.address.state = payload;
    },

    bidDepositSum(state, payload) {
      state.assetInfo.bidDepositSum = payload;
    },
    principalBalance(state, payload) {
      state.assetInfo.principalBalance = payload;
      state.assetInfo.noteOverview.unpaidPrincipalBalance = payload;
    },
    city(state, payload) {
      state.assetInfo.address.city = payload;
    },
    zipCode(state, payload) {
      state.assetInfo.address.zipCode = payload;
    },
    listingPrice(state, payload) {
      state.assetInfo.listingPrice = payload;
    },
    address(state, payload) {
      state.assetInfo.address.address = payload;
    },
    assetTitle(state, payload) {
      state.assetInfo.name = payload;
    },
    privacy(state, payload) {
      state.assetInfo.privacy = payload;
    },
    requireBidDeposit(state, payload) {
      state.assetInfo.requireBidDeposit = payload;
    },
    pricingPlan(state, payload) {
      state.assetInfo.pricingPlan = payload;
    },
    bestCashOffer(state, payload) {
      state.assetInfo.bestCashOffer = payload;
    },
    originationDate(state, payload) {
      state.assetInfo.noteOverview.originationDate = payload;
    },
    maturityDate(state, payload) {
      state.assetInfo.noteOverview.maturityDate = payload;
    },
    propertyType(state, payload) {
      state.assetInfo.propertyOverview.propertyType = payload;
    },
    collateralSubType(state, payload) {
      state.assetInfo.propertyOverview.subtype = payload;
    },
    units(state, payload) {
      state.assetInfo.propertyOverview.unitOfMeasure = payload;
    },
    constructionType(state, payload) {
      state.assetInfo.propertyOverview.constructionType = payload;
    },
    propertyTaxesCurrent(state, payload) {
      state.assetInfo.propertyOverview.propertyTaxesCurrent = payload;
    },
    buildingLayout(state, payload) {
      state.assetInfo.propertyOverview.buildingLayout = payload;
    },
    condo(state, payload) {
      state.assetInfo.propertyOverview.condo = payload;
    },
    class(state, payload) {
      state.assetInfo.propertyOverview.classField = payload;
    },
    parkingType(state, payload) {
      state.assetInfo.propertyOverview.parkingType = payload;
    },
    environmentalIssues(state, payload) {
      state.assetInfo.propertyOverview.environmentalIssues = payload;
    },
    largestTenantCredit(state, payload) {
      state.assetInfo.propertyOverview.largestTenantCredit = payload;
    },
    tenantType(state, payload) {
      state.assetInfo.propertyOverview.tenantType = payload;
    },
    ownerOccupied(state, payload) {
      state.assetInfo.propertyOverview.isOwnerOccupied = payload;
    },
    multiProperty(state, payload) {
      state.assetInfo.propertyOverview.multiProperty = payload;
    },
    propertyCondition(state, payload) {
      state.assetInfo.propertyOverview.propertyCondition = payload;
    },
    ofLoans(state, payload) {
      state.assetInfo.noteOverview.numberOfLoans = payload;
    },
    loanAmount(state, payload) {
      state.assetInfo.noteOverview.loanAmount = payload;
    },
    unpaidPrincipalBalance(state, payload) {
      state.assetInfo.noteOverview.unpaidPrincipalBalance = payload;
    },
    totalPayoff(state, payload) {
      state.assetInfo.noteOverview.totalPayoff = payload;
    },
    estCollateralValue(state, payload) {
      state.assetInfo.noteOverview.estCollateralValue = payload;
    },
    initialInterestRate(state, payload) {
      state.assetInfo.noteOverview.initialInterestRate = payload;
    },
    initialInterestType(state, payload) {
      state.assetInfo.noteOverview.initialInterestType = payload;
    },
    currentInterestRate(state, payload) {
      state.assetInfo.noteOverview.currentInterestRate = payload;
    },
    defaultInterestRate(state, payload) {
      state.assetInfo.noteOverview.defaultInterestRate = payload;
    },
    term(state, payload) {
      state.assetInfo.noteOverview.term = payload;
    },
    amortization(state, payload) {
      state.assetInfo.noteOverview.amortization = payload;
    },
    recourse(state, payload) {
      state.assetInfo.noteOverview.recourse = payload;
    },
    modified(state, payload) {
      state.assetInfo.noteOverview.modified = payload;
    },
    assumptionOfLoanAllowed(state, payload) {
      state.assetInfo.noteOverview.assumptionOfLoanAllowed = payload;
    },
    prePaymentLimitations(state, payload) {
      state.assetInfo.noteOverview.prePaymentLimitations = payload;
    },
    penalty(state, payload) {
      state.assetInfo.noteOverview.penalty = payload;
    },
    monetaryDefault(state, payload) {
      state.assetInfo.noteOverview.monetaryDefault = payload;
    },
    maturityDefault(state, payload) {
      state.assetInfo.noteOverview.maturityDefault = payload;
    },
    technicalDefault(state, payload) {
      state.assetInfo.noteOverview.technicalDefault = payload;
    },
    yearBuilt(state, payload) {
      state.assetInfo.propertyOverview.yearBuilt = payload;
    },
    yearRenovated(state, payload) {
      state.assetInfo.propertyOverview.yearRenovated = payload;
    },
    lotSize(state, payload) {
      state.assetInfo.propertyOverview.size = payload;
    },
    APN(state, payload) {
      state.assetInfo.propertyOverview.apn = payload;
    },
    nraTotal(state, payload) {
      state.assetInfo.propertyOverview.nraTotal = payload;
    },
    zoning(state, payload) {
      state.assetInfo.propertyOverview.zoning = payload;
    },
    ofUnitsSuites(state, payload) {
      state.assetInfo.propertyOverview.units = payload;
    },
    ofStories(state, payload) {
      state.assetInfo.propertyOverview.stories = payload;
    },
    ofBuildings(state, payload) {
      state.assetInfo.propertyOverview.buildings = payload;
    },
    parkingSpaces(state, payload) {
      state.assetInfo.propertyOverview.parkingSpaces = payload;
    },
    feeLeasehold(state, payload) {
      state.assetInfo.propertyOverview.feeLeasehold = payload;
    },
    ownerOcc(state, payload) {
      state.assetInfo.propertyOverview.ownerOcc = payload;
    },
    setFavoriteIds(state, ids) {
      state.favorites = ids;
    },
    addUserFavoriteAsset(state, id) {
      state.favorites.push(id);
    },
    removeUserFavoriteAsset(state, id) {
      const index = state.favorites.findIndex((itemId) => itemId === id);
      if (index > -1) {
        state.favorites.splice(index, 1);
      }
    },
  },
  actions: {
    async fetchAllAssets({ commit }, params) {
      const response = await Asset.searchAssets(params)
        .then(({ data }) => {
          console.log("data - ", data);
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
    },
    async changeAssetPaymentPlan({ commit }, params) {
      const response = await Asset.changeAssetPaymentPlan(params)
        .then(({ data }) => {
          // console.log("data - ", data);
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
    },
    async revokeAsset({ commit }, params) {
      const response = await Asset.revokeAsset(params)
        .then(({ data }) => {
          // console.log("data - ", data);
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return response;
    },
    setAssetInfoCurrentStep({ commit }, step) {
      commit("setAssetInfoCurrentStep", step);
    },
    addDefaultContact({ rootState, state, commit }, advStatus) {
      if (advStatus) {
        state.contactsEditingStatus = true;
      }
      if (!state.contactsEditingStatus && !state.assetInfo.contacts.length) {
        let defaultUser = {
          firstName: rootState.user.firstName,
          lastName: rootState.user.lastName,
          companyName: rootState.user.companyName,
          emailAddress: rootState.user.email,
          officePhone: rootState.user.officePhone,
          contactAvatar: rootState.user.sellerProfile.avatar,
        };
        commit("addDefaultContact", defaultUser);
      }
    },
    async countSellerAssets({ commit }, params) {
      await countSellerAssets(params)
        .then((resp) => {
          if (resp) {
            commit("setTotalCount", resp.data.searchAssets.total);
          }
        })
        .catch((err) => {
          ErrorParser(err);
        });
    },
    async createDraftAsset(
      { rootState, state, commit, dispatch, getters, rootGetters },
      {
        tier1_id,
        tier2_id,
        tier_home_id,
        saveDraft = false,
        currentStep = null,
        ...restparams
      }
    ) {
      let assetInfo = normalizeForm(state.assetInfo);
      assetInfo.address.country = "USA";
      assetInfo.tier1_id = tier1_id;
      assetInfo.tier2_id = tier2_id;
      assetInfo.tier_home_id = tier_home_id;
      assetInfo.currentStep = currentStep;

      if (assetInfo.contacts?.length) {
        assetInfo.contacts.forEach((contactsInput) => {
          delete contactsInput.avatar;
          delete contactsInput.info;
        });
      }
      if (!router.currentRoute.params.id) {
        const data = await Asset.createPreAsset({
          ...assetInfo,
          ownerEmail: rootState.auth.user.email,
        });
        let id = data.data.createAsset.id;

        await dispatch("getAssetById", id);
        if (!saveDraft) {
          router.push({
            path: `/asset/${id}/edit`,
          });
        } else {
          if (assetInfo.status === "Draft") {
            router.push({
              path: "/seller/filters/active",
            });
          } else {
            router.push({
              path: "/seller/filters/active",
            });
          }
        }
      } else {
        if (getters.checkNoteOverview) {
          await Asset.editAssetOnNext({
            ...assetInfo,
            id: router.currentRoute.params.id,
          });
        } else {
          await Asset.editAssetWithoutNoteOverview({
            ...assetInfo,
            id: router.currentRoute.params.id,
          });
        }
        if (!saveDraft) {
          router.push({
            path: `/asset/${router.currentRoute.params.id}/edit`,
          });
        } else {
          if (assetInfo.status === "Draft") {
            router.push({
              path: `/creationComplete/${router.currentRoute.params.id}`,
            });
          } else {
            router.push({
              path: `/asset/${router.currentRoute.params.id}`,
            });
          }
        }
      }
    },
    async editAssetOnNext({ rootState, rootGetters, state, commit }, params) {
      const { stepIdx } = params || {};

      const assetInfo = normalizeForm(state.assetInfo);
      commit("assetCreationSpinner", true, { root: true });
      if (assetInfo?.contacts?.length) {
        assetInfo.contacts.forEach((contactsInput) => {
          delete contactsInput.avatar;
          delete contactsInput.info;
        });
      }
      try {
        let data;
        // API call
        data = await Asset.editAssetOnNext({
          ...assetInfo,
          id: router.currentRoute.params.id,
          currentStep: rootGetters["Helpers/getAssetCreationFlowStep"](stepIdx),
        });

        commit("assetCreationSpinner", false, { root: true });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("ERR");
        // eslint-disable-next-line no-console
        console.log(err);
        commit("assetCreationSpinner", false, { root: true });
      }
    },
    async finishLater({ rootState, state, commit }, params) {
      const { currentStep = null } = params || {};
      let defaultUser = {
        firstName: rootState.user.firstName,
        lastName: rootState.user.lastName,
        companyName: rootState.user.companyName,
        emailAddress: rootState.user.email,
        officePhone: rootState.user.officePhone,
      };

      const assetInfo = normalizeForm(state.assetInfo);
      commit("spinner", true, { root: true });
      if (assetInfo.contacts && assetInfo.contacts.length > 0) {
        assetInfo.contacts.forEach((contactsInput) => {
          delete contactsInput.avatar;
          delete contactsInput.info;
        });
      }
      try {
        let data;
        const routerParamId = router.history.current.params.id;
        if (routerParamId) {
          data = await Asset.editAssetOnNext({
            ...assetInfo,
            id: routerParamId,
            currentStep: currentStep,
          });
        } else {
          assetInfo.contacts = [defaultUser, ...assetInfo?.contacts];
          assetInfo.address.country = "USA";
          data = await Asset.createAsset({
            ...assetInfo,
            ownerEmail: rootState.auth.user.email,
            currentStep: currentStep,
          });
        }

        router
          .push({
            path: "/seller/filters/active",
          })
          .catch();
        commit("resetAssetInfo");

        commit("spinner", false, { root: true });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("err", err);
        let errText = "";
        if (
          err &&
          err.errors &&
          Array.isArray(err.errors) &&
          err?.errors?.length > 0 &&
          err?.errors[0] &&
          err?.errors?.errorInfo?.length > 0
        ) {
          let errText = err?.errors[0]?.errorInfo[0];
        }
        if (errText.includes("Code: NotFound; Msg: Item not")) {
          commit(
            "openSnackBar",
            {
              text: "Our VDR storage service is experiencing an issue. Please try to save asset later.",
              color: "warning",
            },
            { root: true }
          );
        } else {
          // eslint-disable-next-line no-console
          console.log("ERROR", err);
          commit(
            "openSnackBar",
            {
              text: "There are required fields you need to fill out",
              color: "warning",
            },
            { root: true }
          );
        }

        commit("spinner", false, { root: true });
      }
    },
    async editAsset({ rootState, rootGetters, state, commit }, params) {
      const { stepIdx } = params || {};

      let defaultUser = {
        firstName: rootState.user.firstName,
        lastName: rootState.user.lastName,
        companyName: rootState.user.companyName,
        emailAddress: rootState.user.email,
        officePhone: rootState.user.officePhone,
      };
      const assetInfo = normalizeForm(state.assetInfo);
      commit("spinner", true, { root: true });
      if (assetInfo.contacts && assetInfo.contacts.length > 0) {
        assetInfo.contacts.forEach((contactsInput) => {
          delete contactsInput.avatar;
          delete contactsInput.info;
        });
      }
      // if (!assetInfo.contactsDisclosed) {
      //   assetInfo.contacts = [];
      // }
      try {
        let data;
        const routerParamId = router.history.current.params.id;
        if (routerParamId) {
          data = await Asset.editAssetOnNext({
            ...assetInfo,
            id: routerParamId,
            currentStep: stepIdx
              ? rootGetters["Helpers/getAssetCreationFlowStep"](stepIdx)
              : null,
          });
        } else {
          assetInfo.contacts = [defaultUser, ...assetInfo?.contacts];
          assetInfo.address.country = "USA";
          if (!assetInfo.contactsDisclosed) {
            assetInfo.contacts = [];
          }
          data = await Asset.createAsset({
            ...assetInfo,
            ownerEmail: rootState.auth.user.email,
          });
        }

        commit("resetAssetInfo");
        const isContactPage =
          router.history.current.fullPath.includes("contact");
        if (routerParamId && assetInfo.status === "Draft") {
          router.push({
            path: isContactPage
              ? `/creationComplete/${routerParamId}`
              : "/seller/filters/active",
          });
        } else {
          router.push({
            path: "/seller/filters/active",
          });
        }
        commit("spinner", false, { root: true });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("err", err);
        let errText = "";
        if (
          err &&
          err.errors &&
          Array.isArray(err.errors) &&
          err?.errors?.length > 0 &&
          err?.errors[0] &&
          err?.errors?.errorInfo?.length > 0
        ) {
          let errText = err?.errors[0]?.errorInfo[0];
        }
        if (errText.includes("Code: NotFound; Msg: Item not")) {
          commit(
            "openSnackBar",
            {
              text: "Our VDR storage service is experiencing an issue. Please try to save asset later.",
              color: "warning",
            },
            { root: true }
          );
        } else {
          // eslint-disable-next-line no-console
          console.log("ERROR", err);
          commit(
            "openSnackBar",
            {
              text: "There are required fields you need to fill out",
              color: "warning",
            },
            { root: true }
          );
        }

        commit("spinner", false, { root: true });
      }
    },
    getUserFavoriteAssetsIds({ commit }) {
      Asset.getUserFavoriteAssetsIds().then(({ data }) => {
        commit(
          "setFavoriteIds",
          data.getUserFavoriteAssets.assets.map((asset) => asset.id)
        );
      });
    },
    async getAssetById({ commit }, id) {
      commit("spinner", true, { root: true });
      try {
        let asset = await Asset.getAsset(id);
        asset = asset.data.getAsset;
        if (asset?.propertyOverview?.class) {
          asset.propertyOverview.classField = asset.propertyOverview?.class;
        }
        if (asset?.propertyOverview?.subtype === null) {
          asset.propertyOverview.subtype = [];
        }
        if (asset?.assetStatus === null) {
          asset.assetStatus = [];
        }

        commit("updateCurrentAsset", asset);

        const adminValuation = localStorage.getItem("adminValuation");
        if (adminValuation) {
          const parsedValuation = JSON.parse(adminValuation);
          const {
            perfomingLoanDetails = {
              term: "",
              originationDate: "",
              amortization: "",
              interestType: "",
            },
            specifics = {
              interestRate: "",
            },
          } = parsedValuation ?? {};
          const {
            term = "",
            originationDate = "",
            amortization = "",
            interestType = "",
          } = perfomingLoanDetails;
          const { interestRate = "" } = specifics;

          commit("term", term);
          commit("currentInterestRate", interestRate.split("%").join(""));
          commit("originationDate", originationDate);
          commit("amortization", amortization);
          commit("initialInterestType", interestType);

          localStorage.removeItem("adminValuation");
        }
        commit("spinner", false, { root: true });
      } catch (err) {
        console.error(err);
        commit("spinner", false, { root: true });
      }
    },
    async getAssetsForCarousel({ commit }) {
      try {
        const data = await Asset.searchAssets({
          offset: 0,
          size: 16,
        });

        const assets = data.data.searchAssets.assets;

        commit("setCarouselAssets", assets);

        assets.forEach((asset) => {
          if (asset.images) {
            Storage.get(`${asset.images[0]}-thumbnail`)
              .then((result) => {
                commit("setCarouselImages", {
                  result,
                  id: asset.id,
                });
              })
              .catch((err) => console.error(err));
          }
        });
      } catch (err) {
        console.error(err);
      }
    },
    async sendAssetViewTrack({}, assetId) {
      try {
        const { data } = await trackAssetView({ asset_id: assetId });
        // eslint-disable-next-line no-console
        console.log("sendAssetViewTrack , data", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
