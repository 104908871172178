<template>
  <div class="headerAdminNavigation">
    <div class="headerTabs">
      <div class="pa-0">
        <v-row no-gutters class="headerTabs__visual">
          <v-col cols="12" md="8" class="pa-0">
            <SubHeaderTabsDefault
              v-if="activeTab !== 'profile'"
              :activeTab="activeTab"
            >
              <div v-if="$vuetify.breakpoint.xs">
                <v-btn
                  color="#2969FF"
                  v-if="$store.getters.isSeller && showPublishButton"
                  @click="navigateToChoosePlan"
                  height="64px"
                  width="224px"
                >
                  <div class="white--text">Publish Asset</div>
                </v-btn>
              </div>
            </SubHeaderTabsDefault>

            <SubHeaderTabsProfile v-else :activeTab="activeTab" />
          </v-col>

          <v-col
            cols="0"
            md="4"
            class="pa-0 d-flex justify-end"
            v-if="!$vuetify.breakpoint.xs"
          >
            <div
              class="d-flex justify-end align-center"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              <v-btn
                v-if="!$store.getters.isBuyer"
                text
                color="white"
                to="/profile/buyer"
                class="ml-5"
              >
                + Become a Buyer
              </v-btn>
              <div v-if="SHOW_SELLER_PROFILE_ENV">
                <v-btn
                  @click="onBecomeSellerClick"
                  v-if="!$store.getters.isSeller"
                  text
                  color="white"
                  to="/profile/seller"
                  class="ml-3"
                >
                  + Become a Seller
                </v-btn>
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn
                color="#2969FF"
                v-if="$store.getters.isSeller && showPublishButton"
                @click="navigateToChoosePlan"
                height="64px"
                width="224px"
              >
                <div class="white--text">Publish Asset</div>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      class="headerSubTabs"
      v-if="subListing && !['valuations', 'profile'].includes(activeTab)"
    >
      <v-container>
        <v-tabs
          color="secondary"
          height="64"
          :optional="isOptional"
          center-active
        >
          <v-tab v-for="(item, i) in subListing" :key="i" :to="item.link">
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-container>
    </div>
    <ProfileSellerEditDialog
      :custom="true"
      :step="profileSellerEditStep"
      :active="isSellerEditDialogOpen"
      @setIsDialogOpen="(val) => (isSellerEditDialogOpen = val)"
      @setStep="(val) => (profileSellerEditStep = val)"
    />
  </div>
</template>

<script>
import showRolesAccessMixin from "@/mixins/showRolesAccessMixin";
import ProfileSellerEditDialog from "@/components/Profile/ProfileSeller/ProfileSellerEditDialog.vue";
import SubHeaderTabsDefault from "@/components/SubHeader/SubHeaderTabsDefault.vue";
import SubHeaderTabsProfile from "@/components/SubHeader/SubHeaderTabsProfile.vue";
import app_config from "@/app_config";

export default {
  name: "SubHeader",

  mixins: [showRolesAccessMixin],
  components: {
    ProfileSellerEditDialog,
    SubHeaderTabsDefault,
    SubHeaderTabsProfile,
  },

  data() {
    return {
      subListing: null,
      profileSellerEditStep: 1,
      isSellerEditDialogOpen: false,
      links: [
        {
          title: "Buying",
          slug: "buyer",
          link: "/buyer",
          items: [
            {
              title: "Single Asset Offers",
              link: "/buyer/single-asset-offers",
            },
            {
              title: "Multi-Asset Offers",
              link: "/buyer/multi-asset-offers",
            },
            {
              title: "Favorites",
              link: "/buyer/favorites",
            },
          ],
        },
        {
          title: "Selling",
          slug: "seller",
          link: "/seller",
        },
        {
          title: "Admin",
          slug: "admin",
          link: "/admin",
          items: [
            {
              title: "Buyers",
              link: "/admin/buyers",
            },
            {
              title: "Sellers",
              link: "/admin/sellers",
            },
            {
              title: "Assets",
              link: "/admin/asset-approval",
            },
          ],
        },
        {
          title: "Profile",
          slug: "profile",
          link: "/profile",
          items: [
            {
              title: "Account Settings",
              link: "/profile/settings",
            },
            {
              title: "Profile Data",
              link: "/profile/edit",
            },
            {
              title: "Buyer Info",
              link: "/profile/buyer",
            },
            {
              title: "Seller Info",
              link: "/profile/seller",
            },
            {
              title: "Billing",
              link: "/profile/billing",
            },
          ],
        },
        {
          title: "Favorites",
          slug: "favorites",
          link: "/buyer/favorites",
        },
      ],
    };
  },

  watch: {
    $route() {
      this.createSubListing();
    },
  },

  created() {
    this.createSubListing();
  },

  methods: {
    createSubListing() {
      this.links.map((item) => {
        if (item.slug === this.activeTab) {
          if (item.hasOwnProperty("items")) {
            this.subListing = item.items;
          } else {
            this.subListing = null;
          }
        }
      });
    },
    async onBecomeSellerClick() {
      if (!this.$store.state.user.filled.user) {
        const isCompleted = await this.$root.openProfileEditDialog({
          firstComplete: true,
          afterFinishBtnClick: () => (this.isSellerEditDialogOpen = true),
        });
        if (!isCompleted) {
          return;
        }
      } else {
        this.isSellerEditDialogOpen = true;
      }
    },
    navigateToChoosePlan() {
      const sellerProfile = this.$store.state.user.sellerProfile;
      if (sellerProfile && sellerProfile.status !== "Revoked") {
        if (localStorage.getItem("currentUserProfile")) {
          if (
            localStorage.getItem("currentUserProfile").roles &&
            localStorage
              .getItem("currentUserProfile")
              .roles.includes("Concierges")
          ) {
            this.$router.push("/choosePlan");
          } else {
            this.$router.push("/choosePlan");
          }
        } else {
          this.$store.commit("openSnackBar", {
            text: "You need update your profile first",
            color: "warning",
          });
        }
      } else {
        this.$store.commit("openSnackBar", {
          text: "Your access is blocked by administrator. Check your email for more information or contact us via info@xchange.loans.",
          color: "warning",
        });
      }
    },
  },

  computed: {
    sellerDialog: {
      get() {
        return this.$store.state.asset.sellerDialog;
      },
      set(val) {
        this.$store.commit("asset/setSellerDialog", val);
      },
    },
    isOptional() {
      return this.$route.fullPath === "/admin";
    },
    SHOW_SELLER_PROFILE_ENV() {
      // eslint-disable-next-line no-console

      return app_config.SHOW_SELLER_PROFILE === "TRUE";
    },
    showPublishButton() {
      // return  this.$route;
      return [
        "/buyer/single-asset-offers",
        "/buyer/multi-asset-offers",
        "/buyer/favorites",
        "/seller/filters/active",
        "/seller/filters/archive",
        "/valuations",
      ].includes(this.$route.fullPath);
    },
    activeTab() {
      if (
        [
          "/profile/settings",
          "/profile/edit",
          "/profile/seller",
          "/profile/buyer",
          "/profile/billing",
        ].includes(this.$route.path)
      ) {
        return "profile";
      } else if (
        [
          "/buyer/single-asset-offers",
          "/buyer/multi-asset-offers",
          "/buyer/favorites",
        ].includes(this.$route.path)
      ) {
        return "buyer";
      } else if (
        ["/seller/filters/active", "/seller/filters/archive"].includes(
          this.$route.path
        )
      ) {
        return "seller";
      } else if (
        [
          "/admin",
          "/admin/buyers",
          "/admin/sellers",
          "/admin/asset-approval",
          "/admin/listing",
          "/admin/sellersNew",
          "/admin/buyer-requests",
          "/admin/buyers/all",
          "/admin/buyers/requests",
        ].includes(this.$route.path)
      ) {
        return "admin";
      } else if (["/valuations"].includes(this.$route.path)) {
        return "valuations";
      } else {
        return "";
      }
    },
    visibleListing() {
      return this.links.filter(function (u) {
        return u.included();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: -1 !important;
}
::v-deep {
  .v-tab {
    color: map-get($colors, "primary") !important;
  }
  .v-btn__content {
    font-size: 16px;
  }
}

.headerTabs {
  background-color: map-get($colors, "primary-darken");
  overflow: hidden;
}

.headerTabs__visual {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ::v-deep .v-tabs {
    &-slider-wrapper {
      width: 0 !important;
    }

    .v-item-group {
      background-color: map-get($colors, "primary-darken") !important;

      .v-tab {
        min-width: 150px;
        color: white !important;

        &--active {
          background-color: white !important;
          color: map-get($colors, "primary-darken") !important;
          .v-icon {
            color: map-get($colors, "primary-darken") !important;
          }
        }
        &:hover {
          background-color: #2969ff;
          color: white;
          .v-icon {
            color: white !important;
          }
        }
      }
    }
  }
}
@include mediaQ(
  map-get($mediabreakpoints, "xs"),
  map-get($mediabreakpoints, "sm")
) {
  .headerAdminNavigation {
    margin-top: 40px;
  }
}
.headerAdminNavigation {
  margin-top: 52px;
}
</style>
