import { API, graphqlOperation } from "aws-amplify";

export default function subscribeToDeclineBuyerRequest() {
  const query = `subscription {
    subscribeToDeclineBuyerRequest {
            id
            status
            creator
            type
        }
    }`;

  return API.graphql(graphqlOperation(query));
}
