<template>
  <v-dialog
    width="1143"
    v-model="dialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.xs"
    persistent
    @keydown.esc="dialog = false"
  >
    <div class="dialog-wrapper">
      <div class="d-flex justify-center">
        <div class="profile-dialog-title">Basic User Profile</div>
        <div class="content-wrapper">
          <div>
            <BasicInfoForm
              :form-data="user"
              :editable="true"
              @formUpdate="onFormUpdate"
            ></BasicInfoForm>
          </div>
        </div>
      </div>
      <div class="buttons-wrapper justify-center">
        <div class="d-flex justify-center">
          <AppButton
            buttonType="outlined"
            width="230px"
            height="40px"
            buttonText="Cancel"
            @click="close(false)"
          />
          <AppButton
            :disabled="loading || !valid"
            width="230px"
            color="#2969FF"
            height="40px"
            rounded
            shaped
            buttonText="Submit"
            class="ml-8"
            @click="submit"
          >
          </AppButton>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import BasicInfoForm from "@/components/ProfileEditDialog/BasicInfoForm.vue";
import AppButton from "@/components/ui/AppButton.vue";
import { mapActions } from "vuex";
export default {
  name: "UserProfileDialog",
  components: {
    AppButton,
    BasicInfoForm,
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      valid: false,
      loading: false,
      formData: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {},
  methods: {
    ...mapActions("User", ["updateUserProfile"]),
    onFormUpdate(formData) {
      this.valid = !formData?.invalid;
      this.formData = this.valid ? formData.form : (this.formData = null);
    },
    async submit() {
      const params = {
        ...this.user,
        ...this.formData,
      };

      this.loading = true;
      const isUpdatedUser = await this.updateUserProfile(params);
      this.loading = false;
      this.close(isUpdatedUser);
    },
    open() {
      this.dialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    close(v) {
      this.resolve(v);
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/overridings/ui/profileSeller.scss";

.profile-dialog-title {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  letter-spacing: 0.32px !important;
  color: #0f2d59;
}
.close-icon {
  position: absolute;
  right: 24px;
  top: 24px;
}
.dialog-wrapper {
  background: white;
  border-radius: 5px;
  max-width: 1143px;
  margin: 0 auto;
  overflow: auto;
  max-height: 891px;
  padding: 20px 10px;
  > div:first-child {
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    margin-bottom: 40px;
  }
}
.content-wrapper {
  width: 847px;
  position: relative;
}
</style>
