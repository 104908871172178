import { API } from "aws-amplify";

export default (id) => {
  const query = `query {
    getAsset(id: "${id}") {
        id,
        tier_1_whitelist
        tier_2_whitelist
      }
    }
  `;

  return API.graphql({
    query,
    authMode: "AWS_IAM",
  });
};
