import { Loader } from "google-maps";
import appConfig from "../app_config";

// console.log('language', navigator.language)

const options = {
  libraries: ["places"],
  language: "en-us",
  region: "US",
  types: ["hospital", "pharmacy", "bakery", "country"],
};

export default new Loader(appConfig.GOOGLE_MAP_API_KEY, options);
