export default new (class OfferCalculator {
  calcOfferPrice(totalUpb, percent) {
    return (totalUpb * percent) / 100;
  }
  calcOfferPercent(totalUpb, offerPrice) {
    // return (offerPrice * 100) / totalUpb;
    return (offerPrice * 100) / totalUpb;
  }
  formatMultiOfferForQuery(multiBidOffer, selectedAssets, user) {
    let variables = {
      type: "MultipleBid",
      notes: multiBidOffer.comment,
      dueDiligencePeriod: multiBidOffer.ddPeriod,
      closing: multiBidOffer.closing,
      bidPrice: multiBidOffer.offerPrice,
      earnestMoneyDeposit: multiBidOffer.earnestMoneyDeposit,
    };
    let offerAssets = selectedAssets.map((asset) => {
      return {
        id: asset.id,
        bidAllocation: {
          amount: asset.offerPrice,
        },
        title: asset.name,
        principalBalance: {
          amount: asset.upb,
        },
      };
    });
    let contacts = [
      {
        emailAddress: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        officePhone: user.officePhone,
      },
    ];
    variables.assets = offerAssets;
    variables.contacts = contacts;
    return variables;
  }
})();
