import { API } from "aws-amplify";

export default async (assetInfo) => {
  const mutation = `mutation edit(
        $id: String!,
        $ALA: DocumentInput
      ) {
        editAsset(
          id: $id
          ALA: $ALA
        ) {
          id
        }
      }`;

  let variables = {
    id: assetInfo.id,
    ALA: {
      type: "ALA",
      key: assetInfo.key,
    },
  };

  return API.graphql({
    query: mutation,
    variables,
  });
};
