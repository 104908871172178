import { API } from "aws-amplify";

export default ({ id, firstName, lastName, officePhone }) => {
  const mutation = `mutation adminUpdateUserProfile($id: String!, 
      $firstName: String,
      $lastName: String,
      $officePhone: String,
    ) {
      adminUpdateUserProfile(id: $id, 
        firstName: $firstName,
        lastName: $lastName,
        officePhone: $officePhone,
    ) {
        id
        roles
        firstName
        lastName
        officePhone
        address {
          country
          state
          city
          address 
          zipCode
        }
      }
    }`;
  return API.graphql({
    query: mutation,
    variables: {
      id,
      firstName,
      lastName,
      officePhone,
    },
  });
};
