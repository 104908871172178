import { API } from "aws-amplify";

export default (data) => {
  const query = `query searchOffersByUser($status: [String], $offerType: [String], $size: Int $offset: Int){
      searchOffersByUser(status: $status, offerType: $offerType, size: $size, offset: $offset) {
        total
      }
    }`;

  return API.graphql({
    query,
    variables: {
      status: data.statuses,
      offerType: data.offerType,
      offset: data.offset,
      size: data.size,
    },
  });
};
