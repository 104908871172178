import { API } from "aws-amplify";

export default (data) => {
  const query = `query searchAssetsSeller(
    $assetStatus: [String],
    $status: [String],
    $propertyType: [String],
    $size: Int,
    $offset: Int,
    $address: String,
    $state: [String],
    $owners: [String],
    $country: String,
    $city: String,
    $name: String,
    $sortBy:ESSortingBy,
    $orderBy: OrderByChoices,
    $id:String
    ){
      listAssetsByUser:searchAssets(
            assetStatus: $assetStatus,
            status: $status,
            propertyType: $propertyType,
            size: $size,
            offset: $offset
            address: $address,
            owners: $owners,
            state: $state,
            country: $country,
            city: $city,
            name: $name,
            sortBy: $sortBy,
            orderBy: $orderBy,
            id:$id
        ) {
            assets {
                id
                name
                type
                upb
                tier_1_whitelist
                
               
               documents{
                 type
               }
                offers 
                listingPrice {
                  amount
                }
                callForOffersPeriod {
                  startDate
                  endDate
                }
                bestAndFinalPeriod {
                  startDate
                  endDate
                }
                underSelectionPeriod {
                  startDate
                  endDate
                }
                status
                assetStatus
                address
                creationDate
                images
                assetType
                favoriteForUsers
                numberOfCAs
                numberOfOffers
                numberOfActiveOffers
                numberOfAllOffers
                numberOfTier2s
                calculatorResult{
                  id
                  lowValueRange
                  percentageLowValueRangeToUpb
                  highValueRange
                  percentageHighValueRangeToUpb
                  noteRateFloorCalculated
                  chartData
                  lowSideSavings
                  lowSideTime
                  highSideSavings
                  highSideTime
                  foreclosureProcess
                  receivershipProcess
                  biddingProcess
                  seasoning
                  remainingTerm
                  ltv
                  XLLoanClass
                  marketTier
                  recommendation
                }
                noteOverview{
                  unpaidPrincipalBalance{
                    amount
                    currency
                  }
                  totalPayoff{
                    amount
                    currency
                  }
                }
              }

        }
      }`;

  // eslint-disable-next-line no-console
  // console.log("q- ",query);

  // eslint-disable-next-line no-console
  // console.log("s - ", data);

  return API.graphql({
    query,
    variables: {
      status: data.status
        ? data.status
        : [
          "Published",
          "CallForOffers",
          "BestAndFinal",
          "UnderSelection.CallForOffers",
          "UnderSelection.BestAndFinal",
          "ContractPending.CallForOffers",
          "ContractPending.BestAndFinal",
          "Closed",
          "Draft",
        ],
      assetStatus: data.assetStatus || null,
      propertyType: data.propertyType || null,
      size: data.size || 12,
      offset: data.offset || 0,
      address: data.address || null,
      state: data.state || null,
      country: data.country || null,
      city: data.city || null,
      owners: data.owners,
      name: data.name || null,
      sortBy: data.sortBy || null,
      orderBy: data.orderBy || null,
      id: data.id,
    },
    authMode: "AWS_IAM",
  });
};

//normal query
