<template>
  <div class="app-navigation-drawer">
    <img
      src="@/assets/img/icons/app-bar-nav-mobile.svg"
      class="app-navigation-drawer__activator"
      @click="onOpenNavigation"
      alt="drawer-activator"
    />

    <v-navigation-drawer
      :value="drawer"
      app
      fixed
      temporary
      disable-route-watcher
      right
      tag="div"
      :width="$vuetify.breakpoint.xs ? '100%' : '480px'"
      color="#FFFFFF"
    >
      <div class="app-navigation-drawer__content">
        <div
          class="app-navigation-drawer__header"
          :class="{
            'app-navigation-drawer__header--navigation':
              !drawerIsProfileLevelOpened,
          }"
        >
          <v-btn
            icon
            @click="onCloseDrawer"
            color="primary"
            width="32px"
            height="32px"
          >
            <v-img
              src="@/assets/img/icons/app-bar-nav-close.svg"
              alt="drawer-close"
            />
          </v-btn>

          <router-link to="/">
            <v-img
              src="@/assets/img/xchangeloans-logo-second.svg"
              alt="Xchange.Loans - the online marketplace for non-performing loans"
              max-width="188"
            />
          </router-link>

          <img
            alt="user-avatar"
            class="app-navigation-drawer__user-avatar"
            :src="userAvatar"
          />
        </div>

        <div class="app-navigation-drawer__menu">
          <template v-if="drawerIsProfileLevelOpened">
            <navigation-buttons />
            <navigation-menu
              v-if="isAdmin"
              class="mt-6"
              title="Admin Tools"
              :items="adminItems"
            />
            <navigation-menu
              class="mt-6"
              title="Profile"
              :items="profileItems"
            />
            <div class="app-navigation-drawer__logout" @click="onLogout">
              Log out
              <img
                alt="arrow"
                src="@/assets/img/icons/navMobileIcons/navigation_arrow.svg"
              />
            </div>
          </template>

          <template v-else>
            <navigation-menu :items="navigationItems" />
            <v-btn
              v-if="!isAuthUser"
              class="app-navigation-drawer__login"
              @click="onLoginRegister"
            >
              <div class="app-navigation-drawer__login-text">
                Login/Register
              </div>
            </v-btn>
          </template>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import NavigationMenu from "@/components/layout/Drawer/NavigationMenu.vue";
import NavigationButtons from "@/components/layout/Drawer/NavigationButtons.vue";
import showRolesAccessMixin from "@/mixins/showRolesAccessMixin";
import { Storage } from "aws-amplify";
import defaultAvatarImage from "@/assets/img/profileSellerEditDialog/profileAvatar.svg";
export default {
  name: "AppNavigationDrawerNew",
  mixins: [showRolesAccessMixin],
  components: {
    NavigationMenu,
    NavigationButtons,
  },
  data() {
    return {
      userAvatar: defaultAvatarImage,
      navigationItems: [
        {
          title: "Buy",
          link: "/buy-commercial-real-estate-loans",
        },
        {
          title: "Sell: Self-Service",
          link: "/loan-sales",
        },
        {
          title: "Sell: Full- Service",
          link: "/loan-sale-advisory",
        },
        {
          title: "Value",
          link: "/free-loan-valuation/general",
        },
        {
          title: "Knowledge",
          link: "https://knowledge.xchange.loans",
          external: true,
        },
        {
          title: "FAQ",
          link: "/faq",
        },
        {
          title: "About Us",
          link: "/aboutus",
        },
      ],
      profileItems: [
        {
          title: "User",
          link: "/profile/edit",
        },
        {
          title: "Buyer",
          link: "/profile/buyer",
        },
        {
          title: "Seller",
          link: "/profile/seller",
        },
        {
          title: "Billing",
          link: "/profile/billing",
        },
      ],
      adminItems: [
        {
          title: "Admin Assets",
          link: "/admin/asset-approval",
        },
        {
          title: "Admin Buyers",
          link: "/admin/buyers/requests",
        },
        {
          title: "Admin Users",
          link: "/admin/users",
        },
        {
          title: "Admin Settings",
          link: "/admin/settings",
        },
      ],
    };
  },
  created() {
    window.onpopstate = () => {
      this.onCloseDrawer(false);
    };
    this.onSetUserAvatar();
  },
  watch: {
    async user() {
      await this.onSetUserAvatar();
    },
    $route() {
      this.onCloseDrawer();
    },
    drawer(v) {
      if (v === true) {
        const history = window.history.state ?? {};
        history.hasDialog = true;
        history.dialog = "sidebar";
        window.history.replaceState(history, "");
        window.history.pushState(null, "");
      } else {
        let history = window.history.state ? window.history.state : {};
        if ("hasDialog" in history && history.dialog === "sidebar") {
          history.hasDialog = false;
          history.dialog = "";
          window.history.replaceState(history, "");
        }
      }
    },
  },
  computed: {
    ...mapState(["drawer", "auth", "drawerIsProfileLevelOpened"]),
    ...mapGetters(["isAdmin", "user"]),
    isAuthUser() {
      const { isAuthenticated = false } = this.auth ?? {};
      return isAuthenticated;
    },
    getUser() {
      const {
        firstName = "",
        lastName = "",
        sellerProfile = {
          avatar: "",
        },
      } = this.user ?? {};

      return {
        firstName,
        lastName,
        sellerProfile,
      };
    },
  },
  methods: {
    ...mapMutations([
      "setDrawer",
      "setIsDrawerOpenedFromProfile",
      "setDrawerIsProfileLevelOpened",
    ]),
    ...mapActions(["logOut"]),
    onOpenRegisterDialog() {
      this.$root.openRegisterDialog({});
    },
    onOpenLoginDialog() {
      this.$root.openLoginDialog({});
    },
    onLoginRegister() {
      this.onOpenRegisterDialog();
      this.setDrawer(false);
    },
    onOpenNavigation() {
      this.setDrawer(true);
    },
    onCloseDrawer() {
      this.setDrawer(false);
      this.setDrawerIsProfileLevelOpened(false);
      this.setIsDrawerOpenedFromProfile(false);
    },
    async onLogout() {
      await this.logOut();
      this.onCloseDrawer();
    },
    async onSetUserAvatar() {
      const { sellerProfile = {} } = this.getUser ?? {};

      if (this.isAuthUser && Object.keys(sellerProfile).length) {
        const { avatar = "" } = sellerProfile ?? {};

        if (avatar) {
          this.userAvatar = await Storage.get(avatar);
        } else {
          this.userAvatar = defaultAvatarImage;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.app-navigation-drawer {
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid #9aabc2;
    margin-bottom: 16px;
    &--navigation {
      margin-bottom: 24px;
    }
  }
  &__user-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 3px 4px 15px rgba(#001154, 0.14);
  }
  &__login {
    width: 100%;
    background-color: #2969ff !important;
    border-radius: 7px !important;
    margin-top: 64px;
  }
  &__login-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
  }
  &__logout {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 20px;
    color: #001154;
    margin-top: 40px;
  }
  &__menu {
    // NOTE: Added padding-bottom because on iOS the control bar is at the bottom.
    padding-bottom: 120px;
  }
}

::v-deep {
  .v-navigation-drawer {
    overflow: scroll !important;
  }

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 40px;
    height: auto !important;
  }
}
</style>
