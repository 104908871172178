// import Dashboard from "@/pages/Dashboard/index.vue";

export default [
  {
    path: "/dashboard/buyer",
    name: "dashboard",
    component: () => import("./pages/Dashboard/index"),
    meta: {
      requiresAuth: true,
      title: "Buyer Dashboard - Xchange.Loans",
    },
  },
  {
    path: "/dashboard/empty",
    component: () => import("./pages/Dashboard/Empty"),
    name: "DashboardEmpty",
    meta: {
      requiresAuth: true,
      title: "Empty - Dashboard - Xchange.Loans",
    },
  },
];
