import store from "../store";

export function guardSellerRevoked(to, from, next) {
  guardSeller(to, from, next);
  if (
    store.state.user.sellerProfile &&
    store.state.user.sellerProfile.status === "Revoked"
  ) {
    store.commit("openSnackBar", {
      text: "Your access is blocked by administrator. Check your email for more information or contact us via info@xchange.loans.",
      color: "error",
    });
    next("/contact");
  }
}

export function guardSeller(to, from, next) {
  if (store.getters.showSeller) {
    next();
  } else {
    next();
  }
}
