<template>
  <div class="logo-container">
    <img
      class="zentinel-logo"
      :src="
        isMobile
          ? require('@/assets/img/zentinelLogoMobile.svg')
          : require('@/assets/img/zentinel_logo.svg')
      "
      alt="Powered by Zentinel"
      @click="onLogoClick"
    />
    <div class="logo-tooltip">
      Zentinel is a leading data security solution revolutionizing data
      protection & management
    </div>
  </div>
</template>

<script>
export default {
  name: "ZentinelLogo",
  methods: {
    onLogoClick() {
      window.open(
        "https://www.prettyfluidtechnologies.com/zentinel/",
        "_blank"
      );
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  // margin-top: 35px;
  position: relative;
  text-align: center;
}

// .logo-container:hover .logo-tooltip {
//     display: block;
// }

.logo-tooltip {
  display: none;
  position: absolute;
  top: 87%;

  width: 285px;
  padding: 13px;

  border-radius: 6px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #000;
}

.logo-tooltip:before {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -8px;

  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 8px solid #fff;
}
</style>
