import Vuetify from "vuetify/lib";
import Vue from "vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#001154",
        "primary-darken": "#002050",
        pink: "#dd226f",
        // accent: "#7fe0e8",
        accent: "#7BEAF3",
        error: "#d52819",
        "primary-dark": "#313944",
        "dark-grey": "#979797",
        grey: "#f1f1f1",
        "grey-lighten-1": "#f8f8f8",
        "grey-lighten-2": "#fcfcfc",

        secondary: "#001154",
        secondaryText1: "#aaaaaa",
        iconInactive: "#D8DDE3",
        darkBlue: "#001154",
        cyanPrimary: "#2969FF",
      },
    },
  },
});
