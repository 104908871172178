import { API, graphqlOperation } from "aws-amplify";

export default function subscribeToRoleRemove() {
  const query = `subscription {
    subscribeToRoleRemove {
              id
          }
      }`;

  return API.graphql(graphqlOperation(query));
}
