import { API } from "aws-amplify";

export default (data) => {
  const query = `query search(
    $assetStatus: [String],
    $status: [String],
    $propertyType: [String],
    $size: Int,
    $offset: Int,
    $address: String,
    $state: [String],
    $country: String,
    $city: String,
    $name: String,
    $sortBy:ESSortingBy
    $orderBy:OrderByChoices
    $coordinates: CoordinatesInput
    $upbMin: Float,
    $upbMax: Float,
    $offers:[String]
    ){
      searchAssets(
        assetStatus: $assetStatus,
        status: $status,
        propertyType: $propertyType,
        size: $size,
        offset: $offset
        address: $address,
        state: $state,
        country: $country,
        city: $city,
        name: $name
        sortBy:$sortBy
        orderBy:$orderBy,
        upbMin: $upbMin,
        upbMax: $upbMax,
        coordinates: $coordinates
        offers:$offers
            ) {
                assets {
                   id
                   offersNumber
                   assetStatus
                   name
                   address
                   creationDate
                   type
                   city
                   state
                   country
                   bestCashOffer
                   status
                   dateModified
                   creationDate
                   numberOfCAs
                   zipCode
                   assetType
                   listing
                   images
                   price
                   upb
                   sellerEntityName
                   sellerFirstName
                   sellerLastName
                   sellerPhoneNumber
                   listingPrice{
                    amount
                    currency
                   }
                   callForOffersPeriod {
                    startDate
                    endDate
                  }        
                  bestAndFinalPeriod {
                    startDate
                    endDate
                  }
                   noteOverview{
                    estCollateralValue{
                      amount
                    currency
                    }

                   }
                   contacts{
                    firstName
                    lastName
                    officePhone
                    emailAddress
                    companyName
                    contactAvatar
                    whatsappNumber
                   }
                
                
                  }
                total
               
            
              }
          }`;

  let currentUserProfile = localStorage.getItem("currentUserProfile");
  let isAdmin = false;
  if (currentUserProfile) {
    currentUserProfile = JSON.parse(currentUserProfile);
    isAdmin = currentUserProfile?.roles?.includes("Admins");
  }

  return API.graphql({
    query,
    variables: {
      status: data.status
        ? data.status
        : [
            "Published",
            "CallForOffers",
            "BestAndFinal",
            "UnderSelection.CallForOffers",
            "UnderSelection.BestAndFinal",
            "ContractPending.CallForOffers",
            "ContractPending.BestAndFinal",
            "UnderContract",
            "Closed",
          ],
      assetStatus: data.assetStatus || null,
      propertyType: data.propertyType || null,
      size: data.size || 12,
      offset: data.offset || 0,
      address: data.address || null,
      state: data.state || null,
      country: data.country || null,
      city: data.city || null,
      name: data.name ? data.name : data.assetName || null,
      sortBy: data.sortBy || "status",
      orderBy: data.orderBy || "desc",
      coordinates: data.coordinates || null,
      upbMin: data.upbMin || null,
      upbMax: data.upbMax || null,
      offers: data.offers || null,
    },
    authMode: isAdmin ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
  });
};
