import { API } from "aws-amplify";

export default (variables) => {
  const query = `mutation discardAsset($id:String!) {
    discardAsset(
            id:$id, 
            ) 
             {
                id          
             }
    }
    `;
  console.log("variables", variables);
  return API.graphql({
    query,
    variables,
  });
};
