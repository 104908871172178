export const convertOfferToESOffer = (currentESOffer, offer) => {
  const converted = {
    ...offer,
    acceptedDate: offer.acceptedDate,
    bidPrice: offer.bidPrice.amount,
    closing: offer.closing,
    creationDate: offer.creationDate,
    dateModified: offer.dateModified,
    declinedDate: offer.declinedDate,
    dueDiligencePeriod: offer.dueDiligencePeriod,
    earnestMoneyDeposit: offer.earnestMoneyDeposit.amount,
    id: offer.id,
    notes: offer.notes,
    offerType: offer.type,
    status: offer.status,
    winningDate: offer.winningDate,
    withdrawDate: offer.withdrawDate,
    BDASignedDate: offer.BDASignedDate,
    buyerClosingDocumentsAccepted: offer.buyerClosingDocumentsAccepted,
    declineReason: offer.declineReason,
    assetsDetailed: offer.assetsDetailed,
    assets: convetOfferAssetsToEsAsset(currentESOffer.assets, offer.assets),
  };

  return converted;
};
export const convetOfferAssetsToEsAsset = (
  currentOfferAssets = [],
  offerAssets = []
) => {
  let newAssetsList = [];
  if (currentOfferAssets.length) {
    currentOfferAssets.forEach((currentOfferAsset, idx) => {
      const updatedAsset = offerAssets[idx];

      newAssetsList.push({
        ...currentOfferAsset,
        bidAllocation: updatedAsset.bidAllocation,
      });
    });
  } else if (offerAssets.length) {
    offerAssets.forEach((offerAsset, idx) => {
      const updatedAsset = currentOfferAssets[idx];

      newAssetsList.push({
        ...offerAsset,
        ...updatedAsset,
        bidAllocation: offerAsset.bidAllocation.amount,
      });
    });
  }

  // currentOfferAssets.forEach((currentOfferAsset, idx) => {
  //   console.log("idx - ", idx);
  //   const updatedAsset = offerAssets[idx];
  //
  //   console.log("updatedAsset - ", updatedAsset);
  //   newAssetsList.push({
  //     ...currentOfferAsset,
  //     bidAllocation: updatedAsset.bidAllocation,
  //     // principalBalance: updatedAsset.principalBalance.amount
  //     //   ? updatedAsset.principalBalance.amount
  //     //   : currentOfferAsset.principalBalance.amount,
  //   });
  //   console.log("newAssetsList - ", newAssetsList);
  // });
  console.log("finish - ", newAssetsList);
  return newAssetsList;
};

export const converOfferToESOfferWithDetailedAsset = (
  currentESOffer,
  offer
) => {
  const converted = {
    acceptedDate: offer.acceptedDate,
    bidPrice: offer.bidPrice.amount,
    closing: offer.closing,
    creationDate: offer.creationDate,
    dateModified: offer.dateModified,
    declinedDate: offer.declinedDate,
    dueDiligencePeriod: offer.dueDiligencePeriod,
    earnestMoneyDeposit: offer.earnestMoneyDeposit.amount,
    id: offer.id,
    notes: offer.notes,
    offerType: offer.type,
    status: offer.status,
    winningDate: offer.winningDate,
    withdrawDate: offer.withdrawDate,
    BDASignedDate: offer.BDASignedDate,
    buyerClosingDocumentsAccepted: offer.buyerClosingDocumentsAccepted,
    assets: offer.assetsDetailed.map((ad, idx) => {
      return {
        ...offer.assets[idx],
        ...ad,
        title: ad.name,
      };
    }),
  };

  return converted;
};
