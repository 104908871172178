import { API } from "aws-amplify";
export default {
  getCalculatorResponse(asset) {
    const query = `mutation getCalculatorResponse(
      $assetStatus: String!
      $county: String
      $state: String
      $upb: Float
      $dscr: Float
      $fairMarketValue: Float
      $originationDate: AWSDate
      $maturityDate: AWSDate
      $monthsOpenAtPar: Int
      $prepaymentPenalty: Boolean
      $interestType: String
      $lienPosition: String
      $noteRateFloor: Float
      $interestRate: Float
      $lastDelinquencyDate: AWSDate
      $numberDelinquentPayments: Int
      $additionalLiens: Boolean
      $summaryJudgmentReceived: Boolean
      $totalPayoff: Float
      $recourseGuaranty: String
      $hotelFlaggedAtFMVDate: Boolean
      $hotelFlaggedCurrently: Boolean
      $PIPRequirement: Float
      $userInput: CalculatorUserInput
    ) {
      getCalculatorResponse(
        assetStatus: $assetStatus
        county: $county
        state: $state
        upb: $upb
        dscr: $dscr
        fairMarketValue: $fairMarketValue
        originationDate: $originationDate
        maturityDate: $maturityDate
        monthsOpenAtPar: $monthsOpenAtPar
        prepaymentPenalty: $prepaymentPenalty
        interestType: $interestType
        lienPosition: $lienPosition
        noteRateFloor: $noteRateFloor
        interestRate: $interestRate
        lastDelinquencyDate: $lastDelinquencyDate
        numberDelinquentPayments: $numberDelinquentPayments
        additionalLiens: $additionalLiens
        summaryJudgmentReceived: $summaryJudgmentReceived
        totalPayoff: $totalPayoff
        recourseGuaranty: $recourseGuaranty
        hotelFlaggedAtFMVDate: $hotelFlaggedAtFMVDate
        hotelFlaggedCurrently: $hotelFlaggedCurrently
        PIPRequirement: $PIPRequirement
        userInput:$userInput
        ) {
          id
          lowValueRange
          percentageLowValueRangeToUpb
          highValueRange
          percentageHighValueRangeToUpb
          noteRateFloorCalculated
          chartData
          lowSideSavings
          lowSideTime
          highSideSavings
          highSideTime
          foreclosureProcess
          receivershipProcess
          biddingProcess
          seasoning
          remainingTerm
          recommendation
          XLLoanClass
          marketTier               
          }
      }`;

    return API.graphql({
      query,
      variables: {
        ...asset,
      },
    });
  },
};
