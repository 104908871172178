import { render, staticRenderFns } from "./AppNavigationDrawerNew.vue?vue&type=template&id=4dc12566&scoped=true"
import script from "./AppNavigationDrawerNew.vue?vue&type=script&lang=js"
export * from "./AppNavigationDrawerNew.vue?vue&type=script&lang=js"
import style0 from "./AppNavigationDrawerNew.vue?vue&type=style&index=0&id=4dc12566&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc12566",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VImg,VNavigationDrawer})
