import subscribeToAssetUpdatesBySeller from "@/services/apiCalls/offer/subscriptions/subscribeToAssetUpdate";
import subscribeToAssetsUpdatesByBot from "@/services/apiCalls/offer/subscriptions/subscribeToAssetsUpdatesByBot";
import subscribeToOfferWin from "@/services/apiCalls/offer/subscriptions/subscribeToOfferWin";
import subscribeToEditOffer from "@/services/apiCalls/offer/subscriptions/subscribeToEditOffer";
import subscribeToOffersUpdatesByBot from "@/services/apiCalls/offer/subscriptions/subscribeToOffersUpdatesByBot";
import subscribeToOfferDecline from "@/services/apiCalls/offer/subscriptions/subscribeToOfferDecline";
import subscribeToNoticeESOffersUpdate from "@/services/apiCalls/offer/subscriptions/subscribeToNoticeESOffersUpdate";

export const subscribeAssetChange = async (onNext) => {
  subscribeToAssetUpdatesBySeller().subscribe({
    next: async (data) => {
      const asset = data.value.data.subscribeToAssetUpdatesBySeller;
      onNext(asset);
    },
    // eslint-disable-next-line no-console
    error: (error) => console.log(error),
  });
};

export const onSubscribeAssetUpdateByBot = async (onNext) => {
  subscribeToAssetsUpdatesByBot().subscribe({
    next: async (data) => {
      onNext(data);
    },
    // eslint-disable-next-line no-console
    error: (error) => console.log(error),
  });
};

export const onSubscribeToOfferWin = (onNext) => {
  subscribeToOfferWin().subscribe({
    next: async (data) => onNext(data, "subscribeToOfferWin"),
    // eslint-disable-next-line no-console
    error: (error) => console.log(error),
  });
};
export const onSubscribeToEditOffer = (onNext) => {
  subscribeToEditOffer().subscribe({
    next: async (data) => {
      onNext(data, "subscribeToEditOffer");
    },
    // eslint-disable-next-line no-console
    error: (error) => console.log(error),
  });
};
export const onSubscribeToOffersUpdatesByBot = (onNext) => {
  subscribeToOffersUpdatesByBot().subscribe({
    next: async (data) => {
      onNext(data);
    },
    // eslint-disable-next-line no-console
    error: (error) => console.log(error),
  });
};

export const onSubscribeToOfferDecline = (onNext) => {
  subscribeToOfferDecline().subscribe({
    next: async (data) => onNext(data, "subscribeToOfferDecline"),

    // eslint-disable-next-line no-console
    error: (error) => console.log(error),
  });
};
export const onSubscribeToNoticeESOffersUpdate = (onNext) => {
  console.log("onSubscribeToNoticeESOffersUpdate");
  try {
    subscribeToNoticeESOffersUpdate().subscribe({
      next: async (data) => onNext(data, "subscribeToNoticeESOffersUpdate"),
      // eslint-disable-next-line no-console
      error: (error) => console.log(error),
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("onSubscribeToNoticeESOffersUpdate", err);
  }
};
