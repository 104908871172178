<template>
  <nav>
    <ul class="listNavigation">
      <li>
        <a
          href="https://knowledge.xchange.loans/"
          target="_self"
          class="header-link custom-header-link"
        >
          Knowledge
        </a>
      </li>
      <li>
        <router-link
          :to="'/about-xchange-loans'"
          class="header-link custom-header-link header-link--first"
        >
          About
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../scss/components/navbarItems";

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .listNavigation {
    justify-content: center;
    column-gap: 40px;
  }
  .custom-header-link {
    &::after {
      height: 2px !important;
      background-color: #dd226f !important;
      border-radius: 5px !important;
    }
  }
  .header-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .header-link {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
  .listNavigation {
    column-gap: 56px;
  }
}
</style>
