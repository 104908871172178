import * as Sentry from "@sentry/vue";
import store from "@/store";

export default function SentryFlow(to, from, sentryReplay, replayStatus) {
  const isProduction = ["prod"].includes(store.getters.environment);

  let status = replayStatus;

  if (to.meta.makeAssetFlow && !from.meta.makeAssetFlow && !status) {
    if (to.params.id) {
      Sentry.setTag("assetID", to.params.id);
      Sentry.setTag("flow", "asset_editing");
    } else {
      Sentry.setTag("flow", "asset_creation");
    }
    console.log("make asset replay START");
    isProduction && sentryReplay?.start();
    status = true;
  }
  if (!to.meta.makeAssetFlow && from.meta.makeAssetFlow && status) {
    console.log("make asset replay STOP");
    isProduction && sentryReplay?.stop();
    status = false;
  }

  if (to.meta.makeOfferFlow && !from.meta.makeOfferFlow && !status) {
    if (to.params.id) {
      Sentry.setTag("offerID", to.params.id);
      Sentry.setTag("flow", "offer_editing");
    } else {
      Sentry.setTag("flow", "offer_creation");
    }
    console.log("make offer replay START");
    isProduction && sentryReplay?.start();
    status = true;
  }
  if (!to.meta.makeOfferFlow && from.meta.makeOfferFlow && status) {
    console.log("make offer replay STOP");
    isProduction && sentryReplay?.stop();
    status = false;
  }

  if (to.meta.closingDoc && !from.meta.closingDoc && !status) {
    Sentry.setTag("flow", `closing_document_${to.params.role}`);
    Sentry.setTag("offerID_closing", to.params.id);
    console.log("make closing replay START");
    isProduction && sentryReplay?.start();
    status = true;
  }
  if (!to.meta.closingDoc && from.meta.closingDoc) {
    console.log("make closing replay STOP");
    isProduction && sentryReplay?.stop();
    status = false;
  }

  return { status };
}
