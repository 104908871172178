import { render, staticRenderFns } from "./AppBarDashboard.vue?vue&type=template&id=3dd53ccc&scoped=true"
import script from "./AppBarDashboard.vue?vue&type=script&lang=js"
export * from "./AppBarDashboard.vue?vue&type=script&lang=js"
import style0 from "./AppBarDashboard.vue?vue&type=style&index=0&id=3dd53ccc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dd53ccc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VTooltip})
