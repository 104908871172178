export default {
  computed: {
    showBuyer() {
      if (this.$store.state.user && this.$store.state.user.roles) {
        const roles = this.$store.state.user.roles;

        if (roles.includes("ApprovedBuyers")) return true;
        else {
          return false;
        }
      } else {
        return false;
      }
    },
    showAdmin() {
      if (
        this.$store.state.user &&
        this.$store.state.user &&
        this.$store.state.user.roles
      ) {
        if (this.$store.state.user.roles.includes("Admins")) return true;
      }
      return false;
    },
    showSellers() {
      const roles = ["Admins", "Sellers", "Concierges"];
      if (this.$store.state.user && this.$store.state.user.roles) {
        if (this.$store.state.user.roles.some((role) => roles.includes(role)))
          return true;
      }
      return false;
    },
  },
};
