import Numeral from "numeraljs";

/**
 * @param {Number, String} value
 * @return {String} Return formatted currency string
 */
export default (value) => {
  const formatted = Numeral(value).format("$0,0[.]00");
  if (formatted.includes(".00")) {
    formatted.replace(".00", "");
  }
  return formatted;
};
