<template>
  <div class="seller-profile-mobile-dialog-wrapper">
    <div class="close-icon" @click="$emit('close')">
      <v-icon color="#001154"> mdi-close </v-icon>
    </div>
    <div class="dialog-content__title">
      <div>Complete Seller Profile</div>
      <div></div>
    </div>
    <div class="dialog-content">
      <div class="form-content mt-4">
        <div class="form-content__title">Seller Entity</div>
        <div class="mt-3 full-width">
          <StepInformation title="Contact information for a legal entity" />
        </div>

        <SellerEntityForm
          @setSellerEntityForm="setSellerEntityForm"
          ref="sellerEntityForm"
        />
      </div>
      <div class="form-content mt-4">
        <div class="form-content__title">Seller Confirmation</div>
        <div class="mt-3 full-width">
          <StepInformation title="Confirm authority to transact" />
        </div>
        <SellerConfirmationForm
          @setSellerConfirmationForm="setSellerConfirmationForm"
          ref="sellerConfirmationForm"
        />
      </div>
    </div>
    <div class="button-wrapper" @click="onScrollToError">
      <v-btn
        class="custom-btn"
        small
        height="56"
        color="#2969FF"
        :disabled="isDisabled"
        @click="onConfirm"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>
<script>
import SellerEntityForm from "@/components/Seller/SellerDialogForms/SellerEntityForm.vue";
import SellerConfirmationForm from "../Seller/SellerDialogForms/SellerConfirmationForm.vue";
import scrollToError from "@/utils/scrollToError";
import StepInformation from "@/components/ProfileEditDialog/StepInformation.vue";
export default {
  name: "SellerProfileEditDialogMobile",
  data: function () {
    return {
      formData: {
        entityName: "",
        entityAddress: {
          address: null,
          city: null,
          state: null,
          country: "USA",
          zipCode: null,
        },
        signedByFirstName: "",
        signedByLastName: "",
        country: null,
        executive: false,
        financePartner: [],
        avatar: null,
        phoneNumber: "",
        title: null,
      },
      sellerEntityForm: null,
      sellerConfirmationForm: null,
    };
  },
  methods: {
    setSellerEntityForm(v) {
      this.sellerEntityForm = v;
    },
    setSellerConfirmationForm(v) {
      this.sellerConfirmationForm = v;
    },
    onConfirm() {
      const formData = {
        ...this.$refs.sellerConfirmationForm.formData,
        ...this.$refs.sellerEntityForm.formData,
      };
      this.$emit("finishLaterUpdateSellerEntityBlock", formData);
    },
    onScrollToError() {
      this.sellerEntityForm.$touch();
      this.sellerConfirmationForm.$touch();
      scrollToError(this, "error--text", true);
    },
  },
  computed: {
    isDisabled() {
      if (this.sellerEntityForm && this.sellerConfirmationForm) {
        return !(
          !this.sellerEntityForm.$invalid &&
          !this.sellerConfirmationForm.$invalid
        );
      } else {
        return true;
      }
    },
  },

  components: {
    StepInformation,
    SellerEntityForm,
    SellerConfirmationForm,
  },
};
</script>
<style scoped lang="scss">
.seller-profile-mobile-dialog-wrapper {
  height: 100%;
  width: 100%;
  background: white;
  position: relative;
}
.close-icon {
  position: absolute;
  top: 14px;
  right: 16px;
  z-index: 204;
}
.dialog-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 180px;
  min-height: 100vh;
  overflow: auto;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */
    display: flex;
    position: fixed;
    text-align: center;
    width: 100%;
    /* Primary/DarkBlue */
    padding-top: 16px;
    // justify-content: space-between;
    color: #001154;
    background: white;
    z-index: 202;

    padding-bottom: 5px;
    justify-content: center;
  }
}
.form-content {
  padding: 8px 16px 16px 16px;
  background: #fefefe;
  box-shadow: 2.66667px 2.66667px 20px rgba(173, 173, 173, 0.25);
  border-radius: 6.66667px;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #001154;
    border-bottom: 1.33333px solid #f1f1f1;
  }
  &__fields {
    margin-top: 11px !important;
  }
}
.custom-btn {
  width: 100% !important;
  height: 40px !important;
  background-color: rgba(20, 90, 255, 1) !important;
  border-radius: 5px;
  ::v-deep {
    .v-btn__content {
      color: white !important;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }
  }
}
.button-wrapper {
  position: fixed;
  bottom: 0px;
  background: white;
  width: 100%;
  padding: 0px 16px;
  z-index: 204;
  padding-bottom: 22px;
}
::v-deep {
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    border: none !important;
  }
}
</style>
