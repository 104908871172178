<template>
  <v-alert
    v-model="dialog"
    class="notification-alert rounded"
    transition="scroll-x-transition"
    elevation="10"
  >
    <v-row no-gutters>
      <v-col cols="2" class="text-right">
        <v-img
          class="logo"
          src="@/assets/img/logo_notifications.svg"
          alt="logo_notifications"
        />
      </v-col>
      <v-col cols="9">
        <div>{{ content }}</div>
      </v-col>
      <v-col cols="1" class="text-right">
        <v-icon class="cursor-pointer" color="secondary" @click="close"
          >mdi-close</v-icon
        >
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "AlertNotificationDialog",
  data() {
    return {
      dialog: false,
      resolve: null,
      content: null,
      title: null,
    };
  },

  methods: {
    open({ title, content }) {
      this.content = content;
      this.title = title;
      this.dialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close() {
      this.text = null;
      this.content = null;
      this.title = null;
      this.dialog = false;
      this.resolve();
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-alert {
  position: fixed;
  top: 10vh;
  right: 20px;
  z-index: 999;
  width: 100%;
  max-width: 500px;
  @media (max-width: 960px) {
    right: 0;
  }
  &.rounded {
    border-radius: 8px !important;
  }
}
.logo {
  width: 100%;
  max-width: 45px;
}
</style>
