import { API } from "aws-amplify";

export default ({
  propertyType,
  address,
  state,
  city,
  assetStatus,
  country,
  name,
}) => {
  const query = `query countAssets($propertyType: [String], $address: String, $state: [String], $assetStatus: [String], $name: String, $city: String, $country: String) {
        countAssets (
                assetStatus: $assetStatus, 
                assetType: null,
                status: [
                    "Published",
                    "CallForOffers",
                    "BestAndFinal",
                    "UnderSelection.CallForOffers",
                    "UnderSelection.BestAndFinal",
                    "Closed"
                ], 
                propertyType: $propertyType, 
                address: $address,
                state: $state, 
                country: $country, 
                city: $city,
                name: $name,
                field: "propertyType" ) {
                countedAssets { 
                  key
                  doc_count
                }
                field
        }
    }`;

  return API.graphql({
    query,
    variables: {
      propertyType,
      assetStatus,
      address,
      name,
      city,
      state,
      country,
    },
  });
};
