import { API } from "aws-amplify";
export default (variables) => {
  const query = `query getUserAssetData($id:String) {
    getUserAssetData(id: $id ) {             
        PK
        SK
        CAExecutionDate
        Tier2AccessDate
        latestVisitDate
        totalVisits
        
      }
    }
  `;

  return API.graphql({
    query,
    variables,
  });
};
