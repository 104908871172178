import store from "../store";
import Offer from "@/services/apiCalls/offer/offer";

async function editOfferGuard(to, from, next) {
  const { id } = to.params;
  if (!id) {
    next();
    return;
  }

  let isOffer = store.state.RouteGuard.guardOffer;
  if (
    isOffer &&
    store.getters.user.id &&
    isOffer?.owners[0] === store.getters.user.id
  ) {
    next();
  } else {
    try {
      const { data } = await Offer.getOffer(id);
      isOffer = data?.getOffer;
      store.commit("RouteGuard/setGuardOffer", isOffer);
      if (
        isOffer &&
        store.getters.user.id &&
        isOffer?.owners[0] === store.getters.user.id
      ) {
        next();
      } else {
        store.commit("openSnackBar", {
          text: "Please verify the link you're using. It might be incorrect, or you may lack the necessary permissions to access this page.",
          color: "warning",
        });
        next("/");
      }
    } catch (error) {
      store.commit("openSnackBar", {
        text: "Please verify the link you're using. It might be incorrect, or you may lack the necessary permissions to access this page.",
        color: "warning",
      });
      next("/");
    }
  }
}

export { editOfferGuard };
