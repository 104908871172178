export default {
  namespaced: true,
  state: {
    guardAsset: "",
    guardOffer: "",
  },
  mutations: {
    setGuardAsset(state, payload) {
      state.guardAsset = payload;
    },
    setGuardOffer(state, payload) {
      state.guardOffer = payload;
    },
  },
};
