const getInitials = (user) => {
  const { email, firstName, lastName } = user;

  if (firstName && lastName) {
    return `${firstName?.at(0)}${lastName?.at(0)}`;
  } else if (email) {
    return email.at(0);
  } else {
    return "AS";
  }
};

export default getInitials;
