import ErrorParser from "@/utils/ErrorParser";
import Vdr from "@/services/apiCalls/vdr/vdr";
import Asset from "@/services/apiCalls/asset/asset";
import generateLPADocument from "@/services/apiCalls/asset/queries/generateLPADocument";

import getDemoBDADocumentForSeller from "@/services/apiCalls/asset/queries/getDemoBDADocumentForSeller";
import { LOCAL_DATABASE } from "@/constants/localDatabase";

export default {
  namespaced: true,
  state: {
    currentAsset: null,
    currentStep: null,
    currentStepComponent: "assetInfo",
    currentStepIndex: 0,
    currentStepTitle: "Asset Info",
    stepList: [],
    assetInfoStep: "",
    assetNoteOverview: "",
    assetPropDetails: "",
    assetPictures: "",
    assetDueDiligence: "",
    assetLegal: "",
    assetAdvisors: "",
    pricingPlan: "",
    lpaDocumentToSign: null,
    reviewCAActiveTab: "Xchange Loans default CA",
    caFile: null,
    legalStep: {
      component: "ca",
      title: "I agree with the Confidentiality Agreement",
      value: false,
      error: false,
    },
    legalSteps: [
      {
        component: "ca",
        title: "I agree with the Confidentiality Agreement",
        value: false,
        error: false,
      },
      // {
      //   component: "lpa",
      //   title: "I agree with the Loan Purchase Agreement",
      //   value: false,
      //   error: false,
      // },
      {
        component: "bda",
        title: "I agree with the Bid Deposit Agreement",
        value: false,
        error: false,
      },

      // {
      //   component: "tac",
      //   title: "I agree with the Terms and Conditions",
      //   value: false,
      //   error: false,
      // },
    ],
    startFromAdmin: false,
  },
  getters: {
    getLegalStep: (state) => (component) => {
      return state.legalSteps.find((item) => item.component === component);
    },
    getLegalSteps(state, getters, rootState) {
      const userSettings = rootState.UserSettings.userSettings;
      if (userSettings.autoDeposit) {
        return state.legalSteps;
      }
      return state.legalSteps.filter((ls) => {
        return ls.component === "ca";
      });
    },
  },
  mutations: {
    setStartFromAdmin(state, value) {
      state.startFromAdmin = value;
    },
    setAsset(state, asset) {
      state.currentAsset = asset;
    },
    setLPADocumentToSign(state, payload) {
      state.lpaDocumentToSign = payload;
    },
    updateCurrentStepField(state, value) {
      state.currentAsset.currentStep = value;
    },
    setStepList(state, list) {
      state.stepList = list;
    },
    setStepComponent(state, value) {
      state.currentStepComponent = value;
    },
    setStepIndex(state, value) {
      state.currentStepIndex = value;
    },
    setStepTitle(state, value) {
      state.currentStepTitle = value;
    },
    setReviewCAActiveTab(state, payload) {
      state.reviewCAActiveTab = payload;
    },
    setCaFile(state, payload) {
      state.caFile = payload;
    },
    setPricingPlan(state, value) {
      state.pricingPlan = value;
    },
    setStepByOrder(state, value) {
      const newStep = state.stepList.find((item) => item.order === value);
      state.currentStepComponent = newStep.component;
      state.currentStepTitle = newStep.title;
      state.currentStepIndex = newStep.order - 1;
    },
    setStepNextComponent(state, value) {
      const newIndex = state.currentStepIndex + 1;
      const newStep = state.stepList[newIndex];

      state.currentStepIndex = newIndex;
      state.currentStepComponent = newStep.component;
      state.currentStepTitle = newStep.title;
    },
    // value is component
    setNextStep(state, value) {
      const newIndex = state.stepList.findIndex((slItem) => {
        return slItem.component === value;
      });

      const newStep = state.stepList.find((slItem) => {
        return slItem.component === value;
      });

      state.currentStepIndex = newIndex;
      state.currentStepComponent = newStep.component;
      state.currentStepTitle = newStep.title;
    },
    setLegalStep(state, value) {
      state.legalStep = {
        ...state.legalStep,
        ...value,
      };
    },

    setLegalStepInList(state, value) {
      const idx = state.legalSteps.findIndex(
        (item) => item.component === value.component
      );
      state.legalSteps[idx] = value;
    },
    setLegalSteps(state, value) {
      const caLegalSteps = state.legalSteps.find((ls) => ls.component === "ca");
      const tocLegalSteps = state.legalSteps.find(
        (ls) => ls.component === "tac"
      );
      let stateCA = state.legalSteps.find((ls) => ls.component === "ca");
      stateCA.value = value.caConfirmed || caLegalSteps.value;

      let stateTac = state.legalSteps.find((ls) => ls.component === "tac");
      // lpaConfirmed
      if (stateTac) {
        stateTac.value = value.TOCConfirmed || tocLegalSteps.value;
      }
      // let stateLpa = state.legalSteps.find((ls) => ls.component === "lpa");
      // if (stateLpa) {
      //   stateLpa.value = value.lpaConfirmed || stateLpa.value;
      // }
      let stateBda = state.legalSteps.find((ls) => ls.component === "bda");
      if (stateBda) {
        stateBda.value = value.BDAConfirmed || stateBda.value;
      }
    },
    resetAssetComponentState(state, rootState) {
      state.currentStepIndex = 0;
      state.currentStepComponent = "assetInfo";
      state.currentStepTitle = "Asset Info";
      state.currentAsset = null;
      state.legalStep = {
        component: "ca",
        title: "I agree with the Confidentiality Agreement",
        value: false,
        error: false,
      };
      state.legalSteps = [
        {
          component: "ca",
          title: "I agree with the Confidentiality Agreement",
          value: false,
          error: false,
        },
        // {
        //   component: "lpa",
        //   title: "I agree with the Loan Purchase Agreement",
        //   value: false,
        //   error: false,
        // },
        {
          component: "bda",
          title: "I agree with the Bid Deposit Agreement",
          value: false,
          error: false,
        },
        // {
        //   component: "tac",
        //   title: "I agree with the Terms and Conditions",
        //   value: false,
        //   error: false,
        // },
      ];
    },
    updateCaDoc(state, value) {
      state.currentAsset["customCADoc"] = value;
    },
  },
  actions: {
    async fetchAssetById({ state, commit }, id) {
      const result = await Asset.getAsset(id)
        .then(({ data }) => {
          const { getAsset: asset } = data;
          const newAsset = assetDestruction(asset);
          let idx = 0;
          if (!newAsset.registrationStatus) {
            idx = state.stepList.findIndex(
              (item) => item.component === newAsset.currentStep
            );
          }

          if (state.stepList.length) {
            commit("setStepComponent", state.stepList[idx].component);
            commit("setStepTitle", state.stepList[idx].title);
          }
          commit("setStepIndex", idx);
          commit("setAsset", newAsset);
          commit("setLegalStep", {
            value: newAsset.caConfirmed,
          });
          commit("setLegalSteps", {
            caConfirmed: newAsset.caConfirmed,
            TOCConfirmed: newAsset.TOCConfirmed,
            BDAConfirmed: newAsset.BDAConfirmed,
          });
          return newAsset;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async generateLPADocument({ state, commit }) {
      try {
        const res = await generateLPADocument({
          id: state.currentAsset.id,
          docType: "LPA",
        });
        return res.data.generateLegalDocument;
      } catch (err) {
        console.log("generate lpa doc err", err);
        return null;
      }
    },
    async generateBDADocument({ state, commit }) {
      try {
        const res = await getDemoBDADocumentForSeller({});
        return res.data.getDemoBDADocumentForSeller;
      } catch (err) {
        console.log("generate lpa doc err", err);
        return null;
      }
    },

    setStepUrl({ state }, value) {
      switch (value) {
        case "assetInfo":
          return "asset-info";
        case "noteOverview":
          return "note-overview";
        case "propertyDetails":
          return "property-details";
        case "pictures":
          return "pictures";
        case "dueDiligence":
          return "due-diligence";
        case "legal":
          return "legal/ca";
        case "advisors":
          return "advisors";
        default:
          return null;
      }
    },
    setStepList({ commit }, params) {
      const list = [
        {
          title: "Asset Info",
          component: "assetInfo",
          url: "asset-info",
          completed: false,
          order: 1,
        },
        {
          title: "Note Overview",
          component: "noteOverview",
          url: "noteOverview",
          completed: false,
          order: 2,
        },
        {
          title: "Property Details",
          component: "propertyDetails",
          url: "property-details",
          completed: false,
          order: 3,
        },
        {
          title: "Pictures",
          component: "pictures",
          url: "pictures",
          completed: false,
          order: 4,
        },
        {
          title: "Due Diligence",
          component: "dueDiligence",
          url: "due-diligence",
          completed: false,
          order: 5,
        },
        {
          title: "Legal",
          component: "legal",
          url: "legal",
          completed: false,
          order: 6,
        },
        {
          title: "Advisors",
          component: "advisors",
          url: "advisors",
          completed: false,
          order: 7,
        },
      ];
      commit("setStepList", list);
    },
    async createAsset({ commit, rootState }, params) {
      let newAsset = null;
      const response = await Asset.createPreAsset(params)
        .then(async ({ data }) => {
          const { createAsset } = data;
          newAsset = createAsset;
          if (createAsset) {
            commit("UserAssets/addAsset", createAsset, { root: true });
            return createAsset;
          }
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        })
        .finally((resp) => {
          if (newAsset && rootState?.localDatabase?.isOpen()) {
            rootState?.localDatabase?.put(LOCAL_DATABASE.USER_ASSETS, {
              ...newAsset,
              bidAllocation: newAsset.bidAllocation,
            });
          }
          return resp;
        });
      return response;
    },
    async updateAsset({ commit, rootState }, params) {
      let req = "";
      switch (params.requestType) {
        case "assetInfo":
          delete params.requestType;
          req = Asset.editAssetAssetInfo(params);
          break;
        case "noteOverview":
          delete params.requestType;
          req = Asset.editAssetNoteOverview(params);
          break;
        case "propertyDetails":
          delete params.requestType;
          req = Asset.editAssetPropertyDetails(params);
          break;
        case "pictures":
          delete params.requestType;
          req = Asset.editAssetPicture(params);
          break;
        case "dueDiligence":
          delete params.requestType;
          req = Asset.editAssetDueDiligence(params);
          break;
        case "legal":
          delete params.requestType;
          req = Asset.editAssetLegal(params);
          break;
        case "advisors":
          delete params.requestType;
          req = Asset.editAssetAdvisors(params);
          break;
        default:
          break;
      }

      let newAsset = null;
      const result = await req
        .then(async ({ data }) => {
          console.log(111, data);
          if (data) {
            console.log(222);
            const { editAsset = {} } = data;
            console.log(333, editAsset);
            newAsset = editAsset;
            const { id, customCADoc } = editAsset;
            console.log(444, id);
            if (customCADoc) {
              commit("updateCaDoc", customCADoc);
            }
            commit(
              "UserAssets/updateAssetById",
              { assetId: editAsset.id, newAsset: editAsset },
              { root: true }
            );
            // await rootState.localDatabase.put(LOCAL_DATABASE.USER_ASSETS, {
            //   ...editAsset,
            //   bidAllocation: editAsset.bidAllocation,
            // });

            console.log(555, id);
            return id;
          }
          console.log(666);
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        })
        .finally((resp) => {
          if (newAsset && rootState?.localDatabase?.isOpen()) {
            rootState?.localDatabase?.put(LOCAL_DATABASE.USER_ASSETS, {
              ...newAsset,
              bidAllocation: newAsset.bidAllocation,
            });
          }
          return resp;
        });
      return result;
    },

    async createVDR({}, params = "") {
      const response = await Vdr.createVDR(params);
      let vdrResult = response.data.createVDR;
      return vdrResult;
    },
  },
};

function assetDestruction(asset) {
  const {
    currentStep,
    registrationStatus,
    noteOverview = {},
    propertyOverview = {},
    principalBalance = {},
    listingPrice = {},
    contacts = [],
  } = asset;

  let contactsWithId = contacts.map((item, idx) => {
    return {
      ...item,
      id: idx,
    };
  });

  const { amount: listingAmount } = listingPrice ?? {};
  const {
    estCollateralValue = {},
    totalPayoff = {},
    unpaidPrincipalBalance = {},
    loanAmount = {},
  } = noteOverview ?? {};

  const newAsset = {
    ...asset,
    currentStep: currentStep || "assetInfo",
    registrationStatus: !!registrationStatus,
    noteOverview: {
      ...noteOverview,
      loanAmount,
      estCollateralValue,
      totalPayoff,
      unpaidPrincipalBalance,
    },
    propertyOverview,
    principalBalance,
    listingPrice: { amount: listingAmount },
    contacts: contactsWithId,
  };

  return newAsset;
}
