import { API } from "aws-amplify";
export default {
  updateClosingNotificationSeenInOffer(variables) {
    const query = `mutation updateClosingNotificationSeenInOffer($id: String!, $closingNotificationSeenBuyer: Boolean, $closingNotificationSeenSeller: Boolean) {
      updateClosingNotificationSeenInOffer(id: $id, closingNotificationSeenBuyer: $closingNotificationSeenBuyer, closingNotificationSeenSeller: $closingNotificationSeenSeller) {
        id
        closingNotificationSeenBuyer
        closingNotificationSeenSeller
      }
    }`;

    return API.graphql({
      query,
      variables,
    });
  },
  searchClosingInProgressOffersBuyer(variables) {
    const query = `query searchClosingInProgressOffersBuyer($size:Int, $offset:Int) {
      searchClosingInProgressOffersBuyer(size: $size, offset: $offset) {
        offers {
          owners
          buyerClosingPeriod {
            startDate
            endDate
          }
          sellerClosingPeriod {
            startDate
            endDate
          }
          closingNotificationSeenBuyer
          closingNotificationSeenSeller
          buyerClosingDocumentsAccepted
          sellerClosingDocumentsAccepted
          id
          bidPrice
          earnestMoneyDeposit
          closing
          dueDiligencePeriod
          assets {
           id
           owners
           name
           title
           assetStatus
           images
           noteOverview {
            unpaidPrincipalBalance {
              amount
            }
           }
           address {
            country
            state
            city
            address
            zipCode
           }
           principalBalance {
            amount
            currency
           }
          }
        }
        total
      }
    }`;

    return API.graphql({
      query,
      variables,
    });
  },
  searchClosingInProgressOffersSeller(variables) {
    const query = `query searchClosingInProgressOffersSeller($size:Int, $offset:Int) {
      searchClosingInProgressOffersSeller(size: $size, offset: $offset) {
        offers {
          owners
          buyerClosingPeriod {
            startDate
            endDate
          }
          sellerClosingPeriod {
            startDate
            endDate
          }
          closingNotificationSeenBuyer
          closingNotificationSeenSeller
          id
          bidPrice
          earnestMoneyDeposit
          closing
          dueDiligencePeriod
          assets {
           id
           owners
           name
           title
           assetStatus
           images
           noteOverview {
            unpaidPrincipalBalance {
              amount
            }
           }
           address {
            country
            state
            city
            address
            zipCode
           }
           principalBalance {
            amount
            currency
           }
          }
        }
        total
      }
    }`;

    return API.graphql({
      query,
      variables,
    });
  },

  editBuyerClosingPeriod(id, buyerClosingPeriod) {
    const query = `mutation editBuyerClosingPeriod($id: String!, $buyerClosingPeriod: PeriodInput) {
      editBuyerClosingPeriod(id: $id, buyerClosingPeriod: $buyerClosingPeriod) {
        id
      }
    }`;

    return API.graphql({
      query,
      variables: { id, buyerClosingPeriod },
    });
  },
  editSellerClosingPeriod(id, sellerClosingPeriod) {
    const query = `mutation editSellerClosingPeriod($id: String!, $sellerClosingPeriod: PeriodInput) {
      editSellerClosingPeriod(id: $id, sellerClosingPeriod: $sellerClosingPeriod) {
        id
      }
    }`;

    return API.graphql({
      query,
      variables: { id, sellerClosingPeriod },
    });
  },
  setOfferClosingHistoryDate(id, opened, docType) {
    const query = `mutation setOfferClosingHistoryDate($id: String!, $opened: Boolean, $docType: DocumentTypeForSign!) {
        setOfferClosingHistoryDate(id: $id, opened: $opened, docType: $docType) {
          id
        }
      }`;

    return API.graphql({
      query,
      variables: { id, opened, docType },
    });
  },
  selectBankForOfferBuyer(offerId, buyerBankingInfoId) {
    const query = `mutation selectBankForOfferBuyer($offerId: String, $buyerBankingInfoId: String) {
        selectBankForOfferBuyer(offerId: $offerId, buyerBankingInfoId: $buyerBankingInfoId) {
          id
        }      
      }`;

    return API.graphql({
      query,
      variables: { offerId, buyerBankingInfoId },
    });
  },
  selectBankForOfferSeller(offerId, sellerBankingInfoId) {
    const query = `mutation selectBankForOfferSeller($offerId: String, $sellerBankingInfoId: String) {
        selectBankForOfferSeller(offerId: $offerId, sellerBankingInfoId: $sellerBankingInfoId) {
          id
          assetsDetailed {
            id
          }
        }      
      }`;

    return API.graphql({
      query,
      variables: { offerId, sellerBankingInfoId },
    });
  },
  selectBankForOfferAdmin(offerId, adminBankingInfoId) {
    const query = `mutation selectBankForOfferAdmin($offerId: String, $adminBankingInfoId: String) {
        selectBankForOfferAdmin(offerId: $offerId, adminBankingInfoId: $adminBankingInfoId) {
          id
        }      
      }`;

    return API.graphql({
      query,
      variables: { offerId, adminBankingInfoId },
    });
  },
  generateBDADocument() {
    const query = `mutation generateBDADocument {
              generateBDADocument 
          }`;

    return API.graphql({
      query,
    });
  },
  signBDADocument(id, signature) {
    const query = `mutation signBDADocument($id: String!, $signature: String!) {
              signBDADocument(id: $id, signature: $signature) {
                key
              }
          }`;

    return API.graphql({
      query,
      variables: { id, signature },
    });
  },
  generateLegalDocument(id, docType) {
    const query = `mutation generateLegalDocument($id: String!, $docType: DocumentTypeForSign!) {
              generateLegalDocument(id: $id, docType: $docType) {
                pdfKey,
                rawKey
              }
          }`;

    return API.graphql({
      query,
      variables: { id, docType },
    });
  },
  signLegalDocument(id, signature, type, signatureDimensions) {
    const query = `mutation signLegalDocument($id: String!, $signature: String!, $type: DocumentTypeForSign!, $signatureDimensions: DimensionsInput) {
              signLegalDocument(id: $id, signature: $signature, type: $type, signatureDimensions: $signatureDimensions) {
                pdfKey,
                rawKey
              }
          }`;

    return API.graphql({
      query,
      variables: { id, signature, type, signatureDimensions },
    });
  },
};
