<template>
  <v-tooltip bottom content-class="second-tooltip" nudge-top="15">
    <template
      v-slot:activator="{ on, attrs }"
      class="--hide-arrow second-tooltip"
    >
      <div class="icon-wrapper">
        <v-btn icon to="/buyer/favorites" v-if="$store.state.auth.user">
          <div v-on="on" v-bind="attrs">
            <img
              class="icon-wrapper__image"
              src="@/assets/img/icons/navHeart.svg"
            />
            <div
              class="count-border"
              v-if="favoritesNumber && favoritesNumber > 0"
            ></div>
            <div
              class="count-icon"
              v-if="favoritesNumber && favoritesNumber > 0"
            >
              {{ favoritesNumber }}
            </div>
          </div>
        </v-btn>
      </div>
    </template>
    <span>View Favorites</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "AppNavFavorites",
  computed: {
    favoritesNumber() {
      return this.$store.state.asset?.favorites?.length;
    },
  },
};
</script>
<style scoped lang="scss">
.count-icon {
  width: 12px;
  height: 12px;
  border-radius: 44px;
  background: #dd226f;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  line-height: 6px;
  position: absolute;
  left: 24px;
  top: 17px;
  border: 3px solid transparent;
  z-index: 6;
  padding-top: 2px;
}
.count-border {
  display: block;
  content: "";
  z-index: 1;
  width: 16px;
  height: 16px;
  position: absolute;
  background: 00136d;
  left: 22px;
  top: 15px;
  border-radius: 19px;
  background: #00136d;
}
.icon-wrapper {
  position: relative;
  &__image {
    padding: 3px;
    position: relative;
    top: 3px;
  }
}

::v-deep .v-btn--active {
  &::before {
    opacity: 0 !important;
  }
}
</style>
