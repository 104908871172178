<template>
  <div
    class="c-label"
    :class="computedClass"
    :style="isMobile && 'font-size: 12px !important'"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    error: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  name: "c-label",
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    additionalClass() {
      if (this.error.length > 0) {
        // return "label-error";
        return "";
      }
      if (this.disabled) {
        return "disabled";
      } else {
        return "";
      }
    },
    computedClass() {
      return [
        {
          "c-label--required": this.required,
        },
        this.additionalClass,
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  color: grey;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .c-label {
    font-size: 12px !important;
  }
}
</style>
