import { API } from "aws-amplify";
export default (id) => {
  const query = `mutation requestAssetPublish($id: String!) {
        requestAssetPublish(id: $id) {
            id
        }
    }`;
  return API.graphql({
    query,
    variables: {
      id,
    },
  });
};
