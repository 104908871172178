import { CONTACT_INFO } from "./ContactInfo";
export const CONTACT = `
firstName
lastName
officePhone
emailAddress
companyName
contactAvatar
whatsappNumber
info{
    ${CONTACT_INFO}
}
`;
