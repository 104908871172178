import getUsersValuations from "./queries/getUsersValuations";
import getValuationById from "./queries/getValuationById";
import deleteValuation from "./mutations/deleteValuation";
import editValuation from "./mutations/editValuation";
import convertToAsset from "./mutations/convertToAsset";
export default {
  getUsersValuations,
  deleteValuation,
  getValuationById,
  editValuation,
  convertToAsset,
};
