export default (phoneNumberString) => {
  if (!phoneNumberString) return;
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|2|3|4|5|6|7|8|9)?(\d{3})(\d{3})(\d{4,})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};
