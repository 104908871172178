import { API } from "aws-amplify";

export default (id, key) => {
  const query = `query document($id:String!, $key:String!) {
        getUserDocument(id:$id, key:$key)
    }`;

  return API.graphql({
    query,
    variables: {
      id,
      key,
    },
  });
};
