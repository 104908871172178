import { API } from "aws-amplify";

export default {
  getOrCreateMessageAllChats(variables) {
    const query = `mutation getOrCreateMessageAllChats($assetID: String!, $buyerIDs: [String]!) {
        getOrCreateMessageAllChats(assetID: $assetID, buyerIDs: $buyerIDs) {
          PK
          SK
          buyerID
          sellerID
          assetID
          lastActivityDate
          creationDate
          twilioSID
          assetInfo {
            id
            name
            assetStatus
            contactsDisclosed
            assetImage
          }
          sellerInfo {
            id
            email
            firstName
            lastName
            sellerProfile {
              avatar
            }
            anonymous
          }
          buyerInfo {
            id
            email
            firstName
            lastName
            sellerProfile {
              avatar
            }
            anonymous
          }
        }
      }`;

    return API.graphql({
      query,
      variables,
    });
  },

  getUserTwilioToken() {
    const query = `query getUserTwilioToken {
       getUserTwilioToken
      }`;

    return API.graphql({
      query,
    });
  },

  noticeMessageAdded(variables) {
    const query = `mutation noticeMessageAdded($msgText:String, $senderID:String, $receiverID:String, $chatTwilioSID:String) {
      noticeMessageAdded(msgText: $msgText, senderID: $senderID, receiverID: $receiverID, chatTwilioSID: $chatTwilioSID) {
        msgText
        senderID
        receiverID
        chatTwilioSID
      }
    }`;
    return API.graphql({
      query,
      variables,
    });
  },

  createChat(variables) {
    const query = `mutation createChat($sellerID: String!, $buyerID: String!, $assetID: String!) {
      createChat(sellerID: $sellerID, buyerID: $buyerID, assetID: $assetID) {
        PK
        SK
        buyerID
        sellerID
        assetID
        lastActivityDate
        creationDate
        twilioSID
        assetInfo {
          id
          name
          assetStatus
          contactsDisclosed
          assetImage
        }
        sellerInfo {
          id
          email
          firstName
          lastName
          sellerProfile {
            avatar
          }
          anonymous
        }
        buyerInfo {
          id
          email
          firstName
          lastName
          sellerProfile {
            avatar
          }
          anonymous
        }
      }
    }`;
    return API.graphql({
      query,
      variables,
    });
  },
  getChatInfo(variables) {
    const query = `query getChatInfo($sellerID: String!, $buyerID: String!, $assetID: String!) {
      getChatInfo(sellerID: $sellerID, buyerID: $buyerID, assetID: $assetID) {
        PK
        SK
        buyerID
        sellerID
        assetID
        lastActivityDate
        creationDate
        twilioSID
        assetInfo {
          id
          name
          assetStatus
          contactsDisclosed
          assetImage
        }
        sellerInfo {
          id
          email
          firstName
          lastName
          sellerProfile {
            avatar
          }
          anonymous
        }
        buyerInfo {
          id
          email
          firstName
          lastName
          sellerProfile {
            avatar
          }
          anonymous
        }
      }
    }`;
    return API.graphql({
      query,
      variables,
    });
  },
  getSellerChatListing(variables) {
    const query = `query getSellerChatListing($limit: Int, $nextToken: String) {
      getSellerChatListing(limit: $limit, nextToken: $nextToken) {
        chats {
          twilioSID
          buyerID
          sellerID
          assetID
          assetInfo {
            id
            name
            assetStatus
            assetImage
          }
          sellerInfo {
            id
            email
            firstName
            lastName
            sellerProfile {
              avatar
            }
            anonymous
          }
          buyerInfo {
            id
            email
            firstName
            lastName
            sellerProfile {
              avatar
            }
            anonymous
          }
        }
        count
        nextToken
      }
    }`;
    return API.graphql({
      query,
      variables,
    });
  },

  getBuyerChatListing(variables) {
    const query = `query getBuyerChatListing($limit: Int, $nextToken: String) {
      getBuyerChatListing(limit: $limit, nextToken: $nextToken) {
        chats {
          twilioSID
          buyerID
          sellerID
          assetID
          assetInfo {
            id
            name
            assetStatus
            assetImage
          }
          sellerInfo {
            id
            email
            firstName
            lastName
            sellerProfile {
              avatar
            }
            anonymous
          }
          buyerInfo {
            id
            email
            firstName
            lastName
            sellerProfile {
              avatar
            }
            anonymous
          }
        }
        count
        nextToken
      }
    }`;
    return API.graphql({
      query,
      variables,
    });
  },

  addChatInfo(variables) {
    const query = `mutation addChatInfo($chatID: String, $assetID: String, $members: [String]) {
      addChatInfo(chatID: $chatID, assetID: $assetID, members: $members) {
        id
        assetInfo {
          id
          name
          assetStatus
          assetImage
        }
        membersInfo {
          id
          email
          firstName
          lastName
          avatar
          anonymous
        }
      }
    }`;
    return API.graphql({
      query,
      variables,
    });
  },
};
