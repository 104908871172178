import { API } from "aws-amplify";

export default (id, data) => {
  const mutation = `mutation assignBuyerRole($id: String!, $data: ExtraProfileData!) {
      assignBuyerRole(id: $id, data: $data) {
        id
        roles
      }
    }`;
  return API.graphql({
    query: mutation,
    variables: { id, data },
  });
};
