<template>
  <div class="spinner-wrapper">
    <v-progress-circular
      :width="width"
      :size="size"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "ComponentSpinner",

  props: {
    size: {
      type: Number,
      default: 30,
      required: true,
    },
    width: {
      type: Number,
      default: 2,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: #c5c5c578;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
