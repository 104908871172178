import Users from "@/services/apiCalls/admin/users";
import UserProfile from "@/services/apiCalls/profile/profile";

import ErrorParser from "@/utils/ErrorParser";
export default {
  namespaced: true,
  state: {
    users: [],
    user: null,
  },
  getters: {},
  mutations: {
    setUsers(state, value) {
      state.users = value;
    },
    addUsers(state, value) {
      state.users = [...state.users, ...value];
    },
  },
  actions: {
    async fetchUsers({ commit }, params) {
      const result = await Users.searchUsers(params)
        .then(({ data }) => {
          const users = data.searchUsers;
          if (users) {
            commit("addUsers", users.profiles);
            return users.profiles.length;
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async addAdmin({ state, commit }, id) {
      const result = await Users.addAdmin(id)
        .then(({ data }) => {
          if (data.addAdmin) {
            const { id, roles } = data.addAdmin;
            const userIdx = state.users.findIndex((user) => user.id === id);
            state.users[userIdx].roles = roles;
            commit("setUsers", state.users);
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async removeAdmin({ state, commit }, id) {
      const result = await Users.removeAdmin(id)
        .then(({ data }) => {
          if (data.removeAdmin) {
            const { id, roles } = data.removeAdmin;
            const userIdx = state.users.findIndex((user) => user.id === id);
            state.users[userIdx].roles = roles.length ? roles : ["Unassigned"];
            commit("setUsers", state.users);
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async assignBuyer({ state, commit }, params) {
      const { id, data } = params;
      const result = await Users.assignBuyer(id, data)
        .then(({ data }) => {
          if (data.assignBuyerRole) {
            const { id, roles } = data.assignBuyerRole;
            const userIdx = state.users.findIndex((user) => user.id === id);
            state.users[userIdx].roles = roles;
            commit("setUsers", state.users);
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async removeBuyer({ state, commit }, id) {
      const result = await Users.removeBuyer(id)
        .then(({ data }) => {
          if (data.removeBuyerRole) {
            const { id, roles } = data.removeBuyerRole;
            const userIdx = state.users.findIndex((user) => user.id === id);
            state.users[userIdx].roles = roles.length ? roles : ["Unassigned"];
            commit("setUsers", state.users);
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async updateUserProfileContact({ state, commit }, params) {
      const result = await Users.updateUserProfileContact(params)
        .then(({ data }) => {
          if (data.adminUpdateUserProfile) {
            const { firstName, lastName, officePhone } =
              data.adminUpdateUserProfile;

            const userIdx = state.users.findIndex(
              (user) => user.id === params.id
            );
            Object.assign(state.users[userIdx], {
              ...state.users[userIdx],
              firstName,
              lastName,
              officePhone,
            });

            commit("setUsers", state.users);
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
    },
    async updateUserProfileLocation({ state, commit }, params) {
      const result = await Users.updateUserProfileLocation(params)
        .then(({ data }) => {
          if (data.adminUpdateUserProfile) {
            const { address } = data.adminUpdateUserProfile;

            const userIdx = state.users.findIndex(
              (user) => user.id === params.id
            );
            Object.assign(state.users[userIdx], {
              ...state.users[userIdx],
              address,
            });

            commit("setUsers", state.users);
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
    },
    async updateUserProfileCompany({ state, commit }, params) {
      const result = await Users.updateUserProfileCompany(params)
        .then(({ data }) => {
          if (data.adminUpdateUserProfile) {
            const { companyName, jobTitle, companyProfile, aum } =
              data.adminUpdateUserProfile;

            const userIdx = state.users.findIndex(
              (user) => user.id === params.id
            );
            Object.assign(state.users[userIdx], {
              ...state.users[userIdx],
              companyName,
              jobTitle,
              companyProfile,
              aum,
            });

            commit("setUsers", state.users);
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
    },
  },
};
