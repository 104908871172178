// Abstract subscriptions handler for asset/assetsList
import subscribeToNoticeAssetActivitiesUpdate from "@/services/apiCalls/asset/subscriptions/subscribeToNoticeAssetActivitiesUpdate";
import subscribeToNoticeViewedOnlyAndAllActivitiesUpdate from "@/services/apiCalls/asset/subscriptions/subscribeToNoticeViewedOnlyAndAllActivitiesUpdate";
import subscribeToAssetUpdatesBySeller from "@/services/apiCalls/asset/subscriptions/subscribeToAssetUpdatesBySeller";
import subscribeToAssetsUpdatesByBot from "@/services/apiCalls/asset/subscriptions/subscribeToAssetsUpdatesByBot";
import subscribeToNoticeESAssetsUpdate from "@/services/apiCalls/asset/subscriptions/subscribeToNoticeESAssetsUpdate";

const subscribeFunctions = {
  subscribeToNoticeAssetActivitiesUpdate,
  subscribeToNoticeViewedOnlyAndAllActivitiesUpdate,
  subscribeToAssetUpdatesBySeller,
  subscribeToAssetsUpdatesByBot,
  subscribeToNoticeESAssetsUpdate,
};
// #TODO update when it will be more asset subscriptions

export class AssetSubscriptionManager {
  /**
   * @typedef AssetSubscription
   * @type {object}
   * @property {string} name - Subscription name.
   * @property {function} onNext - Function to call on status updates
   */

  /** @type {Array<AssetSubscription>} */
  subscriptions = [];
  constructor(subscriptions) {
    this.subscriptions = subscriptions;
  }

  async subscribe() {
    let subPromises = [];
    this.subscriptions.forEach((sub) => {
      subPromises.push(
        subscribeFunctions[sub.name]().subscribe({
          next: sub.onNext,
        })
      );
    });
    try {
      await Promise.all(subPromises);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("subscribe err", err);
    }

    return subPromises;
  }
}
