import { closingGuard } from "./routerGuards/guardClosingProcess";

export default [
  {
    path: "/buy-commercial-real-estate-loans/:role/",
    component: () => import("./components/ClosingDocument/BaseBuyer.vue"),
    meta: {
      hideFooter: true,
      closingDoc: true,
    },
    beforeEnter: closingGuard,
    children: [
      // {
      //   path: "lpa/:id",
      //   component: () => import("./components/ClosingDocument/LPA.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     title: "Settings - Xchange.Loans",
      //     hideFooter: true,
      //   },
      // },
      {
        path: "ss/:id",
        component: () => import("./components/ClosingDocument/PoF.vue"),
        meta: {
          requiresAuth: true,
          title: "Settings - Xchange.Loans",
          hideFooter: true,
          closingDoc: true,
        },
      },
      // {
      //   path: "banking-info/:id",
      //   component: () => import("./components/ClosingDocument/BankingInfo.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     title: "Settings - Xchange.Loans",
      //     hideFooter: true,
      //   },
      // },
      {
        path: "review-submit/:id",
        component: () =>
          import("./components/ClosingDocument/ReviewSubmit.vue"),
        meta: {
          requiresAuth: true,
          title: "Settings - Xchange.Loans",
          hideFooter: true,
          closingDoc: true,
        },
      },
    ],
  },
];
