<template>
  <v-dialog
    v-model="verifyEmailDialog"
    :width="isMobile ? '100%' : '692'"
    class="approve-transfer-dialog"
    @click:outside="close"
    @keydown.esc="close"
  >
    <div class="approve-dialog-wrapper">
      <div class="approve-dialog-wrapper__title">Verify Email</div>
      <div class="close-icon" @click="close">
        <v-icon> mdi-close </v-icon>
      </div>

      <img
        src="@/assets/img/verify-email.svg"
        class="mt-6 approve-transfer-icon"
      />
      <div class="approve-dialog-wrapper__text mt-8">
        <div>
          For performing this action, it is necessary to verify your email
          address.
        </div>
        <div>Would you like to verify now?</div>
      </div>
      <div class="approve-dialog-wrapper__buttons mt-15">
        <AppButton
          buttonType="outlined"
          color="#2969FF"
          width="230px"
          height="40px"
          buttonText="Cancel"
          @click="close"
        />
        <AppButton
          :disabled="loading"
          color="#2969FF"
          width="230px"
          height="40px"
          rounded
          shaped
          buttonText="Verify"
          @click="verify"
        />
      </div>
    </div>
  </v-dialog>
</template>
<script>
import AppButton from "@/components/ui/AppButton.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "VerifyEmailDialog",
  components: { AppButton },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("AuthHelpers", ["verifyEmailDialog"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapMutations("AuthHelpers", ["setVerifyEmailDialog"]),
    ...mapActions("AuthHelpers", ["sendVerificationEmail"]),
    close() {
      this.setVerifyEmailDialog(false);
    },
    verify() {
      this.loading = true;
      localStorage.setItem("pathForVerify", this.$route.path);
      this.sendVerificationEmail().then((resp) => {
        this.loading = false;
        this.close();
        this.$store.commit("openSnackBar", {
          text: resp,
          color: "success",
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.approve-dialog-wrapper {
  padding: 40px 0;
  background: white;
  box-shadow: 0px 0px 20px 0px rgba(177, 201, 216, 0.36);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.32px;
    text-align: center;
  }
  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    width: 500px;
  }
  &__buttons {
    display: flex;
    column-gap: 40px;
  }
}
.close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.approve-transfer-icon {
  width: 104px;
  height: 104px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .approve-dialog-wrapper__buttons {
    width: 100%;
    column-gap: 20px;
    padding: 0px 16px;
  }
  .approve-dialog-wrapper__text {
    width: 100%;
  }
  .approve-dialog-wrapper__buttons {
    button {
      flex: 1;
      //width: 50% !important;
      flex-basis: 50%;
    }
  }
  ::v-deep {
    .v-dialog {
      margin: 0;
      height: 100%;
      max-height: 100%;
    }
  }

  .approve-dialog-wrapper {
    height: 100%;
  }
}
</style>
