import getAssetEntities from "@/services/apiCalls/asset/queries/getAssetEntities";
import Offer from "@/services/apiCalls/offer/offer.js";
import Asset from "@/services/apiCalls/asset/asset.js";
import Deposit from "@/services/apiCalls/deposit/Deposit";
import ErrorParser from "@/utils/ErrorParser";

export default {
  namespaced: true,
  state: {
    sidePanelParams: {
      isOpened: false,
      item: null,
      asset: null,
      changeOfferFunc: () => {},
    },
  },
  getters: {},
  mutations: {
    setSidePanelParams(state, payload) {
      state.sidePanelParams = payload;
    },
  },
  actions: {
    async getAssetEntityDetails(
      {},
      { id, limit = 200, nextToken, entityType }
    ) {
      try {
        const { data } = await getAssetEntities({
          id,
          limit: limit ? limit : 200,
          nextToken,
          entityType,
        });
        return data.getAssetEntities;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("err", err);
      }
    },
    async editOfferNotes({ state }, { asssetEntity, id, notes }) {
      try {
        await Offer.editOfferSidePanelSeller({
          id,
          notes,
        });
        asssetEntity.offer.notes = notes;
      } catch (err) {
        console.error(err);
      }
    },
    async getUserAssetData({}, { id, userId }) {
      try {
        const { data } = await Asset.getAssetData({ id, userId });
        return data.getUserAssetData;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async getRequestData({}, { userId, assetId }) {
      try {
        const { data } = await Offer.getTier2RequestForUser({
          userId,
          assetId,
        });
        return data.getTier2RequestForUser;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async declineDeposit({ state, commit }, { id, declineFault, comment }) {
      try {
        commit("spinner", true, { root: true });

        const { data } = await Deposit.declineDeposit({
          id,
          declineFault,
          comment,
        });
        const sidePanelParams = state.sidePanelParams;
        commit("setSidePanelParams", {
          ...sidePanelParams,
          item: {
            ...sidePanelParams.item,
            deposit: {
              ...data.declineDeposit.deposit,
            },
          },
        });
        const changeOfferFunc = state.sidePanelParams.changeOfferFunc;
        if (changeOfferFunc) {
          changeOfferFunc({
            id,
            deposit: data.declineDeposit,
          });
        }
        commit(
          "openSnackBar",
          {
            text: "The Platform Fee will not be transferred automatically, since XL Admin payment account was not provided. Please go to the payment platform’s dashboard to manually transfer the fee.",
            color: "success",
          },
          { root: true }
        );
      } catch (err) {
        commit("spinner", false, { root: true });

        commit(
          "openSnackBar",
          {
            text: ErrorParser(err),
            color: "error",
          },
          { root: true }
        );
      }
      commit("spinner", false, { root: true });
    },

    async approveDepositSeller({ state, commit }, { id }) {
      try {
        commit("spinner", true, { root: true });
        const { data } = await Deposit.approveDepositSeller({ id });
        commit(
          "openSnackBar",
          {
            text: "The deposit transfer process was initiated successfully",
            color: "success",
          },
          { root: true }
        );
        return true;
      } catch (err) {
        commit("spinner", false, { root: true });

        commit(
          "openSnackBar",
          {
            text: ErrorParser(err),
            color: "error",
          },
          { root: true }
        );

        return false;
      } finally {
        commit("spinner", false, { root: true });
      }
    },

    async approveDeposit({ state, commit }, { id, depositAmount }) {
      try {
        commit("spinner", true, { root: true });
        const { data } = await Deposit.approveDeposit({ id, depositAmount });
        const sidePanelParams = state.sidePanelParams;
        commit("setSidePanelParams", {
          ...sidePanelParams,
          item: {
            ...sidePanelParams.item,
            deposit: {
              ...data.approveDeposit.deposit,
            },
          },
        });
        const changeOfferFunc = state.sidePanelParams.changeOfferFunc;
        if (changeOfferFunc) {
          changeOfferFunc({
            id,
            deposit: data.approveDeposit,
          });
        }
        commit(
          "openSnackBar",
          {
            text: "The deposit transfer process was initiated successfully",
            color: "success",
          },
          { root: true }
        );
        return true;
      } catch (err) {
        commit("spinner", false, { root: true });

        commit(
          "openSnackBar",
          {
            text: ErrorParser(err),
            color: "error",
          },
          { root: true }
        );

        return false;
      } finally {
        commit("spinner", false, { root: true });
      }
    },
  },
};
