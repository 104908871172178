import { API } from "aws-amplify";
import { ES_ASSET } from "@/services/apiCalls/types/Asset/ESAsset";

export default (variables) => {
  const query = `query searchAssets_LOCAL_DB(
    $assetStatus: [String],
    $status: [String],
    $propertyType: [String],
    $size: Int,
    $offset: Int,
    $address: String,
    $state: [String],
    $owners: [String],
    $country: String,
    $city: String,
    $name: String,
    $sortBy:ESSortingBy,
    $orderBy: OrderByChoices,
    $id:String
    )
     {
     searchAssets(
            assetStatus: $assetStatus,
            status: $status,
            propertyType: $propertyType,
            size: $size,
            offset: $offset
            address: $address,
            owners: $owners,
            state: $state,
            country: $country,
            city: $city,
            name: $name,
            sortBy: $sortBy,
            orderBy: $orderBy,
            id:$id
        ){
        assets{
          ${ES_ASSET}
        }   
 
        }
        
       
    }
  `;

  return API.graphql({
    query,
    variables,
  });
};
