import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default async (assetInfo) => {
  console.log("note - ", assetInfo);
  const mutation = `mutation editAssetOnNext(
        $currentStep: String,
        $id: String!,
        $noteOverview: AssetNoteInput,
      ) {
        editAsset(
          currentStep: $currentStep,
          id: $id,
          noteOverview: $noteOverview
        ) {
       ${ASSET}
        }
      }`;

  let variables = assetInfo;

  return API.graphql({
    query: mutation,
    variables,
  });
};
