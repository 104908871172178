// date.toISOString()
import { API } from "aws-amplify";
const states = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "Various",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];
export default async (assetInfo) => {
  assetInfo.privacy = assetInfo.privacy ? "public" : "public";

  const mutation = `mutation create(
        $assetDescription: String,
        $name: String!,
        $type: AssetType!,
        $assetStatus: [String]!,
        $privacy: String,
        $principalBalance: ValueAmountInput!,
        $listingPrice: ValueAmountInput,
        $privacy: String,
        $requireBidDeposit: Boolean,
        $bidDepositSum: ValueAmountInput!,
        $preferredBidDepositChannel: String,
        $contacts: [ContactInput]!,
        $address: AddressInput!,
        $noteOverview: AssetNoteInput,
        $propertyOverview: PropertyInfoInput,
        $callForOffersPeriod: PeriodInput,
        $bestAndFinalPeriod: PeriodInput,
        $displayDueDate: DisplayDueDate,
        $upbDate: String,
        $images: [String],
      
        $bestCashOffer: Boolean,
        $tierTitle: String!,
        $tier1:String!,
        $tier2: String!,
        $ownerEmail: AWSEmail!
      ) {
        createAsset(
          name: $name
          description: $assetDescription
          privacy: $privacy
          type: $type,
          assetStatus: $assetStatus,
          upbDate: $upbDate
          ownerEmail: $ownerEmail
          callForOffersPeriod: $callForOffersPeriod,
          bestAndFinalPeriod: $bestAndFinalPeriod,
          displayDueDate: $displayDueDate,
          images: $images,
          contacts: $contacts,
          principalBalance: $principalBalance,
          listingPrice: $listingPrice,
          requireBidDeposit: $requireBidDeposit,
          bidDepositSum: $bidDepositSum,
          preferredBidDepositChannel: $preferredBidDepositChannel,
          address: $address,
          
          bestCashOffer: $bestCashOffer,
          noteOverview: $noteOverview,
          propertyOverview: $propertyOverview,
          tier_home_id: $tierTitle,
          tier1_id: $tier1,
          tier2_id: $tier2
        ) {
          id
          name
        }
      }`;
  // const currentUser = await this.getProfile()
  // const contacts = [
  //     {
  //         firstName: currentUser.data.getUserProfile.firstName,
  //         lastName: currentUser.data.getUserProfile.lastName,
  //         emailAddress: currentUser.data.getUserProfile.email,
  //         officePhone:
  //             currentUser.data.getUserProfile.officePhone ||
  //             '+380636363333'
  //     }
  // ]
  // eslint-disable-next-line no-console
  // eslint-disable-next-line no-console
  let variables = {
    name: assetInfo.name,
    assetDescription: assetInfo.assetDescription || null,
    assetStatus: assetInfo.assetStatus || [],
    privacy: assetInfo.privacy || null,
    type: assetInfo.type,
    upbDate: new Date(assetInfo.upbDate?.split("-").join("/")).toISOString(),
    preferredBidDepositChannel: assetInfo.preferredBidDepositChannel || null,
    requireBidDeposit: assetInfo.requireBidDeposit || null,
    images: assetInfo.images,
    contacts: assetInfo.contacts,
    ownerEmail: assetInfo.ownerEmail,
    principalBalance: {
      amount: +assetInfo.principalBalance,
    },
    listingPrice:
      (assetInfo.listingPrice && {
        amount: +assetInfo.listingPrice,
      }) ||
      null,
    bidDepositSum: {
      amount: +assetInfo.bidDepositSum,
    },
    displayDueDate: assetInfo.displayDueDate || null,
    callForOffersPeriod:
      !assetInfo.callForOffersPeriod.startDate ||
      !assetInfo.callForOffersPeriod.endDate
        ? null
        : {
            startDate: assetInfo.callForOffersPeriod.startDate
              ? new Date(assetInfo.callForOffersPeriod.startDate).toISOString()
              : null,
            endDate: assetInfo.callForOffersPeriod.endDate
              ? new Date(assetInfo.callForOffersPeriod.endDate).toISOString()
              : null,
          },
    bestAndFinalPeriod:
      !assetInfo.bestAndFinalPeriod.startDate ||
      !assetInfo.bestAndFinalPeriod.endDate
        ? null
        : {
            startDate: assetInfo.bestAndFinalPeriod.startDate
              ? new Date(assetInfo.bestAndFinalPeriod.startDate).toISOString()
              : null,
            endDate: assetInfo.bestAndFinalPeriod.endDate
              ? new Date(assetInfo.bestAndFinalPeriod.endDate).toISOString()
              : null,
          },
    address: {
      country: assetInfo.address.country || null,
      state:
        (states.includes(assetInfo.address.state) && assetInfo.address.state) ||
        "Various",
      city: assetInfo.address.city || null,
      address: assetInfo.address.address || null,
      zipCode: +assetInfo.address.zipCode,
    },
    tierTitle: assetInfo.tier_home_id,
    tier1: assetInfo.tier1_id,
    tier2: assetInfo.tier2_id,
    pricingPlan: assetInfo.pricingPlan,
    bestCashOffer: assetInfo.bestCashOffer,
    noteOverview: {
      numberOfLoans: +assetInfo.noteOverview.numberOfLoans || null,
      loanAmount:
        (assetInfo.noteOverview.loanAmount && {
          amount: +assetInfo.noteOverview.loanAmount,
        }) ||
        null,
      modified: assetInfo.noteOverview.modified || null,
      originationDate: assetInfo.noteOverview.originationDate
        ? new Date(
            assetInfo.noteOverview.originationDate?.split("-").join(" ")
          ).toISOString()
        : null,
      maturityDate: assetInfo.noteOverview.maturityDate
        ? new Date(
            assetInfo.noteOverview.maturityDate?.split("-").join("/")
          ).toISOString()
        : null,
      totalPayoff:
        (+assetInfo.noteOverview.totalPayoff && {
          amount: assetInfo.noteOverview.totalPayoff,
        }) ||
        null,
      estCollateralValue:
        (+assetInfo.noteOverview.estCollateralValue && {
          amount: assetInfo.noteOverview.estCollateralValue,
        }) ||
        null,
      unpaidPrincipalBalance: {
        amount: +assetInfo.noteOverview.unpaidPrincipalBalance || null,
      },
      initialInterestRate: +assetInfo.noteOverview.initialInterestRate || null,
      initialInterestType: assetInfo.noteOverview.initialInterestType || null,
      currentInterestRate: +assetInfo.noteOverview.currentInterestRate || null,
      defaultInterestRate: +assetInfo.noteOverview.defaultInterestRate || null,
      term: +assetInfo.noteOverview.term || null,
      amortization: +assetInfo.noteOverview.amortization || null,
      // recourse: assetInfo.noteOverview.recourse || null,
      // monetaryDefault: assetInfo.noteOverview.monetaryDefault || null,
      // maturityDefault: assetInfo.noteOverview.maturityDefault || null,
      // technicalDefault: assetInfo.noteOverview.technicalDefault || null,
      // assumptionOfLoanAllowed:
      //     assetInfo.noteOverview.assumptionOfLoanAllowed || null,
      // prePaymentLimitations:
      //     assetInfo.noteOverview.prePaymentLimitations || null,
      assumptionOfLoanAllowed:
        assetInfo.noteOverview.assumptionOfLoanAllowed === "yes"
          ? true
          : assetInfo.noteOverview.assumptionOfLoanAllowed === "no"
          ? false
          : null,
      recourse:
        assetInfo.noteOverview.recourse === "yes"
          ? true
          : assetInfo.noteOverview.recourse === "no"
          ? false
          : null,
      prePaymentLimitations:
        assetInfo.noteOverview.prePayLimitations === "yes"
          ? true
          : assetInfo.noteOverview.prePayLimitations === "no"
          ? false
          : null,
      monetaryDefault:
        assetInfo.noteOverview.monetaryDefault === "yes"
          ? true
          : assetInfo.noteOverview.monetaryDefault === "no"
          ? false
          : null,
      maturityDefault:
        assetInfo.noteOverview.maturityDefault === "yes"
          ? true
          : assetInfo.noteOverview.maturityDefault === "no"
          ? false
          : null,
      technicalDefault:
        assetInfo.noteOverview.technicalDefault === "yes"
          ? true
          : assetInfo.noteOverview.technicalDefault === "no"
          ? false
          : null,
      penalty: assetInfo.noteOverview.penalty || null,
    },
    propertyOverview: {
      propertyType: assetInfo.propertyOverview.propertyType || null,
      subtype: assetInfo.propertyOverview.subtype.length
        ? assetInfo.propertyOverview.subtype
        : ["None"],
      classField: assetInfo.propertyOverview.classField || null,
      yearBuilt: +assetInfo.propertyOverview.yearBuilt || null,
      yearRenovated: +assetInfo.propertyOverview.yearRenovated || null,
      size: +assetInfo.propertyOverview.size || null,
      apn: assetInfo.propertyOverview.apn || null,
      zoning: assetInfo.propertyOverview.zoning || null,
      nraTotal: +assetInfo.propertyOverview.nraTotal,
      unitOfMeasure: assetInfo.propertyOverview.unitOfMeasure || null,
      units: +assetInfo.propertyOverview.units || null,
      constructionType: assetInfo.propertyOverview.constructionType || null,
      buildingLayout: assetInfo.propertyOverview.buildingLayout || null,
      stories: assetInfo.propertyOverview.stories || null,
      buildings: assetInfo.propertyOverview.buildings || null,
      condo:
        assetInfo.propertyOverview.condo === "yes"
          ? true
          : assetInfo.propertyOverview.condo === "no"
          ? false
          : null,
      multiProperty:
        assetInfo.propertyOverview.multiProperty === "yes"
          ? true
          : assetInfo.propertyOverview.multiProperty === "no"
          ? false
          : null,
      parkingType: assetInfo.propertyOverview.parkingType || null,
      parkingSpaces: +assetInfo.propertyOverview.parkingSpaces,
      environmentalIssues:
        assetInfo.propertyOverview.environmentalIssues || null,
      propertyCondition: assetInfo.propertyOverview.propertyCondition || null,
      feeLeasehold: assetInfo.propertyOverview.feeLeasehold || null,
      largestTenantCredit:
        assetInfo.propertyOverview.largestTenantCredit || null,
      tenantType: assetInfo.propertyOverview.tenantType || null,
      isOwnerOccupied:
        assetInfo.propertyOverview.isOwnerOccupied === "yes"
          ? true
          : assetInfo.propertyOverview.isOwnerOccupied === "no"
          ? false
          : null,
      ownerOcc: +assetInfo.propertyOverview.ownerOcc || null,
      // assumptionOfLoanAllowed:
      //     assetInfo.propertyOverview.assumptionOfLoanAllowed === 'yes'
      //         ? true
      //         : assetInfo.propertyOverview.assumptionOfLoanAllowed ===
      //           'no'
      //         ? false
      //         : null,
      // recourse:
      //     assetInfo.propertyOverview.recourse === 'yes'
      //         ? true
      //         : assetInfo.propertyOverview.recourse === 'no'
      //         ? false
      //         : null,
      // prePayLimitations:
      //     assetInfo.propertyOverview.prePayLimitations === 'yes'
      //         ? true
      //         : assetInfo.propertyOverview.prePayLimitations === 'no'
      //         ? false
      //         : null,
      // monetaryDefault:
      //     assetInfo.propertyOverview.monetaryDefault === 'yes'
      //         ? true
      //         : assetInfo.propertyOverview.monetaryDefault === 'no'
      //         ? false
      //         : null,
      // maturityDefault:
      //     assetInfo.propertyOverview.maturityDefault === 'yes'
      //         ? true
      //         : assetInfo.propertyOverview.maturityDefault === 'no'
      //         ? false
      //         : null,
      // technicalDefault:
      //     assetInfo.propertyOverview.technicalDefault === 'yes'
      //         ? true
      //         : assetInfo.propertyOverview.technicalDefault === 'no'
      //         ? false
      //         : null,
      // interestType: assetInfo.propertyOverview.interestType || null
    },
  };

  // return client.mutate({
  //     mutation: gql(mutation),
  //     variables
  // })
  return API.graphql({
    query: mutation,
    variables,
  });
};
