import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default async (assetInfo) => {
  assetInfo.contacts?.forEach((item) => {
    delete item.id;
    delete item.index;
  });

  console.log("assetInfo1 - ", assetInfo);
  const mutation = `mutation editAssetOnNext(
        $currentStep: String,
        $id: String!,
        $propertyOverview: PropertyInfoInput,
      ) {
        editAsset(
          currentStep: $currentStep,
          id: $id
          propertyOverview: $propertyOverview,
        ) {
        ${ASSET}
        }
      }`;

  console.log("assetInfo2 - ", assetInfo);
  /*let variables = {
    currentStep: assetInfo.currentStep,
    id: assetInfo.id,
    name: assetInfo.name,
    assetStatus: assetInfo.assetStatus,
    contactsDisclosed: assetInfo.contactsDisclosed,
    type: assetInfo.type,
    caConfirmed: assetInfo.caConfirmed,
    TOCConfirmed: assetInfo.TOCConfirmed,
    customCAText: assetInfo.customCAText,
    customCADoc: assetInfo.customCADoc ? assetInfo.customCADoc : "",
    assetDescription: assetInfo.description || null,
    // privacy: assetInfo.privacy || null,
    upbDate: assetInfo.upbDate
      ? new Date(assetInfo.upbDate.split("-").join("/")).toISOString()
      : "",
    preferredBidDepositChannel: assetInfo.preferredBidDepositChannel || null,
    requireBidDeposit: assetInfo.requireBidDeposit || null,
    images: assetInfo.images,
    contacts: assetInfo.contacts,
    principalBalance:
      (typeof assetInfo.principalBalance !== "object" && {
        amount: +assetInfo.principalBalance,
      }) ||
      null,
    listingPrice: (typeof assetInfo.listingPrice !== "object" &&
      assetInfo.listingPrice && {
        amount: +assetInfo.listingPrice,
      }) || {
      amount: null,
    },
    bidDepositSum:
      (typeof assetInfo.bidDepositSum !== "object" && {
        amount: +assetInfo.bidDepositSum,
      }) ||
      null,
    // displayDueDate: assetInfo.displayDueDate || null,
    callForOffersPeriod:
      (assetInfo.callForOffersPeriod &&
        !assetInfo.callForOffersPeriod.startDate) ||
      (assetInfo.callForOffersPeriod &&
        !assetInfo.callForOffersPeriod.endDate) ||
      !assetInfo.callForOffersPeriod ||
      !assetInfo.callForOffersPeriod
        ? null
        : {
            startDate:
              (assetInfo.callForOffersPeriod &&
                assetInfo.callForOffersPeriod.startDate &&
                new Date(
                  assetInfo.callForOffersPeriod.startDate
                ).toISOString()) ||
              null,
            endDate:
              (assetInfo.callForOffersPeriod &&
                assetInfo.callForOffersPeriod.endDate &&
                new Date(
                  assetInfo.callForOffersPeriod.endDate
                ).toISOString()) ||
              null,
          },
    bestAndFinalPeriod:
      (assetInfo.bestAndFinalPeriod &&
        !assetInfo.bestAndFinalPeriod.startDate) ||
      (assetInfo.bestAndFinalPeriod && !assetInfo.bestAndFinalPeriod.endDate) ||
      !assetInfo.bestAndFinalPeriod ||
      !assetInfo.bestAndFinalPeriod
        ? null
        : {
            startDate:
              (assetInfo.bestAndFinalPeriod &&
                assetInfo.bestAndFinalPeriod.startDate &&
                new Date(
                  assetInfo.bestAndFinalPeriod.startDate
                ).toISOString()) ||
              null,
            endDate:
              (assetInfo.bestAndFinalPeriod &&
                assetInfo.bestAndFinalPeriod.endDate &&
                new Date(assetInfo.bestAndFinalPeriod.endDate).toISOString()) ||
              null,
          },
    ownerEmail: assetInfo.ownerEmail,
    address: {
      country: assetInfo.address.country || null,
      state: assetInfo.address.state,
      city: assetInfo.address.city || null,
      address: assetInfo.address.address || null,
      zipCode:
        (typeof assetInfo.address.zipCode !== "object" &&
          +assetInfo.address.zipCode) ||
        null,
    },
    bestCashOffer: assetInfo.bestCashOffer,
    noteOverview: {
      numberOfLoans:
        (typeof assetInfo.noteOverview.numberOfLoans !== "object" &&
          +assetInfo.noteOverview.numberOfLoans) ||
        null,
      loanAmount:
        (typeof assetInfo.noteOverview.loanAmount !== "object" && {
          amount: +assetInfo.noteOverview.loanAmount,
        }) ||
        null,
      modified:
        assetInfo.noteOverview.modified === "yes"
          ? true
          : assetInfo.noteOverview.maturityDefault === "no"
          ? false
          : null,
      // assetInfo.noteOverview.maturityDefault === "yes"
      //                 ? true
      //                 : assetInfo.noteOverview.maturityDefault === "no"
      //                   ? false
      //                   : null,
      originationDate: assetInfo.noteOverview.originationDate
        ? new Date(
            assetInfo.noteOverview.originationDate.split("-").join("/")
          ).toISOString()
        : null,
      maturityDate: assetInfo.noteOverview.maturityDate
        ? assetInfo.noteOverview.maturityDate &&
          new Date(
            assetInfo.noteOverview.maturityDate.split("-").join("/")
          ).toISOString()
        : null,
      totalPayoff:
        (typeof assetInfo.noteOverview.totalPayoff !== "object" && {
          amount: +assetInfo.noteOverview.totalPayoff,
        }) ||
        null,
      estCollateralValue:
        (typeof assetInfo.noteOverview.estCollateralValue !== "object" && {
          amount: +assetInfo.noteOverview.estCollateralValue,
        }) ||
        null,
      unpaidPrincipalBalance:
        (typeof assetInfo.noteOverview.unpaidPrincipalBalance !== "object" && {
          amount: +assetInfo.noteOverview.unpaidPrincipalBalance,
        }) ||
        null,
      initialInterestRate:
        (typeof assetInfo.noteOverview.initialInterestRate !== "object" &&
          +assetInfo.noteOverview.initialInterestRate) ||
        null,
      initialInterestType: assetInfo.noteOverview.initialInterestType || null,
      currentInterestRate:
        (typeof assetInfo.noteOverview.currentInterestRate !== "object" &&
          +assetInfo.noteOverview.currentInterestRate) ||
        null,
      defaultInterestRate:
        (typeof assetInfo.noteOverview.defaultInterestRate !== "object" &&
          +assetInfo.noteOverview.defaultInterestRate) ||
        null,
      term:
        (typeof assetInfo.noteOverview.term !== "object" &&
          +assetInfo.noteOverview.term) ||
        null,
      amortization:
        (typeof assetInfo.noteOverview.amortization !== "object" &&
          +assetInfo.noteOverview.amortization) ||
        null,
      assumptionOfLoanAllowed:
        assetInfo.noteOverview.assumptionOfLoanAllowed === "yes"
          ? true
          : assetInfo.noteOverview.assumptionOfLoanAllowed === "no"
          ? false
          : null,
      recourse:
        assetInfo.noteOverview.recourse === "yes"
          ? true
          : assetInfo.noteOverview.recourse === "no"
          ? false
          : null,
      prePaymentLimitations:
        assetInfo.noteOverview.prePaymentLimitations === "yes"
          ? true
          : assetInfo.noteOverview.prePaymentLimitations === "no"
          ? false
          : null,
      monetaryDefault:
        assetInfo.noteOverview.monetaryDefault === "yes"
          ? true
          : assetInfo.noteOverview.monetaryDefault === "no"
          ? false
          : null,
      maturityDefault:
        assetInfo.noteOverview.maturityDefault === "yes"
          ? true
          : assetInfo.noteOverview.maturityDefault === "no"
          ? false
          : null,
      technicalDefault:
        assetInfo.noteOverview.technicalDefault === "yes"
          ? true
          : assetInfo.noteOverview.technicalDefault === "no"
          ? false
          : null,
      penalty: assetInfo.noteOverview.penalty || null,
    },
    propertyOverview: {
      propertyType:
        !assetInfo.propertyOverview.propertyType ||
        (!assetInfo.propertyOverview.propertyType.length && null) ||
        assetInfo.propertyOverview.propertyType,
      subtype: assetInfo.propertyOverview?.subtype?.length
        ? assetInfo.propertyOverview.subtype
        : null,
      classField: assetInfo.propertyOverview.classField || null,
      yearBuilt:
        (typeof assetInfo.propertyOverview.yearBuilt !== "object" &&
          +assetInfo.propertyOverview.yearBuilt) ||
        null,
      yearRenovated:
        (typeof assetInfo.propertyOverview.yearRenovated !== "object" &&
          +assetInfo.propertyOverview.yearRenovated) ||
        null,
      size:
        (typeof assetInfo.propertyOverview.size !== "object" &&
          +assetInfo.propertyOverview.size) ||
        null,
      apn: assetInfo.propertyOverview.apn,
      zoning: assetInfo.propertyOverview.zoning || null,
      nraTotal:
        (typeof assetInfo.propertyOverview.nraTotal !== "object" &&
        assetInfo.propertyOverview.nraTotal &&
        typeof assetInfo.propertyOverview.nraTotal === "string"
          ? Number.parseFloat(
              assetInfo.propertyOverview.nraTotal.replaceAll(",", "")
            )
          : assetInfo.propertyOverview.nraTotal) || null,
      unitOfMeasure: assetInfo.propertyOverview.unitOfMeasure || null,
      units:
        (typeof assetInfo.propertyOverview.units !== "object" &&
          +assetInfo.propertyOverview.units) ||
        null,
      constructionType: assetInfo.propertyOverview.constructionType || null,
      propertyTaxesCurrent:
        assetInfo.propertyOverview.propertyTaxesCurrent || null,
      buildingLayout: assetInfo.propertyOverview.buildingLayout || null,
      stories: assetInfo.propertyOverview.stories || null,
      buildings: assetInfo.propertyOverview.buildings || null,
      condo:
        assetInfo.propertyOverview.condo === "yes"
          ? true
          : assetInfo.propertyOverview.condo === "no"
          ? false
          : null,
      multiProperty:
        assetInfo.propertyOverview.multiProperty === "yes"
          ? true
          : assetInfo.propertyOverview.multiProperty === "no"
          ? false
          : null,
      parkingType: assetInfo.propertyOverview.parkingType || null,
      parkingSpaces:
        (typeof assetInfo.propertyOverview.parkingSpaces !== "object" &&
          +assetInfo.propertyOverview.parkingSpaces) ||
        null,
      environmentalIssues:
        assetInfo.propertyOverview.environmentalIssues || null,
      propertyCondition: assetInfo.propertyOverview.propertyCondition || null,
      feeLeasehold: assetInfo.propertyOverview.feeLeasehold || null,
      largestTenantCredit:
        assetInfo.propertyOverview.largestTenantCredit || null,
      tenantType: assetInfo.propertyOverview.tenantType || null,
      isOwnerOccupied:
        assetInfo.propertyOverview.isOwnerOccupied === "yes"
          ? true
          : assetInfo.propertyOverview.isOwnerOccupied === "no"
          ? false
          : null,
      ownerOcc:
        (typeof assetInfo.propertyOverview.ownerOcc !== "object" &&
          +assetInfo.propertyOverview.ownerOcc) ||
        null,
    },
  }; */

  let variables = assetInfo;

  return API.graphql({
    query: mutation,
    variables,
  });
};
