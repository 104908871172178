<template>
  <v-snackbar
    :value="isSnackBarShown"
    :timeout="timeout"
    :class="{
      'v-snack--error': isError,
    }"
    top
    right
  >
    <div
      class="v-snack__timeline"
      :style="{
        width: `${this.getTimerline}%`,
      }"
    />
    <div class="d-flex">
      <div class="image-wrapper">
        <img :src="getLogo" alt="logo_notifications" />
      </div>
      <div class="d-flex align-center message-wrapper">
        <p class="mb-0 pa-3 message" v-if="!snackbar.isHtml">
          {{ getSnackbarText }}
        </p>
        <p class="mb-0 pa-3 message" v-else v-html="getSnackbarText"></p>
      </div>
    </div>

    <v-btn slot="action" @click="onClose" icon>
      <img :src="getCloseIcon" alt="close" />
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

const TIMEOUT = 7000;
const STEP = 30;

export default {
  name: "AppSnackBar",
  data() {
    return {
      timeoutId: null,
      timer: TIMEOUT,
      timeout: TIMEOUT,
    };
  },
  computed: {
    ...mapState(["snackbar"]),
    ...mapState("Helpers", ["oneMoreNotification"]),
    isSnackBarShown() {
      return this.snackbar.isSnackBarShown;
    },
    isError() {
      return this.snackbar.color === "error";
    },
    getSnackbarText() {
      return this.snackbar.text;
    },
    getTimerline() {
      return (this.timer * 100) / TIMEOUT;
    },
    getCloseIcon() {
      if (this.isError) {
        return require("@/assets/img/icons/close-thin-dark.svg");
      }
      return require("@/assets/img/icons/close-thin-light.svg");
    },
    getLogo() {
      if (this.isError) {
        return require("@/assets/img/snackbar-logo-dark.png");
      }
      return require("@/assets/img/snackbar-logo-light.png");
    },
  },
  methods: {
    ...mapMutations(["closeSnackBar"]),
    ...mapMutations("Helpers", ["setOneMoreNotification"]),
    async onClose() {
      this.closeSnackBar();
      await this.$nextTick();
      this.timeout = TIMEOUT;
      if (
        this.oneMoreNotification &&
        this.snackbar.text !== this.oneMoreNotification.text
      ) {
        await this.$nextTick();
        this.$store.commit("openSnackBar", { ...this.oneMoreNotification });
        await this.$nextTick();
        this.setOneMoreNotification(null);
      }
    },
  },
  watch: {
    isSnackBarShown(val) {
      if (val) {
        this.timeoutId = setInterval(() => {
          this.timer = this.timer - STEP;
          if (this.timer <= 0) {
            clearInterval(this.timeoutId);
            this.onClose();
          }
        }, STEP);
      } else {
        this.timer = TIMEOUT;
      }
    },
  },
};
</script>

<style scoped lang="scss">
$error-background: #fff;
$error-text: #d52819;
$timeline-background: #dd226f;

.v-snack--error {
  ::v-deep {
    .v-snack__wrapper {
      background: $error-background !important;
    }

    .message {
      color: $error-text !important;
    }
  }
}

.v-snack__timeline {
  position: absolute;
  top: 0px;
  height: 2px;
  background: $timeline-background;
}
</style>
