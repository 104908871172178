<template>
  <v-dialog
    v-model="dialog"
    width="1230"
    @keydown.esc="close(false)"
    persistent
    :fullscreen="isMobile"
  >
    <div v-if="!isMobile" class="img-wrapper">
      <img src="@/assets/img/contact-form-image.png" class="contact-img" />
    </div>
    <div class="form-wrapper">
      <div>
        <div class="contact-form">
          <div class="contact-title">Contact Us</div>
          <v-form class="contactFrom" ref="form">
            <div class="close-icon" @click="close">
              <v-icon> mdi-close </v-icon>
            </div>
            <v-row :no-gutters="isMobile">
              <v-col cols="12" md="6" class="c-col">
                <AppInput
                  placeholder="John Smith"
                  v-model="form.name"
                  :error-messages="nameErrors"
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                  required
                  labelText="Full Name"
                />
              </v-col>
              <v-col cols="12" md="6" class="c-col mt-4 mt-sm-0">
                <AppInput
                  v-model="form.email"
                  placeholder="user@mail.com"
                  :error-messages="emailErrors"
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                  labelText="Email"
                />
              </v-col>
              <v-col cols="12" md="6" class="c-col mt-4 mt-sm-0">
                <AppInput
                  v-model="form.phone"
                  placeholder="(800) 913-8139"
                  v-mask="'(###)-###-####'"
                  labelText="Phone"
                />
              </v-col>
              <v-col cols="12" md="6" class="c-col mt-4 mt-sm-0">
                <AppInput
                  :readonly="isSubjectReadonly"
                  v-model="form.purpose"
                  placeholder="Your subject is"
                  labelText="Subject"
                >
                  <template v-slot:append v-if="isSubjectReadonly">
                    <div class="lock-icon-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                      >
                        <path
                          opacity="0.45"
                          d="M10.5 6.5V4.5C10.5 2 8.5 0 6 0C3.5 0 1.5 2 1.5 4.5V6.5C0.65 6.5 0 7.15 0 8V14.5C0 15.3 0.65 16 1.5 16H10.5C11.35 16 12 15.3 12 14.5V8C12 7.15 11.35 6.5 10.5 6.5ZM2.5 4.5C2.5 2.55 4.05 1 6 1C7.95 1 9.5 2.55 9.5 4.5V6.5H2.5V4.5ZM6.5 11.9V13C6.5 13.3 6.3 13.5 6 13.5C5.7 13.5 5.5 13.3 5.5 13V11.9C4.9 11.7 4.5 11.15 4.5 10.5C4.5 9.65 5.15 9 6 9C6.85 9 7.5 9.65 7.5 10.5C7.5 11.15 7.1 11.7 6.5 11.9Z"
                          fill="#001154"
                        />
                      </svg>
                    </div>
                  </template>
                </AppInput>
                <div class="second-input-with-background"></div>
              </v-col>
            </v-row>

            <v-row class="mt-4" :no-gutters="!isMobile">
              <v-col
                cols="12"
                class="c-col pl-4 pr-4 pl-sm-0 pr-sm-0 pt-0 pt-sm-3"
              >
                <c-label required> Message </c-label>
                <v-textarea
                  v-model="form.message"
                  :error-messages="messageErrors"
                  @input="$v.form.message.$touch()"
                  @blur="$v.form.message.$touch()"
                  required
                  color="black"
                  placeholder="Leave your message"
                ></v-textarea>
              </v-col>
            </v-row>
            <div class="button-wrapper">
              <v-btn
                @click="onSubmit"
                color="#2969FF"
                :width="isMobile ? '100%' : '372px'"
                :height="isMobile ? '40px' : '40px'"
                class="button-second"
                rounded
                shaped
                :disabled="$v.$invalid"
              >
                <div class="white--text button-text">Send</div>
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </div>

    <SuccessContactUsDialog
      ref="successContactUsDialog"
    ></SuccessContactUsDialog>
  </v-dialog>
</template>
<script>
import Clabel from "@/components/Common/CLabel.vue";
import SuccessContactUsDialog from "@/components/GlobalDialogs/SuccessContactUsDialog.vue";
import apiSendContactUs from "@/services/apiCalls/message/message";
import { normalizeForm } from "@/utils/normalize";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AppInput from "@/components/Common/AppInput.vue";
export default {
  name: "ContactUsDialog",

  mixins: [validationMixin],

  directives: {
    mask,
  },
  components: {
    SuccessContactUsDialog,
    "c-label": Clabel,
    AppInput,
  },
  data: () => ({
    form: {
      name: "",
      email: "",
      phone: "",
      purpose: "",
      message: "",
    },
    dialog: false,
    isSubjectReadonly: false,
  }),

  validations: {
    form: {
      email: { required, email },
      // phone: { required },
      name: { required },
      purpose: { required },
      message: { required },
    },
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.snackbar = true;

        this.form = normalizeForm(this.form);
        this.$store.commit("spinner", true);

        try {
          await apiSendContactUs.sendContactUs({
            ...this.form,
            phone: this.form.phone && this.form.phone.replace(/[()-]/g, ""),
          });
          this.form = {
            name: "",
            email: "",
            phone: "",
            purpose: "XL Exclusive Inquiry",
            message: "",
          };

          this.$v.$reset();

          this.$store.commit("spinner", false);
          this.dialog = false;
          this.$refs.successContactUsDialog.open();
        } catch (err) {
          this.$store.commit("spinner", false);
          console.error("err", err);
        }
      }
    },
    setFormFields() {
      const user = this.$store.getters.user;
      if (user) {
        this.form.name =
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : "";
        this.form.phone = user.officePhone ? `${user.officePhone}` : "";
        this.form.email = user.email ? `${user.email}` : "";
      } else {
        this.form = {
          name: "",
          email: "",
          phone: "",
          message: "",
        };
      }
    },
    open(options) {
      this.setFormFields();
      this.isSubjectReadonly = options ? options.isSubjectReadonly : false;
      // eslint-disable-next-line no-console
      console.log("options", options);
      if (this.isSubjectReadonly) {
        this.form.purpose = "XL Exclusive Inquiry";
      }
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("This field is required");
      return errors;
    },
    purposeErrors() {
      const errors = [];
      if (!this.$v.form.purpose.$dirty) return errors;
      !this.$v.form.purpose.required && errors.push("This field is required");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.form.message.$dirty) return errors;
      !this.$v.form.message.required && errors.push("This field is required");
      return errors;
    },
    // phoneErrors() {
    //     const errors = []
    //     if (!this.$v.form.phone.$dirty) return errors
    //     !this.$v.form.phone.required &&
    //         errors.push('This field is required')
    //     return errors
    // },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      if (!this.$v.form.email.required) {
        errors.push("This field is required");
      }
      if (!this.$v.form.email.email) {
        errors.push("Must be valid e-mail");
      }
      return errors;
    },
  },
};
</script>
<style scoped lang="scss">
.contact-form {
  padding: 40px;
  border-radius: 5px;
  position: relative;
}
.form-wrapper {
  min-width: 580px;
  height: 580px;
  width: 100%;
  background: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
::v-deep .v-dialog {
  display: flex;
  overflow: hidden;
}
::v-deep textarea {
  padding-left: 8px;
  color: black !important;
  background: #f1f1f1;
  border-radius: 1px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */

  /* DarkBlue */

  color: #001154;
  height: 148px;
  max-height: 148px;
  padding-top: 15px;
  // opacity: 0.45;
}

::v-deep .v-textarea {
  padding-top: 0px;
}
.contact-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  letter-spacing: 0.426667px;

  color: #001154;

  margin-bottom: 16px;
}
// .c-col {
//   padding: 8px !important;
// }
::v-deep .v-btn {
  min-height: unset !important;
}
.button-wrapper {
  margin-top: 14px;
}
.close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

::v-deep {
  .c-label {
    font-size: 14px !important;
  }
}

.button-text {
  font-size: 16px;
}
.contact-img {
  width: 382px;
  height: 580px;
}
.img-wrapper {
  width: 380px;
}
.lock-icon-wrapper {
  position: relative;
  top: 12px;
}
@include mediaQ(
  map-get($mediabreakpoints, "xs"),
  map-get($mediabreakpoints, "sm")
) {
  .form-wrapper {
    min-width: unset;
    height: 100%;
  }
  .contact-form {
    padding: 24px 16px 0px 16px;
  }
  // .c-col {
  //   padding: 0px !important;
  // }
  ::v-deep {
    .v-text-field__details {
      display: none;
    }
    .v-input__slot {
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    textarea {
      opacity: 1 !important;
      color: #001154 !important;
    }
  }
  .button-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    text-align: center;

    color: #ffffff;
  }
  .contact-title {
    display: flex;
    justify-content: center;
  }
  .close-icon {
    top: 8px;
  }
  .form-wrapper {
    overflow: auto;
  }
  ::v-deep {
    .c-label {
      font-size: 12px !important;
    }
  }
  .contact-title {
    font-size: 24px;
  }
  .lock-icon-wrapper {
    top: 7px;
  }
}

@include mediaQ(600, 960) {
  .form-wrapper {
    height: 100%;
  }
}
</style>
