<template>
  <div v-if="isAuthUser" class="navigation-buttons">
    <div class="navigation-buttons__button chat-btn-wrapper">
      <v-icon
        color="#00116a"
        size="43"
        :disabled="!nonEmptyChatList.length"
        class="chat-btn cursor-pointer"
        @click="onChatIconClick"
      >
        mdi-chat
      </v-icon>
      <span v-if="counter" class="red-dot">
        {{ counter }}
      </span>
    </div>

    <div @click="onFavoritesClick" class="navigation-buttons__button">
      <img src="@/assets/img/icons/navHeart.svg" />
      <div v-if="getFavoritesCount" class="navigation-buttons__button-counter">
        {{ getFavoritesCount }}
      </div>
    </div>

    <div
      @click="onDashboardClick"
      class="navigation-buttons__button"
      v-if="!isCounting"
    >
      <img src="@/assets/img/icons/navDashboard.svg" />
      <div v-if="getTotalItemsCount" class="navigation-buttons__button-counter">
        {{ getTotalItemsCount }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "NavigationButtons",
  computed: {
    ...mapState("asset", ["assetTotalCount", "favorites"]),
    ...mapState("Offers", ["offerTotalCount"]),
    ...mapState(["auth"]),
    ...mapGetters(["isSeller", "isBuyer", "user"]),
    ...mapGetters("Chat", ["nonEmptyChatList", "getNewMsgCounter"]),
    counter() {
      return this.getNewMsgCounter(this.$store.getters.user?.id);
    },
    isAuthUser() {
      return this.auth.isAuthenticated;
    },
    getTotalItemsCount() {
      return this.assetTotalCount + this.offerTotalCount;
    },
    getFavoritesCount() {
      return this.favorites.length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      isCounting: false,
    };
  },
  methods: {
    ...mapActions("asset", ["countSellerAssets"]),
    ...mapActions("Offers", ["countBuyerOffers"]),
    ...mapMutations("Chat", ["toggleChatPanel"]),
    onChatIconClick() {
      this.toggleChatPanel();
    },
    onDashboardClick() {
      const path = this.$route.path;

      if (this.getTotalItemsCount === 0 && path !== "/dashboard/empty") {
        this.$router.push("/dashboard/empty");
      } else if (
        this.isSeller &&
        this.assetTotalCount &&
        path !== "/seller/filters/active"
      ) {
        this.$router.push("/seller/filters/active");
      } else if (
        this.isBuyer &&
        this.offerTotalCount &&
        path !== "/dashboard/buyer"
      ) {
        this.$router.push("/dashboard/buyer");
      }
    },
    onFavoritesClick() {
      this.$router.push("/buyer/favorites");
    },
    async setCountAssets() {
      if (this.isSeller && this.user) {
        const params = {
          owners: [this.user.id],
        };
        await this.countSellerAssets(params);
      }
    },
    async setCountOffers() {
      if (this.isBuyer && this.user) {
        const params = {
          statuses: [],
          offset: 0,
          size: 10,
        };
        await this.countBuyerOffers(params);
      }
    },
  },
  async created() {
    try {
      this.isCounting = true;
      await this.setCountAssets();
      await this.setCountOffers();
      this.isCounting = false;
    } catch (err) {
      this.isCounting = false;

      // eslint-disable-next-line no-console
      console.log("err", err);
    }
  },
};
</script>

<style scoped lang="scss">
.chat-btn-wrapper {
  position: relative;

  .red-dot {
    bottom: -2px;
    right: 8px;
    background: #dd226f;
    border: 1px solid #fff;
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
  }
}
.chat-btn {
  ::v-deep .theme--dark.v-icon.v-icon.v-icon--disabled {
    color: initial;
    opacity: 0.5;
  }
}
.navigation-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-bottom: 1px solid #9aabc2;
  padding-bottom: 16px;
  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 3px 4px 15px rgba(0, 17, 84, 0.14);
    &-counter {
      width: 100%;
      max-width: 16px;
      height: 16px;
      position: absolute;
      border-radius: 50%;
      border: 1px solid #fff;
      color: #fff;
      background: #dd226f;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      bottom: 0;
      right: 0;
    }
    & img {
      width: 37px;
      height: 32px;
      filter: brightness(0) saturate(100%) invert(5%) sepia(66%) saturate(6408%)
        hue-rotate(208deg) brightness(124%) contrast(117%);
    }
  }
}
</style>
