import { API, graphqlOperation } from "aws-amplify";

export default function subscribeToCreateChat() {
  const query = `subscription {
    subscribeToCreateChat {
      PK
      SK
      buyerID
      sellerID
      assetID
      lastActivityDate
      creationDate
      twilioSID
      assetInfo {
        id
        name
        assetStatus
        assetImage
        contactsDisclosed
      }
      sellerInfo {
        id
        email
        firstName
        lastName
        sellerProfile {
          avatar
        }
        anonymous
      }
      buyerInfo {
        id
        email
        firstName
        lastName
        sellerProfile {
          avatar
        }
        anonymous
      }
    }
  }`;

  return API.graphql(graphqlOperation(query));
}
