import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=28ac7a80&scoped=true"
import script from "./AppFooter.vue?vue&type=script&lang=js"
export * from "./AppFooter.vue?vue&type=script&lang=js"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=28ac7a80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ac7a80",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VContainer,VImg,VRow,VTextField,VTextarea})
