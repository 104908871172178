import { API } from "aws-amplify";

export default (email, title, message) => {
  const query = `mutation sendAssetMessage($email: String!, $title: String!, $message: String!) {
    sendAssetMessage(email: $email
                     title: $title
                     message: $message)
  }`;

  return API.graphql({
    query,
    variables: {
      email,
      title,
      message,
    },
  });
};
