import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default async (variables) => {
  const mutation = `mutation updateSellerClosingDocumentsAcceptedInAsset($id:String! , $sellerClosingDocumentsAccepted: Boolean ) {
        updateSellerClosingDocumentsAcceptedInAsset(
         id:$id,
         sellerClosingDocumentsAccepted : $sellerClosingDocumentsAccepted
        ) {
         ${ASSET}
        }
      }`;

  return API.graphql({
    query: mutation,
    variables,
  });
};
