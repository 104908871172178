import getInitials from "@/utils/Initials";
import getHashCode from "@/utils/HashCode";

export default (chat) => {
  const { buyerInfo, sellerInfo } = chat;

  const bInits = getInitials(buyerInfo);
  const sInits = getInitials(sellerInfo);

  const bColor = getHashCode(bInits);
  const sColor = getHashCode(sInits);

  const newChat = {
    ...chat,
    buyerInfo: {
      ...buyerInfo,
      color: bColor,
    },
    sellerInfo: {
      ...sellerInfo,
      color: sColor,
    },
  };

  return newChat;
};
