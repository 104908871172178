import LogsAPI from "@/services/apiCalls/logs.js";

export default {
  methods: {
    async handleLogs(errorInfo, additionalInfo = {}, errorId) {
      try {
        const data = await LogsAPI.putLog(
          JSON.stringify({
            errorInfo,
            browserInfo: navigator.userAgent,
            additionalInfo,
            errorId,
          }),
          this.$store.state.logs.sequenceToken
        );

        const sequenceToken = data.data.putLogEvents.nextSequenceToken;

        this.$store.commit("setSequenceToken", sequenceToken);

        if (
          data.errors &&
          data.errors[0] &&
          data.errors[0].errorInfo &&
          data.errors[0].errorInfo.__type === "InvalidSequenceTokenException"
        ) {
          const sequenceToken = data.errors[0].errorInfo.expectedSequenceToken;
          this.$store.commit("setSequenceToken", sequenceToken);
          this.handleLogs();
        }

        return data;
      } catch (err) {
        if (
          err.errors &&
          err.errors[0] &&
          err.errors[0].errorInfo &&
          err.errors[0].errorInfo.message ===
            "The specified log stream does not exist."
        ) {
          try {
            await LogsAPI.createLogStream();
          } catch (err) {
            console.error(err, "err log stream");
          }
        }
        if (
          err.errors &&
          err.errors[0] &&
          err.errors[0].errorInfo &&
          err.errors[0].errorInfo.__type === "InvalidSequenceTokenException"
        ) {
          const sequenceToken = err.errors[0].errorInfo.expectedSequenceToken;
          this.$store.commit("setSequenceToken", sequenceToken);
          this.handleLogs();
        }
        return err;
      }
    },
  },
};
