<template>
  <div class="second-input-with-background">
    <c-label :required="isFieldRequired"> {{ fieldLabel }}</c-label>
    <v-text-field
      @keypress="isInteger"
      v-bind="$attrs"
      :value="value"
      @input="onInput"
      @blur="$emit('blur')"
      type="number"
      min="0"
      inputmode="numeric"
    >
    </v-text-field>
  </div>
</template>

<script>
import Clabel from "@/components/Common/CLabel.vue";

export default {
  name: "AppNumberInput",

  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    fieldLabel: {
      type: String,
      default: "",
    },
    isFieldRequired: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      localValue: null,
    };
  },

  components: {
    "c-label": Clabel,
  },

  methods: {
    isInteger(evt) {
      const theEvent = evt || window.event;
      let key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
      if (key.length === 0) return;
      const regex = /^[0-9\b]+$/;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    onInput(ev) {
      this.$emit("input", ev);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
