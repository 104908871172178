import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";
export default (variables) => {
  const query = `mutation  convertValuationToAsset(
    $id: String!
    $tier1_id: String!
    $tier2_id: String!
    $tier_home_id: String!
    $pricingPlan: PricingPlan!
  ) {
    convertValuationToAsset(
        id:$id
        tier1_id:$tier1_id
        tier2_id: $tier2_id
        tier_home_id: $tier_home_id
        pricingPlan: $pricingPlan
    ) {
       ${ASSET}
      }
  }`;
  return API.graphql({
    query,
    variables,
  });
};
