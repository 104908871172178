export default function validateCity(city) {
  const allowedRegex = /^[a-zA-Z0-9:'\-. ]+$/;
  const disallowedRegex = /[!$,#_()\/|+?&*]/;

  if (disallowedRegex.test(city)) {
    return false;
  }

  return allowedRegex.test(city);
}
