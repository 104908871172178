<template>
  <div id="chat">
    <ConversationComponent
      v-if="activeConversation"
      :active-conversation="activeConversation"
      @closeChat="onCloseChat"
    />
    <div
      v-if="openedPanel && nonEmptyChatList.length"
      class="conversation-wrapper"
      :class="{
        'overflowed-wrapper': isActiveConversation,
        'double-chat-list': buyerChats.length && sellerChats.length,
      }"
    >
      <template v-if="buyerChats.length">
        <ConversationChatList
          label="buyer"
          class="buyer-list"
          :class="{ 'flat-for-small-opened': isSelectedChat }"
        ></ConversationChatList>
      </template>
      <template v-if="sellerChats.length">
        <ConversationChatList
          label="seller"
          class="seller-list"
          :class="{ 'flat-for-small': buyerChats.length }"
        ></ConversationChatList>
      </template>
    </div>
  </div>
</template>

<script>
import ConversationComponent from "@/components/Chat/Conversation.vue";
import { mapMutations, mapState } from "vuex";
import ConversationChatList from "@/components/Chat/ConversationChatList.vue";

export default {
  name: "ConversationWrapper",
  props: {
    conversationsClient: {
      type: Object,
      default: null,
    },
    subscribedConversations: {
      type: Object,
      default: null,
    },
  },
  components: { ConversationChatList, ConversationComponent },
  data() {
    return {
      activeConversation: null,
      statusString: "",
      isConnected: false,
    };
  },
  computed: {
    ...mapState("Chat", [
      "openedPanel",
      "buyerChats",
      "sellerChats",
      "userFirstId",
      "userSecondId",
      "uniqName",
      "selectedConversation",
    ]),
    isSelectedChat() {
      return !!this.selectedConversation;
    },
    nonEmptyChatList() {
      let list = [...this.sellerChats, ...this.buyerChats];

      const filteredList = list.filter((item) => {
        if (
          item.msgText ||
          item.attributes?.lastMessage ||
          item.TWILIO_LastMessage?.index
        ) {
          return true;
        }
        return false;
      });
      return filteredList;
    },
    isActiveConversation() {
      return !!this.activeConversation;
    },
  },
  mounted() {
    this.initConversationsClient();
  },
  watch: {
    uniqName: {
      async handler(newVal) {
        if (newVal && !this.isOpenedConv(newVal, this.selectedConversation)) {
          await this.createConversation();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("Chat", ["setSelectedConversation"]),
    isOpenedConv(uniqName = "", selectedConv = {}) {
      const uniqNameArr = uniqName.split("#");
      if (uniqNameArr.length === 3) {
        const { assetID, sellerID, buyerID } = selectedConv ?? {};
        if (
          uniqName[0] === assetID &&
          uniqName[1] === sellerID &&
          uniqName[2] === buyerID
        ) {
          return true;
        }
      }
      return false;
    },
    onCloseChat() {
      this.activeConversation = null;
    },
    async initConversationsClient() {
      this.statusString = "Connecting to Twilio…";
      this.conversationsClient.on("connectionStateChanged", (state) => {
        switch (state) {
          case "connected":
            this.statusString = "You are connected.";
            this.isConnected = true;
            break;
          case "disconnecting":
            this.statusString = "Disconnecting from Twilio…";
            break;
          case "disconnected":
            this.statusString = "Disconnected.";
            break;
          case "denied":
            this.statusString = "Failed to connect.";
            break;
        }
      });
    },

    async createConversation() {
      this.activeConversation =
        await this.conversationsClient.getConversationByUniqueName(
          this.uniqName
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation-wrapper {
  position: relative;
  display: flex;
  column-gap: 24px;
  align-items: end;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  #chat {
    position: relative;
  }
  .conversation-wrapper {
    //max-height: 600px;
    //overflow: auto;
    scrollbar-width: thin;
  }
}
</style>
