import searchAssetForMakeOffer from "@/services/apiCalls/asset/queries/searchAssetForMakeOffer";
import { Storage } from "aws-amplify";
import Offer from "@/services/apiCalls/offer/offer.js";
import OfferCalculator from "@/utils/OfferCalculator";
import { formatErrorMessage } from "@/utils/ErrorFormatter";

export default {
  namespaced: true,
  state: {
    assets: [],
    step: 1,
    ownerId: null,
    imagesToView: [],
    images: {},
    selectedAssets: [],
    editOfferId: null,
    multiBidOffer: {
      offerPrice: null,
      earnestMoneyDeposit: null,
      ddPeriod: null,
      closing: null,
      offerPricePercent: null,
      earnestMoneyDepositPercent: null,
      comment: "",
    },
    totalSelectedAssetsOfferPrice: null,
    totalSelectedAssetsOfferPercent: null,
    totalOfferStepValidationObject: null,
    isEditMode: false,
    editOfferFunc: null,
  },
  mutations: {
    setOwnerId(state, payload) {
      state.ownerId = payload;
    },
    setAssets(state, payload) {
      state.assets = payload;
    },
    setImages(state, payload) {
      state.images = payload;
    },
    setImagesToView(state, imgs) {
      state.imagesToView = imgs;
    },
    setSelectedAssets(state, payload) {
      state.selectedAssets = payload;
    },
    setStep(state, payload) {
      state.step = payload;
    },
    setMultiBidOffer(state, payload) {
      state.multiBidOffer = payload;
    },
    setTotalSelectedAssetsOfferPrice(state, payload) {
      state.totalSelectedAssetsOfferPrice = payload;
    },
    resetOfferInfo(state) {
      state.step = 1;
      state.isEditMode = false;
      state.selectedAssets = [];
      state.editOfferId = null;
      state.totalSelectedAssetsOfferPrice = null;
      state.multiBidOffer = {
        offerPrice: null,
        earnestMoneyDeposit: null,
        ddPeriod: null,
        closing: null,
        offerPricePercent: null,
        earnestMoneyDepositPercent: null,
        comment: "",
      };
    },
    setTotalOfferStepValidationObject(state, payload) {
      state.totalOfferStepValidationObject = payload;
    },
    setIsEditMode(state, payload) {
      state.isEditMode = payload;
    },
    setEditOfferId(state, payload) {
      state.editOfferId = payload;
    },
    setEditOfferFunc(state, payload) {
      state.editOfferFunc = payload;
    },
  },
  getters: {
    totalUpb(state) {
      let totalUpb = 0;
      state.selectedAssets.forEach((asset) => {
        totalUpb += asset.upb;
      });
      return totalUpb;
    },
    canMakeOffer(state) {
      if (
        state.multiBidOffer.offerPrice &&
        state.totalSelectedAssetsOfferPrice
      ) {
        let parsedPrice = Number.parseInt(state.multiBidOffer.offerPrice);
        let parsedSelectedPrice = Number.parseInt(
          state.totalSelectedAssetsOfferPrice
        );
        const isPriceValid = parsedPrice === parsedSelectedPrice;
        const isAllAssetsHaveOfferPrice = state.selectedAssets.every(
          (asset) => !!asset.offerPrice
        );
        return isPriceValid && isAllAssetsHaveOfferPrice;
      } else {
        return false;
      }
    },
    totalPayoffPercent(state) {
      let sum = 0;
      state.selectedAssets.forEach((asset) => {
        if (asset.noteOverview.totalPayoff) {
          sum += asset.noteOverview.totalPayoff.amount / asset.upb;
        }
      });
      return (sum / state.selectedAssets.length) * 100;
    },
  },

  actions: {
    async getOwnerAssets({ state, commit }, asstStatus) {
      const params = {
        size: 50,
        owners: [state.ownerId],
        status: [asstStatus],
      };

      const { data } = await searchAssetForMakeOffer(params);
      commit("setAssets", data.listAssetsByUser.assets);
      data.listAssetsByUser.assets.forEach(async (asset) => {
        if (asset.images) {
          await Storage.get(`${asset.images[0]}-thumbnail`)
            .then((result) => {
              commit("setImages", {
                ...state.images,
                [asset.id]: result,
              });
            })
            .catch((err) => console.error(err));
        }
      });
    },
    setOfferPriceForSelectedAsset({ state, commit }, payload) {
      const { assetId, offerPrice } = payload;
      let asset = state.selectedAssets.find((asset) => asset.id === assetId);
      asset.offerPrice = offerPrice;
      let offerPriceSum = 0;
      state.selectedAssets.forEach((asset) => {
        offerPriceSum += asset.offerPrice
          ? Number.parseFloat(asset.offerPrice)
          : 0;
      });
      commit("setTotalSelectedAssetsOfferPrice", offerPriceSum);
    },
    setOfferPercentForSelectedAsset({ state }, payload) {
      const { assetId, offerPercent } = payload;
      let asset = state.selectedAssets.find((asset) => asset.id === assetId);
      asset.offerPercent = offerPercent;
      let percentSum = 0;
      state.selectedAssets.forEach((asset) => {
        if (asset.offerPercent) {
          percentSum += Number.parseFloat(asset.offerPercent.replace("%", ""));
        }
      });
      state.totalSelectedAssetsOfferPercent = percentSum;
    },
    async makeOffer({ state, rootState, commit }) {
      const variables = OfferCalculator.formatMultiOfferForQuery(
        state.multiBidOffer,
        state.selectedAssets,
        rootState.user
      );
      try {
        commit("spinner", true, { root: true });
        const { data } = await Offer.createOffer(variables);
        await Offer.publishOffer(data.createOffer.id);
        commit(
          "openSnackBar",
          {
            text: "The Multi-Asset Offer has been successfully processed. Please visit your Dashboard for more details",
            color: "success",
          },
          { root: true }
        );

        commit("spinner", false, { root: true });
      } catch (err) {
        const msg = formatErrorMessage(err);
        commit(
          "openSnackBar",
          {
            text: msg,
            color: "error",
          },
          { root: true }
        );
        commit("spinner", false, { root: true });
      }
    },
    async editOffer({ state, rootState, commit }) {
      const variables = OfferCalculator.formatMultiOfferForQuery(
        state.multiBidOffer,
        state.selectedAssets,
        rootState.user
      );
      try {
        commit("spinner", true, { root: true });
        const { data } = await Offer.editOffer({
          ...variables,
          assets: variables.assets.map((asset) => {
            delete asset.id;
            return asset;
          }),
          id: state.editOfferId,
        });
        commit(
          "openSnackBar",
          {
            text: "Multi-asset Offer was successfully updated.",
            color: "success",
          },
          { root: true }
        );

        commit("spinner", false, { root: true });
        if (state.editOfferFunc) {
          state.offerObject = data.editOffer;
          state.editOfferFunc({ id: state.editOfferId, offer: data.editOffer });
        }
      } catch (err) {
        commit("spinner", false, { root: true });
      }
    },

    setEditMultiOfferDialogInfo({ state, commit, getters }, offer) {
      commit("setIsEditMode", true);
      commit("setEditOfferId", offer.id);
      commit("setStep", 2);
      if (offer.editOfferFunc) {
        commit("setEditOfferFunc", offer.editOfferFunc);
      }
      const selectedAssets = offer.assets;
      offer.assets.forEach(async (asset) => {
        if (asset.images) {
          await Storage.get(`${asset.images[0]}-thumbnail`)
            .then((result) => {
              commit("setImages", {
                ...state.images,
                [asset.id]: result,
              });
            })
            .catch((err) => console.error(err));
        }
      });

      commit(
        "setSelectedAssets",
        selectedAssets.map((asset) => {
          return {
            ...asset,
            address: asset.address.address,
            offerPrice: asset.bidAllocation.amount,
            name: asset.title,
            upb: asset.principalBalance.amount,
          };
        })
      );
      commit("setMultiBidOffer", {
        offerPrice: offer.bidPrice,
        earnestMoneyDeposit: offer.earnestMoneyDeposit,
        ddPeriod: offer.dueDiligencePeriod,
        closing: offer.closing,
        offerPricePercent: (offer.bidPrice * 100) / getters.totalUpb,
        earnestMoneyDepositPercent:
          (offer.earnestMoneyDeposit * 100) / offer.bidPrice,
        comment: offer.notes,
      });
    },
  },
};
