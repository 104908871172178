import { API } from "aws-amplify";
import {
  PERFORMING_LOAN_DETAILS,
  NON_PERFORMING_LOAN_DETAILS,
  SUB_PERFORMING_LOAN_DETAILS,
  HOTEL_NON_PERFORMING_LOAN_DETAILS,
  SPECIFICS,
} from "../types/types.js";

export default (variables) => {
  const query = `query  getValuation($id:String!) {
    getValuation(id:$id) {
      
        SK
        PK
        calculatorResult{
          lowValueRange
          percentageLowValueRangeToUpb
          highValueRange
          percentageHighValueRangeToUpb
          noteRateFloorCalculated
          chartData
          lowSideSavings
          lowSideTime
          highSideSavings
          highSideTime
          foreclosureProcess
          receivershipProcess
          biddingProcess
          seasoning
          remainingTerm
          ltv
          XLLoanClass
          marketTier
          recommendation
        }
        userInput{
          address{
            address
            additional{
              name
              country
              countryShortName
              administrative_area_level_1
              city
              county
              administrative_area_level_1ShortName
              lng
              lat
              zipCode
            }

          }
         
          propertyType
          ${PERFORMING_LOAN_DETAILS}


        ${NON_PERFORMING_LOAN_DETAILS}
        ${SUB_PERFORMING_LOAN_DETAILS}
        ${HOTEL_NON_PERFORMING_LOAN_DETAILS}
        ${SPECIFICS}
        
      }
      }
  }`;
  return API.graphql({
    query,
    variables,
  });
};
