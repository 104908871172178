import Vue from "vue";
export const EventBus = new Vue();

export const EVENTBUS_EVENTS = {
  OPEN_PROFILE_DIALOG: "OPEN_PROFILE_DIALOG",
  OPEN_LOGIN_DIALOG: "OPEN_LOGIN_DIALOG",
  OPEN_REGISTER_DIALOG: "OPEN_REGISTER_DIALOG",
  UPDATE_ASSET_DIALOG: "UPDATE_ASSET_DIALOG",
  OPEN_BUYER_DIALOG: "OPEN_BUYER_DIALOG",
  DISABLE_DIALOG: "DISABLE_DIALOG",
  EDIT_OFFER: "EDIT_OFFER",
};
