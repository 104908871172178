<template>
  <div>
    <span v-if="userAvatar" class="avatar">
      <img
        :src="userAvatar"
        class="app-bar__dropdown-wrapper-image"
        alt="user-avatar"
      />
    </span>
    <span v-else class="app-bar__dropdown-wrapper">
      <span
        class="cursor-pointer rounded-username anonymous-abbr"
        :style="{ 'background-color': color, color: '#ffffff' }"
      >
        <span class="active-pointer"></span>
        {{ getUsername }}
      </span>
    </span>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
export default {
  name: "ChatAvatar",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    avatar: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userAvatar: null,
    };
  },
  computed: {
    getUsername() {
      if (this.user?.firstName) {
        const initials = `${this.user.firstName?.at(0)}${this.user.lastName?.at(
          0
        )}`;

        return initials;
      }

      return this.user?.email?.at(0) || "AS";
    },
  },
  watch: {
    avatar: {
      async handler(newValue) {
        if (newValue) {
          this.userAvatar = await Storage.get(newValue);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 8px 25px 0px rgba(0, 17, 84, 0.25);
}
.anonymous-abbr {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 8px 25px 0px rgba(0, 17, 84, 0.25);
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  img {
    width: 45px;
    height: 45px;
  }
}
</style>
