<template>
  <v-row class="form-content__fields" no-gutters>
    <div class="disable-block" v-if="!formData.executive"></div>
    <v-dialog v-model="checkboxDialog" content-class="checkbox-dialog">
      <div class="checbox-dialog-content">
        <div class="close-icon" @click="checkboxDialog = false">
          <v-icon color="#001154"> mdi-close </v-icon>
        </div>
        <div class="checbox-dialog-content__title">Seller Confirmation</div>
        <div class="checbox-dialog-content__text">
          I hereby certify that I am the true and legal owner and, or,
          representative of the asset listed, or if in a land trust, have the
          powers of direction, and am empowered to enter into agreements to list
          asset(s) on behalf of the aforementioned seller.
        </div>
      </div>
    </v-dialog>
    <v-col cols="12">
      <div class="d-flex">
        <v-checkbox
          v-model="formData.executive"
          color="#2969FF"
          class="ma-0 checkbox-wrapper"
        >
        </v-checkbox>
        <div>
          <span class="checkbox-text">
            I hereby certify that I am the true and legal owner<span
              class="read-more-text"
              @click.self="checkboxDialog = true"
            >
              read more
            </span>
          </span>
        </div>
      </div>
    </v-col>
    <v-col class="pr-1" cols="6">
      <div class="second-input-with-background mt-4">
        <c-label required> Signed by </c-label>
        <v-text-field
          v-model="formData.signedByFirstName"
          placeholder="Deborah"
          :disabled="!formData.executive"
          :error-messages="getValidationError('formData.signedByFirstName')"
        ></v-text-field>
      </div>
    </v-col>
    <v-col class="pl-1" cols="6">
      <div class="second-input-with-background mt-4">
        <c-label></c-label>
        <v-text-field
          v-model="formData.signedByLastName"
          :disabled="!formData.executive"
          placeholder="Smith"
          :error-messages="getValidationError('formData.signedByLastName')"
        ></v-text-field>
      </div>
    </v-col>
    <v-col class="pr-1 mt-4" cols="6">
      <div class="second-input-with-background">
        <c-label required> Title </c-label>
        <v-text-field
          v-model="formData.title"
          :disabled="!formData.executive"
          placeholder="Senior Vice President - Finance"
          :error-messages="getValidationError('formData.title')"
        ></v-text-field>
      </div>
    </v-col>
    <v-col class="pl-1 mt-4" cols="6">
      <div class="second-input-with-background">
        <c-label required> Phone Number </c-label>
        <v-text-field
          v-model="formData.phoneNumber"
          :disabled="!formData.executive"
          v-mask="'(###)-###-####'"
          placeholder="(800) 913-8139"
          inputmode="numeric"
          :error-messages="getValidationError('formData.phoneNumber')"
        ></v-text-field>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import Clabel from "@/components/Common/CLabel.vue";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import validateName from "@/utils/validations/name";
import ValidationFabric from "@/utils/ValidationFabric.js";
const ERROR_DICTIONARY = {
  required: "",
  email: "",
  validateName: "",
};
export default {
  name: "SellerConfirmationForm",
  directives: {
    mask,
  },
  mixins: [validationMixin],

  data: function () {
    return {
      formData: {
        signedByFirstName: null,
        signedByLastName: null,
        title: null,
        phoneNumber: null,
        executive: false,
      },
      checkboxDialog: false,
    };
  },
  validations: {
    formData: {
      signedByFirstName: { validateName },
      signedByLastName: { validateName },
      title: { required },
      phoneNumber: { required, minLength: minLength(14) },
      executive: { required },
    },
  },
  computed: {
    radionBtnColor() {
      return !this.formData.executive ? "#f3f3f3" : "#2969FF";
    },
  },
  created() {
    this.validationFabric = new ValidationFabric(this.$v, ERROR_DICTIONARY);

    this.$emit("setSellerConfirmationForm", this.$v);
  },
  methods: {
    getValidationError(token) {
      if (!this.validationFabric) {
        return [];
      }

      return this.validationFabric.getFieldError(token, ERROR_DICTIONARY);
    },
  },
  components: {
    "c-label": Clabel,
  },
};
</script>
<style scoped lang="scss">
.checbox-dialog-content {
  padding: 12px 24px 24px 24px;
  width: 100%;
  height: 100%;
  background: white;
  position: relative;
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;

    text-align: center;

    color: #001154;
  }
  &__text {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    /* Primary/DarkBlue */
    padding-top: 8px;
    color: #001154;
  }
}
.read-more-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Primary/DarkBlue */

  color: #2969ff;
}
.checkbox-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  color: #001154;
}
.checkbox-wrapper {
  width: 56px !important;
  //   top: -10px;
  ::v-deep {
    .v-input__slot {
      height: 20px !important;
    }
    .v-icon {
      font-size: 20px !important;
    }
    .v-input--selection-controls__ripple {
      &::before {
        display: none !important;
      }
    }
  }
}
::v-deep {
  .checkbox-dialog {
    margin: 0px !important;
  }
}
.close-icon {
  top: 12px;
  right: 16px;
  position: absolute;
}
.disable-block {
  background: rgba(255, 255, 255, 0.65);
  position: absolute;
  width: 100%;
  height: 240px;
  top: 62px;
  z-index: 200;
}
.form-content__fields {
  position: relative !important;
}
</style>
