<template>
  <v-form>
    <p class="cardPopUp__description title-description">
      {{ registrationText }}
    </p>
    <div class="second-input-with-background email">
      <c-label> Email </c-label>
      <v-text-field
        v-model="email"
        placeholder="user@mail.com"
        :color="inputColor"
        :error-messages="emailErrors"
        @blur="$v.email.$touch()"
        @input="$v.email.$touch()"
      ></v-text-field>
    </div>

    <v-btn
      :color="$vuetify.theme.themes.light.accent"
      :loading="$store.state.ui.buttonLoading"
      @click="submit"
      width="100%"
      class="submit-btn"
      :disabled="!isAllValid || loading"
      height="40px"
    >
      Create Account
    </v-btn>

    <ZentinelLogo />
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import handleLogsMixin from "@/mixins/handleLogsMixin";
import ZentinelLogo from "@/components/layout/ZentinelLogo";
import Clabel from "@/components/Common/CLabel.vue";
import validateEmail from "@/utils/validations/email";
import { mapActions } from "vuex";

export default {
  name: "RegisterFormSimple",

  mixins: [validationMixin, handleLogsMixin],

  components: {
    ZentinelLogo,
    "c-label": Clabel,
  },

  props: {
    registrationText: {
      type: String,
      required,
    },
    initialEmail: {
      type: String,
      default: null,
    },
    formTitle: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      inputColor: "#3ba9e0",
      email: "",
    };
  },

  validations: {
    email: { required, email, validateEmail },
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.validateEmail && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },
    validation() {
      return {
        uppercase: /[A-Z]/g.test(this.password),
        lowercase: /[a-z]/g.test(this.password),
        number: /\d/g.test(this.password),
        specialChar: /\W|_/g.test(this.password),
        isLength: this.password.length >= 8,
      };
    },
    isAllValid() {
      return this.email.length && this.emailErrors.length === 0;
    },
  },

  methods: {
    ...mapActions("AuthHelpers", ["signUp", "signIn"]),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loading = true;
      this.signUp({ email: this.email }).then(async (resp) => {
        if (resp) {
          const { TempPassword } = resp;
          // this.$store.dispatch("logIn");
          await this.signInFlow(this.email, TempPassword);
        }
        this.$eventHub.$emit("successfulRegistration");
        this.loading = false;
      });
    },

    async signInFlow(username, password) {
      try {
        const user = await this.signIn({ username, password });

        if (user) {
          this.$store.dispatch("logIn");
        }
      } catch (err) {
        this.$store.commit("openSnackBar", {
          text: err.message,
          color: "error",
        });
        this.handleLogs({
          errorInfo: JSON.stringify(err),
          errorId: "Logging in User",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.second-input-with-background.email {
  margin-bottom: 145px;
}

.listValidatePassword {
  color: map-get($colors, "primary");
  font-size: 11px;
  list-style: none;
  margin: 0 0 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.list {
  margin-left: 8px;
  margin-bottom: 14px;
  padding-right: 10px;
  &__wrapper {
    background-color: rgba(41, 105, 255, 0.1);
  }
  &__item {
    margin-bottom: -2px;
    margin-right: -10px;
    font-size: 10px;
    color: rgba(0, 17, 84, 0.7);
  }
}
.validation__description {
  margin-left: 9px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #001154;
  letter-spacing: 0;
}
.text-check {
  align-items: baseline;
  font-size: 9px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #001154;
  margin-top: 2px !important;
  margin-left: 24px;
}
.custom-eye-icon {
  padding-top: 2px;
  margin-right: 10px !important;
}
::v-deep {
  .list__item {
    i {
      font-size: 12px !important;
    }
    font-size: 12px;
  }
  .list__wrapper {
    padding-top: 8px;
    height: 73px;
    display: flex;
    flex-direction: column;
  }
  .v-btn__content {
    font-size: 16px !important;
  }
  .second-input-with-background {
    font-size: 14px !important;
  }
}
.cardPopUp__text {
  .v-btn {
    margin-bottom: 16px !important;
  }
}
</style>
