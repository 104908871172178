<template>
  <v-dialog
    v-model="isDialogOpen"
    class="dialog"
    width="1019px"
    persistent
    @keydown.esc="closeModal"
  >
    <div class="dialog__content">
      <div class="close-icon" @click="closeModal">
        <v-icon color="#979797"> mdi-close</v-icon>
      </div>
      <vue-calendly
        url="https://calendly.com/xchangeloans/discovery-call?month=2022-08"
      />
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "selectDatePopUp",
  data() {
    return {
      isOpenModal: false,
    };
  },
  props: {
    value: { type: Boolean, default: false },
  },
  watch: {
    value() {
      this.isOpenModal = this.value;
    },
  },
  methods: {
    closeModal() {
      this.$emit("input", false);
    },
    changeModal(e) {
      this.$emit("input", e);
    },
  },
  computed: {
    isDialogOpen: {
      get() {
        return this.isOpenModal;
      },
      set(v) {
        this.changeModal(v);
      },
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  height: 100vh;
  //overflow: hidden;
  position: relative;
  top: 15px;
  max-height: none;
}
.dialog {
  display: flex !important;
  flex-direction: column !important;
  &__content {
    width: 100%;
    position: relative;
    overflow: hidden;

    .calendly {
      height: 100vh !important;
      overflow: hidden !important;
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}
.close-icon {
  position: absolute;
  right: 4%;
  top: 44px;
  cursor: pointer;
}
</style>
