import _ from "lodash";
import { createLogger } from "vuex";

export default {
  namespaced: true,
  state: {
    offerItems: [],
    loadedOffers: false,
    sidePanelParams: {
      isOpened: false,
      item: null,
    },
    userAssetData: null,
    multiUserAssetData: [],
  },
  mutations: {
    SET_USER_ASSET_DATA(state, payload) {
      state.userAssetData = payload;
    },
    SET_MULTI_USER_ASSET_DATA(state, payload) {
      state.multiUserAssetData = payload;
    },

    SET_OFFER_SIDE_PANEL(state, { isOpened, item }) {
      state.sidePanelParams = {
        isOpened,
        item,
      };
    },
    UPDATE_OFFER_PRICING(state, offer) {
      let itemToUpdate = state.offerItems.find((item) => {
        return item.id === offer.id;
      });
      const fieldsToUpdate = [
        "bidPrice",
        "closing",
        "dueDiligencePeriod",
        "earnestMoneyDeposit",
        "notes",
      ];
      fieldsToUpdate.forEach((fieldName) => {
        itemToUpdate[fieldName] = offer[fieldName];
      });
    },

    UPDATE_OFFER_IN_LIST(state, { offerToUpdateIdx, newOffer }) {
      if (!offerToUpdateIdx) {
        return;
      }
      const oldOffer = state.offerItems[offerToUpdateIdx];

      state.offerItems.splice(offerToUpdateIdx, 1, {
        ...oldOffer,
        ...newOffer,
      });
    },
    UPDATE_OFFER_BY_ID(state, { offerToUpdateId, newOffer }) {
      const idxToUpdate = state.offerItems.findIndex((offer) => {
        return offer.id === offerToUpdateId;
      });
      const oldOffer = state.offerItems.find((offer) => {
        return offer.id === offerToUpdateId;
      });
      if (!oldOffer) {
        return;
      }

      state.offerItems.splice(idxToUpdate, 1, { ...oldOffer, ...newOffer });
    },
    CANCEL_OFFER(state, offerId) {
      const offer = state.offerItems.find((offer) => offer.id === offerId);
      const offerIdx = state.offerItems.findIndex(
        (offer) => offer.id === offerId
      );
      state.offerItems.splice(offerIdx, 1, {
        ...offer,
        status: "Withdrawn",
        withdrawDate: new Date(),
      });

      // state.offerItems[offerId].status = "Withdrawn";
      // state.offerItems[offerId].withdrawDate = new Date();
      //
    },
    PUSH_OFFERS(state, offers) {
      state.offerItems.push(...offers);
    },
    SET_OFFER_ITEMS(state, offers) {
      state.offerItems = offers;
      state.loadedOffers = true;
    },
    ADD_OFFER(state, offer) {
      const offerIds = state.offerItems.map((oi) => oi.id);
      if (!offerIds.includes(offer.id)) {
        state.offerItems = [...state.offerItems, offer];
      }
    },
  },

  actions: {
    updateAsset({ state, commit }, asset) {
      let offerItemToUpdate = null;
      state.offerItems.forEach((offerItem) => {
        let assetToUpdate = offerItem.assets.find(
          (offerAsset) => offerAsset.id === asset.id
        );

        if (assetToUpdate) {
          offerItemToUpdate = offerItem;
          const indexToUpdateIndex = offerItem.assets.findIndex(
            (offerItemAsset) => offerItemAsset.id === assetToUpdate.id
          );

          assetToUpdate = {
            ...assetToUpdate,
            ...asset,
          };
          offerItem.assets.splice(
            indexToUpdateIndex,
            1,
            _.cloneDeep(assetToUpdate)
          );
        }
      });
      if (offerItemToUpdate) {
        const offerToUpdateIndex = state.offerItems.findIndex(
          (offer) => offerItemToUpdate.id === offer.id
        );
        commit("UPDATE_OFFER_IN_LIST", {
          offerToUpdateIndex,
          newOffer: _.cloneDeep(offerItemToUpdate),
        });
        return _.cloneDeep(offerItemToUpdate);
      }
    },
  },
};
