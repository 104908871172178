import router from "../../router";
import Helper from "@/services/apiCalls/helper";
import { API } from "aws-amplify";

export default {
  namespaced: true,
  state: {
    beforeRouteForAssetPage: "",
    displaySectionStatus: true,
    buyPageMobileDisplaySections: null,
    hideFooterForMobile: false,
    currentStepIdx: 0,
    assetCreationFlowSteps: [
      "assetInfo",
      "noteOverview",
      "propertyDetails",
      "pictures",
      "dueDiligence",
      "legal",
      "advisors",
    ],
    oneMoreNotification: null,
    authModalOptions: null,
  },
  getters: {
    getAssetCreationFlowStep: (state) => (idx) => {
      return state.assetCreationFlowSteps.at(idx);
    },
    getAssetCreationFlowIdx: (state) => (step) => {
      return state.assetCreationFlowSteps.indexOf(step);
    },
  },
  mutations: {
    setAuthModalOptions(state, value) {
      state.authModalOptions = value;
    },
    setOneMoreNotification(state, value) {
      state.oneMoreNotification = value;
    },
    setBeforeRouteForAssetPage(state, url) {
      state.beforeRouteForAssetPage = url;
    },
    setDisplaySectionStatus(state, status) {
      state.displaySectionStatus = status;
    },
    setBuyPageMobileDisplaySections(state, section) {
      state.buyPageMobileDisplaySections = section;
    },
    editCurrentStepIdx(state, idx) {
      state.currentStepIdx = idx;
    },
    onAssetEditRedirection(state, asset) {
      if (asset.registrationStatus) {
        router.push(`/asset/${asset.id}/edit`);
      }
    },
    setHideFooterForMobile(state, value) {
      state.hideFooterForMobile = value;
    },
  },
  actions: {
    saveUTMParameters({ state, commit }, payload) {
      Helper.saveUTMParameters({ ...payload });
    },
    stopRequest({ state }, requestToStop) {
      API.cancel(requestToStop, "STOP MESSAGE");
    },
  },
};
