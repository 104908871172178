import { API } from "aws-amplify";
import { USER_ASSET_DATA } from "@/services/apiCalls/types/Asset/UserAssetData";
import { ASSET_ENTITY } from "@/services/apiCalls/types/Asset/AssetEntity";

export default async (variables) => {
  const mutation = `mutation trackAssetView(
        $asset_id: String
      ) {
        trackAssetView(
         asset_id:$asset_id
        ) {
          assetEntities{
          ${ASSET_ENTITY}
          }
          userAssetData{
          ${USER_ASSET_DATA} 
          }
        }
      }`;

  return API.graphql({
    query: mutation,
    variables,
  });
};
