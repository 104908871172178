<template>
  <v-form ref="basicInfoForm" lazy-validation>
    <div class="fields-wrapper">
      <div class="field-wrapper">
        <div class="second-input-with-background">
          <c-label :required="getUserProfileRequired"> First Name </c-label>
          <v-text-field
            v-model="basicInfoForm.firstName"
            :rules="nameRules"
            placeholder="John"
            :readonly="isNameEditingDisabled"
            required
            @input="(v) => (basicInfoForm.firstName = capitalize(v))"
            :error-messages="getValidationError('basicInfoForm.firstName')"
          >
          </v-text-field>
        </div>
      </div>
      <div class="field-wrapper">
        <div class="second-input-with-background">
          <c-label :required="getUserProfileRequired"> Last Name </c-label>
          <v-text-field
            v-model="basicInfoForm.lastName"
            @input="(v) => (basicInfoForm.lastName = capitalize(v))"
            :rules="nameRules"
            placeholder="Smith"
            :readonly="isNameEditingDisabled"
            required
            :error-messages="getValidationError('basicInfoForm.lastName')"
          >
          </v-text-field>
        </div>
      </div>
      <div class="field-wrapper">
        <div class="second-input-with-background">
          <c-label :required="getUserProfileRequired"> Phone </c-label>
          <v-text-field
            v-model="basicInfoForm.officePhone"
            :rules="phoneRules"
            placeholder="(080) 913 81 39"
            v-mask="phoneMask"
            inputmode="numeric"
            required
            :error-messages="getValidationError('basicInfoForm.officePhone')"
          >
          </v-text-field>
        </div>
      </div>
      <div class="field-wrapper">
        <div class="second-input-with-background">
          <c-label :required="getUserProfileRequired"> Email </c-label>
          <v-text-field :value="userEmail" readonly>
            <v-tooltip
              bottom
              slot="append"
              content-class="second-tooltip"
              nudge-top=""
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="16"
                  color="rgba(0, 17, 84, 0.45)"
                  class="pointer custom-lock-icon"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-lock
                </v-icon>
              </template>
              <span>
                Contact support@xchange.loans <br />
                to update this field
              </span>
            </v-tooltip>
          </v-text-field>
        </div>
      </div>
    </div>
  </v-form>
</template>
<script>
import Clabel from "@/components/Common/CLabel.vue";
import { mask } from "vue-the-mask";
import _ from "lodash";
import ValidationFabric from "@/utils/ValidationFabric.js";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import validateName from "@/utils/validations/name";

const ERROR_DICTIONARY = {
  required: "",
  email: "",
  validateName: "",
};

export default {
  name: "BasicInfoForm",
  components: { "c-label": Clabel },
  validations: {
    basicInfoForm: {
      firstName: {
        required,
        validateName,
      },
      lastName: {
        required,
        validateName,
      },
      officePhone: { required, minLength: minLength(14) },
    },
  },
  mixins: [validationMixin],
  directives: {
    mask,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      basicInfoForm: {
        firstName: null,
        lastName: null,
        officePhone: null,
      },
      nameRules: [(v) => !!v || "", (v) => !!validateName(v)],
      phoneRules: [(v) => !!v || "", (v) => (v && v.length === 14) || ""],
      validationFabric: null,
      phoneMask: "(###)-###-####",
    };
  },
  computed: {
    ...mapState("UserSettings", ["userSettings"]),
    getUserProfileRequired() {
      const { userProfileRequired = true } = this.userSettings ?? {};

      return userProfileRequired;
    },
    userEmail() {
      return this.$store.getters.user?.email;
    },
    isNameEditingDisabled() {
      if (this.editable) {
        return false;
      }
      return !!(
        this.$store.state.user?.firstName && this.$store.state.user?.lastName
      );
    },
  },
  watch: {
    basicInfoForm: {
      handler(v) {
        this.$emit("formUpdate", {
          form: v,
          invalid: this.$v.$invalid,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.basicInfoForm = _.pick(this.formData, [
      "firstName",
      "lastName",
      "officePhone",
    ]);
    this.$emit("formUpdate", {
      form: this.basicInfoForm,
      invalid: this.$v.$invalid,
    });
  },
  created() {
    this.validationFabric = new ValidationFabric(this.$v, ERROR_DICTIONARY);
  },
  methods: {
    onCheckContactFields() {
      if (!this.formData.firstName) {
        this.isValidFirstName = true;
      }
      if (!this.formData.lastName) {
        this.isValidLastName = true;
      }
      if (!this.formData.officePhone) {
        this.isValidOfficePhone = true;
      }
    },
    capitalize(s) {
      if (s && s.length) {
        return s[0].toUpperCase() + s.slice(1);
      }
    },
    getValidationError(token) {
      if (!this.validationFabric) {
        return [];
      }
      return this.validationFabric.getFieldError(token, ERROR_DICTIONARY);
    },
  },
};
</script>
<style scoped lang="scss">
.fields-wrapper {
  row-gap: 16px;
  column-gap: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .fields-wrapper {
    row-gap: 16px;
    column-gap: 8px;
  }
}
</style>
