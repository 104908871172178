export default {
    Auth: {
        identityPoolId: 'us-east-1:d8a67f86-6906-4293-816b-de9dded012e7',
        region: 'us-east-1',
        userPoolId: 'us-east-1_VVJB13SJe',
        userPoolWebClientId: '7ptk8r48f5bjvr821lh7mcjbb2',
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
        aws_appsync_graphqlEndpoint:
            'https://bm3aent6afc7xmc5bsi4h5kvsi.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
    },
    Storage: {
        AWSS3: {
            bucket: 'rhenium-test-storage-graphql',
            region: 'us-east-1'
        }
    }
}
