import { API } from "aws-amplify";
export default (email) => {
  const query = `query users($filter: UserFilter) {
        listUsers(filter: $filter) {
          nextToken
          users {
            firstName
            lastName
            companyName
            email
            officePhone
          }
        }
      }`;

  return API.graphql({
    query: query,
    variables: {
      filter: {
        email,
      },
    },
  });
};
