import fromCamelCase from "@/utils/fromCamelCase";

export default (status) => {
  if (!status) return status;
  switch (status) {
  case "Published":
    return "Active Listing";
  case "Closed":
    return "CLOSED";
  default:
    return fromCamelCase(status);
  }
};
