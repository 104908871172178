<template>
  <div class="loader-wrapper" :style="{ position }">
    <div class="loader1">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalLoader",

  props: {
    position: {
      type: String,
      default: "fixed",
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  opacity: 0.6;
}
.loader1 {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
}
.loader1 span {
  vertical-align: middle;
  border-radius: 100%;

  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px 2px;
  -webkit-animation: loader1 0.8s linear infinite alternate;
  animation: loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  // background: rgba(245, 103, 115, 0.6);
  background: map-get($colors, "primary");
  opacity: 0.6;
}
.loader1 span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  // background: rgba(245, 103, 115, 0.8);
  background: map-get($colors, "primary");
  opacity: 0.8;
}
.loader1 span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  // background: rgba(245, 103, 115, 1);
  background: map-get($colors, "primary");
  opacity: 1;
}
.loader1 span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  // background: rgba(245, 103, 115, 0.8);
  background: map-get($colors, "primary");
  opacity: 0.8;
}
.loader1 span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  // background: rgba(245, 103, 115, 0.4);
  background: map-get($colors, "primary");
  opacity: 0.4;
}

@keyframes loader1 {
  from {
    transform: scale(0, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes loader1 {
  from {
    -webkit-transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
  }
}
</style>
