import { API } from "aws-amplify";
import { ASSET_ENTITY_CONNECTION } from "./../../types/Asset/AssetEntityConnection";
export default (variables) => {
  const query = `query getAssetEntities($id: String!, $limit: Int, $nextToken: String , $entityType:AssetEntityType) {
    getAssetEntities(id: $id, limit: $limit, nextToken: $nextToken , entityType:$entityType) {
      ${ASSET_ENTITY_CONNECTION}
      }
    }
  `;

  return API.graphql({
    query,
    variables,
  });
};
