<template>
  <v-tooltip
    bottom
    class="--hide-arrow"
    content-class="second-tooltip"
    nudge-top="15"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="icon-wrapper" v-bind="attrs" v-on="on">
        <v-btn
          icon
          @click="onDashboardClick"
          v-if="$store.state.auth.user && isCounted"
        >
          <!-- <v-icon size="32" color="#fff"> mdi-heart-outline </v-icon> -->
          <img src="@/assets/img/icons/navDashboard.svg" />
          <div class="count-border" v-if="totalItemsCount > 0"></div>
          <div class="count-icon" v-if="totalItemsCount > 0">
            {{ totalItemsCount }}
          </div>
        </v-btn>
      </div>
    </template>
    <span>Manage Assets</span>
  </v-tooltip>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AppBarDashboard",
  created() {
    // eslint-disable-next-line no-console
    console.log("APP BAR DASH CREATED");
  },
  watch: {
    "$store.getters.user": {
      async handler() {
        try {
          this.isCounted = false;
          await this.countAssets();
          await this.countOffers();
          this.isCounted = true;
        } catch (err) {
          this.isCounted = true;
        }
      },
      immediate: true,
    },
  },
  data: function () {
    return {
      assetsCount: 0,
      show: true,
      offersCount: 0,
      isCounted: false,
    };
  },
  computed: {
    ...mapState("asset", ["assetTotalCount"]),
    ...mapState("Offers", ["offerTotalCount"]),
    totalItemsCount() {
      return this.assetTotalCount + this.offerTotalCount;
    },
  },
  methods: {
    ...mapActions("asset", ["countSellerAssets"]),
    ...mapActions("Offers", ["countBuyerOffers"]),
    onDashboardClick() {
      // eslint-disable-next-line no-console
      console.log("onDashboardClick this.isCounted", this.isCounted);
      if (!this.isCounted) {
        return;
      }
      const path = this.$route.path;
      if (this.totalItemsCount === 0 && path !== "/dashboard/empty") {
        this.$router.push("/dashboard/empty");
      } else if (
        this.$store.getters.isSeller &&
        this.assetTotalCount &&
        path !== "/seller/filters/active"
      ) {
        this.$router.push("/seller/filters/active");
      } else if (
        this.$store.getters.isBuyer &&
        this.offerTotalCount &&
        path !== "/dashboard/buyer"
      ) {
        this.$router.push("/dashboard/buyer");
      }
    },
    async countAssets() {
      if (this.$store.getters.isSeller) {
        const params = {
          owners: [this.$store.getters.user.id],
        };
        return await this.countSellerAssets(params);
      }
    },
    async countOffers() {
      if (this.$store.getters.isBuyer) {
        const params = {
          statuses: [
            // "Successful",
            // "Active.CallForOffers",
            // "Active.BestAndFinal",
          ],
          offset: 0,
          size: 10,
        };
        await this.countBuyerOffers(params);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.count-icon {
  width: 12px;
  height: 12px;
  border-radius: 44px;
  background: #dd226f;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  line-height: 6px;
  position: absolute;
  left: 27px;
  top: 12px;
  border: 3px solid transparent;
  z-index: 6;
  padding-top: 2px;
}
.count-border {
  display: block;
  content: "";
  z-index: 1;
  width: 16px;
  height: 16px;
  position: absolute;
  background: 136d;
  left: 25px;
  top: 10px;
  border-radius: 19px;
  background: #00136d;
}
.icon-wrapper {
  position: relative;
}

@include mediaQ(
  map-get($mediabreakpoints, "xs"),
  map-get($mediabreakpoints, "sm")
) {
  ::v-deep {
    .v-btn {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
::v-deep .v-btn--active {
  &::before {
    opacity: 0 !important;
  }
}
::v-deep .v-tooltip__content {
  &::before {
    display: none !important;
  }
}
</style>
