export default {
  namespaced: true,
  state: {
    dialogStatus: false,
    assetInfo: {},
  },
  getters: {},
  mutations: {
    updateDialogStatus(state, value) {
      state.dialogStatus = value;
    },
    updateAssetInfo(state, value) {
      state.assetInfo = {
        ...value,
      };
    },
  },
  actions: {},
};
