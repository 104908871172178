import User from "@/services/apiCalls/user/user.js";
import ErrorParser from "@/utils/ErrorParser";
import * as Sentry from "@sentry/vue";
import { Auth } from "aws-amplify";

export default {
  namespaced: true,
  state: {
    verifyEmailDialog: false,
  },
  getters: {},
  mutations: {
    setVerifyEmailDialog(state, value) {
      state.verifyEmailDialog = value;
    },
  },
  actions: {
    async sendVerificationEmail({ commit }) {
      const result = await User.sendVerificationEmail()
        .then(({ data }) => {
          if (data.sendVerificationEmail) {
            return data.sendVerificationEmail;
          }
          return false;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async signIn({ commit }, { username, password }) {
      let user = null;
      try {
        user = await Auth.signIn(username, password);
      } catch (e) {
        commit(
          "openSnackBar",
          { text: e.message, color: "error" },
          { root: true }
        );
      }

      if (user) {
        let ip = "";
        try {
          const response = await fetch("https://api.ipify.org?format=json");
          const data = await response.json();
          ip = data.ip;
        } catch (error) {
          console.error("Error fetching IP address:", error);
        }
        const { attributes = {}, username: awsUsername } = user ?? {};
        const { sub, email } = attributes ?? {};
        const params = {
          id: sub,
          username: awsUsername,
          email: email,
          ip_address: ip,
        };
        Sentry.setUser(params);
      }

      return user;
    },
    async signUp({ commit }, params) {
      const result = await User.signUp(params)
        .then(({ data }) => {
          if (data.signUp) {
            return data.signUp;
          }
          return false;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async verifyUser({ commit }, params) {
      console.log(5);
      const result = await User.verifyUser(params)
        .then(({ data }) => {
          console.log(6);

          commit(
            "openSnackBar",
            {
              text: "Your password has been updated successfully. Please log in again.",
              color: "success",
            },
            { root: true }
          );

          return true;
        })
        .catch((err) => {
          console.log(7);
          console.log("verifyUser action err - ", err);
          const errMsg = ErrorParser(err);
          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    isUserVerified({ state, commit }, user) {
      if (user?.roles?.includes("VerifiedUsers")) {
        return true;
      }
      commit("setVerifyEmailDialog", true);
      return false;
    },
  },
};
