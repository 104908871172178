import store from "../store";
import Asset from "@/services/apiCalls/asset/asset";

async function editAssetGuard(to, from, next) {
  const { id } = to.params;

  if (store.getters.user?.roles.includes("Admins")) {
    next();
    return;
  }
  let isAsset = store.state.RouteGuard.guardAsset;
  if (
    isAsset &&
    store.getters.user?.id &&
    isAsset.owners[0] === store.getters.user?.id
  ) {
    next();
  } else {
    try {
      const { data } = await Asset.getAsset(id);
      isAsset = data?.getAsset;
      store.commit("RouteGuard/setGuardAsset", isAsset);
      if (
        isAsset &&
        store.getters.user?.id &&
        isAsset.owners[0] === store.getters.user?.id
      ) {
        next();
      } else {
        store.commit("openSnackBar", {
          text: "Please verify the link you're using. It might be incorrect, or you may lack the necessary permissions to access this page.",
          color: "warning",
        });
        next("/");
      }
    } catch (error) {
      store.commit("openSnackBar", {
        text: "Please verify the link you're using. It might be incorrect, or you may lack the necessary permissions to access this page.",
        color: "warning",
      });
      next("/");
    }
  }
}

export { editAssetGuard };
