export default (value) => {
  if (value === null) {
    return null;
  }
  if (value === true) {
    return "yes";
  }
  if (value === false) {
    return "no";
  }
};
