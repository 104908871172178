<template>
  <v-dialog
    v-model="dialog"
    width="1143px"
    persistent
    @keydown.esc="close(false)"
    :fullscreen="isMobile"
  >
    <div class="dialog-wrapper custom-inputs custom-scrollbar" v-if="!isMobile">
      <div class="d-flex full-height">
        <div class="stepper-wrapper">
          <HorizontalStepper
            :activeStep="step"
            :stepperTitle="'Seller Profile'"
            :steps="getSteps"
            :showCompletedSteps="true"
            :customCompletedSteps="false"
          />
        </div>
        <div class="content-wrapper">
          <div v-if="step === 1">
            <div class="buttons-wrapper buttons-top">
              <div class="profile-dialog-title">Seller Entity</div>
            </div>
            <div class="px-10 mb-2">
              <v-divider />
            </div>
            <div class="px-10 mb-6">
              <StepInformation title="Contact information for a legal entity" />
            </div>
          </div>
          <div v-if="step === 2">
            <div class="buttons-wrapper buttons-top">
              <div class="profile-dialog-title">Seller Confirmation</div>
            </div>
            <div class="px-10 mb-2">
              <v-divider />
            </div>
            <div class="px-10 mb-6">
              <StepInformation title="A current POF is required for bids" />
            </div>
          </div>
          <v-stepper v-model="step" class="pb-0" vertical :key="key">
            <v-stepper-content
              step="1"
              v-if="step === 1"
              class="first-step-wrapper"
            >
              <v-form ref="sellerEntityForm" lazy-validation>
                <div class="fields-wrapper">
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity Name </c-label>
                      <v-text-field
                        placeholder="Xchange.Loans, Inc."
                        v-model="formData.entityName"
                        :rules="rules.required"
                        required
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity Address </c-label>
                      <v-text-field
                        v-model="formData.entityAddress.address"
                        :rules="rules.required"
                        class="custom-google-input"
                        placeholder="1100 5th Ave S"
                        id="placeAuto3"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity City </c-label>
                      <v-text-field
                        v-model="formData.entityAddress.city"
                        :rules="rules.required"
                        placeholder="Naples"
                        required
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity State </c-label>
                      <v-text-field
                        v-model="formData.entityAddress.state"
                        :rules="rules.required"
                        placeholder="Florida"
                        required
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity Zipcode </c-label>
                      <v-text-field
                        v-model="formData.entityAddress.zipCode"
                        :rules="rules.zipCode"
                        v-mask="'#####'"
                        inputmode="numeric"
                        placeholder="34102"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </v-form>
            </v-stepper-content>
            <v-stepper-content
              step="2"
              v-if="step === 2"
              style="position: relative"
              class="second-step-wrapper"
            >
              <div
                class=""
                :class="{ 'disabled-inputs': !form.executive }"
              ></div>
              <v-form ref="sellerConfirmationForm">
                <div cols="12" sm="6" class="fields-wrapper">
                  <div style="height: 40px">
                    <v-checkbox
                      v-model="form.executive"
                      label="I hereby certify that I am the true and legal owner and, or, representative of the asset listed or in a land trust, have the powers of direction, and am empowered to enter into agreements to list asset(s) on behalf of the aforementioned Seller Entity."
                      color="#2969FF"
                      class="ma-0 checkbox-wrapper"
                    ></v-checkbox>
                  </div>
                  <div class="field-wrapper mt-2">
                    <div class="second-input-with-background">
                      <c-label required> Signed By </c-label>
                      <v-text-field
                        :value="formData.signedByFirstName"
                        @input="
                          (v) => {
                            formData.signedByFirstName = capitalize(v);
                          }
                        "
                        :rules="rules.required"
                        placeholder="Deborah"
                        :disabled="!form.executive"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label></c-label>
                      <v-text-field
                        :value="formData.signedByLastName"
                        @input="
                          (v) => {
                            formData.signedByLastName = capitalize(v);
                          }
                        "
                        :rules="rules.required"
                        :disabled="!form.executive"
                        placeholder="Smith"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Title </c-label>
                      <v-text-field
                        v-model="form.title"
                        :rules="rules.required"
                        :disabled="!form.executive"
                        placeholder="Senior Vice President - Finance"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper"></div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Phone Number </c-label>
                      <v-text-field
                        v-model="formData.phoneNumber"
                        :rules="rules.phoneRules"
                        :disabled="!form.executive"
                        v-mask="'(###)-###-####'"
                        placeholder="(800) 913-8139"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </v-form>
            </v-stepper-content>

            <div class="buttons-wrapper" :class="{ 'justify-end': step === 1 }">
              <v-btn
                v-if="step !== 1"
                :loading="loading"
                button-second
                block
                tile
                style="max-width: 177px"
                width="177px"
                height="32px"
                color="#fff"
                @click="previousStep"
                class="button-second custom-btn"
                rounded
                shaped
              >
                <div class="button-text custom-btn-text">
                  <v-icon size="15">mdi-arrow-left</v-icon>
                  Back
                </div>
              </v-btn>
              <div @mouseover="validate">
                <v-btn
                  :loading="loading"
                  @click="updateSellerEntityBlock"
                  button-second
                  block
                  tile
                  style="max-width: 177px"
                  width="177px"
                  height="32px"
                  color="#2969FF"
                  class="button-second"
                  rounded
                  shaped
                  :disabled="isButtonDisabled"
                >
                  <div class="button-text" v-if="step === 2">Finish</div>
                  <div class="button-text" v-else>
                    Next
                    <v-icon size="15">mdi-arrow-right</v-icon>
                  </div>
                </v-btn>
              </div>
            </div>
          </v-stepper>
        </div>
      </div>
    </div>
    <SellerProfileEditDialogMobile v-else @close="close(false)" />
  </v-dialog>
</template>
<script>
import Clabel from "@/components/Common/CLabel.vue";
import apiProfile from "@/services/apiCalls/profile/profile";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import handleLogsMixin from "@/mixins/handleLogsMixin";
import { mask } from "vue-the-mask";
import { Auth } from "aws-amplify";
import HorizontalStepper from "@/components/ui/HorizontalStepper.vue";
import loader from "@/services/googleApi";
import proofOfFundsMixin from "@/mixins/proofOfFundsMixin";
import SellerProfileEditDialogMobile from "@/components/GlobalDialogs/SellerProfileEditDialogMobile.vue";
import { mapActions, mapState } from "vuex";
import StepInformation from "@/components/ProfileEditDialog/StepInformation.vue";

export default {
  name: "UserProfileSellerDialog",
  mixins: [validationMixin, handleLogsMixin, proofOfFundsMixin],
  directives: {
    mask,
  },
  components: {
    StepInformation,
    "c-label": Clabel,
    HorizontalStepper,
    SellerProfileEditDialogMobile,
  },
  validations: {
    form: {
      companyName: { required },
      entityName: { required },
      phoneNumber: { required, minLength: minLength(14) },
      userAddress: { required },
      userAddressCity: { required },
      zipCode: { required, minLength: minLength(5), maxLength: maxLength(5) },
      faxNumber: { required, minLength: minLength(14) },
      stateOfFormation: { required },
      title: { required },
    },
    formData: {
      entityName: { required },
      entityAddress: {
        address: { required },
        city: { required },
        country: { required },
        state: { required },
        zipCode: { required, minLength: minLength(5), maxLength: maxLength(5) },
      },
      signedByFirstName: { required },
      signedByLastName: { required },
      phoneNumber: { required, minLength: minLength(14) },
    },
  },
  data() {
    return {
      step: 1,
      valid: true,
      dialog: false,
      resolve: null,

      additionalAddressData: null,
      loading: false,
      rules: {
        phoneRules: [(v) => !!v || "", (v) => (v && v.length === 14) || ""],
        zipCode: [
          (v) => !!v || "",
          (v) => (v && v.length === 5) || "Zipcode must be 5 characters",
        ],
        required: [(v) => !!v || ""],
        textRules: [(v) => !!v || "", (v) => (v && v.length >= 2) || ""],
      },
      steps: [
        {
          order: 1,
          title: "Seller Entity",
        },
        {
          order: 2,
          title: "Seller Confirmation",
        },
      ],
      key: 0,
      formData: {
        entityName: "",
        entityAddress: {
          address: null,
          city: null,
          state: null,
          country: null,
          zipCode: null,
        },
        signedByFirstName: "",
        signedByLastName: "",
        country: null,
        executive: false,
        avatar: null,
        phoneNumber: "",
        title: null,
      },
      form: {
        aum: "1",
        entityName: "",
        entityAddress: {
          address: null,
          city: null,
          state: null,
          country: null,
          zipCode: null,
        },
        signedByFirstName: "",
        signedByLastName: "",
        companyName: "",
        companyProfile: null,
        currentStrategy: null,
        country: null,
        email: "",
        executive: false,
        faxNumber: null,
        firstName: "",
        lastName: "",
        avatar: null,
        phoneNumber: "",
        officePhone: "",
        state: null,
        userAddress: "",
        userAddressCity: "",
        zipCode: "",
        title: null,
        stateOfFormation: "",
      },
    };
  },
  watch: {
    formData: {
      handler() {
        this.validate();
      },
      deep: true,
    },
    step(v) {
      this.key = this.key + 1;
      if (v === 1) {
        this.initAutocomplete();
      }
    },
    additionalAddressData() {
      this.setAddress();
    },
    dialog: {
      handler() {
        this.initAutocomplete();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("UserSettings", ["userSettings"]),
    getSteps() {
      return this.steps;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isButtonDisabled() {
      if (this.step === 1) {
        return !(
          !this.$v.formData.entityName.$invalid &&
          !this.$v.formData.entityName.$invalid &&
          !this.$v.formData.entityAddress.$invalid &&
          !this.$v.formData.entityAddress.address.$invalid &&
          !this.$v.formData.entityAddress.city.$invalid &&
          !this.$v.formData.entityAddress.country.$invalid &&
          !this.$v.formData.entityAddress.state.$invalid &&
          !this.$v.formData.entityAddress.zipCode.$invalid
        );
      } else {
        return !(
          !this.$v.formData.signedByFirstName.$invalid &&
          !this.$v.formData.signedByLastName.$invalid &&
          !this.$v.formData.phoneNumber.$invalid &&
          !this.$v.form.title.$invalid
        );
      }
    },
    addressInfo() {
      return this.$store.state.value.address;
    },
    name() {
      return this.addressInfo?.additional?.name;
    },
    locality() {
      return this.addressInfo?.additional?.locality;
    },
    administrative_area_level_1() {
      return this.addressInfo?.additional?.administrative_area_level_1;
    },
    administrative_area_level_1ShortName() {
      return this.addressInfo?.additional?.administrative_area_level_1ShortName;
    },
    county() {
      return this.addressInfo?.additional?.country;
    },
    zipCode() {
      return this.addressInfo?.additional?.zipCode;
    },
  },
  methods: {
    ...mapActions("User", ["updateSellerProfile"]),
    open() {
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close(v) {
      this.dialog = false;
      this.resolve(v);
    },
    validate() {
      if (this.step === 1 && this.$refs.sellerEntityForm) {
        this.valid = !this.$refs.sellerEntityForm.validate();
      }
      if (this.step === 2 && this.$refs.sellerConfirmationForm) {
        this.valid = !this.$refs.sellerConfirmationForm.validate();
      }
    },
    async updateSellerEntityBlock() {
      const params = {
        phoneNumber: this.formData.phoneNumber,
        executive: !!this.form.executive,
        entityName: this.formData.entityName,
        entityAddress: this.formData.entityAddress,
        title: this.form.title || null,
        signedByFirstName: this.formData.signedByFirstName,
        signedByLastName: this.formData.signedByLastName,
      };

      this.$store.commit("spinner", true);
      const result = await this.updateSellerProfile(params);
      this.$store.commit("spinner", false);

      if (this.step < 2) {
        if (result) {
          this.step = this.step + 1;
          this.valid = this.$refs.sellerConfirmationForm.validate();
        }
      } else {
        this.dialog = true;
        await this.refreshToken();
        this.dialog = false;

        this.close(!!result);
      }
    },
    async initAutocomplete() {
      this.google = await loader.load();
      const options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["address"],
      };
      this.dialog &&
        this.$nextTick(() => {
          const autocomplete = new this.google.maps.places.Autocomplete(
            document.getElementById("placeAuto3"),
            options
          );
          this.autocomplete = autocomplete;
          autocomplete.addListener("place_changed", this.setPlace);
        });
    },
    setPlace() {
      let place = this.autocomplete.getPlace();
      const administrative_area_level_1 =
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0].long_name;
      const administrative_area_level_1ShortName =
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0].short_name;
      const county =
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_2")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_2")
        )[0].short_name;
      const country =
        place.address_components.filter((el) =>
          el.types.includes("country")
        )[0] &&
        place.address_components.filter((el) => el.types.includes("country"))[0]
          .long_name;
      const countryShortName =
        place.address_components.filter((el) =>
          el.types.includes("country")
        )[0] &&
        place.address_components.filter((el) => el.types.includes("country"))[0]
          .short_name;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const name = place.name;
      const locality =
        place.address_components.filter((el) =>
          el.types.includes("locality")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("locality")
        )[0].long_name;
      const zipCode =
        place.address_components.filter((el) =>
          el.types.includes("postal_code")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("postal_code")
        )[0].long_name;
      this.additionalAddressData = {
        administrative_area_level_1,
        country,
        lat,
        lng,
        name,
        countryShortName,
        administrative_area_level_1ShortName,
        locality,
        zipCode,
        county,
      };
      this.address = document.getElementById("placeAuto3").value;
    },
    setAddress() {
      if (this.additionalAddressData) {
        if (this.step === 1) {
          this.$nextTick(() => {
            this.formData.entityAddress.state =
              this.additionalAddressData.administrative_area_level_1;
            this.formData.entityAddress.zipCode =
              this.additionalAddressData.zipCode;
            this.formData.entityAddress.address =
              this.additionalAddressData.name;
            this.formData.entityAddress.city =
              this.additionalAddressData.locality;
            this.formData.entityAddress.country = "USA";
          });
        }
      }
    },
    previousStep() {
      this.step = this.step - 1;
      this.valid = false;
    },
    async refreshToken() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        currentUser.refreshSession(currentSession.refreshToken, async () => {
          const currentUpdatedUser = await Auth.currentAuthenticatedUser();
          this.$store.commit("setAuthState", {
            isAuthenticated: true,
            user: currentUpdatedUser.attributes,
          });
          localStorage.setItem(
            "currentUser",
            JSON.stringify(currentUpdatedUser.attributes)
          );
          this.$store.dispatch("getCurrentUserProfile");
        });
      } catch (err) {
        console.error(err);
      }
    },
    capitalize(s) {
      if (s && s.length) {
        return s[0].toUpperCase() + s.slice(1);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../styles/overridings/ui/profileSeller.scss";
::v-deep {
  .c-label {
    color: #001154 !important;
  }
  .v-stepper__wrapper {
    min-height: 330px;
  }
  .custom-input-border .v-input:focus-within {
    border: 1px solid rgba(41, 105, 255, 0.6);
  }
  .google-input-border:focus-within {
    border: 1px solid rgba(41, 105, 255, 0.6);
  }
  .radiogroup-wrapper {
    padding-top: 8px;
    margin-top: 4px;
    .v-label {
      color: #001154;
    }
    &--disabled {
      .v-label {
        opacity: 0.5;
      }
    }
  }
  .first-step-wrapper {
    //margin-bottom: 89px !important;
  }
  .second-step-wrapper {
    //margin-bottom: 16px !important;
    padding-top: 12px !important;
  }
  .disabled-inputs {
    position: absolute;
    width: 100%;
    height: 238px;
    left: 0;
    top: 17%;
    background: rgba(255, 255, 255, 0.65);
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: -26px;
    margin-top: 10px;
    z-index: 2;
  }
  .second-input-with-background .custom-google-input {
    background-color: #f3f3f3;
    padding-left: 10px;
    padding-right: 20px;
    color: #001154 !important;
  }
  .custom-google-input--focus:focus-within {
    border: 1px solid rgba(41, 105, 255, 0.6);
  }
  .custom-input-error {
    background: rgba(213, 40, 25, 0.08) !important;
    border: 1px solid rgba(213, 40, 25, 0.6);
    &:focus-within {
      border: 1px solid rgba(213, 40, 25, 0.6);
    }
    &::placeholder {
      color: rgba(213, 40, 25, 0.7);
    }
  }
  .custom-google-input {
    margin-top: 4px;
    outline: unset;
    height: 40px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    color: #001154;
  }
  .profile-dialog-title {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 32px !important;
    letter-spacing: 0.32px !important;
    color: #0f2d59;
  }
  .custom-btn {
    border: 1px solid #2969ff !important;
  }
  .custom-btn:hover {
    background-color: #145aff !important;
    .custom-btn-text {
      color: #ffffff !important;
    }
  }
  .custom-btn-text {
    color: #2969ff !important;
  }
  .buttons-wrapper {
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .buttons-top {
    padding-top: 15px !important;
    padding: 0 40px;
    margin-bottom: 13px;
  }
  .v-radio .v-input--selection-controls__input .v-icon {
    font-size: 20px !important;
    margin-left: 0;
    padding-left: 10px;
  }
  .field-wrapper .req-icon {
    margin-left: -3px;
  }
  .v-radio .v-input--selection-controls__ripple {
    height: 24px !important;
    width: 24px !important;
    left: -2px !important;
    top: calc(50% - 20px);
  }
  .v-radio .v-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
    margin-right: -10px;
  }
  .second-input-with-background .c-label {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 17px;
    color: rgba(0, 17, 84, 0.7);
  }
  .v-text-field__slot input {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    color: rgba(0, 17, 84, 1) !important;
  }

  .mdi-camera::before {
    color: #2969ff !important;
    font-size: 18px;
    align-self: center;
  }
  .v-file-input__text--placeholder {
    color: #2969ff !important;
    margin-left: -6px;
    font-size: 12px;
    letter-spacing: 0;
  }
  .v-input__prepend-outer {
    align-self: center;
  }
  .second-input-with-background .req-icon {
    margin-left: -3px;
  }
  .v-dialog {
    overflow: hidden;
  }
  .v-btn {
    min-height: unset !important;
    min-width: unset !important;
  }
  .v-text-field,
  .app-custom-input {
    height: 40px;
    padding-top: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
  }
  v-input--radio-group--column {
    margin-top: 0;
    position: relative;
    top: 8px;
  }
  .checkbox-wrapper {
    .v-label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* or 150% */
      /* DarkBlue */
      color: #001154;
      opacity: 0.8;
    }
  }
  .v-stepper--vertical .v-stepper__content {
    padding: 24px 60px 16px 23px;
  }
  .c-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 17px;
    /* identical to box height, or 155% */
    min-height: 20px;
    color: rgba(0, 17, 84, 0.7);
  }
  .v-input--radio-group {
    background: white;
  }
  .v-input--radio-group__input {
    display: flex;
    column-gap: 32px;
  }
  .v-input--selection-controls__ripple {
    color: #2969ff !important;
  }
  v-radio {
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 26px;
      /* identical to box height, or 217% */
      /* DarkBlue */
      color: #001154;
    }
  }
}
.stepper-wrapper {
  width: 295px;
}
.content-wrapper {
  width: 847px;
  position: relative;
}
.dialog-wrapper {
  background: white;
  border-radius: 5px;
  max-width: 1143px;
  margin: 0 auto;
  overflow: auto;
  max-height: 891px;
  height: 597px;
}
.seller-dialog-title {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  /* identical to box height, or 13 3% */
  padding-top: 32px;
  letter-spacing: 0.32px !important;
  /* DarkBlue */
  padding-left: 40px;
  color: map-get($colors, "primary");
}
.sub-title {
  width: 413px;
  padding-left: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  opacity: 0.8;
  color: map-get($colors, "primary");
}
.step {
  display: flex;
  margin-top: 41px;
  &__number {
    width: 24px;
    height: 24px;
    border-radius: 26px;
    text-align: center;
    color: white;
    background-color: map-get($colors, "primary");
  }
  &__title {
    font-size: 12px;
    line-height: 26px;
    color: map-get($colors, "primary");
  }
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
  padding: 0px 40px !important;
}
.button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  text-align: center;
  /* White */
  color: #ffffff;
}
.fields-wrapper {
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 24px;
  row-gap: 20px;
}
.field-wrapper {
  width: 47%;
}
.finish-later {
  color: #2969ff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  text-align: center;
  cursor: pointer;
  /* Cyan */
  color: #2969ff;
}
.app-custom-input {
  height: 40px !important;
}
.app-custom-input > div {
  height: 63px;
}
.app-custom-input {
  input {
    height: 40px;
  }
  .c-label {
    margin-bottom: 3px;
  }
}
.close-icon {
  position: absolute;
  right: 24px;
  top: 24px;
}
.req-icon {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  /* identical to box height, or 155% */
  /* Red */
  color: #d52819;
}
::v-deep {
  .stepper-title {
    font-size: 20px !important;
  }
}
</style>
