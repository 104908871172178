// error codes cant be added for appsync erors
//thats why we use appsync message as a key
const appsyncErrors = new Map([
  [
    "Not Authorized to access createOffer on type Mutation",
    "You no longer have right to submit offers",
  ],
]);

const backendErrors = new Map([
  ["4011", "Buyer with expired funds approval can`t create offer"],
  ["4012", "Buyer without funds approval can`t create"],
]);

// Check your Proof of Funds to continue

export const formatErrorMessage = (err) => {
  let errDetails = null;
  if (err && err.errors && err.errors[0]) {
    errDetails = err.errors[0];
  }

  if (!errDetails) {
    return "";
  }
  const appsyncErrorMsg = appsyncErrors.get(errDetails.message);
  if (appsyncErrorMsg) {
    return appsyncErrorMsg;
  }
  const backendErrorMsg = backendErrors.get(errDetails.errorType);
  if (backendErrorMsg) {
    return backendErrorMsg;
  }

  return "";
};
