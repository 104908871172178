<template>
  <div class="field-wrapper">
    <div class="second-input-with-background">
      <c-label>
        {{ cLabel }}
        <span class="req-icon" v-if="isRequired"> * </span></c-label
      >
      <v-text-field
        v-on="$listeners"
        v-bind="$attrs"
        @input="(e) => $emit('input', e)"
        @keydown="onkeydown"
        :value="value"
      >
        <template v-slot:append>
          <slot></slot>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import Clabel from "@/components/Common/CLabel.vue";
import { mask } from "vue-the-mask";

export default {
  name: "appSecondTextField",
  props: {
    cLabel: { type: String, default: null },
    isRequired: { type: Boolean, default: true },
    value: {
      type: String,
      default: "",
    },
  },
  components: {
    "c-label": Clabel,
  },
  methods: {
    onkeydown(evt) {
      this.$emit("onkeydown", evt);
    },
  },
};
</script>

<style scoped lang="scss">
.field-wrapper {
  width: 47%;
}
</style>
