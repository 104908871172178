import { API, graphqlOperation } from "aws-amplify";
import { ES_OFFER } from "@/services/apiCalls/types/Offer/ESOffer";

export default function subscribeToNoticeESOffersUpdate() {
  const query = `subscription  {
    subscribeToNoticeESOffersUpdate{
    ${ES_OFFER}
    }
    
    
    }`;

  return API.graphql(graphqlOperation(query));
}
