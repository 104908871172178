import { API } from "aws-amplify";
export default (id) => {
  const query = `query {
    getAsset(id: "${id}") {
        upbDate
         
           
       
           
         
          
          pricingPlan
          
            propertyOverview {
              propertyType
           
          }
          
          
           
      }
    }
  `;

  return API.graphql({
    query,
    authMode: "AWS_IAM",
  });
};
