import { API } from "aws-amplify";

export default (key) => {
  const query = `query getUserDocument_SidePanel($key:String!) {
        getUserDocument(key:$key)
    }`;

  return API.graphql({
    query,
    variables: {
      key,
    },
  });
};
