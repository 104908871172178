import { VALUE_AMOUNT } from "../Global/ValueAmount";
import { OFFER_ASSET } from "./OfferAsset";
import { CONTACT } from "../Contact/Contact";
import { PAYMENT } from "../Payment/Payment";
import { REFAUND } from "../Refaund/Refaund";
import { OFFER_HISTORY } from "./OfferHistory";
import { CLOSING_HISTORY } from "@/services/apiCalls/types/ClosingHistory/ClosingHistory";

import { DEPOSIT } from "@/services/apiCalls/types/Offer/Deposit";
import { DOCUMENT_FOR_SIGN } from "@/services/apiCalls/types/Offer/DocumentForSign";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";
import { DEPOSIT_HISTORY } from "@/services/apiCalls/types/Offer/DepositHistory";
export const OFFER = `
id
offerStatusChangeTrigger
assets{
    ${OFFER_ASSET}
}
assetsDetailed{
    ${ASSET}
}
bidPrice{
    ${VALUE_AMOUNT}
}
declineReason
publishedDate
closing
dateModified
BDASignedDate
dueDiligencePeriod
earnestMoneyDeposit{
    ${VALUE_AMOUNT}

}
buyerClosingPeriod {
    startDate
    endDate
}
sellerClosingPeriod {
    startDate
    endDate
}
buyerClosingDocumentsAccepted
sellerClosingDocumentsAccepted
closingNotificationSeenBuyer
closingNotificationSeenSeller
notes
owners
owner_token
asset_owner_token
status
type
contacts{
    ${CONTACT}
}

payment{
    ${PAYMENT}
}
refund{
    ${REFAUND}
}
pofSum{
    ${VALUE_AMOUNT}

}
fundsApprovalDate
fundsApprovalExpiryDate
offerHistory{
    ${OFFER_HISTORY}
}
creationDate
acceptedDate
winningDate
declinedDate
withdrawDate
publishedDate

SSHistory{
${CLOSING_HISTORY}
}

deposit{
${DEPOSIT}
}
depositHistory {
    ${DEPOSIT_HISTORY}
}
            

SSDocument{
${DOCUMENT_FOR_SIGN}

}
BDApdfKey
`;
