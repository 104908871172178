import { API } from "aws-amplify";

export default (id) => {
  const query = `mutation removeUserFavoriteAsset($id: String!) {
    removeUserFavoriteAsset(id: $id) {
      id
    }
  }`;

  return API.graphql({
    query,
    variables: {
      id,
    },
  });
};
