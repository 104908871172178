export default {
  prefix: "",
  suffix: "",
  separator: ",",
  decimal: ".",
  precision: 2,
  prefill: true,
  reverseFill: false,
  minimumFractionDigits: false,
  min: false,
  max: false,
  nullValue: "",
};
