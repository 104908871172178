export default {
  namespaced: true,
  state: {
    assetsFilter: null,
    assetsSearchSelect: null,
    assetsSortName: "",
    sellerFilter: null,
    sortValueName: "",
    sellerPage: "active",
  },
  getters: {},
  mutations: {
    setAssetFilter(state, newFilter) {
      state.assetsFilter = {
        ...state.assetsFilter,
        ...newFilter,
      };
    },
    setAssetSearchSelect(state, searchSelect) {
      state.assetsSearchSelect = searchSelect;
    },
    setAssetSortName(state, sortName) {
      state.assetsSortName = sortName;
    },
    setSellerFilter(state, sellerFilter) {
      state.sellerFilter = sellerFilter;
    },
    setSortValue(state, sortValue) {
      state.sortValueName = sortValue;
    },
    setSellerPage(state, sellerPage) {
      state.sellerPage = sellerPage;
    },
  },
  actions: {},
};
