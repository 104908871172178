export default function validateEmail(email) {
  const allowedRegex = /^[a-zA-Z0-9@!$#|_+'\/.\-]+$/;
  const disallowedRegex = /[,:()\s&*?]/;

  if (disallowedRegex.test(email)) {
    return false;
  }

  return allowedRegex.test(email);
}
