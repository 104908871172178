<template>
  <v-tabs color="secondary" height="64" optional center-active>
    <v-tab
      to="/buyer"
      v-if="$store.getters.isBuyer"
      :class="{ active: activeTab === 'buyer' }"
    >
      Buying
    </v-tab>

    <v-tab
      to="/seller"
      v-if="$store.getters.isSeller"
      :class="{ active: activeTab === 'seller' }"
    >
      Selling
    </v-tab>
    <v-tab to="/valuations" :class="{ active: activeTab === 'valuations' }">
      Valuations
    </v-tab>
    <v-tab>
      <slot />
    </v-tab>
  </v-tabs>
</template>
<script>
export default {
  name: "SubHeaderTabsDefault",
  props: {
    activeTab: {
      type: String,
      default: "",
    },
  },
};
</script>
