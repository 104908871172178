export default (err) => {
  return (
    (err &&
      err.errors &&
      err.errors[0] &&
      err.errors[0].errorInfo &&
      err.errors[0].errorInfo[0] &&
      err.errors[0].errorInfo[0].description) ||
    (err && err.errors && err.errors[0] && err.errors[0].message)
  );
};
