export default {
  namespaced: true,
  state: {
    assetInfo: {
      name: "",
      description: "",
      type: "",
      upbDate: "",
      ownerEmail: "",
      callForOffersPeriod: {
        startDate: "",
        endDate: "",
      },
      bestAndFinalPeriod: {
        startDate: "",
        endDate: "",
      },
      displayDueDate: "Date",
      principalBalance: "",
      listingPrice: "",
      privacy: "",
      images: "",
      requireBidDeposit: false,
      bidDepositSum: "",
      preferredBidDepositChannel: "",
      address: {
        city: "",
        state: "",
        country: "",
        zipCode: "",
        address: "",
      },
    },
  },
  mutations: {
    resetAssetInfo(state) {
      Object.keys(state.assetInfo).map((key) => {
        if (key !== "noteOverview" || key !== "propertyOverview") {
          state.assetInfo[key] = "";
        } else {
          Object.keys(state.assetInfo[key]).map((key2) => {
            state.assetInfo[key][key2] = "";
            if (key2 === "collateralType" || key2 === "subtype") {
              state.assetInfo[key][key2] = [];
            }
          });
        }
        state.assetInfo.status = "Draft";
      });
    },
    addContactsToAssetInfo(state, payload) {
      state.assetInfo.contacts = state.assetInfo.contacts || [];
      if (Array.isArray(payload)) {
        state.assetInfo.contacts = [...state.assetInfo.contacts, ...payload];
      } else {
        state.assetInfo.contacts.push(payload);
      }
    },
    deleteContactFromAssetInfo(state, payload) {
      state.assetInfo.contacts = state.assetInfo.contacts.filter(
        (contact) => contact.emailAddress !== payload
      );
    },
    callForOffersStart(state, payload) {
      state.assetInfo.callForOffersPeriod.startDate = payload;
    },
    callForOffersEnd(state, payload) {
      state.assetInfo.callForOffersPeriod.endDate = payload;
    },
    bestAndFinalStart(state, payload) {
      state.assetInfo.bestAndFinalPeriod.startDate = payload;
    },
    bestAndFinalEnd(state, payload) {
      state.assetInfo.bestAndFinalPeriod.endDate = payload;
    },
    upbDate(state, payload) {
      state.assetInfo.upbDate = payload;
    },
    assetDescription(state, payload) {
      state.assetInfo.description = payload;
    },
    assetType(state, payload) {
      state.assetInfo.type = payload;
    },
    displayDueDate(state, payload) {
      state.assetInfo.displayDueDate = payload;
    },
    bidDepositChannel(state, payload) {
      state.assetInfo.preferredBidDepositChannel = payload;
    },
    country(state, payload) {
      state.assetInfo.address.country = payload;
    },
    state(state, payload) {
      state.assetInfo.address.state = payload;
    },

    bidDepositSum(state, payload) {
      state.assetInfo.bidDepositSum = payload;
    },
    principalBalance(state, payload) {
      state.assetInfo.principalBalance = payload;
    },
    city(state, payload) {
      state.assetInfo.address.city = payload;
    },
    zipCode(state, payload) {
      state.assetInfo.address.zipCode = payload;
    },
    listingPrice(state, payload) {
      state.assetInfo.listingPrice = payload;
    },
    address(state, payload) {
      state.assetInfo.address.address = payload;
    },
    assetTitle(state, payload) {
      state.assetInfo.name = payload;
    },
    requireBidDeposit(state, payload) {
      state.assetInfo.requireBidDeposit = payload;
    },
  },
};
