export default [
  {
    path: "/free-loan-valuation",
    name: "Value",
    redirect: "/free-loan-valuation/primaryInfo",
    component: () => import("./pages/Value/Value"),
    children: [
      {
        name: "assetCreation",
        path: "/free-loan-valuation",
        redirect: "/free-loan-valuation/general",
        component: () =>
          import("./pages/Value/ValueAssetCreate/ValueAssetCreate.vue"),
        children: [
          {
            name: "general",
            path: "/free-loan-valuation/general/:id?",
            component: () =>
              import("./pages/Value/ValueModificationSteps/ValueGeneral.vue"),
            meta: {
              title:
                "XL Value | Free self-serving non-performing loan valuation",
            },
          },
          {
            name: "specifics",
            path: "/free-loan-valuation/specifics/:id?",
            component: () =>
              import("./pages/Value/ValueModificationSteps/ValueSpescific.vue"),
            meta: {
              title:
                "XL Value Specifics | Free self-serving non-performing loan valuation",
            },
          },
          {
            name: "details",
            path: "/free-loan-valuation/details/:id?",
            component: () =>
              import("./pages/Value/ValueModificationSteps/ValueDetails.vue"),
            meta: {
              title:
                "XL Value Details | Free self-serving non-performing loan valuation",
            },
          },
          {
            name: "valuation",
            path: "/free-loan-valuation/valuation/:id?",
            component: () =>
              import("./pages/Value/ValueModificationSteps/ValueValuation.vue"),
            meta: {
              title: "XL Value - Valuation - Xchange.Loans",
            },
          },
        ],
      },
      {
        name: "primaryInfo",
        path: "/value/primaryInfo",
        redirect: "/free-loan-valuation/general",
        component: () => import("./components/Value/ValueAddressInput"),
      },
      {
        name: "pdf",
        path: "/free-loan-valuation/pdf",
        component: () =>
          import("./components/Value/ValueValuation/ValuationPDFTemplate.vue"),
      },
    ],
  },
];
