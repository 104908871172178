import { API } from "aws-amplify";
export default () => {
  const isAuth = localStorage.getItem("authenticated");

  const query = `query getSettings {
          getSettings {
            autoDeposit
            userProfileRequired
            userNotificationsCriteriaRequired
            passwordlessRegistrationEnabled
            depositPaymentProvider
            depositIframeProvider
          }
      }`;
  return API.graphql({
    query: query,
    variables: {},
    authMode: isAuth ? "" : "AWS_IAM",
  });
};
