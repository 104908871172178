export const DEPOSIT_HISTORY = `
requestedDate
pendingDate
inProgressDate
paidDate
fundFailedDate
fundFailedReason
refundToBuyerDate
refundToBuyerCode
refundToBuyerFailedDate
refundToBuyerFailedReason
withdrawToSellerDate
withdrawToSellerCode
withdrawToSellerFailedDate
withdrawToSellerFailedReason
withdrawToAdminDate
withdrawToAdminCode
withdrawToAdminFailedDate
withdrawToAdminFailedReason
`;
