import { guardSellerRevoked, guardSeller } from "./routerGuards/guardSeller";
export default [
  {
    path: "/seller/filters/:type",
    name: "dashboardSeller",
    component: () =>
      import(/* webpackChunkName: "seller" */ "./pages/Seller/Seller"),
    meta: {
      requiresAuth: true,
      title: "Seller Dashboard - Xchange.Loans",
      hideFooter: true,
    },
    beforeEnter: guardSellerRevoked,
  },
  {
    path: "/seller",
    component: () =>
      import(/* webpackChunkName: "seller" */ "./pages/Seller/Seller"),
    redirect: "seller/filters/active",
    meta: {
      requiresAuth: true,
      title: "Seller Dashboard - Xchange.Loans",
      hideFooter: true,
    },
    beforeEnter: guardSeller,
    children: [
      {
        path: "asset-overview/:id",
        component: () =>
          import(
            /* webpackChunkName: "multi-asset-offers" */ "./pages/Buyer/BuyerMultiAssetOffers"
          ),
        meta: {
          requiresAuth: true,
          title: "Multi-Asset Offers - Buyer Dashboard - Xchange.Loans",
        },
      },
    ],
  },
  {
    path: "/seller/filters/:type",
    component: () =>
      import(/* webpackChunkName: "seller" */ "./pages/Seller/Seller"),
    meta: {
      requiresAuth: true,
      title: "Seller Dashboard - Xchange.Loans",
    },
    beforeEnter: guardSellerRevoked,
  },
];
