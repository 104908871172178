import { API } from "aws-amplify";

export default (id) => {
  const mutation = `mutation addAdmin($id: String!) {
      addAdmin(id: $id) {
        id
        roles
      }
    }`;
  return API.graphql({
    query: mutation,
    variables: { id },
  });
};
