import { API } from "aws-amplify";

export default {
  sendContactUs(dataToSend) {
    const mutation = `mutation contactUs (
                $name: String!,
                $email: String!,
                $purpose: String!,
                $msg: String!,
                $phone: String
            ) {
                contactUs (
                    name: $name,
                    email: $email,
                    purpose: $purpose,
                    msg: $msg,
                    phone: $phone
            )
        }`;
    const variables = {
      name: dataToSend.name,
      email: dataToSend.email,
      purpose: dataToSend.purpose,
      msg: dataToSend.message,
      phone: dataToSend.phone,
    };
    return API.graphql({
      query: mutation,
      variables,
      authMode: "AWS_IAM",
    });
  },
};
