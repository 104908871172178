<template>
  <v-dialog
    :width="isMobile ? '100%' : '848'"
    v-model="dialog"
    @keydown.esc="dialog = false"
  >
    <div class="dialog-wrapper">
      <div class="dialog-title">Delete Bank</div>
      <div class="d-flex justify-center mt-1">
        <img src="@/assets/img/icons/delete-bank-icon.svg" />
      </div>
      <div class="bank-data-wrapper">
        <div class="bank-data-wrapper__title">
          Are you sure you want to delete the bank?
        </div>
        <div class="bank-info">
          <div class="bank-name">
            {{ bankInfo.bankName }}
          </div>
          <div class="mt-2 d-flex">
            <div class="d-flex">
              <div class="bank-info-title">Routing Number</div>
              <div class="ml-2 bank-info-value">
                {{ bankInfo.routingNumber }}
              </div>
            </div>
          </div>
          <div class="mt-1 d-flex">
            <div class="d-flex">
              <div class="bank-info-title">Account Number</div>
              <div class="ml-2 bank-info-value">
                {{ getAccountNumber }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="close-icon" @click="dialog = false">
        <v-icon> mdi-close </v-icon>
      </div>

      <div class="buttons-wrapper">
        <AppButton
          buttonType="outlined"
          :width="isMobile ? '50%' : '230px'"
          height="40px"
          buttonText="Cancel"
          @click="dialog = false"
        />
        <AppButton
          :width="isMobile ? '50%' : '230px'"
          color="#2969FF"
          height="40px"
          rounded
          shaped
          buttonText="Delete Bank"
          :class="!isMobile && 'ml-8'"
          @click="onDelete"
        >
        </AppButton>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import AppButton from "@/components/ui/AppButton.vue";
import ErrorParser from "@/utils/ErrorParser";
import { mapActions } from "vuex";

export default {
  name: "DeleteBankDialog",
  components: { AppButton },
  data() {
    return {
      dialog: false,
      isFormValid: false,
      bankInfo: {
        lastFour: "",
        accountNumber: "",
      },
    };
  },
  methods: {
    ...mapActions({
      deleteBankingInfo: "deleteBankingInfo",
    }),
    open(bankInfo) {
      this.bankInfo = bankInfo;
      this.dialog = true;
    },
    async onDelete() {
      try {
        this.$store.commit("spinner", true);
        await this.deleteBankingInfo(this.bankInfo.bankingInfoId);
        this.$store.commit("spinner", false);
        this.$emit("deleteBank");
        this.dialog = false;
      } catch (err) {
        this.$store.commit("openSnackBar", {
          text: ErrorParser(err),
          color: "error",
        });
        this.$store.commit("spinner", false);
      }
    },
  },
  computed: {
    getAccountNumber() {
      if (this.bankInfo.lastFour) {
        return `*************${this.bankInfo.lastFour}`;
      }
      if (this.bankInfo.accountNumber) {
        const accNumberString = this.bankInfo.accountNumber.toString();
        if (accNumberString.length > 4) {
          return (
            "*".repeat(accNumberString.length - 4) + accNumberString.slice(-4)
          );
        }
      }
      return "*****************";
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-wrapper {
  background: white;
  width: 100%;
  height: 100%;
  min-height: 450px;
  padding: 0 40px;
  position: relative;
}
.dialog-title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
  color: #0f2d59;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
  letter-spacing: 0.444px;
}
.dialog-info {
  border-radius: 1px;
  background: #f2f6ff;
  box-shadow: 2px 2px 15px 0px rgba(173, 173, 173, 0.25);
  height: 52px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  display: flex;
}
.close-icon {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
::v-deep {
  input {
    position: relative;
    top: 6px !important;
  }
  .v-btn {
    min-height: unset !important;
  }
  .field-wrapper {
    width: 100% !important;
  }
}
.buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.bank-name {
  color: #001154;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
}
.bank-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep {
    .v-dialog {
      margin: 0 !important;
    }
  }
  .buttons-wrapper {
    justify-content: unset;
    column-gap: 16px;
    button {
      flex: 1;
    }
  }
  .dialog-wrapper {
    padding: 0 16px;
  }
  .bank-info-title {
    width: 116px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400;
    line-height: 18px;
  }
  .bank-info-value {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .bank-data-wrapper {
    &__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .dialog-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  .bank-data-wrapper {
    margin-top: 32px;
  }
}
</style>
