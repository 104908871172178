var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.key,staticClass:"stepper 11111",class:{
    'stepper--mobile': _vm.mobile,
  }},[(!_vm.mobile)?_c('div',{staticClass:"stepper-title"},[_vm._v(" "+_vm._s(_vm.stepperTitle)+" ")]):_vm._e(),_c('div',{staticClass:"steps",class:{
      'steps--mobile': _vm.mobile,
    }},_vm._l((_vm.steps),function(step,i){return _c('div',{key:i,staticClass:"step",class:{
        pointer:
          _vm.activatedSteps ||
          (_vm.withRouter &&
            (_vm.assetInfo.registrationStatus ||
              step.order <= _vm.getIndex + 1 ||
              _vm.checkIsStepCompleted(step))),
        '--completed':
          (_vm.activatedSteps && step.order !== _vm.activeStep) ||
          step.order <= _vm.getIndex ||
          _vm.assetInfo.registrationStatus ||
          _vm.checkIsStepCompleted(step),
        'step--mobile': _vm.mobile,
      },on:{"click":function($event){return _vm.onStepClick(step)}}},[(step.order !== 1)?_c('div',{staticClass:"dots-wrapper"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"3","height":"10","viewBox":"0 0 3 10","fill":"none"}},[_c('circle',{attrs:{"cx":"1.5","cy":"1.5","r":"1.5","fill":_vm.activatedSteps ||
              _vm.checkIsStepCompleted(step) ||
              (_vm.checkIsStepActive(step) && _vm.showCompletedSteps)
                ? '#35F9A7'
                : 'white'}}),_c('circle',{attrs:{"cx":"1.5","cy":"8.5","r":"1.5","fill":_vm.activatedSteps ||
              _vm.checkIsStepCompleted(step) ||
              (_vm.checkIsStepActive(step) && _vm.showCompletedSteps)
                ? '#35F9A7'
                : 'white'}})])]):_vm._e(),_c('div',{staticClass:"step-order-wrapper",class:{ 'step-order-wrapper--active': _vm.checkIsStepActive(step) }},[_c('div',{staticClass:"step__order",class:{
            '--active': _vm.checkIsStepActive(step),
            'step__order--mobile': _vm.mobile,
          }},[_vm._v(" "+_vm._s(step.order)+" ")])]),_c('div',{staticClass:"step__title",class:_vm.checkIsStepActive(step) && '--active'},[_vm._v(" "+_vm._s(step.title)+" ")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }