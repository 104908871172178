import { OFFER } from "../Offer/Offer";
import { OFFER_HISTORY } from "../Offer/OfferHistory";
export const ASSET_ENTITY = `
PK
SK
userEmail

offer{
    ${OFFER}
}
offerHistory{
    ${OFFER_HISTORY}
}
Tier1
Tier2
Favorite
viewed
userAssetData {
    latestVisitDate
    totalVisits
}
user{
    firstName
    lastName
    email
    officePhone
    id
    companyName
    companyProfile
    jobTitle
    aum
    address{
        country
        state
        city
        address
        zipCode
    }
    buyerProfile {
        completed
        bankingInfo{
          bankingInfoId
          bankName
          accountNumber
          routingNumber
          lastFour
          token
          category
          federalTaxID
          driversLicense
        }
        purchasingEntity
        purchasingEntityAddress{
            country
            state
            city
            address
            zipCode
        }
        mmgpFirstName
        mmgpLastName
        mmgpAddress {
            country
            state
            city
            address
            zipCode
        }
        pofDocument {
            name
            key
            type
            dateModified
            dataOwnerId
        }
        pofSum
        fundsApprovalDate
        fundsApprovalExpiryDate
        approved
    }
}

`;
