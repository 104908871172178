import { API, graphqlOperation } from "aws-amplify";

export default function subscribeToRoleAssign() {
  const query = `subscription {
      subscribeToRoleAssign {
              id
          }
      }`;

  return API.graphql(graphqlOperation(query));
}
