import { API } from "aws-amplify";
export default (variables) => {
  const query = ` mutation revokeAsset($id: String! $statusComment: String){
    revokeAsset(id:$id statusComment:$statusComment){
        id
        status
        statusComment
    }
    }`;
  return API.graphql({
    query,
    variables,
  });
};
