<template>
  <v-tabs color="secondary" height="64" optional center-active>
    <v-tab to="/profile/settings" :class="{ active: activeTab === 'buyer' }">
      Account Settings
    </v-tab>

    <v-tab to="/profile/edit" :class="{ active: activeTab === 'seller' }">
      Profile Data
    </v-tab>

    <v-tab to="/profile/buyer" :class="{ active: activeTab === 'profile' }">
      Buyer Info
    </v-tab>

    <v-tab to="/profile/seller" :class="{ active: activeTab === 'favorites' }">
      Seller Info
    </v-tab>
    <v-tab to="/profile/billing" :class="{ active: activeTab === 'favorites' }">
      Billing
    </v-tab>
  </v-tabs>
</template>
<script>
export default {
  name: "SubHeaderTabsProfile",
  props: {
    activeTab: {
      type: String,
      default: "",
    },
  },
};
</script>
