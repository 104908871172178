import { API } from "aws-amplify";

export default {
  getPlaidLinkToken() {
    const query = `mutation getPlaidLinkToken {
      getPlaidLinkToken {
        token
      }
    }`;

    return API.graphql({
      query,
    });
  },
  serviceBroker() {
    const query = `query MyQuery {
      service {service_version}
      }`;

    return API.graphql({
      query,
    });
  },
  saveUTMParameters(variables) {
    const query = `mutation saveUTMParameters($utmParameters: utmParametersInput!) {
        saveUTMParameters(utmParameters: $utmParameters) {
          creationDate
        }
      }`;

    return API.graphql({
      query: query,
      variables,
    });
  },
};
