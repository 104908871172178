import { API } from "aws-amplify";
export default (variables) => {
  const query = `query getDemoBDADocumentForSeller {
    getDemoBDADocumentForSeller
    }
  `;

  return API.graphql({
    query,
    variables,
  });
};
