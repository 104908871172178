export default [
  {
    path: "/buyer",
    component: () => import(/* webpackChunkName: "buyer" */ "./pages/Buyer"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "favorites",
        component: () =>
          import(
            /* webpackChunkName: "favorites" */ "./pages/Buyer/BuyerFavorites"
          ),
        redirect: "/favorites",
        meta: {
          requiresAuth: true,
          title: "Favorites - Buyer Dashboard - Xchange.Loans",
        },
      },
      {
        path: "single-asset-offers",
        redirect: "/buy-commercial-real-estate-loans",
        component: () =>
          import(
            /* webpackChunkName: "single-asset-offers" */ "./pages/Buyer/BuyerSingleAssetOffers"
          ),
        meta: {
          requiresAuth: true,
          title: "Single Asset Offers - Buyer Dashboard - Xchange.Loans",
        },
      },
      {
        path: "multi-asset-offers",
        redirect: "/buy-commercial-real-estate-loans",
        component: () =>
          import(
            /* webpackChunkName: "multi-asset-offers" */ "./pages/Buyer/BuyerMultiAssetOffers"
          ),
        meta: {
          requiresAuth: true,
          title: "Multi-Asset Offers - Buyer Dashboard - Xchange.Loans",
        },
      },
    ],
  },
];
