<template>
  <v-dialog
    v-model="dialog"
    :width="isMobile ? '100%' : '720'"
    :attach="attach"
    @keydown.esc="close(false)"
    :persistent="!hasCLose"
  >
    <div class="dialog-wrapper py-6 px-2 px-md-8">
      <v-btn v-if="hasCLose" icon class="close-icon" @click="close(false)">
        <v-icon size="16"> mdi-close </v-icon>
      </v-btn>
      <div class="dialog-title">Add a new Bank</div>
      <div class="separator mt-4 mb-8"></div>
      <div class="data-wrapper mb-15">
        <div class="type mb-4">
          <Clabel class="mr-4">
            You are a
            <span class="req-icon"> * </span>
          </Clabel>
          <v-radio-group
            v-model="licenseTypeModel"
            class="ma-0 pa-0"
            hide-details
            @change="onLicenceTypeChange"
          >
            <v-radio
              label="Business"
              value="Business"
              color="#2969FF"
              class="mr-2 mr-md-7"
            ></v-radio>
            <v-radio
              label="Individual"
              value="Individual"
              color="#2969FF"
            ></v-radio>
          </v-radio-group>
        </div>
        <div class="license">
          <Clabel class="mb-2"> {{ licenceType }} Number </Clabel>
          <v-text-field
            :key="1"
            v-if="licenseTypeModel === 'Business'"
            v-mask="maskNumber"
            v-model="numberModel"
            :error="!valid"
            placeholder="11-2222222"
            height="50px"
            solo
            filled
            outlined
            hide-details
            @input="onNumberInput"
          ></v-text-field>
          <v-text-field
            v-else
            :key="2"
            v-model="numberModel"
            :error="!valid"
            placeholder="G544061739250"
            height="50px"
            solo
            filled
            outlined
            hide-details
            @input="toUppercase"
          ></v-text-field>
        </div>
      </div>
      <div class="buttons-wrapper">
        <div class="d-flex">
          <AppButton
            v-if="hasCLose"
            buttonType="outlined"
            color="#2969FF"
            :width="isMobile ? '86%' : '230px'"
            height="40px"
            buttonText="Cancel"
            @click="close(false)"
          />
          <AppButton
            :disabled="!numberModel || !valid"
            :width="isMobile ? '86%' : '230px'"
            color="#2969FF"
            height="40px"
            rounded
            shaped
            buttonText="Add"
            :class="isMobile ? 'ml-2' : 'ml-8'"
            @click="addData"
          >
          </AppButton>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import AppButton from "@/components/ui/AppButton.vue";
import Clabel from "@/components/Common/CLabel.vue";
import { mask } from "vue-the-mask";
import { isValid } from "driver-license-validator";

export default {
  name: "AddNewBankIndividualDataDialog",
  components: { Clabel, AppButton },
  directives: {
    mask,
  },
  props: {
    attach: {
      type: [String, Boolean],
      default: false,
    },
    hasCLose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      addBankEnabledValue: null,

      valid: true,

      maskNumber: "##-#######",
      licenseTypeModel: "Individual",
      numberModel: "",
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    licenceType() {
      if (this.licenseTypeModel) {
        return this.licenseTypeModel === "Business"
          ? "Federal Tax ID"
          : "Driver’s License";
      }
      return "";
    },
  },
  methods: {
    toUppercase(value) {
      const input = value;
      const uppercasedInput = input.toUpperCase();
      this.numberModel = uppercasedInput;
      this.valid = isValid(this.numberModel);
    },
    isTaxIdValid(value) {
      return value.length === 10;
    },
    onNumberInput() {
      if (this.licenseTypeModel === "Individual") {
        this.valid = isValid(this.numberModel);
      } else {
        this.valid = this.isTaxIdValid(this.numberModel);
      }
    },
    addData() {
      const numberValue =
        this.licenseTypeModel === "Business"
          ? this.numberModel.replace(/-/g, "")
          : this.numberModel;

      const params = {
        category: this.licenseTypeModel,
        [this.licenseTypeModel === "Business"
          ? "federalTaxID"
          : "driversLicense"]: numberValue,
      };

      this.$emit("addData", params);
      this.close(params);
    },
    onLicenceTypeChange() {
      this.numberModel = "";
      this.valid = true;
    },
    open() {
      this.dialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close(value) {
      this.dialog = false;
      this.resolve(value);
      this.licenseTypeModel = "Individual";
      this.numberModel = "";
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-wrapper {
  background: white;
  width: 100%;
  height: 100%;
  min-height: 350px;
  position: relative;
  border-radius: 7px;

  .type {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  .license {
    ::v-deep .text--error {
      border: 1px solid rgba(213, 40, 25, 0.6);
    }
  }

  ::v-deep {
    .c-label,
    .v-radio .v-label {
      color: #001154;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 18px !important;
    }

    .v-text-field--filled > .v-input__control > .v-input__slot,
    .v-text-field--full-width > .v-input__control > .v-input__slot,
    .v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: auto;
      box-shadow: none;
    }
    .v-text-field--outlined fieldset {
      border: none;
      background: #f4f4f4;
    }
    .v-text-field__slot input {
      color: #001154;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      ::placeholder {
        color: #9aabc2;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .error--text {
      border: 1px solid #d52819;
    }
    .error--text .v-text-field__slot input {
      color: #d52819;
    }
  }
}
.close-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}
.dialog-title {
  color: #001154;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.32px;
}
.separator {
  height: 1px;
  border-radius: 1px;
  background: rgba(9, 49, 107, 0.4);
}
.data-wrapper {
  width: 400px;
  text-align: center;
  margin: 0 auto;
}
.buttons-wrapper {
  display: flex;
  justify-content: center;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .data-wrapper {
    width: auto;
    max-width: 315px;
  }
  .buttons-wrapper .d-flex {
    width: 100%;
    justify-content: space-between;
    button {
      width: 40% !important;
    }
  }
}
</style>
