import { API } from "aws-amplify";
export default {
  createLogStream() {
    const mutation = `mutation createLogStream {
            createLogStream{
              logStreamName
            }
          }`;
    return API.graphql({
      query: mutation,
    });
  },
  putLog(message, sequenceToken) {
    const mutation = `mutation putLog($LogEventInput: [LogEventInput]!, $sequenceToken: String) {
            putLogEvents(logEvents: $LogEventInput, sequenceToken: $sequenceToken) {
              nextSequenceToken
              rejectedLogEventsInfo{
                expiredLogEventEndIndex
                tooNewLogEventStartIndex
                tooOldLogEventEndIndex
              }
            }
          }`;

    return API.graphql({
      query: mutation,
      variables: {
        LogEventInput: [{ message, timestamp: Date.now() }],
        sequenceToken,
      },
    });
  },
};
