<template>
  <div
    class="twilio-chat"
    :class="[
      sellerChats.length && 'seller-chat',
      buyerChats.length && 'buyer-chat',
    ]"
  >
    <template v-if="conversationsClient">
      <ConversationWrapper
        :conversationsClient="conversationsClient"
        :subscribedConversations="subscribedConversations"
      ></ConversationWrapper>
    </template>
  </div>
</template>

<script>
import ConversationWrapper from "@/components/Chat/ConversationWrapper.vue";
import subscribeToCreateChat from "@/services/apiCalls/subscriptions/subscribeToCreateChat";
import subscribeToNoticeMessageAdded from "@/services/apiCalls/subscriptions/subscribeToNoticeMessageAdded";
import { mapActions, mapMutations, mapState } from "vuex";
import { Client as ConversationsClient } from "@twilio/conversations";
import AddColorToNewChat from "@/utils/AddColorToNewChat";

export default {
  name: "ChatSection",
  components: {
    ConversationWrapper,
  },
  data() {
    return {
      subscribedConversations: null,
      subscribeToCreateChat: null,
      subscribeToNoticeMessageAdded: null,
      conversationsClient: null,
    };
  },
  computed: {
    ...mapState("Chat", ["sellerChats", "buyerChats", "selectedConversation"]),
    isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },
  },
  async created() {
    this.initChatClient();
  },
  mounted() {
    this.$eventHub.$on("renewChatModule", () => {
      this.initChatClient();
    });

    this.subscribeToCreateChat = subscribeToCreateChat().subscribe({
      next: ({ value }) => {
        console.log("subscribeToCreateChat value - ", value);
        const { subscribeToCreateChat } = value.data;
        const newChat = AddColorToNewChat(subscribeToCreateChat);

        if (newChat.sellerID === this.$store.getters.user.id) {
          this.pushToSellerChats(newChat);
        } else {
          this.pushToBuyerChats(newChat);
        }
      },
      error: (error) => {
        console.error("subscribeToCreateChat Subscription error:", error);
      },
    });
    this.subscribeToNoticeMessageAdded =
      subscribeToNoticeMessageAdded().subscribe({
        next: ({ value }) => {
          console.log("subscribeToNoticeMessageAdded value - ", value);
          const { subscribeToNoticeMessageAdded: newMsg } = value.data;
          const indexChatToUpdate = this.sellerChats.findIndex(
            (chat) => chat.twilioSID === newMsg.chatTwilioSID
          );
          if (indexChatToUpdate !== -1) {
            let updatedSellerChats = this.sellerChats.map((item) => {
              return this.updateChatsWithNewMessage(item, newMsg);
            });

            const chatToMove = updatedSellerChats.splice(
              indexChatToUpdate,
              1
            )[0];
            updatedSellerChats.unshift(chatToMove);

            this.setSellerChats(updatedSellerChats);
          } else {
            const chatIdx = this.buyerChats.findIndex(
              (chat) => chat.twilioSID === newMsg.chatTwilioSID
            );

            const updatedBuyerChats = this.buyerChats.map((item) => {
              return this.updateChatsWithNewMessage(item, newMsg);
            });
            const chatToMove = updatedBuyerChats.splice(chatIdx, 1)[0];
            updatedBuyerChats.unshift(chatToMove);

            this.setBuyerChats(updatedBuyerChats);
          }
        },
        error: (error) => {
          console.error(
            "subscribeToNoticeMessageAdded Subscription error:",
            error
          );
        },
      });
  },
  methods: {
    ...mapMutations("Chat", [
      "pushToSellerChats",
      "pushToBuyerChats",
      "setBuyerChats",
      "setSellerChats",
      "setSelectedChat",
      "setSelectedChatAsset",
      "setSelectedConversation",
      "setSelectedChatType",
      "setFUserId",
      "setSUserId",
      "setUniqName",
      "setChatStatusOnline",
      "setConversationsClient",
    ]),
    ...mapActions("Chat", [
      "getSellerChatListing",
      "getBuyerChatListing",
      "getTwilioUserToken",
    ]),
    async initChatClient() {
      window.conversationsClient = ConversationsClient;
      const token = await this.getTwilioUserToken();
      this.conversationsClient = new ConversationsClient(token);
      this.setConversationsClient(this.conversationsClient);

      this.subscribedConversations =
        await this.conversationsClient.getSubscribedConversations();

      const listItems = this.subscribedConversations.items.filter(
        (item) => item.uniqueName
      );

      const buyerList = await this.getBuyerChatListing({ limit: 100 });
      const combinedArray = buyerList?.map((roleListItem) => {
        const customRoleItem = AddColorToNewChat(roleListItem);

        const matchingItem = listItems.find(
          (twilioListItem) => twilioListItem.sid === customRoleItem.twilioSID
        );
        let customItem = {
          ...customRoleItem,
        };
        if (matchingItem) {
          customItem = this.matchItem(customItem, matchingItem);
        }
        return customItem;
      });
      this.setBuyerChats(combinedArray);

      if (this.$store.getters.isSeller) {
        const sellerList = await this.getSellerChatListing({ limit: 100 });
        const combinedArray = sellerList.map((roleListItem) => {
          const customRoleItem = AddColorToNewChat(roleListItem);

          const matchingItem = listItems.find(
            (twilioListItem) => twilioListItem.sid === customRoleItem.twilioSID
          );

          let customItem = {
            ...customRoleItem,
          };
          if (matchingItem) {
            customItem = this.matchItem(customItem, matchingItem);
          }

          return customItem;
        });
        this.setSellerChats(combinedArray);
      }

      if (this.$route.query.chat) {
        const selectedTwilioChatSID = this.$route.query.chat;

        const selectedConversation = listItems.find(
          (item) => item.sid === selectedTwilioChatSID
        );
        let selectedChat = null;
        if (this.sellerChats.length) {
          selectedChat = this.sellerChats.find(
            (item) => item.twilioSID === selectedTwilioChatSID
          );
          this.setSelectedChatType("seller");
        }
        if (!selectedChat && this.buyerChats.length) {
          selectedChat = this.buyerChats.find(
            (item) => item.twilioSID === selectedTwilioChatSID
          );
          this.setSelectedChatType("buyer");
        }

        const {
          assetInfo: selectedChatAsset,
          assetID,
          sellerID,
          buyerID,
        } = selectedChat;

        this.setSelectedChatAsset(selectedChatAsset);
        this.setSelectedConversation({
          ...selectedChat,
          ...selectedConversation,
        });
        this.setFUserId(sellerID);
        this.setSUserId(buyerID);
        this.setUniqName(`${assetID}#${sellerID}#${buyerID}`);
      }
      this.setChatStatusOnline(true);
    },
    matchItem(item, matchingItem) {
      const customItem = {
        ...item,
        ...matchingItem,
        attributes: { ...matchingItem.attributes },
        TWILIO_LastMessage: matchingItem.lastMessage,
        TWILIO_lastReadMessageIndex: matchingItem.lastReadMessageIndex,
      };

      return customItem;
    },
    updateChatsWithNewMessage(chat, newMsg) {
      if (chat.twilioSID === newMsg.chatTwilioSID) {
        let newChat = { ...chat, ...newMsg };

        // if chat section opened
        if (this.selectedConversation) {
          // if opened same chat
          if (this.selectedConversation.twilioSID === newChat.chatTwilioSID) {
            newChat.TWILIO_lastReadMessageIndex =
              newChat.TWILIO_LastMessage?.index;
          }
        }

        // if chat section closed
        if (!this.selectedConversation) {
          //if no messages
          if (!chat.TWILIO_LastMessage) {
            newChat.TWILIO_lastReadMessageIndex = 0;
            newChat.TWILIO_LastMessage = {};
            newChat.TWILIO_LastMessage.index = 1;
          } else {
            if (!newChat.attributes) {
              newChat.TWILIO_LastMessage.index++;
            }
          }
        }
        return newChat;
      }
      return chat;
    },
  },
  beforeDestroy() {
    if (this.subscribeToCreateChat) {
      this.subscribeToCreateChat.unsubscribe();
    }
    if (this.subscribeToNoticeMessageAdded) {
      this.subscribeToNoticeMessageAdded.unsubscribe();
    }
    this.$eventHub.$off("renewChatModule");
  },
};
</script>

<style lang="scss">
.twilio-chat {
  position: fixed;
  bottom: 0;
  z-index: 100;
  right: 116px;

  button {
    background: #ffffff;
  }

  .v-expansion-panel::before {
    content: none;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .twilio-chat {
    //height: auto;
    width: 80%;
    z-index: 200;
    left: 0;

    .conversation-container {
      width: auto !important;
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  //.twilio-chat {
  //  right: 0;
  //}
}
</style>

<style lang="scss">
@media screen and (max-width: 1100px) {
  .twilio-chat {
    #conversation {
      grid-column: 1;
      position: unset;
      width: 422px;
    }
    .conversation-container {
      width: 422px;
      height: 300px;
    }
    .conversation-wrapper {
      display: grid;
      grid-template-columns: 1fr;
    }
    .chat-list-section {
      grid-column: 1;
      //border-radius: unset;

      &.flat-for-small-opened,
      &.flat-for-small {
        border-radius: unset;
      }
    }
  }
}
@media screen and (min-width: 1100px) and (max-width: 1550px) {
  .twilio-chat {
    //&.seller-chat.buyer-chat {
    //  .chat-list-section:last-child {
    //    border-radius: unset;
    //  }
    //}

    .conversation-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      &.double-chat-list {
        //grid-template-columns: 1fr 1fr;
      }

      #conversation {
        grid-column: 1;
        right: calc(50% + 24px);
      }
    }
    .chat-list-section {
      grid-column: 2;
      &.flat-for-small {
        border-radius: unset;
      }
    }
  }
}
</style>
