import { VALUE_AMOUNT } from "@/services/apiCalls/types/Global/ValueAmount";

export const DEPOSIT = `
value{
${VALUE_AMOUNT}
}
paidDate
depositStatus
depositDecisionDate
declineComment
fundTransactionId
refundTransactionId
`;
