import searchUsers from "./queries/searchUsers";
import addAdmin from "./mutations/addAdmin";
import removeAdmin from "./mutations/removeAdmin";
import assignBuyer from "./mutations/assignBuyer";
import removeBuyer from "./mutations/removeBuyer";
import updateUserProfileContact from "./mutations/updateUserProfileContact";
import updateUserProfileLocation from "./mutations/updateUserProfileLocation";
import updateUserProfileCompany from "./mutations/updateUserProfileCompany";
export default {
  searchUsers,
  addAdmin,
  removeAdmin,
  assignBuyer,
  removeBuyer,
  updateUserProfileContact,
  updateUserProfileLocation,
  updateUserProfileCompany,
};
