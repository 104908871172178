export default {
  namespaced: true,
  state: {
    isOffersLoading: false,
    isUserAssetsLoading: false,
  },
  getters: {},
  mutations: {
    setIsOffersLoading(state, payload) {
      state.isOffersLoading = payload;
    },
    setIsUserAssetsLoading(state, payload) {
      state.isUSerAssetsLoading = payload;
    },
  },
  actions: {},
};
