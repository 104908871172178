import { render, staticRenderFns } from "./VerifyEmailBar.vue?vue&type=template&id=aaf35d1c&scoped=true"
import script from "./VerifyEmailBar.vue?vue&type=script&lang=js"
export * from "./VerifyEmailBar.vue?vue&type=script&lang=js"
import style0 from "./VerifyEmailBar.vue?vue&type=style&index=0&id=aaf35d1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf35d1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
