import { API } from "aws-amplify";

export default ({ id, address }) => {
  const mutation = `mutation adminUpdateUserProfile($id: String!, 
      $address: AddressInput
    ) {
      adminUpdateUserProfile(id: $id, 
        address: $address,
    ) {
        id
        roles
        firstName
        lastName
        officePhone
        address {
          country
          state
          city
          address 
          zipCode
        }
      }
    }`;
  return API.graphql({
    query: mutation,
    variables: {
      id,
      address,
    },
  });
};
