import { API } from "aws-amplify";
export default (variables) => {
  const query = `mutation  deleteValuation($id:String!) {
    deleteValuation(
        id:$id
    ) {
        SK
      }
  }`;
  return API.graphql({
    query,
    variables,
  });
};
