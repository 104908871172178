import Profile from "@/services/apiCalls/profile/profile";
import ErrorParser from "@/utils/ErrorParser";
export default {
  namespaced: true,
  state: {
    users: [],
    user: null,
  },
  getters: {},
  mutations: {},
  actions: {
    async updateUserProfile({ commit }, params) {
      const result = await Profile.updateBasicInfo(params)
        .then(({ data }) => {
          if (data.updateBasicInfo) {
            return true;
          }
          return false;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async updateSellerProfile({ commit }, params) {
      const result = await Profile.updateSellerProfile(params)
        .then(({ data }) => {
          if (data.updateSellerProfile) {
            return data.updateSellerProfile;
          }
          return false;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
  },
};
