import Vue from "vue";
import ErrorParser from "@/utils/ErrorParser";
import BDA from "@/services/apiCalls/bda/bda";

export default {
  namespaced: true,
  state: {
    offers: [],
    buyerOffers: [],
    sellerOffers: [],
    buyerSelectedOffer: null,
    buyerSelectedOffers: [],
    sellerSelectedOffer: null,
    sellerSelectedOffers: [],
    congratulationDialog: false,
    notifications: 0,
    notificationsToggle: false,
  },
  getters: {
    bellOffers(state) {
      if (state.buyerOffers.length) {
        return state.buyerOffers.filter(
          (offer) => !offer.buyerClosingDocumentsAccepted
        );
      } else if (state.sellerOffers.length) {
        return state.sellerOffers.filter(
          (offer) => !offer.sellerClosingDocumentsAccepted
        );
      }
      return [];
    },
  },
  mutations: {
    removeOfferFromWinnerLists(state, value) {
      console.log("removeOfferFromWinnerLists - ", value);
      const idx = state.offers.findIndex(
        (offer) => offer.id === value.offer.id
      );
      state.offers.splice(idx, 1);

      console.log("---- ", idx, state.offers);
      if (value.role === "buyer") {
        console.log("removeOfferFromWinnerLists - 1 ");
        const idx = state.buyerOffers.findIndex(
          (offer) => offer.id === value.offer.id
        );
        state.buyerOffers.splice(idx, 1);
      } else if (value.role === "seller") {
        console.log("removeOfferFromWinnerLists - 1 ");
        const idx = state.sellerOffers.findIndex(
          (offer) => offer.id === value.offer.id
        );
        state.sellerOffers.splice(idx, 1);
      }
    },
    updateBuyerOffer(state, value) {
      const idx = state.buyerOffers.findIndex((offer) => offer.id === value.id);
      if (idx !== -1) {
        Vue.set(state.buyerOffers, idx, {
          ...state.buyerOffers[idx],
          ...value,
        });
      }
    },
    updateSellerOffer(state, value) {
      const idx = state.sellerOffers.findIndex(
        (offer) => offer.id === value.id
      );
      if (idx !== -1) {
        Vue.set(state.sellerOffers, idx, {
          ...state.sellerOffers[idx],
          ...value,
        });
      }
    },
    addRoleOffer(state, value) {
      if (value.role === "buyer") {
        state.buyerOffers.push(value.offer);
      } else {
        state.sellerOffers.push(value.offer);
      }
    },
    setCongratulationDialog(state, value) {
      state.congratulationDialog = value;
    },
    setBuyerOffers(state, value) {
      state.buyerOffers = value;
    },
    setSellerOffers(state, value) {
      state.sellerOffers = value;
    },
    setBuyerSelectedOffer(state, value) {
      state.buyerSelectedOffer = value;
    },
    setBuyerSelectedOffers(state, value) {
      state.buyerSelectedOffers = value;
    },
    setSellerSelectedOffer(state, value) {
      state.sellerSelectedOffer = value;
    },
    setSellerSelectedOffers(state, value) {
      state.sellerSelectedOffers = value;
    },
    addBuyerOffers(state, value) {
      state.buyerOffers = [...state.buyerOffers, ...value].filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
    },
    addSellerOffers(state, value) {
      state.sellerOffers = [...state.sellerOffers, ...value].filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
    },
    setOffers(state, value) {
      state.offers = [...state.offers, ...value].filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
    },
    setNotifications(state, value) {
      state.notifications = value;
      if (state.notifications === 0) {
        state.notificationsToggle = false;
      }
    },
    setNotificationsToggle(state, value) {
      state.notificationsToggle = value;
      if (value) {
        state.notifications = state.offers.length;
      } else {
        state.notifications = 0;
      }
    },
    resetInfo(state) {
      state.offer = null;
    },
  },
  actions: {
    async updateClosingNotificationSeenInOffer({ commit }, params) {
      const result = await BDA.updateClosingNotificationSeenInOffer(params)
        .then(({ data }) => {
          const updatedOffer = data.updateClosingNotificationSeenInOffer;
          if (params.closingNotificationSeenBuyer) {
            commit("updateBuyerOffer", updatedOffer);
          } else {
            commit("updateSellerOffer", updatedOffer);
          }
          return true;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          console.log("updateClosingNotificationSeenInOffer error");
          return false;
        });
      return result;
    },
    async searchClosingInProgressOffersBuyer({ commit }, params) {
      const result = await BDA.searchClosingInProgressOffersBuyer(params)
        .then(({ data }) => {
          if (data.searchClosingInProgressOffersBuyer) {
            const { offers } = data.searchClosingInProgressOffersBuyer;

            commit("setOffers", offers);
            commit("setBuyerOffers", offers);
            commit("setNotifications", offers.length);
            return offers;
          }
          return true;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
    async searchClosingInProgressOffersSeller({ state, commit }, params) {
      const result = await BDA.searchClosingInProgressOffersSeller(params)
        .then(({ data }) => {
          if (data.searchClosingInProgressOffersSeller) {
            const { offers } = data.searchClosingInProgressOffersSeller;

            commit("setOffers", offers);
            commit("setSellerOffers", offers);
            commit("setNotifications", state.notifications + offers.length);
            return offers;
          }
          return true;
        })
        .catch((err) => {
          const errMsg = ErrorParser(err);
          commit(
            "openSnackBar",
            { text: errMsg, color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
  },
};
