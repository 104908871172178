import ErrorParser from "@/utils/ErrorParser";
import Settings from "@/services/apiCalls/admin/settings/settings";
export default {
  namespaced: true,
  state: {
    settings: null,
  },
  getters: {},
  mutations: {
    setSettings(state, val) {
      let settingObj = {};
      for (const key in val) {
        if (val[key] || Number.isInteger(val[key])) {
          settingObj[key] = val[key];
        } else {
          settingObj[key] = false;
        }
      }

      delete settingObj["passwordlessRegistrationEnabled"];
      delete settingObj["autoDeposit"];

      state.settings = settingObj;
    },
  },
  actions: {
    async fetchSettings({ state, commit }, params) {
      const result = await Settings.getSettings()
        .then(({ data }) => {
          if (data.getSettings) {
            const dataSettings = data.getSettings;
            commit("setSettings", dataSettings);
          }
        })
        .catch((err) => {
          commit(
            "openSnackBar",
            { text: ErrorParser(err), color: "error" },
            { root: true }
          );
        });
    },
    async updateSetting({ state, commit }, params) {
      const dataToSend = {
        autoDeposit: state.autoDeposit,
        ...params,
      };

      const result = await Settings.editSettings(dataToSend)
        .then(({ data }) => {
          if (data.editSettings) {
            const dataSettings = data.editSettings;
            commit("setSettings", dataSettings);
            return data.editSettings;
          }
        })
        .catch((err) => {
          commit(
            "openSnackBar",
            { text: ErrorParser(err), color: "error" },
            { root: true }
          );
          return false;
        });
      return result;
    },
  },
};
