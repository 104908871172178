// DynamoDB can't accept blank strings, so we need to replace them with null
function normalizeForm(form) {
  let normalizedForm = {};
  for (let key of Object.keys(form)) {
    if (form[key] === "") {
      normalizedForm[key] = null;
    } else if (
      form[key] !== null &&
      form[key] &&
      form[key].constructor === Object
    ) {
      normalizedForm[key] = normalizeForm(form[key]);
    } else {
      normalizedForm[key] = form[key];
    }
  }
  return normalizedForm;
}

export { normalizeForm };
