import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default async (assetInfo) => {
  console.log("assetInfo1 - ", assetInfo);
  const mutation = `mutation editAssetOnNext(
        $currentStep: String,
        $id: String!,
        $contacts: [ContactInput]!,
        $contactsDisclosed:Boolean!,
      ) {
        editAsset(
          currentStep: $currentStep,
          id: $id,
          contacts: $contacts,
          contactsDisclosed: $contactsDisclosed,
        ) {
         ${ASSET}
        }
      }`;
  let variables = assetInfo;
  console.log("assetInfo2 - ", assetInfo);
  return API.graphql({
    query: mutation,
    variables,
  });
};
