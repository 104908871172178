export default function validateName(name) {
  const allowedRegex = /^[a-zA-Z0-9$,'()\-\./&* ]*$/;
  const disallowedRegex = /[!#:_\|+\?]/;

  if (disallowedRegex.test(name)) {
    return false;
  }

  return allowedRegex.test(name);
}
