import { API, graphqlOperation } from "aws-amplify";

export default function subscribeToRoleChange() {
  const query = `subscription {
        subscribeToRoleChange {
            id
            status
            creator
            type
        }
    }`;

  return API.graphql(graphqlOperation(query));
}
