import { guardAdmin } from "./routerGuards/guardAdmin";

export default [
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "./pages/Admin/Admin.vue"),
    meta: {
      requiresAuth: true,
      title: "Admin Dashboard - Xchange.Loans",
    },
    children: [
      {
        path: "settings",
        component: () => import("./pages/Admin/AdminSettings.vue"),
        beforeEnter: guardAdmin,
        meta: {
          requiresAuth: true,
          title: "Settings - Xchange.Loans",
        },
      },
      {
        path: "users",
        component: () => import("./pages/Admin/AdminUsers.vue"),
        beforeEnter: guardAdmin,
        meta: {
          requiresAuth: true,
          title: "Users - Xchange.Loans",
        },
      },
      {
        path: "asset-approval",
        name: "AdminAssetsDashboard",
        component: () => import("./pages/Admin/AdminAssets.vue"),
        beforeEnter: guardAdmin,
        meta: {
          requiresAuth: true,
          title: "Asset Approval - Xchange.Loans",
        },
      },
      {
        path: "asset-approval/:id",
        component: () => import("./pages/Admin/AdminAssetApproval"),
        beforeEnter: guardAdmin,
        meta: {
          requiresAuth: true,
          title: "Asset Approval - Xchange.Loans",
        },
      },
      {
        path: "buyers",
        component: () => import("./pages/Admin/AdminBuyers.vue"),
        beforeEnter: guardAdmin,
        redirect: "/admin/buyers/requests",
        meta: {
          requiresAuth: true,
          title: "Buyer Approval - Xchange.Loans",
        },
        children: [
          {
            component: () => import("./pages/Admin/AdminBuyerRequests.vue"),
            path: "requests",
          },
          {
            component: () => import("./pages/Admin/AdminBuyersList.vue"),
            path: "all",
          },
        ],
      },
      {
        path: "buyers/:id",
        component: () => import("./pages/Admin/AdminBuyersOld"),
        beforeEnter: guardAdmin,
        meta: {
          requiresAuth: true,
          title: "Buyer Approval - Xchange.Loans",
        },
      },
    ],
  },
];
