import { API } from "aws-amplify";

export default {
  createBuyerRequest(dataToSend) {
    const mutation = `mutation createBuyerRequest(
              $documents: [DocumentInput]
          ) {
          createBuyerRequest(
              documents: $documents
          ) {
            id
            documents {
              type
              key
              name
            }
          }
        }`;

    return API.graphql({
      query: mutation,
      variables: {
        documents: dataToSend,
      },
    });
  },
  approveRequest(id, data) {
    const mutation = `mutation approveRequest($id: String!, $data: ExtraProfileData) {
            approveRoleRequest(id: $id, data: $data) {
              id
              status
              type
              creator
            }
          }`;

    return API.graphql({
      query: mutation,
      variables: {
        id,
        data: {
          buyerProfile: {
            pofSum: data.pofSum || 0,
            approved: data.approved,
            fundsApprovalDate:
              data.fundsApprovalDate &&
              new Date(data.fundsApprovalDate).toISOString(),
          },
        },
      },
    });
  },
  listRoleRequests(nextToken) {
    const query = `query listRoleRequests($nextToken: String ) {
            listRoleRequests(type: ApprovedBuyers,  limit: "12", nextToken: $nextToken , status: Draft) {
               nextToken
               requests {
                 id
                 status
                 profile {
                   email
                   firstName
                   lastName
                   officePhone
                   companyName
                   currentStrategy
                   companyProfile
                   cellPhone
                   jobTitle
                   address {
                    address
                    country
                    state
                    city
                    zipCode
                   }
                   sellerProfile {
                    avatar
                   }
                    buyerProfile {
                      assetType
                      approvedBy{
                        firstName
                        lastName 
                        email     
                      }
                      collateralPreferences
                      loanStatus
                      propertyTypes
                      approved
                      pofSum
                      lienPositions
                      purchasingEntity
                      locations
	                    purchasingEntityAddress {
                        address
                        country
                    state
                    city
                    zipCode
                      }
	                    mmgpFirstName
	                    mmgpLastName
	                    mmgpAddress {
                        address
                      }
                      pofDocument {
                        type
	                      key
                        dataOwnerId
	                      name
	                      dateModified
                      }
                    fundsApprovalDate
                  }
                
                
                }
               }
             }
           }`;

    return API.graphql({
      query,
      variables: {
        nextToken: nextToken || null,
      },
    });
  },
  searchRequests(variables) {
    const query = `query searchRequests($offset:Int, $size:Int, $email:String , $status: [String]) {
      searchRequests(offset :$offset,size:$size,email:$email, status:$status) {
               total
               requests {
                 id
                 status
                 profile {
                   email
                   firstName
                   lastName
                   officePhone
                   companyName
                   currentStrategy
                   companyProfile
                   cellPhone
                   jobTitle
                   address {
                    address
                    country
                    state
                    city
                    zipCode
                   }
                   sellerProfile {
                    avatar
                   }
                    buyerProfile {
                    completed
                    purchasingEntity
                    purchasingEntityAddress{
                        country
                        state
                        city
                        address
                        zipCode
                    }
                    mmgpFirstName
                    mmgpLastName
                    mmgpAddress {
                        country
                        state
                        city
                        address
                        zipCode
                    }
                    pofDocument {
                        name
                        key
                        type
                        dateModified
                        dataOwnerId
                    }
                    pofSum
                    fundsApprovalDate
                    fundsApprovalExpiryDate
                    approved
                     
	                  
                    
                  }
                
                
                }
               }
             }
           }`;

    return API.graphql({
      query,
      variables,
    });
  },
  countRequests(variables) {
    const query = `query countRequests($offset:Int, $size:Int, $email:String , $status:[String]) {
      searchRequests(offset :$offset,size:$size,email:$email , status :$status) {
               total
                
               }
             
           }`;

    return API.graphql({
      query,
      variables,
    });
  },
  declineRequest(id) {
    const mutation = `mutation decline($id: String!) {
            declineRoleRequest(id: $id) {
              id
              creator
              type
            }
          }`;

    return API.graphql({
      query: mutation,
      variables: {
        id,
      },
    });
  },
  countBuyerRequests() {
    const query = `query countBuyerRequests {
          listRoleRequests(type: ApprovedBuyers, status:Draft) {
              count
           }
         }`;

    return API.graphql({
      query,
      variables: {},
    });
  },
  editPOF(variables) {
    const query = `mutation editPOF($id: String, $pofSum: Float, $fundsApprovalDate: AWSDateTime) {
      editPOF(id: $id, pofSum: $pofSum, fundsApprovalDate: $fundsApprovalDate) {
        id
        firstName
        lastName
        }
     }`;

    return API.graphql({
      query,
      variables,
    });
  },

  searchBuyers(variables) {
    const query = `query searchUsers(
      $query: String,
      $roles: [UserRole],
      $size: Int,
      $offset: Int,
      ) {
        searchUsers( 
          roles:$roles,
          query:$query,
          roles:$roles,
          size:$size,
          offset:$offset
           ){
            profiles{
              id
              firstName
              lastName
              companyName
              email
              faxNumber
              officePhone
              cellPhone
              jobTitle
              address{
                country
                state
                city
                address 
                zipCode
              }
              buyerProfile {
                approvedBy{
                  firstName
                  lastName 
                  email     
                }
                completed
                purchasingEntity
                purchasingEntityAddress{
                    country
                    state
                    city
                    address
                    zipCode
                }
                mmgpFirstName
                mmgpLastName
                mmgpAddress {
                    country
                    state
                    city
                    address
                    zipCode
                }
                pofDocument {
                    name
                    key
                    type
                    dateModified
                    dataOwnerId
                }
                pofSum
                fundsApprovalDate
                fundsApprovalExpiryDate
                approved    
              }
            }
          }
            }`;
    return API.graphql({
      query,
      variables: {
        roles: ["RestrictedBuyers", "ApprovedBuyers"],
        ...variables,
      },
    });
  },
};
