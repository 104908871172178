<template>
  <v-row
    no-gutters
    class="item pa-4 py-md-6 px-md-10"
    @click="$emit('selectBank')"
  >
    <v-col cols="12" sm="6" md="4" class="mb-4 mb-md-0">
      <div class="item-label mb-md-2">Bank Name</div>
      <div class="item-value">
        {{ item.bankName }}
      </div>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-4 mb-md-0">
      <div class="item-label mb-md-2">Routing Number</div>
      <div class="item-value">
        <!--        {{ item.routingNumber }}-->
        *********
      </div>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <div class="item-label mb-md-2">Account Number</div>
      <div class="item-value">
        {{ getAccountNumber }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SelectBankModalItem",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getAccountNumber() {
      if (this.item.lastFour) {
        return `*************${this.item.lastFour}`;
      }
      if (this.item.accountNumber) {
        const accNumberString = this.item.accountNumber.toString();
        if (accNumberString.length > 4) {
          return (
            "*".repeat(accNumberString.length - 4) + accNumberString.slice(-4)
          );
        }
      }
      return this.item.accountNumber;
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  border-radius: 7px;
  box-shadow: 2px 2px 15px 0px rgba(173, 173, 173, 0.25);
}
.item-label {
  color: #001154;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.item-value {
  color: #001154;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .item-label {
    font-size: 12px;
  }
}
</style>
