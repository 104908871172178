import { API } from "aws-amplify";
import {
  PERFORMING_LOAN_DETAILS,
  NON_PERFORMING_LOAN_DETAILS,
  SUB_PERFORMING_LOAN_DETAILS,
  HOTEL_NON_PERFORMING_LOAN_DETAILS,
  SPECIFICS,
} from "../types/types.js";
export default (variables) => {
  const query = `mutation editValuation($id:String! $userInput:CalculatorUserDataInput ) {
    editValuation   (
        id:$id
        userInput:$userInput
    ) {
        SK
        PK
        calculatorResult{
          percentageLowValueRangeToUpb
          percentageLowValueRangeToUpb
          highValueRange
          percentageHighValueRangeToUpb
          noteRateFloorCalculated
          chartData
          lowSideSavings
          lowSideTime
          highSideSavings
          highSideTime
          foreclosureProcess
          lowValueRange
          receivershipProcess
          biddingProcess
          seasoning
          remainingTerm
          ltv
          XLLoanClass
          marketTier
          recommendation
          id
        }
        userInput{
          address{
            address
            additional{
              name
              country
              countryShortName
              administrative_area_level_1
              county
              administrative_area_level_1ShortName
              lng
              lat
            }

          }
          propertyType
         ${PERFORMING_LOAN_DETAILS}
         ${NON_PERFORMING_LOAN_DETAILS}
         ${SUB_PERFORMING_LOAN_DETAILS}
         ${HOTEL_NON_PERFORMING_LOAN_DETAILS}
         ${SPECIFICS}

      }
      }
  }`;
  return API.graphql({
    query,
    variables,
  });
};
