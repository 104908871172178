var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"app-bar px-4 px-md-10",class:{
    'app-bar--transparent': _vm.isBackgroundTransparent,
    'app-bar--active': _vm.isDrawerActive,
    'default-background': _vm.routeName,
  },attrs:{"height":"auto","flat":"","app":"","dark":""}},[(_vm.isSmAndDownActive)?_c('div',{staticClass:"111 header-wrapper"},[_c('app-navigation-drawer-new'),_c('div',{staticClass:"mobile-logo-wrapper",on:{"click":_vm.onLogoClick}},[_c('img',{staticClass:"mobile-logo",attrs:{"src":require("@/assets/img/xchange-loans-second-logo.svg"),"alt":"Xchange.Loans - the online marketplace for non-performing loans"}})]),(!_vm.isMdAndUpActive)?_c('div',{staticClass:"dashboard-icon-wrapper-mobile",on:{"click":_vm.onUserAvatarClick}},[(_vm.userAvatar)?_c('img',{staticClass:"app-bar__dropdown-wrapper-image",attrs:{"src":_vm.userAvatar,"alt":"user-avatar"}}):_c('img',{staticClass:"app-bar__dropdown-wrapper-image app-bar__dropdown-wrapper-image--white",attrs:{"src":require('@/assets/img/profileSellerEditDialog/profileAvatar.svg'),"alt":"profile-avatar"}})]):_vm._e()],1):_vm._e(),(_vm.isMdAndUpActive)?[_c('v-row',{staticClass:"22222 no-gutters py-2"},[_c('v-col',{staticClass:"d-flex align-center left-menu",attrs:{"cols":"4"}},[_c('navbar')],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"app-bar__logo",attrs:{"src":require("@/assets/img/xchange-loans-second-logo.svg"),"alt":"Xchange.Loans - the online marketplace for non-performing loans","contain":""}})],1)],1),(_vm.isUserInformationExist && _vm.isAuthUser)?_c('v-col',{staticClass:"d-flex right-column app-bar__actions",attrs:{"cols":"4"}},[_c('NavbarRight'),_c('div',{staticClass:"logged-in-menu-section"},[_c('AppBarChat',{attrs:{"disabled":!_vm.nonEmptyChatList.length}}),_c('AppBarDashboard'),_c('AppNavFavorites'),_c('div',{staticClass:"app-bar__dropdown"},[_c('v-menu',{attrs:{"offset-y":"","nudge-top":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({staticClass:"app-bar__dropdown-username cursor-pointer",class:{
                    'app-bar__dropdown-username--active':
                      _vm.active || _vm.isProfilePage,
                  },style:({
                    height: _vm.isProfilePage ? '113%' : '100%',
                  })},on),[(_vm.userAvatar)?_c('div',{staticClass:"app-bar__dropdown-wrapper"},[_c('img',{staticClass:"app-bar__dropdown-wrapper-image",attrs:{"src":_vm.userAvatar,"alt":"user-avatar"}})]):_c('div',{staticClass:"app-bar__dropdown-wrapper"},[_c('span',{staticClass:"cursor-pointer rounded-username"},[_vm._v(" "+_vm._s(_vm.getUsername)+" ")])])])]}}],null,false,3835333807),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('ul',{staticClass:"app-bar__dropdown-list"},[_vm._l((_vm.getMenuList),function(item){return _c('li',{key:item.to,staticClass:"app-bar__dropdown-list-item"},[_c('router-link',{attrs:{"to":item.to}},[_c('div',[_c('img',{attrs:{"src":item.image,"alt":item.name}})]),_c('div',{staticClass:"app-bar__dropdown-list-item-title"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)}),_c('li',{staticClass:"app-bar__dropdown-list-item"},[_c('div',{staticClass:"app-bar__dropdown-list-item-logout",on:{"click":_vm.onLogOut}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/icons/appBar/logout.svg")}})]),_c('div',{staticClass:"app-bar__dropdown-list-item-title"},[_vm._v(" Logout ")])])])],2)])],1)],1)],1):_c('v-col',{staticClass:"d-flex right-column",attrs:{"cols":"4"}},[_c('NavbarRight'),_c('v-btn',{staticClass:"button-second c-button",staticStyle:{"background":"transparent"},attrs:{"width":"112px","height":"30px","rounded":"","shaped":""},on:{"click":_vm.onOpenLoginDialog}},[_c('div',{staticClass:"white--text button-text"},[_vm._v("Login/Register")])])],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }