<template>
  <v-app-bar
    class="app-bar px-4 px-md-10"
    height="auto"
    flat
    app
    dark
    :class="{
      'app-bar--transparent': isBackgroundTransparent,
      'app-bar--active': isDrawerActive,
      'default-background': routeName,
    }"
  >
    <div v-if="isSmAndDownActive" class="111 header-wrapper">
      <app-navigation-drawer-new />

      <div class="mobile-logo-wrapper" @click="onLogoClick">
        <img
          src="@/assets/img/xchange-loans-second-logo.svg"
          alt="Xchange.Loans - the online marketplace for non-performing loans"
          class="mobile-logo"
        />
      </div>

      <div
        v-if="!isMdAndUpActive"
        class="dashboard-icon-wrapper-mobile"
        @click="onUserAvatarClick"
      >
        <!-- <app-bar-dashboard />  -->

        <img
          v-if="userAvatar"
          :src="userAvatar"
          class="app-bar__dropdown-wrapper-image"
          alt="user-avatar"
        />
        <img
          v-else
          class="app-bar__dropdown-wrapper-image app-bar__dropdown-wrapper-image--white"
          :src="
            require('@/assets/img/profileSellerEditDialog/profileAvatar.svg')
          "
          alt="profile-avatar"
        />
      </div>
    </div>

    <template v-if="isMdAndUpActive">
      <v-row class="22222 no-gutters py-2">
        <v-col cols="4" class="d-flex align-center left-menu">
          <navbar />
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-center">
          <router-link to="/">
            <v-img
              src="@/assets/img/xchange-loans-second-logo.svg"
              alt="Xchange.Loans - the online marketplace for non-performing loans"
              contain
              class="app-bar__logo"
            />
          </router-link>
        </v-col>
        <v-col
          v-if="isUserInformationExist && isAuthUser"
          cols="4"
          class="d-flex right-column app-bar__actions"
        >
          <NavbarRight></NavbarRight>
          <div class="logged-in-menu-section">
            <AppBarChat :disabled="!nonEmptyChatList.length" />
            <AppBarDashboard />
            <AppNavFavorites />
            <div class="app-bar__dropdown">
              <v-menu offset-y v-model="active" nudge-top="12">
                <template #activator="{ on }">
                  <div
                    v-on="on"
                    class="app-bar__dropdown-username cursor-pointer"
                    :class="{
                      'app-bar__dropdown-username--active':
                        active || isProfilePage,
                    }"
                    :style="{
                      height: isProfilePage ? '113%' : '100%',
                    }"
                  >
                    <div v-if="userAvatar" class="app-bar__dropdown-wrapper">
                      <img
                        :src="userAvatar"
                        class="app-bar__dropdown-wrapper-image"
                        alt="user-avatar"
                      />
                    </div>
                    <div v-else class="app-bar__dropdown-wrapper">
                      <span class="cursor-pointer rounded-username">
                        {{ getUsername }}
                      </span>
                    </div>
                  </div>
                </template>

                <ul class="app-bar__dropdown-list">
                  <li
                    v-for="item in getMenuList"
                    :key="item.to"
                    class="app-bar__dropdown-list-item"
                  >
                    <router-link :to="item.to">
                      <div>
                        <img :src="item.image" :alt="item.name" />
                      </div>
                      <div class="app-bar__dropdown-list-item-title">
                        {{ item.name }}
                      </div>
                    </router-link>
                  </li>

                  <li class="app-bar__dropdown-list-item">
                    <div
                      class="app-bar__dropdown-list-item-logout"
                      @click="onLogOut"
                    >
                      <div>
                        <img src="@/assets/img/icons/appBar/logout.svg" />
                      </div>
                      <div class="app-bar__dropdown-list-item-title">
                        Logout
                      </div>
                    </div>
                  </li>
                </ul>
              </v-menu>
            </div>
          </div>
        </v-col>
        <v-col v-else cols="4" class="d-flex right-column">
          <NavbarRight></NavbarRight>
          <v-btn
            @click="onOpenLoginDialog"
            style="background: transparent"
            width="112px"
            height="30px"
            class="button-second c-button"
            rounded
            shaped
          >
            <div class="white--text button-text">Login/Register</div>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-app-bar>
</template>

<script>
import showRolesAccessMixin from "@/mixins/showRolesAccessMixin";
import globalLayoutDisplayalMixin from "@/mixins/globalLayoutDisplayalMixin";
import AppNavigationDrawerNew from "@/components/layout/AppNavigationDrawerNew";
import AppNavFavorites from "@/components/layout/AppNavFavorites.vue";
import AppBarDashboard from "@/components/AppBar/AppBarDashboard.vue";
import AppBarChat from "@/components/layout/AppBarChat.vue";
import Navbar from "@/components/Navs/Navbar.vue";
import NavbarRight from "@/components/Navs/NavbarRight.vue";
import { Storage } from "aws-amplify";
import { mapGetters, mapState } from "vuex";
export default {
  name: "AppBar",
  components: {
    AppNavigationDrawerNew,
    Navbar,
    NavbarRight,
    AppNavFavorites,
    AppBarDashboard,
    AppBarChat,
  },
  mixins: [showRolesAccessMixin, globalLayoutDisplayalMixin],
  watch: {
    async "$store.getters.user"() {
      await this.setUserAvatar();
    },
    isAuthUser() {
      this.userAvatar = null;
    },
  },
  props: {
    backgroundColor: {
      getters: {
        isLogin(state) {
          return state.auth.isAuthenticated;
        },
      },
      type: String,
      default: "#374252",
    },
    theme: {
      type: String,
      default: "light",
    },
    logo: {
      type: Boolean,
      default: false,
    },
    elevated: {
      type: Boolean,
      default: false,
    },
    logoShrink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shown: false,
      active: false,
      offsetTop: null,
      dialogLogin: false,
      dialogRegister: false,
      profileSellerEditStep: 1,
      userAvatar: null,

      tooltip: {
        show: false,
      },
      branchEnvManager: "",
    };
  },
  computed: {
    ...mapState("Chat", ["sellerChats", "buyerChats"]),
    ...mapGetters("Chat", ["nonEmptyChatList"]),
    routeName() {
      const routeName = this.$route.name;
      switch (routeName) {
        case "HomePage":
        case "Self service":
        case "Full service":
        case "Faq":
        case "AboutUs":
          return true;
        default:
          return false;
      }
    },
    checkEnvManager() {
      return this.branchEnvManager.includes("enabled=true");
    },
    isDrawerActive() {
      return this.$store.state.drawer;
    },
    isAuthUser() {
      return this.$store.state.auth.isAuthenticated;
    },
    isUserSeller() {
      return this.$store.getters.isSeller;
    },
    isUserAdmin() {
      return this.$store.getters.isAdmin;
    },
    isAssetPage() {
      return this.$route.name === "AssetPage";
    },
    isUserFilled() {
      return this.$store.state.user.filled.user;
    },
    isBackgroundTransparent() {
      return [
        "Sell",
        "ChoosePlan",
        "HomePage",
        "PrivacyPolicy",
        "Terms",
        "Self service",
        "Faq",
        "Full service",
        "AboutUs",
      ].includes(this.$route.name);
    },
    isProfilePage() {
      return this.$route.path.includes("profile");
    },
    isMdAndUpActive() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isSmAndDownActive() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isUserInformationExist() {
      return Object.keys(this.getUser).some((field) =>
        Boolean(this.getUser[field])
      );
    },
    getUser() {
      const {
        firstName = "",
        lastName = "",
        sellerProfile = {
          avatar: "",
        },
      } = this.$store.getters.user ?? {};
      const { avatar = "" } = sellerProfile || {};

      return {
        firstName,
        lastName,
        sellerProfile: avatar,
        email: this.getUserEmail,
      };
    },
    getUserEmail() {
      return this.$store.getters.userMainInfo || "";
    },
    getUsername() {
      if (this.getUser.firstName && this.getUser.lastName) {
        return `${this.getUser.firstName.at(0)}${this.getUser.lastName.at(0)}`;
      }

      return this.getUser.email.at(0);
    },

    getMenuList() {
      return [
        {
          to: "/buyer/favorites",
          image: require("@/assets/img/icons/appBar/favorites.svg"),
          name: "Favorites",
          rule: this.isUserAdmin,
        },
        {
          to: "/profile/edit",
          image: require("@/assets/img/icons/appBar/profile.svg"),
          name: "Profile",
          rule: true,
        },
        {
          to: "/admin/asset-approval",
          image: require("@/assets/img/icons/appBar/admin.svg"),
          name: "Admin Assets",
          rule: this.isUserAdmin,
        },
        {
          to: "/admin/buyers/requests",
          image: require("@/assets/img/icons/appBar/admin.svg"),
          name: "Admin Buyers",
          rule: this.isUserAdmin,
        },
        {
          to: "/admin/users",
          image: require("@/assets/img/icons/appBar/admin.svg"),
          name: "Admin Users",
          rule: this.isUserAdmin,
        },
        {
          to: "/admin/settings",
          image: require("@/assets/img/icons/appBar/admin.svg"),
          name: "Admin Settings",
          rule: this.isUserAdmin,
        },
      ].filter((menuItem) => menuItem.rule);
    },
  },
  methods: {
    async setUserAvatar() {
      if (this.isAuthUser && this.getUser.sellerProfile) {
        this.userAvatar = await Storage.get(this.getUser.sellerProfile);
      }
    },
    onLogoClick() {
      this.$router.push("/");
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    onOpenLoginDialog() {
      this.$root.openRegisterDialog({});
    },
    onLogOut() {
      this.$store.dispatch("logOut");
      this.active = false;
    },
    onUserAvatarClick() {
      if (this.isAuthUser) {
        this.$store.commit("setDrawer", true);
        this.$store.commit("setDrawerIsProfileLevelOpened", true);
        this.$store.commit("setIsDrawerOpenedFromProfile", true);
      } else {
        this.$root.openRegisterDialog({});
      }
    },
  },
  created() {
    if (this.$store.getters.user) {
      this.$store
        .dispatch("getMarketplaceBuildBranch")
        .then((resp) => {
          this.branchEnvManager = resp;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }

    this.$root.$on("show-login-dialog", () => {
      this.dialogLogin = true;
    });
    this.setUserAvatar();
  },
};
</script>

<style lang="scss" scoped>
.rounded-username {
  width: 37px;
  height: 37px;
  background: rgb(241, 245, 254);
  color: #001154;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-bar {
  height: 59px;
  display: flex;
  align-items: center;
  background: none !important;
  z-index: 9 !important;
  &.v-app-bar--is-scrolled {
    background: radial-gradient(
      265.07% 13732.15% at 87.99% -74.04%,
      #00116a 0%,
      #002c91 100%
    ) !important;
  }
  ::v-deep .v-toolbar__content:after {
    content: none;
  }
  .right-column {
    justify-content: end;
  }
  .header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .app-navigation-drawer {
    display: flex;
    align-items: center;
  }
  .mobile-logo-wrapper {
    display: flex;
    align-items: center;
    .mobile-logo {
      width: 188px;
      height: auto;
    }
  }
  .dashboard-icon-wrapper-mobile {
    display: flex;
    background: #fefefe;
    border-radius: 50%;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .app-bar {
    transition: all 0.25s;
    background: radial-gradient(
      265.07% 13732.15% at 87.99% -74.04%,
      #00116a 0%,
      #002c91 100%
    ) !important;
    .logged-in-menu-section {
      display: flex;
      align-items: center;
    }
    &__logo {
      width: 190px;
      height: 37px;
      margin: 0 auto;
    }
    &__actions {
      align-items: center;
      display: flex;
      margin: 0;
    }
    &__dropdown {
      //min-width: 135px;
      margin-left: 8px;
      &-username {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: end;
        //padding-right: 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 1px;
          height: 37px;
          background: #ffffff;
          opacity: 0.2;
          border-radius: 1.33333px;
        }
        &--active {
          height: 113%;
        }
      }
      &-wrapper {
        display: flex;
        padding-left: 16px;
        &-image {
          height: 37px;
          width: 37px;
          border-radius: 100px;
          border: 2px solid #c4c4c4;
          position: relative;
          &--white {
            background: white;
          }
        }
      }
    }
    &__dropdown-list {
      background-color: #fff;
      font-size: 16px;
      font-weight: 300;
      line-height: 31px;
      list-style: none;
      margin: 16px 0 0;
      width: 100%;
      min-width: 150px;
      padding: 0;
      box-shadow: 0 20px 30px 0 rgba(223, 223, 223, 0.3);
      &-item {
        a {
          display: flex;
          column-gap: 12px;
          align-items: center;
        }
        &-logout {
          display: flex;
          column-gap: 12px;
          align-items: center;
          cursor: pointer;
        }
        &-title {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 32, 80, 0.82);
        }
        padding-left: 12px;
        padding-top: 8px;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 8px;
      }
    }
    &--transparent {
      background: transparent !important;
      &.v-app-bar--is-scrolled {
        background-color: radial-gradient(
          265.07% 13732.15% at 87.99% -74.04%,
          #00116a 0%,
          #002c91 100%
        ) !important;
        background: radial-gradient(
          265.07% 13732.15% at 87.99% -74.04%,
          #00116a 0%,
          #002c91 100%
        ) !important;
      }
    }
    .right-column {
      column-gap: 40px;
      align-items: center;
    }
  }
  // Other styles
  .v-menu__content {
    box-shadow: none !important;
    contain: initial;
    overflow: initial;
  }
  .link-btn {
    .linkProfilBlank {
      &:hover {
        background-color: map-get($colors, "primary");
        color: white;
      }
    }
  }
  .link-btn-item {
    cursor: pointer;
    padding-left: 24px;
    &:hover {
      background-color: map-get($colors, "primary");
      color: white;
    }
  }
  .logo {
    position: relative;
    top: 5px;
  }
  ::v-deep .router-link-active {
    &.header-link {
      position: relative;
      &::after {
        position: absolute;
        display: block;
        content: "";
        height: 1px;
        bottom: -2px;
        width: 100%;
        background: white;
      }
    }
  }
  .link-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 32, 80, 0.82);
  }
  ::v-deep .v-btn {
    min-height: unset !important;
    min-width: unset !important;
  }
  ::v-deep .v-btn::before {
    background-color: transparent !important;
  }
  .button-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    color: #ffffff;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .app-bar {
    z-index: 200 !important;
    background: radial-gradient(
      265.07% 13732.15% at 87.99% -74.04%,
      #00116a 0%,
      #002c91 100%
    ) !important;
    &.default-background {
      background: none !important;

      &.v-app-bar--is-scrolled {
        background: radial-gradient(
          265.07% 13732.15% at 87.99% -74.04%,
          #00116a 0%,
          #002c91 100%
        ) !important;
      }
    }
    &--active {
      z-index: 2000 !important;
    }
  }
  ::v-deep {
    .v-toolbar__content {
      padding: 0;
    }
  }
  .dashboard-icon-wrapper {
    position: relative;
    left: 27px;
  }
  .app-bar__dropdown-wrapper-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .app-bar {
    ::v-deep .v-toolbar__content:after {
      content: "";
    }
  }
}
@media #{map-get($display-breakpoints, 'xl-only')} {
  .app-bar {
    height: 73px;
    .left-menu {
      justify-content: start;
    }
    .right-column {
      justify-content: end;
      column-gap: 56px;
    }
    .app-bar__logo {
      width: 315px;
      height: 62px;
    }
  }
}
header {
  &.v-app-bar--is-scrolled {
    ::v-deep {
      .v-toolbar__content {
        &:after {
          display: none !important;
        }
      }
    }
  }
  &:not(.app-bar--transparent) {
    ::v-deep {
      .v-toolbar__content {
        &:after {
          display: none !important;
        }
      }
    }
  }
}
</style>
