<template>
  <v-btn
    v-if="getButtonType !== 'outlined'"
    class="button-second c-button"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="button-text" v-if="!isDefaultSlotPassed">
      {{ $attrs.buttonText }}
    </div>
    <slot v-else />
  </v-btn>
  <v-btn
    v-else
    v-on="$listeners"
    color="cyanPrimary"
    class="oulined-button"
    v-bind="$attrs"
    :outlined="true"
  >
    <div class="outlined-button-text" v-if="!isDefaultSlotPassed">
      {{ $attrs.buttonText }}
    </div>
    <slot v-else />
  </v-btn>
</template>

<script>
/*
Props:
@buttonText

@buttonType
there are 2 types
default and outlined
pass buttonType outlined to get oulined button

*/
export default {
  name: "AppButton",
  computed: {
    getButtonType() {
      return this.$attrs.buttonType;
    },
    isDefaultSlotPassed() {
      return !!this.$slots.default;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .v-size--default {
    min-height: unset !important;
  }
}
.c-button {
  min-height: unset !important;
}
.button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
}
.outlined-button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  text-align: center;

  color: #2969ff;
}
.oulined-button {
  min-height: unset !important;
  border-radius: 7px;
  &.v-btn--disabled {
    .outlined-button-text {
      font-style: normal;

      color: #9aabc2 !important;
    }
  }
}
</style>
