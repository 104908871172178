<template>
  <div>
    <v-text-field
      v-model="modelValue"
      v-number="number"
      @input="onChange"
      :placeholder="placeholder"
      :error-messages="errorMessages"
      inputmode="decimal"
    />
  </div>
</template>

<script>
export default {
  name: "AppDigitInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    returnFloat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: "",

      number: {
        decimal: ".",
        separator: ",",
        prefix: "",
        suffix: "",
        precision: 2,
        nullValue: "",
        masked: true,
        reverseFill: false,
        prefill: false,
        minimumFractionDigits: 2,
      },
    };
  },
  computed: {
    modelValue: {
      set(v) {
        this.onChange(v);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    onChange(val) {
      this.$emit("input", val);
    },
  },
  // directives: {
  //   number: VNumber,
  // },
};
</script>
