<template>
  <!-- isModalOpen -->
  <v-dialog
    v-model="isModalOpen"
    width="1143px"
    persistent
    @keydown.esc="isModalOpen = false"
    :fullscreen="isMobile"
  >
    <div class="dialog-wrapper custom-inputs custom-scrollbar" v-if="!isMobile">
      <div class="d-flex full-height">
        <div class="stepper-wrapper">
          <HorizontalStepper
            :activeStep="step"
            :stepperTitle="'Seller Profile'"
            :steps="getSteps"
            :showCompletedSteps="true"
            :customCompletedSteps="false"
          />
        </div>
        <div class="content-wrapper">
          <div v-if="step === 1">
            <div class="buttons-wrapper buttons-top">
              <div class="profile-dialog-title">Seller Entity</div>
              <v-btn
                :loading="loading"
                button-second
                block
                tile
                style="max-width: 177px"
                width="177px"
                height="32px"
                color="#001154"
                @click="finishLaterUpdateSellerEntityBlock"
                class="button-second"
                rounded
                shaped
              >
                <div class="button-text">Finish later</div>
              </v-btn>
            </div>
            <div class="px-10 mb-2">
              <v-divider />
            </div>
            <div class="px-10 mb-6">
              <StepInformation title="Contact information for a legal entity" />
            </div>
          </div>
          <div v-if="step === 2">
            <div class="buttons-wrapper buttons-top">
              <div class="profile-dialog-title">Seller Confirmation</div>

              <v-btn
                :loading="loading"
                button-second
                block
                tile
                style="max-width: 177px"
                width="177px"
                height="32px"
                color="#001154"
                @click="finishLaterUpdateSellerEntityBlock"
                class="button-second"
                rounded
                shaped
              >
                <div class="button-text">Finish later</div>
              </v-btn>
            </div>
            <div class="px-10 mb-2">
              <v-divider />
            </div>
            <div class="px-10 mb-6">
              <StepInformation title="Confirm authority to transact" />
            </div>
          </div>
          <v-stepper v-model="step" class="pb-0" vertical :key="key">
            <v-stepper-content
              step="1"
              v-if="step === 1"
              class="first-step-wrapper"
            >
              <v-form ref="sellerEntityForm" lazy-validation>
                <div class="fields-wrapper">
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity Name </c-label>
                      <v-text-field
                        placeholder="Xchange.Loans, Inc."
                        v-model="formData.entityName"
                        :rules="rules.name"
                        required
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity Address </c-label>
                      <v-text-field
                        v-model="formData.entityAddress.address"
                        :rules="rules.address"
                        class="custom-google-input"
                        placeholder="1100 5th Ave S"
                        id="placeAuto3"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity City </c-label>
                      <v-text-field
                        v-model="formData.entityAddress.city"
                        :rules="rules.city"
                        placeholder="Naples"
                        required
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity State </c-label>
                      <v-text-field
                        v-model="formData.entityAddress.state"
                        :rules="rules.required"
                        :class="{
                          'error--text':
                            isValidAddress && !formData.entityAddress.state,
                        }"
                        placeholder="Florida"
                        required
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Entity Zipcode </c-label>
                      <v-text-field
                        v-model="formData.entityAddress.zipCode"
                        :rules="rules.zipCode"
                        v-mask="'#####'"
                        inputmode="numeric"
                        placeholder="34102"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </v-form>
            </v-stepper-content>
            <v-stepper-content
              step="2"
              v-if="step === 2"
              style="position: relative"
              class="second-step-wrapper"
            >
              <div
                class=""
                :class="{ 'disabled-inputs': !form.executive }"
              ></div>
              <v-form ref="sellerConfirmationForm">
                <div cols="12" sm="6" class="fields-wrapper">
                  <div style="height: 40px">
                    <v-checkbox
                      v-model="form.executive"
                      label="I hereby certify that I am the true and legal owner and, or, representative of the asset listed or in a land trust, have the powers of direction, and am empowered to enter into agreements to list asset(s) on behalf of the aforementioned Seller Entity."
                      color="#2969FF"
                      class="ma-0 checkbox-wrapper"
                    ></v-checkbox>
                  </div>
                  <div class="field-wrapper mt-2">
                    <div class="second-input-with-background">
                      <c-label required> Signed By </c-label>
                      <v-text-field
                        :value="formData.signedByFirstName"
                        @input="
                          (v) => {
                            formData.signedByFirstName = capitalize(v);
                          }
                        "
                        :rules="rules.name"
                        placeholder="Deborah"
                        :disabled="!form.executive"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div
                      class="second-input-with-background field-without-label"
                    >
                      <c-label></c-label>
                      <v-text-field
                        :value="formData.signedByLastName"
                        @input="
                          (v) => {
                            formData.signedByLastName = capitalize(v);
                          }
                        "
                        :rules="rules.name"
                        :disabled="!form.executive"
                        placeholder="Smith"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Title </c-label>
                      <v-text-field
                        v-model="form.title"
                        :rules="rules.required"
                        :disabled="!form.executive"
                        placeholder="Senior Vice President - Finance"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field-wrapper"></div>
                  <div class="field-wrapper">
                    <div class="second-input-with-background">
                      <c-label required> Phone Number </c-label>
                      <v-text-field
                        v-model="formData.phoneNumber"
                        :rules="rules.phoneRules"
                        :disabled="!form.executive"
                        v-mask="'(###)-###-####'"
                        placeholder="(800) 913-8139"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </v-form>
            </v-stepper-content>

            <div class="buttons-wrapper" :class="{ 'justify-end': step === 1 }">
              <v-btn
                v-if="step !== 1"
                :loading="loading"
                button-second
                block
                tile
                style="max-width: 177px"
                width="177px"
                height="32px"
                color="#fff"
                @click="previousStep"
                class="button-second custom-btn"
                rounded
                shaped
              >
                <div class="button-text custom-btn-text">
                  <v-icon size="15">mdi-arrow-left</v-icon>
                  Back
                </div>
              </v-btn>
              <div @mouseover="validate">
                <v-btn
                  :loading="loading"
                  @click="updateSellerEntityBlock"
                  button-second
                  block
                  tile
                  style="max-width: 177px"
                  width="177px"
                  height="32px"
                  color="#2969FF"
                  class="button-second"
                  rounded
                  shaped
                  :disabled="isButtonDisabled"
                >
                  <div class="button-text" v-if="step === 2">Finish</div>
                  <div class="button-text" v-else>
                    Next
                    <v-icon size="15">mdi-arrow-right</v-icon>
                  </div>
                </v-btn>
              </div>
            </div>
          </v-stepper>
        </div>
      </div>
    </div>
    <SellerProfileEditDialogMobile
      v-else
      @finishLaterUpdateSellerEntityBlock="finishLaterUpdateSellerEntityBlock"
      @close="isModalOpen = false"
    />
  </v-dialog>
</template>
<script>
import Clabel from "@/components/Common/CLabel.vue";
import { normalizeForm } from "@/utils/normalize";
import apiProfile from "@/services/apiCalls/profile/profile";
import ErrorParser from "@/utils/ErrorParser";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import handleLogsMixin from "@/mixins/handleLogsMixin";
import { mask } from "vue-the-mask";
import { Auth, Storage } from "aws-amplify";
import HorizontalStepper from "@/components/ui/HorizontalStepper.vue";
import states from "@/assets/data/select/states.json";
import loader from "@/services/googleApi";
import proofOfFundsMixin from "@/mixins/proofOfFundsMixin";
import SellerProfileEditDialogMobile from "@/components/GlobalDialogs/SellerProfileEditDialogMobile.vue";
import { mapState } from "vuex";
import StepInformation from "@/components/ProfileEditDialog/StepInformation.vue";
import _ from "lodash";
import validateName from "@/utils/validations/name";
import {
  replaceTildeCharacters,
  validateAddress,
} from "@/utils/validations/address";
import validateCity from "@/utils/validations/city";

export default {
  name: "ProfileSellerEditDialog",
  props: {
    page: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    sellerProfileRedirectToAssetPage: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    mask,
  },
  mixins: [validationMixin, handleLogsMixin, proofOfFundsMixin],
  watch: {
    "formData.entityAddress.address": {
      handler(newVal) {
        this.formData.entityAddress.address = replaceTildeCharacters(newVal);
      },
    },
    formData: {
      handler() {
        this.validate();
      },
      deep: true,
    },
    step(v) {
      this.key = this.key + 1;
      if (v === 1) {
        this.initAutocomplete();
      }
    },
    additionalAddressData() {
      this.setAddress();
    },
    isModalOpen() {
      this.initAutocomplete();
    },
  },
  components: {
    StepInformation,
    "c-label": Clabel,
    HorizontalStepper,
    SellerProfileEditDialogMobile,
  },
  data: function () {
    return {
      valid: true,
      isValidAddress: false,
      birthdayMenu: false,
      additionalAddressData: null,
      sellerBankingInfo: [],
      loading: false,
      isDepositFormValid: false,
      states: states.states,
      rules: {
        name: [(v) => !!v || "", (v) => !!validateName(v)],
        city: [(v) => !!v || "", (v) => !!validateCity(v)],
        address: [(v) => !!v || "", (v) => !!validateAddress(v)],
        phoneRules: [(v) => !!v || "", (v) => (v && v.length === 14) || ""],
        zipCode: [
          (v) => !!v || "",
          (v) => (v && v.length === 5) || "Zipcode must be 5 characters",
        ],
        required: [(v) => !!v || ""],
        textRules: [(v) => !!v || "", (v) => (v && v.length >= 2) || ""],
      },
      avatarNew: null,
      avatarSrc: null,
      steps: [
        {
          order: 1,
          title: "Seller Entity",
        },
        {
          order: 2,
          title: "Seller Confirmation",
        },
      ],
      autoDepositSteps: [
        {
          order: 1,
          title: "Seller Entity",
        },
        {
          order: 2,
          title: "Seller Confirmation",
        },
      ],
      key: 0,
      formData: {
        entityName: "",
        entityAddress: {
          address: null,
          city: null,
          state: null,
          country: null,
          zipCode: null,
        },
        signedByFirstName: "",
        signedByLastName: "",
        country: null,
        executive: false,
        avatar: null,
        phoneNumber: "",
        title: null,
      },
      form: {
        aum: "1",
        entityName: "",
        entityAddress: {
          address: null,
          city: null,
          state: null,
          country: null,
          zipCode: null,
        },
        signedByFirstName: "",
        signedByLastName: "",
        companyName: "",
        companyProfile: null,
        currentStrategy: null,
        country: null,
        email: "",
        executive: false,
        faxNumber: null,
        firstName: "",
        lastName: "",
        avatar: null,
        phoneNumber: "",
        officePhone: "",
        state: null,
        userAddress: "",
        userAddressCity: "",
        zipCode: "",
        title: null,
        stateOfFormation: "",
      },
    };
  },
  async created() {
    this.updateSellerProfile();
    await this.getImage(this.form.avatar);
    this.setFormData();
  },
  mounted() {
    this.initAutocomplete();
  },
  validations: {
    form: {
      companyName: { required },
      entityName: { required },
      phoneNumber: { required, minLength: minLength(14) },
      userAddress: { required },
      userAddressCity: { required },
      zipCode: { required, minLength: minLength(5), maxLength: maxLength(5) },
      faxNumber: { required, minLength: minLength(14) },
      stateOfFormation: { required },
      title: { required },
    },
    formData: {
      // first step
      entityName: { required },
      entityAddress: {
        address: { required },
        city: { required },
        country: { required },
        state: { required },
        zipCode: { required, minLength: minLength(5), maxLength: maxLength(5) },
      },
      signedByFirstName: { required },
      signedByLastName: { required },
      phoneNumber: { required, minLength: minLength(14) },
    },
  },
  methods: {
    preventLetter(e) {
      let charCode = typeof e.which === "undefined" ? e.keyCode : e.which;
      let charStr = String.fromCharCode(charCode);
      if (!charStr.match(/^[0-9-]+$/)) e.preventDefault();
    },
    validate() {
      if (this.step === 1 && this.$refs.sellerEntityForm) {
        this.valid = !this.$refs.sellerEntityForm.validate();
      }
      if (this.step === 2 && this.$refs.sellerConfirmationForm) {
        this.valid = !this.$refs.sellerConfirmationForm.validate();
      }
    },
    onDatePickerChange() {
      this.birthdayMenu = false;
      this.validate();
    },
    onCheckFields() {
      if (this.step === 1) {
        if (
          !this.formData.entityAddress.address ||
          !this.formData.entityAddress.city ||
          !this.formData.entityAddress.zipCode ||
          !this.formData.entityAddress.state ||
          !this.formData.entityName
        ) {
          this.isValidAddress = true;
        }
      }
      if (this.step === 2) {
        if (
          !this.formData.signedByFirstName ||
          !this.formData.signedByLastName ||
          !this.formData.phoneNumber ||
          !this.form.title
        ) {
          this.isValidAddress = true;
        }
      }
    },
    setFormData() {
      const {
        entityName,
        entityAddress,
        signedByFirstName,
        signedByLastName,
        executive,
        phoneNumber,
        title,
        bankingInfo,
      } = this.$store.state.user?.sellerProfile || {};
      this.formData.entityName = entityName;
      this.formData.signedByFirstName = signedByFirstName;
      this.formData.signedByLastName = signedByLastName;
      this.formData.phoneNumber = phoneNumber;
      this.sellerBankingInfo = bankingInfo;
      // eslint-disable-next-line no-console
      console.log("sellerBankingInfo", this.sellerBankingInfo);
      if (!this.sellerBankingInfo || !this.sellerBankingInfo.length) {
        // eslint-disable-next-line no-console
        console.log(
          "this.$store.state.user?.buyerProfile.bankingInfo",
          this.$store.state.user?.buyerProfile?.bankingInfo
        );
        this.sellerBankingInfo = _.cloneDeep(
          this.$store.state.user?.buyerProfile?.bankingInfo
        );
      }

      this.form.executive = executive;
      this.form.title = title;
      if (entityAddress) {
        this.formData.entityAddress = {
          ...entityAddress,
          address: entityAddress.address ? entityAddress.address : null,
          state: entityAddress.state ? entityAddress.state : null,
          zipCode: entityAddress.zipCode ? entityAddress.zipCode : null,
          city: entityAddress.city ? entityAddress.city : null,
        };
      }
      if (!this.formData.entityAddress.country) {
        this.formData.entityAddress.country = "United States";
      }
    },
    async onProfileUpdate() {
      const currentUserProfile = await apiProfile.getProfile();
      this.$store.commit("setUser", {
        user: currentUserProfile.data.getUserProfile,
      });
    },
    async getUserProfile() {
      if (this.$store.getters.user === null) {
        const currentUserProfile = await apiProfile
          .getProfile()
          .catch((err) => {
            this.handleLogs({
              errorInfo: JSON.stringify(err),
              errorId: "Getting User Profile",
            });
          });
        const user = currentUserProfile.data.getUserProfile;
        this.$store.commit("setUser", {
          user,
        });
      }
    },
    async finishLaterDepositProfile() {
      if (this.isAutoDepositEnabled && this.isDepositFormValid) {
        try {
          this.$store.commit("spinner", true);
          await this.$refs.depositFormRef.submitForm();
          this.$store.commit("spinner", false);
          this.isModalOpen = false;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("err", err);
          this.$store.commit("spinner", false);
          this.isModalOpen = false;
        }
      }
    },
    async finishLaterUpdateSellerEntityBlock(sellerData) {
      const valid = true;
      let formData = sellerData ? sellerData : this.formData;

      if (valid) {
        try {
          this.$store.commit("spinner", true);
          const phoneNumber = formData.phoneNumber;
          const executive = formData.executive
            ? formData.executive
            : this.form.executive;
          const { data } = await apiProfile.updateSellerProfile({
            entityName: formData.entityName,
            entityAddress: formData.entityAddress,
            phoneNumber,
            executive: !!executive,
            title: formData.title ? formData.title : this.form.title || null,
            signedByFirstName: formData.signedByFirstName
              ? formData.signedByFirstName
              : "",
            signedByLastName: formData.signedByLastName
              ? formData.signedByLastName
              : "",
          });

          await this.onProfileUpdate(data.updateSellerProfile);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        } finally {
          this.$store.commit("spinner", false);
          this.isModalOpen = false;
          this.$emit("rerenderProfile");

          if (this.$store.getters.isSeller) {
            await this.refreshToken();
            if (this.$route.name === "Self service") {
              this.$router.push("/asset/create");
            }
          }
        }
      }
    },
    async updateSellerEntityBlock() {
      if (this.checkIsFieldsCompleted) {
        this.snackbar = true;
      }

      try {
        this.$store.commit("spinner", true);
        const phoneNumber = this.formData.phoneNumber;
        const executive = this.form.executive;
        const { data } = await apiProfile.updateSellerProfile({
          entityName: this.formData.entityName,
          entityAddress: this.formData.entityAddress,
          phoneNumber,
          executive: !!executive,
          title: this.form.title || null,
          signedByFirstName: this.formData.signedByFirstName,
          signedByLastName: this.formData.signedByLastName,
        });
        await this.onProfileUpdate(data.updateSellerProfile);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        this.$store.commit("spinner", false);
      }
      if (this.custom) {
        const closeAfterEditSecondStep =
          this.step === 2 && !this.isAutoDepositEnabled;
        if (this.step < 2 && !closeAfterEditSecondStep) {
          this.$emit("setStep", this.step + 1);
          this.valid = this.$refs.sellerConfirmationForm.validate();
        } else {
          await this.refreshToken();
          this.isModalOpen = false;

          if (this.$route.name === "ChoosePlan") {
            this.$router.push("/sellerAddAsset/main");
          } else if (this.$route.name === "Self service") {
            this.$router.push("/asset/create");
          } else {
            if (
              this.sellerProfileRedirectToAssetPage &&
              this.$route.path !== "/profile/seller"
            ) {
              this.$router.push("/seller/filters/active");
            }
          }
        }
      } else {
        this.$emit("rerenderProfile");
        this.isModalOpen = false;
      }
    },
    async initAutocomplete() {
      this.google = await loader.load();
      const options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["address"],
      };
      this.isModalOpen &&
        this.$nextTick(() => {
          const autocomplete = new this.google.maps.places.Autocomplete(
            document.getElementById("placeAuto3"),
            options
          );
          this.autocomplete = autocomplete;
          autocomplete.addListener("place_changed", this.setPlace);
        });
    },
    setPlace() {
      let place = this.autocomplete.getPlace();
      const administrative_area_level_1 =
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0].long_name;
      const administrative_area_level_1ShortName =
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0].short_name;
      const county =
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_2")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_2")
        )[0].short_name;
      const country =
        place.address_components.filter((el) =>
          el.types.includes("country")
        )[0] &&
        place.address_components.filter((el) => el.types.includes("country"))[0]
          .long_name;
      const countryShortName =
        place.address_components.filter((el) =>
          el.types.includes("country")
        )[0] &&
        place.address_components.filter((el) => el.types.includes("country"))[0]
          .short_name;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const name = place.name;
      const locality =
        place.address_components.filter((el) =>
          el.types.includes("locality")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("locality")
        )[0].long_name;
      const zipCode =
        place.address_components.filter((el) =>
          el.types.includes("postal_code")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("postal_code")
        )[0].long_name;
      this.additionalAddressData = {
        administrative_area_level_1,
        country,
        lat,
        lng,
        name,
        countryShortName,
        administrative_area_level_1ShortName,
        locality,
        zipCode,
        county,
      };
      this.address = document.getElementById("placeAuto3").value;
    },
    setAddress() {
      if (this.additionalAddressData) {
        if (this.step === 1) {
          this.$nextTick(() => {
            this.formData.entityAddress.state =
              this.additionalAddressData.administrative_area_level_1;
            this.formData.entityAddress.zipCode =
              this.additionalAddressData.zipCode;
            this.formData.entityAddress.address =
              this.additionalAddressData.name;
            this.formData.entityAddress.city =
              this.additionalAddressData.locality;
            this.formData.entityAddress.country = "USA";
          });
        }
      }
    },
    previousStep() {
      this.$emit("setStep", this.step - 1);
      this.valid = false;
    },
    async refreshToken() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        currentUser.refreshSession(currentSession.refreshToken, async () => {
          const currentUpdatedUser = await Auth.currentAuthenticatedUser();
          this.$store.commit("setAuthState", {
            isAuthenticated: true,
            user: currentUpdatedUser.attributes,
          });
          localStorage.setItem(
            "currentUser",
            JSON.stringify(currentUpdatedUser.attributes)
          );
          this.$store.dispatch("getCurrentUserProfile");
        });
      } catch (err) {
        console.error(err);
      }
    },
    updateSellerProfile() {
      if (this.$store.getters.user !== null) {
        this.form.email = this.$store.getters.user.email;
        this.form.companyName = this.$store.getters.user.companyName;
        this.form.companyProfile = this.$store.getters.user.companyProfile;
        this.form.currentStrategy = this.$store.getters.user.currentStrategy;
        this.form.jobTitle = this.$store.getters.user.jobTitle;
        this.form.zipCode = this.$store.getters.user.zipCode;
        this.form.officePhone = this.$store.getters.user.officePhone;
        this.form.cellPhone = this.$store.getters.user.cellPhone;
        this.form.userAddress = this.$store.getters.user.address.address;
        this.form.userAddressCity = this.$store.getters.user.address.city;
        this.form.country = this.$store.getters.user.address.country;
        this.form.state = this.$store.getters.user.address.state;
        this.form.zipCode = this.$store.getters.user.address.zipCode;
        if (this.$store.getters.user.roles !== null) {
          this.roles = this.$store.getters.user.roles;
        }
        if (this.$store.getters.user.sellerProfile !== null) {
          this.form.phoneNumber =
            this.$store.getters.user.sellerProfile.phoneNumber;
          this.form.aum = "1";
          this.form.executive =
            this.$store.getters.user.sellerProfile.executive;
          this.form.faxNumber =
            this.$store.getters.user.sellerProfile.faxNumber;
          this.form.avatar = this.$store.getters.user.sellerProfile.avatar;
          this.form.title = this.$store.getters.user.sellerProfile.title;
          this.form.stateOfFormation =
            this.$store.getters.user.sellerProfile.states &&
            this.$store.getters.user.sellerProfile.states.toString();
          this.form.firstName =
            this.$store.getters.user.sellerProfile.signedByFirstName;
          this.form.lastName =
            this.$store.getters.user.sellerProfile.signedByLastName;
        }
      }
    },
    async getImage(data) {
      if (this.$store.getters.user !== null) {
        if (data) {
          try {
            this.avatarSrc = await Storage.get(`${data}-thumbnail`);
          } catch (err) {
            console.error(err);
          }
        } else if (this.$store.getters.user?.sellerProfile?.avatar) {
          try {
            this.avatarSrc = await Storage.get(
              `${this.$store.getters.user.sellerProfile.avatar}-thumbnail`
            );
          } catch (err) {
            console.error(err);
          }
        }
      }
    },
    async onSelectFile(file) {
      if (file) {
        this.$store.commit("spinner", true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.avatarNew = reader.result;
        };
        Auth.currentAuthenticatedUser().then((user) => {
          Storage.put(user.attributes.sub + "/avatars/" + file.name, file)
            .then((result) => {
              this.form.avatar = result.key;
              this.$store.commit("spinner", false);
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log("err", err);
              this.$store.commit("spinner", false);
            });
        });
      }
    },
    async changeSellerProfileForm(withRequest) {
      if (this.checkIsFieldsCompleted) {
        this.snackbar = true;
      } else {
        return;
      }
      if (this.$store.getters.user) {
        if (!this.$store.getters.user) {
          this.$store.commit("openSnackBar", {
            text: "You must also complete your User Profile section in it's entirety to submit a request for a Buyer and/or Seller role.",
            color: "error",
          });
          return;
        }
        this.form = normalizeForm(this.formData);
        !withRequest && this.$store.commit("spinner", true);
        const phoneNumber = this.formData.phoneNumber?.replace(/[()-]/g, "");
        const { executive } = this.formData;
        const avatar = this.form.avatar;
        try {
          if (this.step !== 3) {
            await apiProfile
              .updateSellerProfile({
                entityName: this.formData.entityName,
                entityAddress: this.formData.entityAddress,
                phoneNumber,
                avatar,
                executive: !!executive,
                title: this.form.title || null,
                signedByFirstName: this.formData.signedByFirstName,
                signedByLastName: this.formData.signedByLastName,
              })
              .catch((err) => {
                this.handleLogs({
                  errorInfo: JSON.stringify(err),
                  errorId: "Updating Seller Profile",
                });
              });
          } else {
            await apiProfile
              .updateSellerProfile({
                avatar,
              })
              .catch((err) => {
                this.handleLogs({
                  errorInfo: JSON.stringify(err),
                  errorId: "Updating Seller Profile",
                });
              });
          }
          const isUserSeller = this.$store.getters.isSeller;
          await apiProfile
            .updateProfile({
              ...this.form,
              phoneNumber,
            })
            .catch((err) => {
              this.handleLogs({
                errorInfo: JSON.stringify(err),
                errorId: "Updating User Profile",
              });
            });
          const currentUserProfile = await apiProfile.getProfile();
          const isRoleChanged =
            currentUserProfile.data.getUserProfile.roles.includes("Sellers") !==
            isUserSeller;
          if (isRoleChanged && this.step > 1) {
            this.$store.commit("openSnackBar", {
              text: "You are Seller now! You can move on to creating and publishing assets.",
              color: "success",
            });
          }
          this.updateSellerProfile();
          this.$store.commit("setUser", {
            user: currentUserProfile.data.getUserProfile,
          });
          this.$store.commit("spinner", false);
          if (!withRequest && !isRoleChanged) {
            await this.refreshToken();
          }
          if (this.custom) {
            if (this.step < this.isAutoDepositEnabled ? 3 : 2) {
              this.$emit("setStep", this.step + 1);
            } else {
              await this.refreshToken();
              this.isModalOpen = false;
              if (this.$route.name === "ChoosePlan") {
                this.$router.push("/sellerAddAsset/main");
              } else {
                if (this.sellerProfileRedirectToAssetPage) {
                  this.$router.push("/seller/filters/active");
                }
              }
            }
          } else {
            this.$emit("rerenderProfile");
            this.isModalOpen = false;
          }
        } catch (err) {
          this.$store.commit("spinner", false);
          this.$store.commit("openSnackBar", {
            text: ErrorParser(err),
            color: "error",
          });
        }
      } else {
        this.$store.commit("openSnackBar", {
          text: "You must also complete your User Profile section in it's entirety to submit a request for a Buyer and/or Seller role.",
          color: "error",
        });
      }
    },

    capitalize(s) {
      if (s && s.length) {
        return s[0].toUpperCase() + s.slice(1);
      }
    },
  },
  computed: {
    ...mapState("UserSettings", ["userSettings"]),

    isAutoDepositEnabled() {
      return this.userSettings.autoDeposit;
    },
    getSteps() {
      if (this.isAutoDepositEnabled) {
        return this.autoDepositSteps;
      }
      return this.steps;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isButtonDisabled() {
      if (this.step === 1) {
        return !(
          !this.$v.formData.entityName.$invalid &&
          validateName(this.$v.formData.entityName.$model) &&
          !this.$v.formData.entityAddress.$invalid &&
          !this.$v.formData.entityAddress.address.$invalid &&
          validateAddress(this.$v.formData.entityAddress.address.$model) &&
          !this.$v.formData.entityAddress.city.$invalid &&
          validateCity(this.$v.formData.entityAddress.city.$model) &&
          !this.$v.formData.entityAddress.country.$invalid &&
          !this.$v.formData.entityAddress.state.$invalid &&
          !this.$v.formData.entityAddress.zipCode.$invalid
        );
      } else {
        return !(
          !this.$v.formData.signedByFirstName.$invalid &&
          validateName(this.$v.formData.signedByFirstName.$model) &&
          !this.$v.formData.signedByLastName.$invalid &&
          validateName(this.$v.formData.signedByLastName.$model) &&
          !this.$v.formData.phoneNumber.$invalid &&
          !this.$v.form.title.$invalid
        );
      }
    },
    addressInfo() {
      return this.$store.state.value.address;
    },
    name() {
      return this.addressInfo?.additional?.name;
    },
    locality() {
      return this.addressInfo?.additional?.locality;
    },
    administrative_area_level_1() {
      return this.addressInfo?.additional?.administrative_area_level_1;
    },
    administrative_area_level_1ShortName() {
      return this.addressInfo?.additional?.administrative_area_level_1ShortName;
    },
    county() {
      return this.addressInfo?.additional?.country;
    },
    zipCode() {
      return this.addressInfo?.additional?.zipCode;
    },
    isModalOpen: {
      set(val) {
        if (!val && this.page === "profileSeller") {
          this.$emit("updateSellerProfilePageFields");
        }
        this.$emit("setIsDialogOpen", val);
      },
      get() {
        return this.active;
      },
    },
    isPageProfileSeller() {
      return this.$route.fullPath === "/profile/seller";
    },
    checkIsFieldsCompleted() {
      if (this.step === 1 && this.$refs.sellerEntityForm) {
        return !this.$refs.sellerEntityForm.validate();
      }
      if (this.step === 2 && this.$refs.sellerConfirmationForm) {
        return !this.$refs.sellerConfirmationForm.validate();
      }
      return false;
    },

    sellerDateModified() {
      if (
        this.$store.getters.user &&
        this.$store.getters.user.sellerProfile &&
        this.$store.getters.user.sellerProfile.dateModified &&
        this.$store.getters.user.sellerProfile.title
      ) {
        return this.$store.getters.user.sellerProfile.dateModified;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../styles/overridings/ui/profileSeller.scss";
::v-deep {
  .c-label {
    color: #001154 !important;
  }
  .v-stepper__wrapper {
    min-height: 330px;
  }
  .custom-input-border .v-input:focus-within {
    border: 1px solid rgba(41, 105, 255, 0.6);
  }
  .google-input-border:focus-within {
    border: 1px solid rgba(41, 105, 255, 0.6);
  }
  .radiogroup-wrapper {
    padding-top: 8px;
    margin-top: 4px;
    .v-label {
      color: #001154;
    }
    &--disabled {
      .v-label {
        opacity: 0.5;
      }
    }
  }
  .first-step-wrapper {
    //margin-bottom: 89px !important;
  }
  .second-step-wrapper {
    //margin-bottom: 16px !important;
    padding-top: 12px !important;
  }
  .disabled-inputs {
    position: absolute;
    width: 100%;
    height: 238px;
    left: 0;
    top: 17%;
    background: rgba(255, 255, 255, 0.65);
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: -26px;
    margin-top: 10px;
    z-index: 2;
  }
  .second-input-with-background .custom-google-input {
    background-color: #f3f3f3;
    padding-left: 10px;
    padding-right: 20px;
    color: #001154 !important;
  }
  .custom-google-input--focus:focus-within {
    border: 1px solid rgba(41, 105, 255, 0.6);
  }
  .custom-input-error {
    background: rgba(213, 40, 25, 0.08) !important;
    border: 1px solid rgba(213, 40, 25, 0.6);
    &:focus-within {
      border: 1px solid rgba(213, 40, 25, 0.6);
    }
    &::placeholder {
      color: rgba(213, 40, 25, 0.7);
    }
  }
  .custom-google-input {
    margin-top: 4px;
    outline: unset;
    height: 40px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    color: #001154;
  }
  .profile-dialog-title {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 32px !important;
    letter-spacing: 0.32px !important;
    color: #0f2d59;
  }
  .custom-btn {
    border: 1px solid #2969ff !important;
  }
  .custom-btn:hover {
    background-color: #145aff !important;
    .custom-btn-text {
      color: #ffffff !important;
    }
  }
  .custom-btn-text {
    color: #2969ff !important;
  }
  .buttons-wrapper {
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .buttons-top {
    padding-top: 15px !important;
    padding: 0 40px;
    margin-bottom: 13px;
  }
  .v-radio .v-input--selection-controls__input .v-icon {
    font-size: 20px !important;
    margin-left: 0;
    padding-left: 10px;
  }
  .field-wrapper .req-icon {
    margin-left: -3px;
  }
  .v-radio .v-input--selection-controls__ripple {
    height: 24px !important;
    width: 24px !important;
    left: -2px !important;
    top: calc(50% - 20px);
  }
  .v-radio .v-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
    margin-right: -10px;
  }
  .second-input-with-background .c-label {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 17px;
    color: rgba(0, 17, 84, 0.7);
  }
  .v-text-field__slot input {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    color: rgba(0, 17, 84, 1) !important;
  }

  .mdi-camera::before {
    color: #2969ff !important;
    font-size: 18px;
    align-self: center;
  }
  .v-file-input__text--placeholder {
    color: #2969ff !important;
    margin-left: -6px;
    font-size: 12px;
    letter-spacing: 0;
  }
  .v-input__prepend-outer {
    align-self: center;
  }
  .second-input-with-background .req-icon {
    margin-left: -3px;
  }
  .v-dialog {
    overflow: hidden;
  }
  .v-btn {
    min-height: unset !important;
    min-width: unset !important;
  }
  .v-text-field,
  .app-custom-input {
    height: 40px;
    padding-top: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
  }
  v-input--radio-group--column {
    margin-top: 0;
    position: relative;
    top: 8px;
  }
  .checkbox-wrapper {
    .v-label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* or 150% */
      /* DarkBlue */
      color: #001154;
      opacity: 0.8;
    }
  }
  .v-stepper--vertical .v-stepper__content {
    padding: 24px 60px 16px 23px;
  }
  .c-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 17px;
    /* identical to box height, or 155% */
    min-height: 20px;
    color: rgba(0, 17, 84, 0.7);
  }
  .v-input--radio-group {
    background: white;
  }
  .v-input--radio-group__input {
    display: flex;
    column-gap: 32px;
  }
  .v-input--selection-controls__ripple {
    color: #2969ff !important;
  }
  v-radio {
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 26px;
      /* identical to box height, or 217% */
      /* DarkBlue */
      color: #001154;
    }
  }
}
.field-without-label {
  height: 100%;
  display: flex;
  align-items: end;
}
.stepper-wrapper {
  width: 295px;
}
.content-wrapper {
  width: 847px;
  position: relative;
}
.dialog-wrapper {
  background: white;
  border-radius: 5px;
  max-width: 1143px;
  margin: 0 auto;
  overflow: auto;
  max-height: 891px;
  height: 597px;
}
.seller-dialog-title {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  /* identical to box height, or 13 3% */
  padding-top: 32px;
  letter-spacing: 0.32px !important;
  /* DarkBlue */
  padding-left: 40px;
  color: map-get($colors, "primary");
}
.sub-title {
  width: 413px;
  padding-left: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  opacity: 0.8;
  color: map-get($colors, "primary");
}
.step {
  display: flex;
  margin-top: 41px;
  &__number {
    width: 24px;
    height: 24px;
    border-radius: 26px;
    text-align: center;
    color: white;
    background-color: map-get($colors, "primary");
  }
  &__title {
    font-size: 12px;
    line-height: 26px;
    color: map-get($colors, "primary");
  }
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
  padding: 0px 40px !important;
}
.button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  text-align: center;
  /* White */
  color: #ffffff;
}
.fields-wrapper {
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 24px;
  row-gap: 20px;
}
.field-wrapper {
  width: 47%;
}
.finish-later {
  color: #2969ff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  text-align: center;
  cursor: pointer;
  /* Cyan */
  color: #2969ff;
}
.app-custom-input {
  height: 40px !important;
}
.app-custom-input > div {
  height: 63px;
}
.app-custom-input {
  input {
    height: 40px;
  }
  .c-label {
    margin-bottom: 3px;
  }
}
.close-icon {
  position: absolute;
  right: 24px;
  top: 24px;
}
.req-icon {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  /* identical to box height, or 155% */
  /* Red */
  color: #d52819;
}
::v-deep {
  .stepper-title {
    font-size: 20px !important;
  }
}
</style>
