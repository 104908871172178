import Chat from "@/services/apiCalls/chat/chat";
import ErrorParser from "@/utils/ErrorParser";
import AddColorToNewChat from "@/utils/AddColorToNewChat";

export default {
  namespaced: true,
  state: {
    chatStatusOnline: false,
    twilioUserToken: "",
    sellerChats: [],
    buyerChats: [],
    selectedChat: null,
    selectedChatType: null,
    selectedChatAsset: null,
    selectedConversation: null,
    userFirstId: "",
    userSecondId: "",
    uniqName: "",
    openedPanel: false,

    conversationsClient: null,
  },
  getters: {
    sellerChatWithMessagesList(state) {
      return state.sellerChats.filter((item) => {
        if (
          item.msgText ||
          item.attributes?.lastMessage ||
          item.TWILIO_LastMessage?.index
        ) {
          return true;
        }
        return false;
      });
    },
    buyerChatWithMessagesList(state) {
      return state.buyerChats.filter((item) => {
        if (
          item.msgText ||
          item.attributes?.lastMessage ||
          item.TWILIO_LastMessage?.index
        ) {
          return true;
        }
        return false;
      });
    },
    nonEmptyChatList(state) {
      let list = [...state.sellerChats, ...state.buyerChats];

      const filteredList = list.filter((item) => {
        if (
          item.msgText ||
          item.attributes?.lastMessage ||
          item.TWILIO_LastMessage?.index
        ) {
          return true;
        }
        return false;
      });
      return filteredList;
    },
    getNewMsgCounter: (state) => (userId) => {
      let counter = 0;

      if (state.sellerChats.length) {
        state.sellerChats.forEach((chat) => {
          if (userId && chat.attributes?.lastMessageOwnerId === userId) {
          } else {
            const lastMessage = chat.TWILIO_LastMessage;
            const lastReadMessageIndex = chat.TWILIO_lastReadMessageIndex;
            if (
              Number.isInteger(lastMessage?.index) &&
              lastReadMessageIndex === null
            ) {
              counter = counter + lastMessage?.index + 1;
            }
            if (lastMessage && Number.isInteger(lastReadMessageIndex)) {
              counter = counter + (lastMessage?.index - lastReadMessageIndex);
            }
          }
        });
      }

      if (state.buyerChats.length) {
        state.buyerChats.forEach((chat) => {
          if (userId && chat.attributes?.lastMessageOwnerId === userId) {
          } else {
            const lastMessage = chat.TWILIO_LastMessage;
            const lastReadMessageIndex = chat.TWILIO_lastReadMessageIndex;
            if (
              Number.isInteger(lastMessage?.index) &&
              lastReadMessageIndex === null
            ) {
              counter = counter + lastMessage?.index + 1;
            }
            if (lastMessage && Number.isInteger(lastReadMessageIndex)) {
              counter = counter + (lastMessage?.index - lastReadMessageIndex);
            }
          }
        });
      }

      return counter;
    },
  },
  mutations: {
    setConversationsClient(state, value) {
      state.conversationsClient = value;
    },
    setChatStatusOnline(state, value) {
      state.chatStatusOnline = value;
    },
    toggleChatPanel(state, value) {
      state.openedPanel = !state.openedPanel;
    },
    pushToSellerChats(state, value) {
      state.sellerChats.push(value);
    },
    pushToBuyerChats(state, value) {
      state.buyerChats.push(value);
    },
    setSelectedChat(state, value) {
      state.selectedChat = value;
    },
    setSelectedChatType(state, value) {
      state.selectedChatType = value;
    },
    setSelectedConversation(state, value) {
      state.selectedConversation = Object.assign({}, value);
    },
    setSelectedChatAsset(state, value) {
      state.selectedChatAsset = value;
    },
    setFUserId(state, value) {
      state.userFirstId = value;
    },
    setSUserId(state, value) {
      state.userSecondId = value;
    },
    setUniqName(state, value) {
      state.uniqName = value;
    },
    getTwilioUserToken(state, value) {
      state.twilioUserToken = value;
    },
    setSellerChats(state, value) {
      state.sellerChats = [...value];
    },
    setBuyerChats(state, value) {
      state.buyerChats = value;
    },
    resetChat(state, value) {
      state.assetChat = null;
      state.userFirstId = "";
      state.userSecondId = "";
      state.uniqName = "";
      state.selectedChat = null;
      state.selectedChatType = null;
      state.selectedChatAsset = null;
      state.selectedConversation = null;
    },
  },
  actions: {
    async sendMultiMessages({ state, commit }, params) {
      const result = await Chat.getOrCreateMessageAllChats(params)
        .then(({ data }) => {
          if (data.getOrCreateMessageAllChats) {
            const list = data.getOrCreateMessageAllChats;
            console.log("list - ", list);
            return list;
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async noticeMessageAdded({ state, commit }, params) {
      const result = await Chat.noticeMessageAdded(params)
        .then(({ data }) => {
          return data.noticeMessageAdded;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async getTwilioUserToken({ state, commit }, params) {
      const result = await Chat.getUserTwilioToken()
        .then(({ data }) => {
          return data.getUserTwilioToken;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async getSellerChatListing({ state, commit }, params) {
      const result = await Chat.getSellerChatListing(params)
        .then(({ data }) => {
          const chatList = data.getSellerChatListing.chats;
          commit("setSellerChats", chatList);
          return chatList;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async getBuyerChatListing({ state, commit }, params) {
      const result = await Chat.getBuyerChatListing(params)
        .then(({ data }) => {
          const chatList = data.getBuyerChatListing.chats;
          commit("setBuyerChats", chatList);
          return chatList;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async getChatInfo({ state, commit }, params) {
      const result = await Chat.getChatInfo(params)
        .then(({ data }) => {
          commit("setSelectedChat", data.getChatInfo);
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async createChat({ state, commit }, params) {
      const result = await Chat.createChat(params)
        .then(({ data }) => {
          // const newChat = data.createChat;

          const newChat = AddColorToNewChat(data.createChat);

          commit("setSelectedConversation", newChat);
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async addChatInfo({ state, commit }, params) {
      const result = await Chat.addChatInfo(params)
        .then(({ data }) => {
          const newChat = data.addChatInfo;
          commit("addChatInfo", newChat);
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
  },
};
