import { API } from "aws-amplify";

export default () => {
  const query = `query {
    getUserFavoriteAssets {
      nextElements
      assets {
        id
        name
        type
        ownerEmail
        address {
          country
          state
          city
          address
          zipCode
        }
        propertyOverview {
          propertyType
        }
        principalBalance {
          amount
        }
        contacts {
          firstName
          lastName
          officePhone
          emailAddress
        }
        images
        bestCashOffer
        pricingPlan
        dateModified
        status
        assetStatus
        noteOverview {
          unpaidPrincipalBalance {
            amount
          }
        }
      }
    }
  }`;

  return API.graphql({
    query,
  });
};
