import { API } from "aws-amplify";
export default {
  // createVDRClientUser(userData) {
  //     console.log('VdrClientUserInput')
  //     const query = `mutation createVDRClientUser($user_data: VdrClientUserInput!) {
  //         createVDRClientUser(user_data: $user_data) {
  //             id
  //             email
  //             username
  //             first_name
  //             last_name
  //             company
  //         }
  //     }`

  //     return API.graphql({
  //         query,
  //         variables: {
  //             user_data: {
  //                 email: userData.email,
  //                 first_name: userData.email,
  //                 last_name: userData.email,
  //                 password: userData.password
  //             }
  //         }
  //     })
  // },
  requestTier2Access(id) {
    const query = `mutation requestTier2Access($id: String!) {
            requestTier2Access(id: $id) {
              id status
            }
          }`;

    return API.graphql({
      query,
      variables: {
        id,
      },
    });
  },
  createVDR(id) {
    const mutation = `mutation {
         createVDR(asset_title: "${id}") {
          tier_home_id
          tier1_id
          tier2_id
         }
       }`;
    return API.graphql({
      query: mutation,
    });
  },
  getVdrTier1Upload(id) {
    const query = `query {
            getVdrTier1Upload(tier1_id: "${id}")
        }`;

    return API.graphql({
      query,
    });
  },
  getVdrTier2Upload(id) {
    const query = `query {
            getVdrTier2Upload(tier2_id: "${id}")
        }`;

    return API.graphql({
      query,
    });
  },
  getVdrTier1(id) {
    const query = `query {
            getVdrTier1(tier1_id: "${id}")
        }`;

    return API.graphql({
      query,
    });
  },
  getVdrTier2(id) {
    const query = `query {
            getVdrTier2(tier2_id: "${id}")
        }`;

    return API.graphql({
      query,
    });
  },
  updateVdrFolderName(folderId, name) {
    const mutation = `mutation update($id: String!, $name: String!) {
            updateVdrFolderName(folder_id: $id, name: $name)
        }`;

    return API.graphql({
      query: mutation,
      variables: {
        id: folderId,
        name,
      },
    });
  },

  getVdrFolders(variables) {
    const mutation = `query getVdrFolder($id: String!) {
      getVdrFolder(id: $id)
  }`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  downloadVdrFile(variables) {
    const mutation = `query downloadVdrFile($id: String!) {
      downloadVdrFile(id: $id)
  }`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  createVdrFolder(variables) {
    const mutation = `mutation createVdrFolder($id: String!, $name: String!) {
      createVdrFolder(id: $id , name: $name)
  }`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  renameVdrItem(variables) {
    const mutation = `mutation renameVdrItem($id: String!, $name: String!) {
      renameVdrItem(id: $id , name: $name)
  }`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  uploadVdrFile(variables) {
    const mutation = `mutation uploadVdrFile($id: String!) {
      uploadVdrFile(id: $id)
  }`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  deleteVdrItem(variables) {
    const mutation = `mutation deleteVdrItem($id: String!) {
      deleteVdrItem(id: $id)
  }`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
};
