<template>
  <div class="bank-selection-section">
    <BankingInfoSelectBankModal
      :attach="'.bank-selection-section'"
      :selectedBank="getBank"
      :hideCloseBtn="true"
      ref="selectBankModal"
      @selectBank="onSelectBank"
    >
      <template v-slot:failedOffer>
        <v-card-text class="px-4 px-md-16 pb-0 pb-md-3">
          <v-row no-gutters class="justify-space-between pr-6">
            <v-col cols="4">
              <div class="text-title mb-4">Asset:</div>
              <div
                v-for="(name, idx) in getFailedOffer.name"
                :key="idx"
                class="text-name-field"
              >
                {{ name }}
              </div>
            </v-col>
            <v-col cols="4">
              <div class="text-title mb-4">
                Deposit:

                <span class="info-icon">
                  <v-tooltip attach=".info-icon" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="#001154"
                        size="17"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      The deposit amount is divided equally between XL Admin and
                      the Seller, following a 50:50 split. The amount you will
                      receive as the Seller is detailed below.
                    </span>
                  </v-tooltip>
                </span>
              </div>
              <div class="text-deposit-field">
                {{ getFailedOffer.deposit }}
              </div>
            </v-col>
            <v-col v-if="role === 'admin'" cols="4">
              <div class="text-title mb-4">Role:</div>
              <div class="text-deposit-field">{{ assetsRole }}</div>
            </v-col>
          </v-row>
          <div class="text-center">
            <span class="add-bank-btn mt-4" @click="onAddNewBank">
              Add New Bank
            </span>
          </div>
        </v-card-text>
      </template>
    </BankingInfoSelectBankModal>

    <AddNewBankIndividualDataDialog ref="addNewBankIndividualDataDialog" />

    <AddBankDialog
      ref="addBankDialog"
      :hideCancelBtn="!getUserBanks"
      :checkSendRequest="false"
      :title="'Add Bank'"
      :attach="'.bank-selection-section'"
      :idValue="bankData"
      @addBank="onAddBank"
    ></AddBankDialog>

    <UserProfileBasicDialog ref="UserProfileBasicDialog" />

    <UserProfileSellerDialog ref="UserProfileSellerDialog" />
  </div>
</template>

<script>
import BankingInfoSelectBankModal from "@/components/ClosingDocument/BankingInfoSelectBankModal.vue";
import AddBankDialog from "@/components/GlobalDialogs/AddNewBankDialog.vue";
import { mapActions, mapGetters } from "vuex";
import ErrorParser from "@/utils/ErrorParser";
import AddNewBankIndividualDataDialog from "@/components/GlobalDialogs/AddNewBankIndividualDataDialog.vue";
import UserProfileSellerDialog from "@/components/GlobalDialogs/UserProfileSellerDialog.vue";
import UserProfileBasicDialog from "@/components/GlobalDialogs/UserProfileBasicDialog.vue";
export default {
  components: {
    UserProfileBasicDialog,
    UserProfileSellerDialog,
    AddNewBankIndividualDataDialog,
    AddBankDialog,
    BankingInfoSelectBankModal,
  },
  data() {
    return {
      newBank: null,
      failedOffers: [],
      selectedFailedOffer: null,
      role: "",
      assetsRole: "",
      bankData: null,
    };
  },
  computed: {
    ...mapGetters(["isSeller", "isAdmin"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getFailedOffer() {
      if (this.selectedFailedOffer) {
        return {
          name: this.selectedFailedOffer.assets.map((item) => item.title),
          deposit: this.$formatMoney(
            this.selectedFailedOffer.deposit.value.amount / 2
          ),
        };
      }
      return { name: "", deposit: "" };
    },
    getUserBanks() {
      const bankingInfo = this.$store.state.user.sellerProfile.bankingInfo;
      return bankingInfo && bankingInfo.length;
    },
    getBank() {
      if (this.newBank) {
        return this.newBank;
      }
      const banks = this.getUserBanks;
      if (banks) {
        return this.$store.state.user.sellerProfile.bankingInfo[0];
      }
      return {
        accountNumber: "",
        bankName: "",
        routingNumber: "",
      };
    },
  },
  async created() {
    let failedAssetsForSeller = null;
    let failedAssetsForAdmin = null;

    if (this.isSeller) {
      failedAssetsForSeller = await this.getFailedDocumentsAssetsForSeller();
    }
    if (this.isAdmin) {
      this.role = "admin";
      failedAssetsForAdmin = await this.getFailedDocumentsAssetsForAdmin();
    }

    if (failedAssetsForSeller) {
      this.assetsRole = "Seller";
      await this.handleFailedAssets(failedAssetsForSeller, "seller");
    }
    if (failedAssetsForAdmin) {
      this.assetsRole = "Admin";
      await this.handleFailedAssets(failedAssetsForAdmin, "admin");
      this.role = "";
    }
  },
  methods: {
    ...mapActions("Deposit", [
      "getFailedDocumentsAssetsForSeller",
      "getFailedDocumentsAssetsForAdmin",
    ]),
    ...mapActions("BDADocument", [
      "fetchOffer",
      "selectBankForOfferSeller",
      "selectBankForOfferAdmin",
    ]),
    ...mapActions("Seller", ["approveDepositSeller", "approveDeposit"]),
    async handleFailedAssets(failedAssets, role) {
      if (failedAssets?.assets?.length) {
        await Promise.all(
          failedAssets.assets.map((asset) => {
            const { assets: assetsList, winningOfferId } = asset ?? {};
            return this.fetchOffer(winningOfferId);
          })
        )
          .then((resp) => {
            this.failedOffers = resp;
          })
          .catch((err) => {
            ErrorParser(err);
          });

        for await (const offer of this.failedOffers) {
          const itemForBankModal = {
            ...offer,
            ...failedAssets.assets.find(
              (asset) => asset.winnerOfferId === offer.id
            ),
          };
          this.selectedFailedOffer = { ...itemForBankModal };

          const selectedBank = await this.$refs.selectBankModal.open();

          if (selectedBank) {
            if (role === "seller") {
              await this.handleFailedAssetsRequests(
                offer.id,
                selectedBank.bankingInfoId,
                "seller"
              );
            }
            if (role === "admin") {
              await this.handleFailedAssetsRequests(
                offer.id,
                selectedBank.bankingInfoId,
                "admin"
              );
            }
          }
        }
      }
    },
    async handleFailedAssetsRequests(offerId, bankingInfoId, role) {
      if (role === "seller") {
        const bankResp = await this.selectBankForOfferSeller({
          offerId: offerId,
          bankingInfoId: bankingInfoId,
        });
        const approveDepResp = await this.approveDepositSeller({
          id: offerId,
        });
      } else {
        const bankResp = await this.selectBankForOfferAdmin({
          offerId: offerId,
          bankingInfoId: bankingInfoId,
        });
        const approveDepResp = await this.approveDeposit({
          id: offerId,
        });
      }
    },
    async onAddNewBank() {
      let isUserFilled = this.isUserProfileFilled();
      if (!isUserFilled) {
        isUserFilled = await this.$refs.UserProfileBasicDialog.open();
      }
      if (!isUserFilled) {
        return;
      }

      let isSeller = true;
      if (!this.$store.getters.isSeller) {
        isSeller = await this.$refs.UserProfileSellerDialog.open();
        return;
      }
      if (!isSeller) {
        return;
      }

      this.bankData = await this.$refs.addNewBankIndividualDataDialog.open();
      if (this.bankData) {
        this.$refs.addBankDialog.open();
      }
    },
    onAddBank(newBank) {
      if (newBank) {
        this.newBank = newBank;
      }
    },
    onSelectBank(item) {
      this.newBank = item;
      this.$emit("addBankEnabled", this.newBank);
    },
    isUserProfileFilled() {
      const { firstName, lastName, officePhone } = this.$store.getters.user;
      return !!(firstName && lastName && officePhone);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-bank-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #001154;
  text-decoration: underline;
  cursor: pointer;
}
.info-icon {
  ::v-deep {
    .v-tooltip__content {
      width: 360px;
      border-radius: 1px;
      background: var(--secondary-cyan-soft, #f2f6ff);
      box-shadow: 2px 2px 15px 0 rgba(173, 173, 173, 0.25);
      &:before {
        display: none;
      }
      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        color: #001154;
      }
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      .v-tooltip__content {
        width: 100%;
        left: 0 !important;
      }
    }
  }
}
.text-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: #001154;
}
.text-name-field {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #001154;
}
.text-deposit-field {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #001154;
}
</style>
