import { API } from "aws-amplify";

export default (title, message) => {
  const query = `
	mutation sendAdminMessage($title: String!, $message: String!) {
    sendAdminMessage(
      title: $title
      message: $message
		)
  }`;

  return API.graphql({
    query,
    variables: {
      title,
      message,
    },
  });
};
