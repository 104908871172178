import { API } from "aws-amplify";

export default (id, title, message) => {
  const query = `mutation sendAnonymousAssetMessage($id: String!, $title: String!, $message: String!) {
    sendAnonymousAssetMessage(id: $id
                     title: $title
                     message: $message)
  }`;

  return API.graphql({
    query,
    variables: {
      id,
      title,
      message,
    },
  });
};
