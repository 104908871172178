import { API } from "aws-amplify";

export default (data) => {
  const query = `query countUserAssets(
        $assetStatus: [String],
        $status: [String],
        $propertyType: [String],
        $size: Int,
        $offset: Int,
        $address: String,
        $state: [String],
        $country: String,
        $city: String,
        $name: String,
        $sortBy:ESSortingBy
        $orderBy:OrderByChoices
        $owners: [String]
        ){
            searchAssets(
                assetStatus: $assetStatus,
                status: $status,
                propertyType: $propertyType,
                size: $size,
                offset: $offset
                address: $address,
                state: $state,
                country: $country,
                city: $city,
                name: $name
                sortBy:$sortBy
                orderBy:$orderBy
                owners:$owners
            ) {
             
                total
            }
          }`;

  return API.graphql({
    query,
    variables: {
      assetStatus: data.assetStatus || null,
      propertyType: data.propertyType || null,
      size: data.size || 12,
      offset: data.offset || 0,
      address: data.address || null,
      state: data.state || null,
      country: data.country || null,
      city: data.city || null,
      name: data.name ? data.name : data.assetName || null,
      sortBy: data.sortBy || null,
      orderBy: data.orderBy || null,
      owners: data.owners,
    },
    authMode: "AWS_IAM",
  });
};
