import { API } from "aws-amplify";

export default () => {
  const query = `query {
    getUserFavoriteAssets {
      assets {
        id
      }
    }
  }`;

  return API.graphql({
    query,
  });
};
