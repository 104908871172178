import { API } from "aws-amplify";
import { OFFER } from "@/services/apiCalls/types/Offer/Offer";
export default {
  firePlaidTransferWebhook(id) {
    const mutation = `mutation firePlaidTransferWebhook($id: String!) {
      firePlaidTransferWebhook(id: $id)
    }`;

    return API.graphql({
      query: mutation,
      variables: { id },
    });
  },
  firePlaidConnectionWebhook(variables) {
    const mutation = `mutation firePlaidConnectionWebhook($id: String!, $status: ConnectionStatus!) {
      firePlaidConnectionWebhook(id: $id, status: $status) {
        ${OFFER}
      }
    }`;

    return API.graphql({
      query: mutation,
      variables,
    });
  },

  getFailedDocumentsAssets() {
    const mutation = `query getFailedDocumentsAssets {
      getFailedDocumentsAssets {
        assets {
          id
          name
          winnerOfferId
        }
        winningOfferId
      }
    }`;

    return API.graphql({
      query: mutation,
      variables: {},
    });
  },
  getFailedDocumentsAssetsForAdmin() {
    const mutation = `query getFailedDocumentsAssetsForAdmin {
      getFailedDocumentsAssetsForAdmin {
        assets {
          id
          name
          winnerOfferId
        }
        winningOfferId
      }
    }`;

    return API.graphql({
      query: mutation,
      variables: {},
    });
  },
  getPayment(offerId) {
    const mutation = `query getPayment ($offerId: String) {
            getPayment(offerId:$offerId) {
              status
            }
        }`;

    return API.graphql({
      query: mutation,
      variables: { offerId },
    });
  },
  reactivateOffer(id) {
    const mutation = `mutation reactivateOffer($id: String!) {
      reactivateOffer(id: $id) {
        ${OFFER}
      }
    }`;

    return API.graphql({
      query: mutation,
      variables: { id },
    });
  },
  editDepositAmount(variables) {
    const mutation = `mutation editDepositAmount($offerId: String, $deposit: DepositInput) {
      editDepositAmount(offerId: $offerId, deposit: $deposit) {
        ${OFFER}
      }
    }`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  refundDeposit(id) {
    const mutation = `mutation refundDeposit ($id: String!) {
            refundDeposit(id:$id) {
                ${OFFER}
            }
        }`;
    return API.graphql({
      query: mutation,
      variables: { id },
    });
  },
  declineDeposit(variables) {
    const mutation = `mutation declineDeposit (
    $id: String!, $declineFault: DepositStatus!, $comment: String
    ) {
            declineDeposit(
            id:$id,
            declineFault:$declineFault,
            comment:$comment
            ) {
                ${OFFER}
            }
        }`;

    return API.graphql({
      query: mutation,
      variables,
    });
  },
  approveDeposit(variables) {
    const mutation = `mutation approveDeposit (
    $id: String!
    $depositAmount: Float
    ) {
            approveDeposit(
            id:$id,
            depositAmount:$depositAmount
            ) {
                ${OFFER}
            }
        }`;

    return API.graphql({
      query: mutation,
      variables,
    });
  },
  approveDepositSeller(variables) {
    const mutation = `mutation approveDepositSeller (
    $id: String!
    ) {
            approveDepositSeller(
            id:$id
            ) {
                ${OFFER}
            }
        }`;

    return API.graphql({
      query: mutation,
      variables,
    });
  },
};
