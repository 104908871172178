<template>
  <div class="bubble">
    <div class="avatar">
      <ChatAvatar
        :user="getMessageInfo"
        :avatar="getAvatar || getMessageInfo.avatar"
        :color="getMessageInfo.color"
      ></ChatAvatar>
    </div>
    <div class="msg-info pl-3">
      <div class="message">{{ getMessageInfo.body }}</div>
      <div class="time mt-2">{{ getMessageInfo.time }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChatAvatar from "@/components/Chat/ChatAvatar.vue";

export default {
  name: "ConversationMessage",
  components: { ChatAvatar },
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState("Chat", ["selectedConversation"]),
    getAvatar() {
      if (this.getMessageInfo.author === this.$store.getters.user.id) {
        return this.$store.getters.user.sellerProfile.avatar;
      }
      return "";
    },
    getMessageInfo() {
      if (this.message.state) {
        const { state } = this.message ?? {};
        const { author, body, timestamp } = state ?? {};

        let msg = { author, body };
        if (this.selectedConversation) {
          if (this.selectedConversation.buyerID === author) {
            const { buyerInfo } = this.selectedConversation ?? {};
            const { sellerProfile } = buyerInfo ?? {};
            const { avatar } = sellerProfile ?? {};
            msg = {
              ...msg,
              ...this.selectedConversation.buyerInfo,
              avatar,
            };
          } else if (this.selectedConversation.sellerID === author) {
            const { sellerInfo } = this.selectedConversation ?? {};
            const { sellerProfile } = sellerInfo ?? {};
            const { avatar } = sellerProfile ?? {};
            msg = {
              ...msg,
              ...this.selectedConversation.sellerInfo,
              avatar,
            };
          }
        }

        return {
          ...msg,
          time: this.$moment(timestamp).format("HH.mm"),
          name: msg.email ? `${msg.lastName} ${msg.firstName}` : "Asset Seller",
        };
      }

      return { sellerProfile: { avatar: "" } };
    },
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  display: flex;
  padding: 10px 0;
  margin: 10px auto 10px 0;

  .message {
    word-break: break-word;
  }
}
.msg-info {
  opacity: 0.7;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #001154;
}
::v-deep {
  .avatar img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  .rounded-username {
    position: relative;
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 8px 25px 0px rgba(0, 17, 84, 0.25);

    .active-pointer {
      position: absolute;
      width: 14px;
      height: 14px;
      background: #368600;
      border-radius: 50%;
      border: 1px solid white;
      bottom: 0px;
      right: -3px;
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .bubble {
    margin: 5px auto 5px 0;
  }
}
</style>
