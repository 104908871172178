<template>
  <v-dialog
    v-model="dialog"
    :width="!isMobile ? width || 834 : '100%'"
    style="z-index: 300"
    persistent
    content-class="reg-dialog"
    attach=".v-application--wrap"
    @keydown.esc="canClose && close(false)"
  >
    <div class="d-flex custom-class" :class="contentClass">
      <v-btn
        v-if="valuation"
        class="close-valuation"
        icon
        @click="onToggleConfirmDialog(true)"
      >
        <v-icon size="16" color="white">mdi-close</v-icon>
      </v-btn>

      <template v-if="!isMobile">
        <v-img
          v-if="!dialogImage"
          src="@/assets/img/registerDialog/stock-build.png"
          alt="Login Form - huge build"
          max-height="650"
        />
        <!--        height="741"-->
        <!--        :min-height="741"-->
        <img
          class="dialog-image"
          v-else
          :src="dialogImage"
          alt="Login Form - huge build"
        />
      </template>
      <v-card class="cardPopUp" :width="!isMobile ? 452 : '100%'">
        <div class="dialog-type-wrapper">
          <div class="dialog-type-wrapper__item" @click="openLoginDialog">
            Sign in
          </div>
          <div
            class="dialog-type-wrapper__item dialog-type-wrapper__item--active"
          >
            Sign Up Now
          </div>
        </div>
        <v-card-title
          class="cardPopUp__title flex-column"
          :class="!contentClass && 'paddings'"
        >
          <v-btn
            class="custom-close-icon"
            icon
            @click="canClose ? (dialog = false) : null"
          >
            <v-icon size="16" color="white">mdi-close</v-icon>
          </v-btn>

          <v-img
            class="mb-1 xl-logo"
            src="@/assets/img/logo-updated.svg"
            alt="Xchange.Loans - the online marketplace for non-performing loans"
            width="194"
          />

          <div
            class="cardPopUp__title__text"
            :class="!contentClass && 'paddings'"
          >
            {{ title ? title : registrationTitle }}
          </div>
        </v-card-title>

        <v-card-text
          class="cardPopUp__text"
          :class="!contentClass && 'paddings'"
        >
          <SimplifiedRegisterForm
            v-if="getSimplifiedRegisterFlag"
            :registrationText="registrationText"
          />
          <register-form
            v-else
            :registrationText="registrationText"
            :initial-email="email"
            :formTitle="subtitle"
            :afterResendCodeDialogClose="afterResendCodeDialogClose"
            :afterRegister="afterRegister"
            @handleRoleChangeSubscription="
              $emit('handleRoleChangeSubscription')
            "
          />
        </v-card-text>
      </v-card>
    </div>

    <confirm-close-valuation-dialog
      :dialog="confirmDialog"
      @close-dialog="onToggleConfirmDialog(false)"
      @confirm-close-dialog="onCloseRegisterDialog"
    />
  </v-dialog>
</template>

<script>
import RegisterForm from "@/components/Auth/RegisterForm.vue";
import SimplifiedRegisterForm from "@/components/Auth/SimplifiedRegisterForm.vue";
import ConfirmCloseValuationDialog from "@/components/GlobalDialogs/ConfirmCloseValuationDialog.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "RegisterDialog",
  components: {
    ConfirmCloseValuationDialog,
    SimplifiedRegisterForm,
    RegisterForm,
  },
  data() {
    return {
      dialog: false,
      confirmDialog: false,
      valuation: false,
      resolve: null,
      email: null,
      contentClass: null,
      title: null,
      subtitle: null,
      dialogImage: null,
      width: null,
      canClose: true,
      //function called after user closes resend code dialog
      afterResendCodeDialogClose: null,
      afterRegister: null,
      registrationText:
        "Create a free account to make offers and gain\n" +
        "access to exclusive Xchange.Loans assets",
      registrationTitle: "Registration Form",
    };
  },

  computed: {
    ...mapGetters("UserSettings", ["getSimplifiedRegisterFlag"]),
    ...mapState("Helpers", ["authModalOptions"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    loggedIn() {
      return this.$store.state.auth.user && this.$store.state.user;
    },
  },

  watch: {
    loggedIn(newVal) {
      if (newVal && this.dialog) {
        this.close(true);
      }
    },
  },

  created() {
    this.$root.openRegisterDialog = this.open;
    this.$root.closeRegisterDialog = this.close;
  },

  methods: {
    ...mapMutations("Helpers", ["setAuthModalOptions"]),
    openLoginDialog() {
      this.$root.closeRegisterDialog();
      this.$root.openLoginDialog({ email: this.email });
    },
    onToggleConfirmDialog(value) {
      this.confirmDialog = value;
    },
    onCloseRegisterDialog() {
      this.setAuthModalOptions(null);
      this.close(true);
      this.$router.push("/");
    },
    open(optionsParams = {}) {
      const options = this.authModalOptions || optionsParams;
      if (options && Object.keys(optionsParams).length) {
        this.setAuthModalOptions(optionsParams);
      }
      this.$emit("opened");

      if (options?.email) {
        this.email = options.email;
      }
      if (options?.contentClass) {
        this.contentClass = options.contentClass;
      }
      if (options?.title) {
        this.title = options.title;
      }
      if (options?.subtitle) {
        this.subtitle = options.subtitle;
      }
      if (options?.dialogImage) {
        this.dialogImage = options.dialogImage;
      }
      if (options?.width) {
        this.width = options.width;
      }
      if (options?.afterResendCodeDialogClose) {
        this.afterResendCodeDialogClose = options.afterResendCodeDialogClose;
      }
      if (options?.afterRegister) {
        this.afterRegister = options.afterRegister;
      }
      if (options.hasOwnProperty("canClose")) {
        this.canClose = options.canClose;
      }
      if (options?.valuation) {
        this.valuation = options.valuation;
      }

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("regdesc")) {
        let urlText = urlParams.get("regdesc").replace(/[\/_]/g, " ");
        this.registrationText = urlText;
      }
      if (urlParams.has("regtitle")) {
        let urlText = urlParams.get("regtitle").replace(/[\/_]/g, " ");
        this.registrationTitle = urlText;
      }

      this.dialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close(v) {
      this.dialog = false;
      this.resolve(v);
      this.email = null;
      this.contentClass = null;
      this.title = null;
      this.valuation = false;
      this.subtitle = null;
      this.dialogTitle = null;
      this.width = null;
      this.canClose = true;
      this.afterResendCodeDialogClose = null;
      this.dialogImage = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-messages__message {
    text-align: right;
    font-size: 10px;
  }
  .second-input-with-background .v-input {
    margin-bottom: 14px;
    padding-right: 0;
  }
  .v-card__title {
    color: #0f2d59;
    justify-content: flex-start;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.32px;
    &.paddings {
      padding: 32px 40px 0 !important;
    }
  }
  .cardPopUp__description {
    font-size: 14px;
    line-height: 17px;
    text-align: start;
    margin-bottom: 14px;
    letter-spacing: 0;
    margin-top: 10px;
  }
  .v-input__slot {
    padding-top: 4px;
    font-size: 14px;
    font-weight: 500;
  }
  .logo-container {
    height: 45px;
  }
  .zentinel-logo {
    height: 43px !important;
  }
  .v-size--default {
    min-height: 32px !important;
  }
  .cardPopUp {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    //overflow: hidden; // !!!!!!!!
  }
  .cardPopUp__text {
    &.paddings {
      padding: 0 40px 0 !important;
    }
  }
  .v-size--default {
    font-size: 12px !important;
  }
  .v-btn.v-size--small .v-btn__content {
    font-size: 9px;
    margin-left: -18px;
  }
  .v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #9aabc2 !important;
    color: #ffffff !important;
    font-size: 12px !important;
    letter-spacing: 0 !important;
    max-height: 40px;
  }
  .v-btn--has-bg {
    background-color: #2969ff !important;
    color: #ffffff;
    border-radius: 5px;
    max-height: 40px;
    font-size: 12px !important;
    margin-bottom: 3px;
    letter-spacing: 0 !important;
  }
  .v-icon {
    margin-bottom: 1px;
    margin-right: 2px;
  }
  .v-messages {
    margin-bottom: -1px;
  }
}
.custom-close-icon {
  position: absolute;
  top: 2px;
  right: 0;
  z-index: 30;
}
.cardPopUp__title__text {
  color: #001154;
  margin-top: 16px;
  margin-bottom: -1px;
  align-self: self-start;
  text-align: left;
}

.v-icon {
  font-size: 18px !important;
}
::v-deep {
  @media (max-width: 600px) {
    .xl-logo {
      display: none;
    }
    .v-card__title.paddings {
      padding-top: 24px !important;
    }
    .cardPopUp__text.paddings {
      padding: 0 16px !important;
    }
    .cardPopUp__title__text {
      margin: 0 !important;
      font-size: 24px;
      line-height: 32px;
      color: #001154;
    }
    .title-description {
      font-size: 14px;
      line-height: 18px;
      color: #001154;
      opacity: 0.8;
      text-align: center;
      margin-bottom: 24px;
    }
    .second-input-with-background {
      .c-label {
        color: rgba(0, 17, 84, 0.8);
      }
      .v-input {
        margin-bottom: 16px;
      }
      &.email-repeat {
        margin-bottom: 32px;
      }
      input {
        font-size: 14px;
        color: #001154;
        &::placeholder {
          opacity: 0.45;
          color: #001154;
        }
      }
    }
    .list__wrapper {
      padding: 8px 16px !important;
      height: 103px;
      margin: 16px 0;
      .validation__description {
        margin: 0 0 4px;
        font-size: 12px;
        line-height: 20px;
        color: #001154;
        opacity: 0.8;
      }
      .listValidatePassword {
        margin: 0 !important;
        height: 70px !important;
      }
      .list__item {
        font-size: 12px;
        line-height: 15px;
        color: #001154 !important;
        opacity: 0.8;
        margin-bottom: 8px;
        margin-right: 56px;
      }
    }
    .submit-btn {
      height: 40px !important;
      // background: #9aabc2 !important;
      border-radius: 7px;
      max-height: none !important;
      margin-bottom: 16px;
      span {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .text-check {
      .v-btn {
        padding: 0;
        margin-left: 4px;
      }
    }
    .text-check,
    .text-check span {
      font-size: 12px !important;
      line-height: 20px;
      margin: 0;
    }
    .reg-dialog {
      margin: 0;
      max-height: none;
      height: 100%;
      > div {
        //height: 100%; // !!!!!!
      }
    }
  }
}
::v-deep {
  .v-image {
    // min-height: 715px;
  }
}

.close-valuation {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 300;
  ::v-deep {
    .v-btn__content i {
      margin: 0 !important;
    }
  }
}

.custom-class {
  height: auto;
  min-height: 100%;
  //max-height: 741px;
}
.dialog-type-wrapper {
  width: 100%;
  background: #001154;
  height: 42px;
  padding-left: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  column-gap: 40px;
  &__item {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
    position: relative;
    &--active {
      &::before {
        content: "";
        height: 2px;
        display: block;
        background: #dd226f;
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
  }
}
::v-deep {
  .c-label {
    font-size: 14px !important;
  }
  @media (max-width: 600px) {
    .c-label {
      font-size: 12px !important;
    }
    .v-input__slot {
      font-size: 12px;
    }
    input {
      font-size: 14px;
    }
  }
}
::v-deep {
  .v-dialog {
    overflow-x: hidden !important;
  }
}
@include mediaQ(600, 960) {
  ::v-deep .v-dialog {
    margin: 0;
    height: 100%;
    max-height: none;
  }
}
</style>
