import { VALUE_AMOUNT } from "../Global/ValueAmount";
import { OFFER_ASSET } from "./OfferAsset";
import { DEPOSIT } from "@/services/apiCalls/types/Offer/Deposit";
import { DEPOSIT_HISTORY } from "@/services/apiCalls/types/Offer/DepositHistory";
export const OFFER_HISTORY = `
assets{
    ${OFFER_ASSET}
}
bidPrice{
    ${VALUE_AMOUNT}
}
closing
dateModified
dueDiligencePeriod
earnestMoneyDeposit{
    ${VALUE_AMOUNT}
} 
notes
status
deposit{
${DEPOSIT}
}

`;
