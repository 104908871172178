import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default async (assetInfo) => {
  console.log("assetInfo1 - ", assetInfo);
  const mutation = `mutation editAssetOnNext(
        $id: String!,
        $currentStep: String,
        $address: AddressInput,
        $principalBalance: ValueAmountInput,
        $upbDate: String,
        $assetStatus: [String],
        $noteOverview: AssetNoteInput,
        $name: String,
        $propertyOverview: PropertyInfoInput,
        $type: AssetType,
        $listingPrice: ValueAmountInput,
        $description: String,
      ) {
        editAsset(
          id: $id
          currentStep: $currentStep,
          address: $address,
          principalBalance: $principalBalance,
          upbDate: $upbDate
          assetStatus: $assetStatus,
          noteOverview: $noteOverview,
          name: $name
          propertyOverview: $propertyOverview,
          type: $type
          listingPrice: $listingPrice,
          description: $description
        ) {
         ${ASSET}
        }
      }`;

  let variables = assetInfo;

  return API.graphql({
    query: mutation,
    variables,
  });
};
