import { API } from "aws-amplify";
export default (variables) => {
  const query = `mutation generateLegalDocument($id: String! , $docType: DocumentTypeForSign!) {
    generateLegalDocument(id:$id , docType:$docType) {
      type
      pdfKey
      rawKey
      dateModified     
     }
    }
  `;

  return API.graphql({
    query,
    variables,
  });
};
