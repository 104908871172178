<template>
  <div class="app-main-input">
    <c-label :required="!$attrs.hideLabel"> {{ $attrs.labelText }} </c-label>
    <v-text-field
      v-bind="$attrs"
      v-model="modelValue"
      :height="isMobile ? '40px' : '50px'"
    >
      <template v-slot:append>
        <slot name="append"> </slot>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import Clabel from "@/components/Common/CLabel.vue";

export default {
  name: "AppInput",
  components: {
    "c-label": Clabel,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    modelValue: {
      set(v) {
        this.onChange(v);
      },
      get() {
        return this.$attrs.value;
      },
    },
  },
  methods: {
    onChange(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style scoped lang="scss">
.app-main-input {
  ::v-deep {
    .v-text-field__details {
      min-height: unset;
      .v-messages {
        min-height: unset;
      }
    }
  }
  .v-text-field,
  .address-container {
    // height: 50px !important;
    padding-top: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
  }
  //   .google-input {
  //     top: 15px;
  //   }

  .v-input,
  .google-input {
    background-color: #f3f3f3;
    padding-left: 10px;
    padding-right: 20px;
    color: #001154 !important;
    &--is-focused {
      outline: 1px solid rgba(41, 105, 255, 0.6);
      &::after {
        display: none;
      }
    }
    &.error--text {
      background: rgba(213, 40, 25, 0.08);
      outline: 1px solid rgba(213, 40, 25, 0.6);
      ::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 26px;
        /* identical to box height, or 217% */

        color: rgba(213, 40, 25, 0.7) !important;
      }
    }
  }
  .c-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 17px;
    /* identical to box height, or 155% */

    /* DarkBlue */
  }
  ::v-deep {
    .v-input__slot {
      margin-bottom: 0px !important;
      &:before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }

  .req-icon {
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    /* identical to box height, or 155% */

    /* Red */

    color: #d52819;
  }
  .v-input--is-disabled {
    background: rgba(241, 241, 241, 0.4);
    background-color: rgba(241, 241, 241, 0.4) !important;
  }
}
</style>
