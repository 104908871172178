import { AssetSubscriptionManager } from "@/utils/assets/AssetSubscriptionManager";
let store;
export const initAssetSubscriptions = async (vuex) => {
  store = vuex;
  console.log("store", store);
  const assetSubscriptions = [
    {
      name: "subscribeToNoticeAssetActivitiesUpdate",
      onNext: (data) => {
        // eslint-disable-next-line no-console
        const result = data.value.data.subscribeToNoticeAssetActivitiesUpdate;
        console.log("subscribeToNoticeAssetActivitiesUpdate", result);
        onActivitiesUpdate(result);
      },
    },
    {
      name: "subscribeToNoticeViewedOnlyAndAllActivitiesUpdate",
      onNext: (data) => {
        const result =
          data.value.data.subscribeToNoticeViewedOnlyAndAllActivitiesUpdate;
        console.log(
          "subscribeToNoticeViewedOnlyAndAllActivitiesUpdate",
          result
        );

        onActivitiesUpdate(result);
      },
    },
    {
      name: "subscribeToAssetUpdatesBySeller",
      onNext: (data) => {
        const result = data.value.data.subscribeToAssetUpdatesBySeller;
        console.log("subscribeToAssetUpdatesBySeller", result);

        onSingleAssetUpdate(result);
      },
    },
    {
      name: "subscribeToAssetsUpdatesByBot",
      onNext: (data) => {
        const result = data.value.data.subscribeToAssetsUpdatesByBot;
        console.log("subscribeToAssetsUpdatesByBot", result);
        onUpdateAssetsByBot(result);
      },
    },
    {
      name: "subscribeToNoticeESAssetsUpdate",
      onNext: (data) => {
        console.log(
          "subscribeToNoticeESAssetsUpdate",
          data.value.data.subscribeToNoticeESAssetsUpdate
        );
        const result = data.value.data.subscribeToNoticeESAssetsUpdate;
        onEsAssetsUpdate(result);
      },
    },
  ];

  try {
    await new AssetSubscriptionManager(assetSubscriptions).subscribe();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("sub dashboard error", err);
  }
};
const onEsAssetsUpdate = (assets) => {
  assets.forEach((updatedAsset) => {
    for (const key in updatedAsset) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        updatedAsset.hasOwnProperty(key) &&
        updatedAsset[key] === null
      ) {
        delete updatedAsset[key];
      }
    }
    store.commit("UserAssets/updateAssetById", {
      assetId: updatedAsset.id,
      newAsset: updatedAsset,
    });
  });
};

const onSingleAssetUpdate = (updatedAsset) => {
  // eslint-disable-next-line no-console
  console.log("onSingleAssetUpdate", updatedAsset);
  for (const key in updatedAsset) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      updatedAsset.hasOwnProperty(key) &&
      updatedAsset[key] === null
    ) {
      delete updatedAsset[key];
    }
  }
  // eslint-disable-next-line no-console
  const userAssets = store.state.UserAssets.userAssets;

  const assetToBeReplaced = userAssets.find(
    (asset) => asset.id === updatedAsset.id
  );

  store.commit("UserAssets/replaceAssetById", {
    assetId: updatedAsset.id,
    newAsset: {
      ...assetToBeReplaced,
      status: updatedAsset.status,
      name: updatedAsset.name,
      principalBalance: updatedAsset.principalBalance.amount,
      bestAndFinalPeriod: updatedAsset.bestAndFinalPeriod,
      callForOffersPeriod: updatedAsset.callForOffersPeriod,
      underSelectionPeriod: updatedAsset.underSelectionPeriod,
    },
  });
};

const onActivitiesUpdate = (updatedAssets) => {
  updatedAssets.forEach((updatedAsset) => {
    for (const key in updatedAsset) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        updatedAsset.hasOwnProperty(key) &&
        updatedAsset[key] === null
      ) {
        delete updatedAsset[key];
      }
    }
    store.commit("UserAssets/updateAssetById", {
      assetId: updatedAsset.id,
      newAsset: updatedAsset,
    });
  });
};
const onUpdateAssetsByBot = (updatedAssets) => {
  updatedAssets.forEach((updatedAsset) => {
    onSingleAssetUpdate(updatedAsset);
  });
};
