import { render, staticRenderFns } from "./SubHeader.vue?vue&type=template&id=4009ac70&scoped=true"
import script from "./SubHeader.vue?vue&type=script&lang=js"
export * from "./SubHeader.vue?vue&type=script&lang=js"
import style0 from "./SubHeader.vue?vue&type=style&index=0&id=4009ac70&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4009ac70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCol,VContainer,VRow,VTab,VTabs})
