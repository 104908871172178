import { ES_OFFER_ASSET } from "@/services/apiCalls/types/Offer/ESOfferAsset";
import { CONTACT } from "@/services/apiCalls/types/Contact/Contact";
import { VALUE_AMOUNT } from "@/services/apiCalls/types/Global/ValueAmount";
import { PERIOD } from "@/services/apiCalls/types/Global/Period";
import { OFFER_HISTORY } from "@/services/apiCalls/types/Offer/OfferHistory";
import { DEPOSIT } from "@/services/apiCalls/types/Offer/Deposit";
import { DOCUMENT_FOR_SIGN } from "@/services/apiCalls/types/Offer/DocumentForSign";

export const ES_OFFER = `
  id
  offerType
  dateModified
  status
  assets{
  ${ES_OFFER_ASSET}
  }
  bidPrice
  closing
  dueDiligencePeriod
  earnestMoneyDeposit
  notes
  offerHistory{
  ${OFFER_HISTORY}
  }
  buyerFirstName
  buyerLastName
  creationDate
  acceptedDate
  winningDate
  declinedDate
  withdrawDate
  publishedDate
  contacts{
  ${CONTACT}
  }
  fundsApprovalDate
  owners
  pofSum{
  ${VALUE_AMOUNT}
  }
  type
  buyerClosingDocumentsAccepted
  sellerClosingDocumentsAccepted
  BDASignedDate
  BDAConfirmed
  BDApdfKey
  SSDocument{
  ${DOCUMENT_FOR_SIGN}
  }
  buyerBankingInfoId
  sellerBankingInfoId
  deposit{
  ${DEPOSIT}
  }
  depositPaid
  depositRefund
  depositWithdrawn
  declineReason
  buyerClosingPeriod{
  ${PERIOD}
  }
  sellerClosingPeriod{
  ${PERIOD}
  }
`;
