const getHashCode = (initials) => {
  const hashCode = initials
    .split("")
    .reduce((a, b) => (a << 5) - a + b.charCodeAt(0), 0);
  const hue = (hashCode % 360) / 360;

  const hsvToRgb = (h, s, v) => {
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);
    const mod = i % 6;
    const colors = [
      [v, t, p],
      [q, v, p],
      [p, v, t],
      [p, q, v],
      [t, p, v],
      [v, p, q],
    ];
    const [r, g, b] = colors[mod];
    return `rgb(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(
      b * 255
    )})`;
  };

  return hsvToRgb(hue, 0.75, 0.8);
};

export default getHashCode;
