<template>
  <v-dialog
    v-model="isDialogOpen"
    persistent
    @keydown.esc="isDialogOpen = false"
  >
    <div class="dialog-content">
      <div class="dialog-content__title">
        {{ dialogData.title }}
      </div>
      <div class="dialog-content__info">
        Please describe the reason for revoking
      </div>
      <div class="textarea-wrapper">
        <v-textarea
          solo
          v-model="comment"
          label="Comment for revoking"
        ></v-textarea>
      </div>
      <div class="buttons">
        <v-btn width="50%" color="white" @click="onSkipBtnClick"> Skip </v-btn>
        <v-btn
          width="50%"
          color="#7FE0E8"
          @click="onSaveBtnClick"
          :disabled="!comment"
        >
          Save
        </v-btn>
      </div>
      <div class="close-icon-wrapper" @click="isDialogOpen = false">
        <v-icon color="primary"> mdi-close </v-icon>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: "RevokeDoalog",
  data() {
    return {
      comment: "",
    };
  },
  computed: {
    isDialogOpen: {
      get() {
        return this.$store.state.revokeDialog.isRevokeDialogOpen;
      },
      set(val) {
        this.$store.commit("setRevokeDialogData", {
          isRevokeDialogOpen: val,
          title: "",
          id: "",
        });
        this.comment = "";
      },
    },
    dialogData() {
      return this.$store.state.revokeDialog;
    },
  },
  methods: {
    onSaveBtnClick() {
      this.dialogData.function(this.comment);
      this.isDialogOpen = false;
      this.comment = "";
    },
    onSkipBtnClick() {
      this.dialogData.function("");
      this.isDialogOpen = false;
      this.comment = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  background: white;
  width: 557px;
  display: flex;
  flex-direction: column;
  padding: 22px 30px;
  position: relative;

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 152%;

    letter-spacing: 0.444444px;

    color: #001154;
  }
  &__info {
    margin-top: 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    color: #001154;
  }
}
::v-deep .v-dialog {
  width: 1200px;
  display: flex;
  justify-content: center;
}
.buttons {
  display: flex;
}
::v-deep {
  .v-textarea {
    margin-top: 20px;
    .v-input__control {
      border-radius: 0;
    }
    .v-input__slot {
      background-color: #f7f7f7 !important;
      border: 1px solid #d8d8d8;
      box-shadow: none !important;
    }
  }
}
.textarea-wrapper {
  margin-top: 25px;
}
.close-icon-wrapper {
  position: absolute;
  top: 28px;
  right: 28px;
  cursor: pointer;
}
</style>
