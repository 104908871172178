<template>
  <v-expansion-panels class="chat-list-section">
    <v-expansion-panel>
      <v-expansion-panel-header class="ma-0 py-2 px-3">
        <span class="avatar-section">
          <ChatAvatar
            :user="$store.getters.user"
            :avatar="userAvatar"
            :color="getColor"
          ></ChatAvatar>
          <span class="ml-3">{{ headerListText }}</span>

          <ConversationChatListNewMsg
            :counter="getNewMsgCounter"
            class="list-header mt-n1 ml-2 pb-1"
          ></ConversationChatListNewMsg>
        </span>
        <template v-slot:actions>
          <v-icon color="primary"> $expand </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="list-rows-wrapper">
          <ConversationChatListLine
            v-for="(chat, chatIdx) in getItemsList"
            :ref="`${label}-${chatIdx}-line`"
            :key="chat.sid"
            :label="label"
            :chat="chat"
          ></ConversationChatListLine>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ChatAvatar from "@/components/Chat/ChatAvatar.vue";
import ConversationChatListLine from "@/components/Chat/ConversationChatListLine.vue";
import ConversationChatListNewMsg from "@/components/Chat/ConversationChatListNewMsg.vue";
import { mapGetters, mapState } from "vuex";
import getHashCode from "@/utils/HashCode";
import getInitials from "@/utils/Initials";

export default {
  name: "ConversationChatList",
  components: {
    ConversationChatListNewMsg,
    ConversationChatListLine,
    ChatAvatar,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    console.log("this.openedPanel - ", this.openedPanel);
    await this.$nextTick();
    console.log("this.$refs - ", this.$refs);
  },
  computed: {
    ...mapState("Chat", ["buyerChats", "sellerChats", "openedPanel"]),
    ...mapGetters("Chat", [
      "sellerChatWithMessagesList",
      "buyerChatWithMessagesList",
    ]),
    headerListText() {
      if (
        this.sellerChatWithMessagesList.length ||
        this.buyerChatWithMessagesList.length
      ) {
        if (this.label === "buyer") {
          return "Messaging with sellers";
        } else if (this.label === "seller") {
          return "Messaging with buyers";
        }
      }
      return "Messages";
    },
    getColor() {
      const inits = getInitials(this.$store.getters.user);

      const color = getHashCode(inits);

      return color;
    },
    getItemsList() {
      let list = [];
      if (this.label === "buyer") {
        list = [...this.buyerChatWithMessagesList];
      } else if (this.label === "seller") {
        list = [...this.sellerChatWithMessagesList];
      }
      return list;
    },
    getNewMsgCounter() {
      if (this.$refs.length) {
        let total = 0;
        this.$refs.forEach((ref) => {
          total += ref.getNewMsgCount;
        });
        return total;
      }
      return this.label === "buyer"
        ? this.getBuyerUnreadMsgsCount
        : this.label === "seller"
          ? this.getSellerUnreadMsgsCount
          : 0;
    },
    userAvatar() {
      if (this.$store.getters.user) {
        const { sellerProfile } = this.$store.getters.user ?? {};
        const { avatar = "" } = sellerProfile ?? {};
        return avatar;
      }
      return "";
    },
    getBuyerUnreadMsgsCount() {
      const counter = this.buyerChats.reduce((acc, item) => {
        if (
          Number.isInteger(item.TWILIO_LastMessage?.index) &&
          item.TWILIO_lastReadMessageIndex === null
        ) {
          return item.TWILIO_LastMessage?.index + 1;
        } else if (
          item.TWILIO_LastMessage &&
          Number.isInteger(item.TWILIO_lastReadMessageIndex)
        ) {
          acc +=
            item.TWILIO_LastMessage.index - item.TWILIO_lastReadMessageIndex;
        } else if (
          item.TWILIO_LastMessage &&
          !item.TWILIO_lastReadMessageIndex
        ) {
          acc += item.TWILIO_LastMessage.index;
        }
        return acc;
      }, 0);
      return counter;
    },
    getSellerUnreadMsgsCount() {
      const counter = this.sellerChats.reduce((acc, item) => {
        if (
          item.attributes?.lastMessageOwnerId === this.$store.getters.user.id
        ) {
          return 0;
        }

        if (
          item.TWILIO_LastMessage &&
          Number.isInteger(item.TWILIO_lastReadMessageIndex)
        ) {
          acc +=
            item.TWILIO_LastMessage.index - item.TWILIO_lastReadMessageIndex;
        } else if (
          item.TWILIO_LastMessage &&
          !item.TWILIO_lastReadMessageIndex
        ) {
          acc += item.TWILIO_LastMessage.index + 1;
        }
        return acc;
      }, 0);
      return counter;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-list-section {
  width: 422px;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 8px 25px 0px rgba(0, 17, 84, 0.25);

  .avatar-section {
    display: flex;
    justify-content: start;
    align-items: center;
    ::v-deep {
      .avatar img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .rounded-username {
        position: relative;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        border-radius: 50%;
        box-shadow: 0px 8px 25px 0px rgba(0, 17, 84, 0.25);

        .active-pointer {
          position: absolute;
          width: 14px;
          height: 14px;
          background: #368600;
          border-radius: 50%;
          border: 1px solid white;
          bottom: 0px;
          right: -3px;
        }
      }
    }
  }

  .list-rows-wrapper {
    max-height: 600px;
    overflow: auto;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .chat-list-section {
    width: 100%;
  }
}
</style>

<style lang="scss">
.chat-list-section {
  .list-header .new-msg-icon {
    padding-bottom: 3px;
  }

  .v-expansion-panel .v-expansion-panel-header__icon .v-icon {
    transform: rotate(180deg);
  }
  .v-expansion-panel.v-expansion-panel--active
    .v-expansion-panel-header__icon
    .v-icon {
    transform: rotate(0deg) !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-expansion-panel .v-expansion-panel-header__icon {
      //margin-right: 100px;
    }
  }
}
</style>
