import { API } from "aws-amplify";
import { ASSET } from "@/services/apiCalls/types/Asset/Asset";

export default async (assetInfo) => {
  console.log("assetInfo1 - ", assetInfo);
  const mutation = `mutation editAssetOnNext(
        $currentStep: String,
        $id: String!,
        $images: [String],
      ) {
        editAsset(
          currentStep: $currentStep,
          id: $id
          images: $images
        ) {
        ${ASSET}
        }
      }`;

  let variables = assetInfo;

  return API.graphql({
    query: mutation,
    variables,
  });
};
