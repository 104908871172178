import { API } from "aws-amplify";

export default (variables) => {
  const query = `query searchUsers(
      $query: String,
      $roles: [UserRole],
      $size: Int,
      $offset: Int,
      $sortBy: ESProfilesSortingBy
      $orderBy: OrderByChoices
      ) {
        searchUsers( 
          roles:$roles,
          query:$query,
          roles:$roles,
          size:$size,
          offset:$offset,
          sortBy:$sortBy,
          orderBy:$orderBy,
           ){
            profiles{
              lastLogin
              roles
              id
              aum
              firstName
              lastName
              companyName
              companyProfile
              email
              faxNumber
              officePhone
              cellPhone
              jobTitle
              notificationsCriteria{
               assetType
                receiveNotificationsWhen
                locations
                minUpb{
                amount
                currency
                }
                maxUpb{
                amount
                currency
                }
                
                userRole,
                receivePipelineNotifications
                assetStatus,
                assetType,
                collateralType,
                collateralSubtype,
              }
              address{
                country
                state
                city
                address 
                zipCode
              }
              sellerProfile {
                entityName
                entityAddress {
                  country
                  state
                  city
                  address
                  zipCode
                }
                avatar
                faxNumber
                phoneNumber
                financePartner
                executive
                states
                title
                status
                comment
                dateModified
                confirmationDate
                signedByFirstName
                signedByLastName
              }
              buyerProfile {
                approved
                pofSum
                purchasingEntity
                approvedBy{
                  firstName
                  lastName
                  email
                
                }
                purchasingEntityAddress {
                  address
                  country
                  state
                  city
                  zipCode
                }
                mmgpFirstName
                mmgpLastName
                mmgpAddress {
                  address
                }
                pofDocument {
                  type
                  key
                  name
                  dateModified
                  dataOwnerId
                }
                fundsApprovalDate
              }
            }
          }
            }`;
  return API.graphql({
    query,
    variables: {
      // roles: ["RestrictedBuyers", "ApprovedBuyers"],
      ...variables,
    },
  });
};
