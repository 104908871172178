export function scrollBehavior(to, from, savedPosition) {
  if (to.hash) {
    return {
      selector: to.hash,
    };
  }
  if (
    to.fullPath.includes("/buy-commercial-real-estate-loans?assetId") ||
    from.fullPath.includes("/buy-commercial-real-estate-loans?assetId") ||
    from.fullPath.includes("?assetId")
  ) {
    return;
  }
  if (
    to.fullPath.includes("/admin/buyer-requests") ||
    to.fullPath.includes("/profile/billing") ||
    to.fullPath.includes("?assetId")
  ) {
    return;
  }

  let position = { x: 0, y: 0 };
  if (savedPosition) {
    position = savedPosition;
  }
  if (to.fullPath === "/" && from.fullPath !== "/") {
    return position;
  }
  if (to.fullPath === "/" && from.fullPath === "/") {
    return;
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log("scroll resolve postition");
      resolve(position);
    }, 0);
  });
}
