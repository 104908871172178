import { API } from "aws-amplify";
export default (variables) => {
  const query = `query getUserAssetData_Sidebar($id: String, $userId: String) {
    getUserAssetData(id:$id , userId:$userId ) {
        PK
  SK
  CAExecutionDate
  Tier2AccessDate
  latestVisitDate
  totalVisits
  
  executedDocuments{
  CA{
  executionDate
  pdfKey
  }
  }
      }
    }
  `;

  return API.graphql({
    query,
    variables,
  });
};
