<template>
  <v-row class="form-content__fields" no-gutters>
    <v-col class="pr-1" cols="6">
      <div class="second-input-with-background">
        <c-label required> Entity Name </c-label>
        <v-text-field
          autocomplete="off"
          placeholder="Xchange.Loans, Inc."
          v-model="formData.entityName"
          :error-messages="getValidationError('formData.entityName')"
        />
      </div>
    </v-col>
    <v-col class="pl-1" cols="6">
      <div class="second-input-with-background">
        <c-label required> Entity Address </c-label>
        <v-text-field
          v-model="formData.entityAddress.address"
          autocomplete="off"
          placeholder="1100 5th Ave S, Napl.."
          id="sellerEntityAddress"
          :error-messages="getValidationError('formData.entityAddress.address')"
        />
      </div>
    </v-col>
    <v-col class="pr-1 mt-4" cols="6">
      <div class="second-input-with-background">
        <c-label required> Entity City </c-label>
        <v-text-field
          autocomplete="off"
          placeholder="Naples"
          v-model="formData.entityAddress.city"
          :error-messages="getValidationError('formData.entityAddress.city')"
        />
      </div>
    </v-col>
    <v-col class="pl-1 mt-4" cols="6">
      <div class="second-input-with-background">
        <c-label required> Entity State </c-label>
        <v-text-field
          autocomplete="off"
          placeholder="Florida"
          v-model="formData.entityAddress.state"
          :error-messages="getValidationError('formData.entityAddress.state')"
        />
      </div>
    </v-col>
    <v-col class="pr-1 mt-4" cols="6">
      <div class="second-input-with-background">
        <c-label required> Entity Zipcode </c-label>
        <v-text-field
          autocomplete="off"
          placeholder="34102"
          v-model="formData.entityAddress.zipCode"
          inputmode="numeric"
          :error-messages="getValidationError('formData.entityAddress.zipCode')"
        />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import Clabel from "@/components/Common/CLabel.vue";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import loader from "@/services/googleApi";
import ValidationFabric from "@/utils/ValidationFabric.js";
import { mapState } from "vuex";
import validateName from "@/utils/validations/name";
import {
  replaceTildeCharacters,
  validateAddress,
} from "@/utils/validations/address";
import validateCity from "@/utils/validations/city";

const ERROR_DICTIONARY = {
  required: "",
  email: "",
  validateName: "",
  validateAddress: "",
  validateCity: "",
};
export default {
  name: "SellerEntityForm",

  directives: {
    mask,
  },
  data: function () {
    return {
      birthdayMenu: false,
      validationFabric: null,

      formData: {
        entityName: "",
        entityAddress: {
          address: null,
          city: null,
          state: null,
          country: "USA",
          zipCode: null,
        },
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      entityName: { validateName },
      entityAddress: {
        address: { validateAddress },
        city: { validateCity },
        state: { required },
        zipCode: { required, minLength: minLength(5), maxLength: maxLength(5) },
      },
    },
  },
  created() {
    this.validationFabric = new ValidationFabric(this.$v, ERROR_DICTIONARY);
    this.initAutocomplete("sellerEntityAddress");
    this.$emit("setSellerEntityForm", this.$v);
  },
  computed: {
    ...mapState("UserSettings", ["userSettings"]),

    isAutoDepositEnabled() {
      return this.userSettings.autoDeposit;
    },
  },
  watch: {
    "formData.entityAddress.address": {
      handler(newVal) {
        this.formData.entityAddress.address = replaceTildeCharacters(newVal);
      },
    },
  },
  methods: {
    preventLetter(e) {
      let charCode = typeof e.which === "undefined" ? e.keyCode : e.which;
      let charStr = String.fromCharCode(charCode);
      if (!charStr.match(/^[0-9-]+$/)) e.preventDefault();
    },
    getValidationError(token) {
      if (!this.validationFabric) {
        return [];
      }

      return this.validationFabric.getFieldError(token, ERROR_DICTIONARY);
    },
    async initAutocomplete(id) {
      this.google = await loader.load();
      const options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["address"],
      };
      this.$nextTick(() => {
        const autocomplete = new this.google.maps.places.Autocomplete(
          document.getElementById(id),
          options
        );
        this.autocomplete = autocomplete;
        autocomplete.addListener("place_changed", this.setPlace);
      });
    },

    setPlace() {
      let place = this.autocomplete.getPlace();
      const administrative_area_level_1 =
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("administrative_area_level_1")
        )[0].long_name;

      const locality =
        place.address_components.filter((el) =>
          el.types.includes("locality")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("locality")
        )[0].long_name;

      const zipCode =
        place.address_components.filter((el) =>
          el.types.includes("postal_code")
        )[0] &&
        place.address_components.filter((el) =>
          el.types.includes("postal_code")
        )[0].long_name;

      this.formData.entityAddress.address = document.getElementById(
        "sellerEntityAddress"
      ).value;
      this.formData.entityAddress.city = locality;
      this.formData.entityAddress.state = administrative_area_level_1;
      this.formData.entityAddress.zipCode = zipCode;
    },
    onDatePickerChange() {
      this.birthdayMenu = false;
    },
  },
  components: {
    "c-label": Clabel,
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .field-wrapper {
    width: 100% !important;
  }
}
</style>
