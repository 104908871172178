<template>
  <div>
    <div class="iframe-wrapper">
      <iframe width="700px" height="700px" :src="VOPAY_EMBEDURL"></iframe>
    </div>
  </div>
</template>
<script>
import app_config from "@/app_config";
import { mapActions } from "vuex";

export default {
  name: "DepositFormVoPay",
  data() {
    return {
      newBank: {
        BankId: "",
        ConnectionType: "",
        Institution: "",
        PaymentMethod: "",
        Step: "",
        Token: "",
        Url: "",
      },
      VOPAY_EMBEDURL: "",
    };
  },
  created() {
    window.addEventListener("message", this.receiveMessage, false);
    console.log("1app_config.VOPAY_EMBEDURL - ", app_config.VOPAY_EMBEDURL);
    this.VOPAY_EMBEDURL = app_config.VOPAY_EMBEDURL;
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage, false);
  },
  methods: {
    ...mapActions({
      addBankingInfo: "addBankingInfo",
    }),
    receiveMessage(event) {
      if (event.origin.includes("vopay")) {
        this.newBank = event.data;

        this.$emit("addBank", this.newBank);
        // this.submitForm();
      }
    },
    async submitForm() {
      if (this.newBank.BankId) {
        const newBankInfo = await this.addBankingInfo({
          token: this.newBank.Token,
          connectionType: this.newBank.ConnectionType,
        });

        this.$emit("addBank", { ...this.newBank, ...newBankInfo });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.iframe-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
