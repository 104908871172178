import { API } from "aws-amplify";
export default () => {
  const query = `query me {
    listDraftAssets {
      nextToken
      assets {
        id
        name
        type
        listingPrice {
          amount
        }
        status
        address {
          address
        }
        contacts {
            firstName
	        lastName
	        officePhone
	        emailAddress
	        companyName
        }
        images
      }
    }
  }`;

  return API.graphql({
    query,
    variables: {},
  });
};
