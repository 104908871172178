import { VALUE_AMOUNT } from "@/services/apiCalls/types/Global/ValueAmount";
import { PERIOD } from "@/services/apiCalls/types/Global/Period";
import { CONTACT } from "@/services/apiCalls/types/Contact/Contact";

export const ES_OFFER_ASSET = `
 id
  title
  principalBalance{
  ${VALUE_AMOUNT}
  }
  bidAllocation{
  ${VALUE_AMOUNT}  
  }
  
  address{
    country
  state
  city
  address
  zipCode
  }
  assetStatus
  callForOffersPeriod{
  ${PERIOD}
  }
  
  bestAndFinalPeriod{
  ${PERIOD}
  }
  
  currentPeriod{
  ${PERIOD}
  }
  
  
  images
  status
  type
  
  noteOverview{
   numberOfLoans
  originationDate
  maturityDate
  estCollateralValue{
  ${VALUE_AMOUNT}  
  }
  
  
  loanAmount{
  ${VALUE_AMOUNT}  
  }
    unpaidPrincipalBalance{
  ${VALUE_AMOUNT}  
  }
  
  totalPayoff{
  ${VALUE_AMOUNT}  
  }
  
  
}
`;
