<template>
  <div>
    <div class="loader2">
      <div class="loader2__content">
        <div class="xl-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.1198 14.6517L3.5035 31.9858L3.27848 32.2203L3.03253 32.0058L0.247821 29.5747L0 29.3584L0.226888 29.1218L16.9441 11.6826L20.1198 14.6517Z"
              fill="#2969FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.01177 3.15724L10.6893 0.551074L10.9251 0.321289L11.1666 0.54637L17.8339 6.76685L14.7905 9.93463L8.01708 3.61536L7.77405 3.38811L8.01177 3.15724Z"
              fill="#DD226F"
            />
            <mask
              id="mask0_1240:25613"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="16"
              y="0"
              width="16"
              height="23"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.586 0.501953H31.471V22.6055H16.586V0.501953Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_1240:25613)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M28.3353 22.6056L16.586 11.8368L27.6684 0.501953L30.9772 3.33398L22.9898 11.7851L31.471 19.6073L28.3353 22.6056Z"
                fill="#FEFEFE"
              />
            </g>
          </svg>
        </div>
        <div class="text">Generating PDF</div>
        <div class="loading-line" :style="`width:${loadingProgress}px`"></div>
      </div>
    </div>
    <div class="loader-wrapper" :style="{ position }"></div>
  </div>
</template>

<script>
export default {
  name: "SecondGlobalLoader",
  data: function () {
    return {
      loadingProgress: 0,
      intervalId: null,
    };
  },
  created() {
    this.loadingProgress = 0;
    this.showLoading();
  },
  beforeDestroy: function () {
    clearInterval(this.intervalId);
  },

  props: {
    position: {
      type: String,
      default: "fixed",
    },
  },
  methods: {
    async showLoading() {
      this.intervalId = setInterval(() => {
        if (this.loadingProgress < 250) {
          this.loadingProgress += 20;
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  z-index: 800;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 17, 84, 0.82);
  opacity: 0.6;
  opacity: 0.6;
}
.xl-icon {
  width: 64px;
  height: 64px;
  background: #001154;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader2 {
  position: fixed;
  z-index: 900;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    display: flex;
    position: relative;
  }
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.444444px;

  /* DarkBlue */
  background-color: white;

  color: #001154;
}
.loading-line {
  display: block;
  content: "";
  width: 124px;
  position: absolute;
  height: 2px;
  bottom: 0px;
  background: #2969ff;
  transition-property: width;
  transition-duration: 3s;
}
</style>
