export const fieldNames = {
  asset: {
    assetAddress: {
      label: "Address",
      placeholder: "1100 5th Ave S, Naples, Florida",
    },
    assetUpb: {
      label: "Unpaid Principal Balance (UPB)",
      placeholder: "$14,200,500",
    },
    assetUpbShort: {
      label: "UPB",
      placeholder: "$14,200,500",
    },
    assetUpbDate: {
      label: "UPB Date",
      placeholder: "03/01/2022",
    },
    assetTotalPayoff: {
      label: "Total Payoff",
      placeholder: "$15,500,100",
    },
    assetListingPrice: {
      label: "Listing Price",
      placeholder: "$15,500,100",
    },
    assetStatus: {
      label: "Asset Status",
      placeholder: "Select",
    },
    assetType: {
      label: "Asset Type",
      placeholder: "Select",
    },
    assetCollateralValue: {
      label: "Est. Collateral Value",
      placeholder: "$22,500,000 ",
    },
    assetCollateralType: {
      label: "Collateral Type",
      placeholder: "Select",
    },
    assetCollateralSubtype: {
      label: "Collateral Subtype",
      placeholder: "Select",
    },
    assetTitle: {
      label: "Asset Title",
      placeholder: "Sunshine Office Complex - NPL",
    },
    assetDescription: {
      label: "Asset Description",
      placeholder: "Describe your asset",
    },
    assetNoteRateFloor: {
      label: "Note Rate Floor",
      placeholder: "1.50%",
    },
    assetDscr: {
      label: "DSCR",
      placeholder: "1.65",
    },
    assetLoansNumber: {
      label: "Number of Loans",
      placeholder: "1",
    },
    assetOriginalLoanAmount: {
      label: "Original Loan Amount",
      placeholder: "$17,500,00",
    },
    assetOriginationDate: {
      label: "Origination Date",
      placeholder: "03/01/2000",
    },
    assetTerm: {
      label: "Term (Years)",
      placeholder: "5",
    },
    assetAmortization: {
      label: "Amortization (Years)",
      placeholder: "20",
    },
    assetCurrentInterestRate: {
      label: "Current Interest Rate",
      placeholder: "4.50%",
    },
    assetInterestType: {
      label: "Interest Type",
      placeholder: "Select",
    },
    assetMaturityDate: {
      label: "Maturity Date",
      placeholder: "03/01/2000",
    },
    assetRecourse: {
      label: "Recourse",
      placeholder: "",
    },
    assetSummaryJudgmentReceived: {
      label: "Summary Judgment Received?",
      placeholder: "",
    },
    assetRecourseGuaranty: {
      label: "Recourse Guaranty?",
      placeholder: "Select",
    },
    assetMonetaryDefault: {
      label: "Monetary Default",
      placeholder: "",
    },
    assetMaturityDefault: {
      label: "Maturity Default",
      placeholder: "",
    },
    assetTechnicalDefault: {
      label: "Technical Default",
      placeholder: "",
    },
    assetPrepayLimitations: {
      label: "Prepay Limitations",
      placeholder: "",
    },
    assetPrepaymentPenalty: {
      label: "Prepayment Penalty",
      placeholder: "Not Applicable",
    },
    assetInitialInterestRate: {
      label: "Initial Interest Rate",
      placeholder: "4.50%",
    },
    assetDefaultInterestRate: {
      label: "Default Interest Rate",
      placeholder: "7.00%",
    },
    assetModified: {
      label: "Modified?",
      placeholder: "",
    },
    assetAssumptionLoanAllowed: {
      label: "Assumption of Loan Allowed",
      placeholder: "",
    },
    assetYearBuilt: {
      label: "Year Built",
      placeholder: "2000",
    },
    assetYearRenovated: {
      label: "Year Renovated",
      placeholder: "2017",
    },
    assetLotSize: {
      label: "Lot Size (SF)",
      placeholder: "850,000",
    },
    assetConstructionType: {
      label: "Improvements",
      placeholder: "Select",
    },
    assetParkingType: {
      label: "Parking Type",
      placeholder: "Select",
    },
    assetClass: {
      label: "Class",
      placeholder: "Select",
    },
    assetBuildingLayout: {
      label: "Building Layout",
      placeholder: "Select",
    },
    assetPropertyCondition: {
      label: "Condition",
      placeholder: "Select",
    },
    assetOpenAtPar: {
      label: "Open at Par (Months)",
      placeholder: "6",
    },
    assetNumberDelinquentPayments: {
      label: "Delinquent Payments",
      placeholder: "Select",
    },
    assetStoriesNumber: {
      label: "Number of Stories",
      placeholder: "20",
    },
    assetBuildingsNumber: {
      label: "Number of Buildings",
      placeholder: "1",
    },
    assetCondo: {
      label: "Condo",
      placeholder: "",
    },
    assetMultiproperty: {
      label: "Multiproperty",
      placeholder: "Select",
    },
    assetNetRentableArea: {
      label: "NRA",
      placeholder: "250,000",
    },
    assetMeasureUnit: {
      label: "Unit of Measure",
      placeholder: "Select",
    },
    assetUnitsNumber: {
      label: "Number of Units/Suites",
      placeholder: "20",
    },
    assetTenantType: {
      label: "Tenant Type",
      placeholder: "Select",
    },
    assetLargestTenantCredit: {
      label: "Largest Tenant Credit",
      placeholder: "Select",
    },
    assetOwnerOccupancy: {
      label: "Owner Occ. %",
      placeholder: "85.5% ",
    },
    assetZoning: {
      label: "Zoning",
      placeholder: "AC-5",
    },
    assetFeeLeasehold: {
      label: "Fee/Leasehold",
      placeholder: "Select",
    },
    assetOwnerOccupied: {
      label: "Owner Occupied?",
      placeholder: "Select",
    },
    assetAssessorsParcel: {
      label: "Assessors Parcel #",
      placeholder: "45-2717-22B",
    },
    assetParkingSpace: {
      label: "Parking space",
      placeholder: "45",
    },
    assetEnvironmentalIssues: {
      label: "Environmental Issues",
      placeholder: "Select",
    },
    assetLienPosition: {
      label: "Lien Position",
      placeholder: "Select",
    },
    assetLastDelinquencyDate: {
      label: "Last Delinquency Date",
      placeholder: "03/01/2000",
    },
    assetadditionalLiens: {
      label: "Additional Liens?",
      placeholder: "",
    },
    assetFairMarketValueDate: {
      label: "Collateral Fair Market Value Date",
      placeholder: "03/01/2000",
    },
    assetFranchiseAgreementExpirationDate: {
      label: "Franchise Agreement Expiration Date",
      placeholder: "03/01/2000",
    },
    assetPipRequirement: {
      label: "PIP Requirement",
      placeholder: "$800,000",
    },
    assetHotelFlagged: {
      label: "Hotel Flagged at FMV Date?",
      placeholder: "",
    },
    hotelFlaggedCurrently: {
      label: "Hotel Flagged Currently?",
      placeholder: "",
    },
    propertyTaxesCurrent: {
      label: "Prop. Taxes Current",
      placeholder: "Select",
    },
  },
  generalProfile: {
    profileFirstName: {
      label: "First Name",
      placeholder: "John",
    },
    profileLastName: {
      label: "Last Name",
      placeholder: "Smith",
    },

    profilePhone: {
      label: "Phone",
      placeholder: "",
    },
    profileAddress: {
      label: "Address",
      placeholder: "132 Bedzel Cir Ste 1401",
    },
    profileCity: {
      label: "City",
      placeholder: "Naples",
    },
    profileState: {
      label: "State",
      placeholder: "Florida",
    },
    profileZipcode: {
      label: "Zipcode",
      placeholder: "34104",
    },
    profileCompanyName: {
      label: "Company Name",
      placeholder: "Xchange,Loans",
    },
    profileJobTitle: {
      label: "Job Title",
      placeholder: "Realtor",
    },
    profileCompanyProfile: {
      label: "Company Profile",
      placeholder: "Select",
    },
    profileAssetsUnderManagement: {
      label: "Assets Under Management (AUM)",
      placeholder: "$16,500,000",
    },
  },
  buyerProfile: {
    buyerEntityName: {
      label: "Entity Name",
      placeholder: "Xchange.Loans, Inc.",
    },
    buyerEntityAddress: {
      label: "Entity Address",
      placeholder: "132 Bedzel Cir Ste 1401",
    },
    buyerEntityCity: {
      label: "Entity City",
      placeholder: "Naples",
    },
    buyerEntityState: {
      label: "Entity State",
      placeholder: "Florida",
    },
    buyerEntityZipcode: {
      label: "Entity Zipcode",
      placeholder: "34104",
    },
    buyerContactFirstName: {
      label: "Contact First Name*",
      placeholder: "John",
    },
    buyerContactLastName: {
      label: "Contact Last Name",
      placeholder: "Doe",
    },
    buyerContactAddress: {
      label: "Contact Address",
      placeholder: "132 Bedzel Cir Ste 1401",
    },
    buyerContactCity: {
      label: "Contact City",
      placeholder: "Naples",
    },
    buyerContactState: {
      label: "Contact State",
      placeholder: "Florida",
    },
    buyerContactZipcode: {
      label: "Contact Zipcode",
      placeholder: "34104",
    },
    buyerAssetStatus: {
      label: "Asset Status",
      placeholder: "Select",
    },
    buyerAssetType: {
      label: "Asset Type",
      placeholder: "Select",
    },
    buyerCollateralType: {
      label: "Collateral Type",
      placeholder: "Select",
    },
    buyerCollateralSubtype: {
      label: "Collateral Subtype",
      placeholder: "Select",
    },
    buyerLocations: {
      label: "Locations",
      placeholder: "Select",
    },
    buyerLienPositions: {
      label: "Lien Positions",
      placeholder: "Select",
    },
    buyerMinUpb: {
      label: "Min Unpaid Principal Balance",
      placeholder: "$0",
    },
    buyerMaxUpb: {
      label: "Max Unpaid Principal Balance",
      placeholder: "$0",
    },
  },
  sellerProfile: {
    sellerEntityName: {
      label: "Entity Name",
      placeholder: "Xchange.Loans, Inc.",
    },
    sellerEntityAddress: {
      label: "Entity Address",
      placeholder: "132 Bedzel Cir Ste 1401",
    },
    sellerEntityCity: {
      label: "Entity City",
      placeholder: "Naples",
    },
    sellerEntityState: {
      label: "Entity State",
      placeholder: "Florida",
    },
    sellerEntityZipcode: {
      label: "Entity Zipcode",
      placeholder: "34104",
    },
    sellerSignedBy: {
      label: "Signed by",
      placeholder: "Deborah",
    },
    sellerTitle: {
      label: "Title",
      placeholder: "Senior Vice President - Finance",
    },
    sellerPhoneNumber: {
      label: "Phone Number",
      placeholder: "(800) 913-8139",
    },
    sellerPrincipalBroker: {
      label: "Principal / Broker",
      placeholder: "",
    },
    assetTitle: {
      label: "Asset title",
      placeholder: "Sunshine Office Complex - NPL",
    },
  },
};
