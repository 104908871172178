import appConfig from "@/./app_config.js";

let env;

if (location.href.includes("https://marketplace.stage.xchange.loans")) {
  env = "STAGING";
} else if (
  location.href.includes("localhost") ||
  location.href.includes("https://marketplace.dev.xchange.loans")
) {
  env = "DEV";
} else if (location.href.includes("https://marketplace.test.xchange.loans")) {
  env = "TEST";
} else {
  env = "PROD";
}

export default appConfig[`GTM_${env}`];
