import { API } from "aws-amplify";
export default (variables) => {
  const query = `mutation changeAssetPaymentStatus($id: String! $pricingPlan:PricingPlan)
   {
        editAsset( id: $id pricingPlan:$pricingPlan) 
          {
            id
            pricingPlan
          }
  }`;
  return API.graphql({
    query,
    variables,
  });
};
