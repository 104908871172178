import { VALUE_AMOUNT } from "../Global/ValueAmount";

export const OFFER_ASSET = `
id
title
status
principalBalance {
    ${VALUE_AMOUNT}
}
bidAllocation{
    ${VALUE_AMOUNT}
}

`;
