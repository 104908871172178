import { API, graphqlOperation } from "aws-amplify";
import { ES_ASSET } from "@/services/apiCalls/types/Asset/ESAsset";

export default function subscribeToNoticeESAssetsUpdate() {
  const query = `subscription subscribeToNoticeESAssetsUpdate {
    subscribeToNoticeESAssetsUpdate {
            ${ES_ASSET}
        }
    }`;

  return API.graphql(graphqlOperation(query));
}
