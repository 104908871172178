// import { client } from '../amplify_config'
// import { client } from '../amplify_config'
// import gql from 'graphql-tag'
import { API } from "aws-amplify";
import revokeAsset from "./mutations/revokeAsset";
import createAsset from "./mutations/createAsset";
import editAsset from "./mutations/editAsset";
import getAsset from "./queries/getAssetById";
import getAssetMin from "./queries/getAssetMin";
import getAssetWhitelists from "./queries/getAssetWhitelists";
import listAllUserAssets from "./queries/listAllAssetsByUser";
import getAssetOverviewData from "./queries/getAssetOverviewData";
import searchContacts from "./queries/searchContacts";
import listDraftAssets from "./queries/listDraftAssets";
import searchAssetsSeller from "./queries/searchAssetsSeller";
import createPreAsset from "./mutations/createPreAsset";
import editPreAsset from "./mutations/editPreAsset";
import requestAssetPublish from "./requestAssetPublish";
import getUserDocument from "./queries/getUserDocument";
import getAssetPrincipalBalance from "./queries/getAssetPrincipalBalance";
import searchAssets from "./queries/searchAssets";
import countAssetsByField from "./queries/countAssetsByField";
import getUserFavoriteAssets from "./queries/getUserFavoriteAssets";
import getUserFavoriteAssetsIds from "./queries/getUserFavoriteAssetsIds";
import addUserFavoriteAsset from "./mutations/addUserFavoriteAsset";
import removeUserFavoriteAsset from "./mutations/removeUserFavoriteAsset";
import sendAssetMessage from "./mutations/sendAssetMessage";
import sendAdminMessage from "./mutations/sendAdminMessage";
import editAssetOnNext from "./mutations/editAssetOnNext";
import editAssetWithoutNoteOverview from "./mutations/editAssetWithoutNoteOverview";
import adminAssetDetails from "./queries/adminAssetDetails";
import discardAsset from "./mutations/discardAsset";
import sendAnonMessage from "./mutations/sendAnonMessage";
import getUserAssetData from "./queries/getUserAssetData";
import editAssetAssetInfo from "@/services/apiCalls/asset/mutations/editAssetAssetInfo";
import editAssetNoteOverview from "@/services/apiCalls/asset/mutations/editAssetNoteOverview";
import editAssetPropertyDetails from "@/services/apiCalls/asset/mutations/editAssetPropertyDetails";
import editAssetPicture from "@/services/apiCalls/asset/mutations/editAssetPicture";
import editAssetDueDiligence from "@/services/apiCalls/asset/mutations/editAssetDueDiligence";
import editAssetLegal from "@/services/apiCalls/asset/mutations/editAssetLegal";
import editAssetAdvisors from "@/services/apiCalls/asset/mutations/editAssetAdvisors";
import getAssetData from "./queries/getAssetData";
import changeAssetPaymentPlan from "@/services/apiCalls/asset/mutations/changeAssetPaymentPlan";
import getUserDocument_SidePanel from "@/services/apiCalls/asset/queries/getUserDocument_SidePanel";

export default {
  revokeAsset,
  editAssetAssetInfo,
  editAssetNoteOverview,
  editAssetPropertyDetails,
  editAssetPicture,
  editAssetDueDiligence,
  editAssetLegal,
  editAssetAdvisors,
  getUserAssetData,
  createAsset,
  createPreAsset,
  editAsset,
  getAssetOverviewData,
  adminAssetDetails,
  getAsset,
  getAssetMin,
  getAssetWhitelists,
  getUserDocument,
  getUserDocument_SidePanel,
  listAllUserAssets,
  searchContacts,
  listDraftAssets,
  requestAssetPublish,
  editPreAsset,
  getAssetPrincipalBalance,
  searchAssets,
  countAssetsByField,
  getUserFavoriteAssets,
  getUserFavoriteAssetsIds,
  addUserFavoriteAsset,
  removeUserFavoriteAsset,
  searchAssetsSeller,
  editAssetOnNext,
  sendAssetMessage,
  editAssetWithoutNoteOverview,
  discardAsset,
  sendAdminMessage,
  sendAnonMessage,
  getAssetData,
  changeAssetPaymentPlan,
  listAssets() {
    const query = `query listAssets($limit: Int, $filter: AssetFilter, $nextToken: String , $sortBy: ESSortingBy , $orderBy: OrderByChoices) {
            listAssets(limit: $limit ,filter: $filter, nextToken: $nextToken , sortBy:$sortBy , orderBy :$orderBy) {
                nextToken
                assets {
                    id
                    name
                    status
                    type
                    listingPrice {
                        amount
                        currency
                    }
                    seller
                    status
                    type
                    address {
                        country
                        state
                        city
                        address
                        zipCode
                    }
                    images
                    dateModified
                    pricingPlan
                    bestCashOffer
                }
            }
        }`;
    return API.graphql({
      query: query,
      authMode: "AWS_IAM",
      variables: {
        limit: 300,
      },
    });
  },
};
